import { api } from '../api'
import {
  Announcement,
  Component,
  FAQ,
  Notification,
  Poll,
  PollVote,
  QAQuestion,
  User,
  WebListItem,
} from '../types'

export const componentService = {
  fetchComponents: async (roomId: string) =>
    api.value<{ component: Component[] }>({
      request: {
        method: 'GET',
        endPoint: '/components',
        parameters: { room_id: roomId },
      },
    }),

  fetchComponent: async (componentId: string) =>
    api.prop<{ component: Component }>('component', {
      request: {
        method: 'GET',
        endPoint: '/component',
        parameters: { component_id: componentId },
      },
    }),

  fetchFAQs: async (componentId: string) =>
    api.prop<{ faqs: FAQ[] }>('faqs', {
      request: {
        method: 'GET',
        endPoint: '/faqs',
        parameters: { component_id: componentId },
      },
    }),

  fetchAnnouncements: async (componentId: string) =>
    api.prop<{ announcements: Announcement[] }>('announcements', {
      request: {
        method: 'GET',
        endPoint: '/announcements',
        parameters: { component_id: componentId },
      },
    }),

  fetchPolls: async (componentId: string) =>
    api.value<{ polls: Poll[]; votes: PollVote[] }>({
      request: {
        method: 'GET',
        endPoint: '/polling/polls/votes',
        parameters: { component_id: componentId },
      },
    }),

  votePoll: async (pollId: string, optionId: string) =>
    api.value<{ poll: Poll; vote: PollVote }>({
      request: {
        method: 'PUT',
        endPoint: '/polling/poll/vote',
        parameters: { poll_id: pollId, option_id: optionId },
      },
    }),

  fetchWebListItems: async (componentId: string) =>
    api.prop<{ web_list_items: WebListItem[] }>('web_list_items', {
      request: {
        method: 'GET',
        endPoint: '/webListItems',
        parameters: { component_id: componentId },
      },
    }),

  fetchQAQuestions: async (componentId: string) =>
    api.value<{ qa_questions: QAQuestion[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/qa/questions',
        parameters: { component_id: componentId },
      },
    }),

  askQAQuestion: async (componentId: string, question: string, isAnonymous: boolean) =>
    api.prop<{ qa_question: QAQuestion }>('qa_question', {
      request: {
        method: 'POST',
        endPoint: '/qa/question',
        parameters: { component_id: componentId, question, is_anonymous: isAnonymous },
      },
    }),

  deleteQAQuestion: async (qaQuestionId: string) =>
    api.prop<{ qa_question: QAQuestion }>('qa_question', {
      request: {
        method: 'DELETE',
        endPoint: '/qa/question',
        parameters: { qa_question_id: qaQuestionId },
      },
    }),

  fetchNotifications: async (roomId: string, offset: number, limit: number) =>
    api.prop<{ notifications: Notification[] }>('notifications', {
      request: {
        method: 'GET',
        endPoint: '/notification',
        parameters: { room_id: roomId, offset, limit },
      },
    }),
}
