import { api } from '../api'
import { Invitation, InvitationRequest } from '../types'

export const invitationService = {
  fetchInvitation: async (invitationId: string) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'GET',
        endPoint: '/audience/invitation/single',
        parameters: { invitation_id: invitationId },
      },
    }),

  createInvitation: async (request: Partial<Invitation>) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'POST',
        endPoint: '/audience/invitation',
        parameters: { ...request },
      },
    }),

  requestInvitation: async (request: Partial<InvitationRequest>) =>
    api.prop<{ invitation_request: InvitationRequest }>('invitation_request', {
      request: {
        method: 'POST',
        endPoint: '/audience/invitation/request',
        parameters: { ...request },
      },
    }),
}
