import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M9 5L9 12L5.48284 12C5.30466 12 5.21543 12.2154 5.34142 12.3414L12 19L18.6586 12.3414C18.7846 12.2154 18.6953 12 18.5172 12L15 12L15 5"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
)

export const DownVoteIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
