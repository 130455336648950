import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M14 12L14.3536 11.6464L14.7071 12L14.3536 12.3536L14 12ZM4 12.5C3.72386 12.5 3.5 12.2761 3.5 12C3.5 11.7239 3.72386 11.5 4 11.5V12.5ZM8.35355 5.64645L14.3536 11.6464L13.6464 12.3536L7.64645 6.35355L8.35355 5.64645ZM14.3536 12.3536L8.35355 18.3536L7.64645 17.6464L13.6464 11.6464L14.3536 12.3536ZM14 12.5H4V11.5H14V12.5Z"
      fill={fill}
    />
    <path d="M20 5L20 19" stroke={fill} />
  </svg>
)

export const MoveRightIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
