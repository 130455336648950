import React from 'react'
import { useSelect } from 'src/utils/hooks/useSelect'
import { Col, FlexRow, LabelMedium } from 'src/features/common/CommonStyles'
import { getEntities } from 'src/redux/reducers/entity'
import { Component, Room } from 'src/repository/types'
import { componentImageUrl } from 'src/utils/helpers/componentHelper'
import styled, { css } from 'styled-components'
import { device } from 'src/utils/hooks/useWindowDimensions'
import { orderBy } from 'lodash'

export const RoomCoverFeatures: React.FC<{
  room: Room
  onFeatureClick: (component: Component) => void
}> = ({ room, onFeatureClick }) => {
  // MARK: - Hooks

  const components = useSelect(state => {
    const rawComponents = getEntities<Component>(
      state.entity,
      'component',
      ({ room_id }) => room_id === room.id,
    )

    return orderBy(rawComponents, 'created_at', 'asc')
  })

  // MARK: - Render

  return (
    <FlexRow style={{ position: 'relative', marginLeft: -20, marginRight: -20 }}>
      <Container>
        {components.map(component => (
          <RoomCoverFeatureItem
            key={component.id}
            component={component}
            onFeatureClick={() => onFeatureClick(component)}
          />
        ))}
      </Container>
    </FlexRow>
  )
}

const RoomCoverFeatureItem: React.FC<{ component: Component; onFeatureClick: () => void }> = ({
  component,
  onFeatureClick,
}) => (
  <ItemContainer onClick={onFeatureClick}>
    <img
      style={{ marginBottom: 12, width: 42, height: 42 }}
      src={component.image_url || componentImageUrl(component.type)}
      alt=""
    />
    <LabelMedium style={{ maxWidth: 130, textAlign: 'center' }}>{component.title}</LabelMedium>
  </ItemContainer>
)

// MARK: - Styles

const Container = styled(FlexRow)`
  flex-wrap: wrap;
  margin-top: -10px;
  max-width: 100%;
  overflow: scroll;
  padding: 0px 12px;
`

const ItemContainer = styled(Col)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};
  border-radius: 12px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 150px;
  justify-content: flex-start;
  margin: 10px 8px 10px 8px;
  min-width: 150px;
  overflow: hidden;
  padding-top: 30px;
  position: relative;

  @media ${device.smallerThan.tablet} {
    width: calc(50% - 16px);
  }

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.primary : 'transparent')};
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
      }
    `}
`
