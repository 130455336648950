import { orderBy, sortBy } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchChatMessage } from 'src/redux/reducers/chat'
import { deleteEntities, getEntities } from 'src/redux/reducers/entity'
import { getMeId } from 'src/redux/reducers/me'
import { Priority, realtime } from 'src/repository/realtime'
import { ChatMessage } from 'src/repository/types'
import { maxPlusNone } from 'src/utils/helpers/arrayHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import { assignGroupsToMessages } from '../helpers/chatHelper'

export const useChatMessageObserver = (userId: string) => {
  // MARK: - Hooks

  const dispatch = useDispatch()

  const meUserId = useSelect(state => getMeId(state.me)!)
  const chatId = sortBy([userId, meUserId], id => id).join('-')
  const path = `chat/${chatId}/${meUserId}`

  const messages = useSelect(state => {
    const rawMessages = orderBy(
      getEntities<ChatMessage>(state.entity, 'message', ({ chat_id }) => chat_id === chatId),
      ['created_at'],
      ['asc'],
    )
    return assignGroupsToMessages(rawMessages)
  })

  // MARK: - Effects

  useEffect(() => {
    realtime.observe(
      path,
      maxPlusNone(messages, 'created_at'),
      { priority: Priority.normal, types: ['child_added'] },
      ({ action, id }) => {
        if (action === 1) dispatch(fetchChatMessage(chatId, id))
        else if (action === 2) dispatch(deleteEntities({ message: [id] }))
      },
    )

    return () => {
      realtime.dispose(path)
    }
  }, [path])

  // MARK: - Render

  return messages
}
