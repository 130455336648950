import classNames from 'classnames'
import React from 'react'
import { CloseIcon } from 'src/assets/images/svg/CloseIcon'
import { FlexCol, LabelHeader } from 'src/features/common/CommonStyles'
import styled, { useTheme } from 'styled-components'
import useWindowDimensions, { device } from '../hooks/useWindowDimensions'

export const ModalWrapper: React.FC<{
  style?: React.CSSProperties
  size: 'large' | 'medium' | 'small' | 'tiny'
  width?: number
  title?: string
  height?: 'dynamic' | 'fixed'
  closeOnBackgroundClick?: boolean
  disableClose?: boolean
  onClose?: () => void
}> = ({
  size,
  style,
  title,
  width: forcedWidth,
  height = 'fixed',
  closeOnBackgroundClick = false,
  disableClose = false,
  onClose,
  children,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { width } = useWindowDimensions()
  const getTargetWidth = (modalType: 'large' | 'medium' | 'small' | 'tiny') => {
    return { large: 1500, medium: 1180, small: 900, tiny: 720 }[modalType]
  }

  // MARK: - Render

  return (
    <div
      className={classNames('modal', 'is-active')}
      onClick={event => {
        event.stopPropagation()
        if (closeOnBackgroundClick) onClose?.()
      }}>
      <Container
        width={width}
        height={height}
        style={style}
        size={forcedWidth ?? getTargetWidth(size)}
        onClick={event => event.stopPropagation()}
        className="modal-inner br-8">
        {title ? <Title>{title}</Title> : null}

        <InnerContainer id={'modal'}>{children}</InnerContainer>

        {!disableClose && (
          <CloseButton onClick={onClose}>
            <CloseIcon size={20} fill={palette.text.secondary} style={{ opacity: 1 }} />
          </CloseButton>
        )}
      </Container>
    </div>
  )
}

// MARK: - Styles

const Title = styled(LabelHeader)`
  height: 54px;
  padding: 20px 24px 12px 24px;
  width: 100%;

  @media ${device.smallerThan.laptop} {
    padding: 20px 12px 12px 12px;
  }
`

const InnerContainer = styled(FlexCol)`
  flex: 1;
  max-height: calc(94vh - 54px);
  overflow: scroll;
  padding: 8px 24px 120px 24px;
  width: 100%;

  @media ${device.smallerThan.laptop} {
    padding: 8px 12px 120px 12px;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
  height: 48px;
  padding: 8px;
  position: absolute;
  right: 4px;
  top: 14px;
  width: 48px;

  @media ${device.smallerThan.laptop} {
    right: -6px;
  }
`

const Container = styled(FlexCol)<{
  width: number
  size: number
  height: 'dynamic' | 'fixed'
}>`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border: 1px solid;
  border-color: ${({ theme }) => (theme.isDark ? theme.palette.background.separator : '#1a1a1a')};
  height: ${({ height }) => (height === 'fixed' ? '94vh' : 'auto')};
  min-width: ${({ width, size }) => (width < size ? '95%' : `${size}px`)};
  overflow: hidden;
  position: relative;
  z-index: 10;

  @media ${device.smallerThan.laptop} {
    padding: 0px;
    min-width: 96%;
  }
`
