import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import {
  FlexCol,
  FlexRow,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  Separator,
} from 'src/features/common/CommonStyles'
import { Loader } from 'src/features/common/Loader'
import { getLoading } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { Component, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RoomHomeComponents: React.FC<{
  room: Room
  onComponentClick: (component: Component) => void
}> = ({ room, onComponentClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()

  const isLoading = useSelect(state => getLoading(state.app, 'components'))
  const components = useSelect(state =>
    getEntities<Component>(state.entity, 'component', component => component.room_id === room.id),
  )

  // MARK: - Handlers

  const handleComponentClick = useCallback(
    (component: Component) => () => {
      onComponentClick(component)
    },
    [],
  )

  // MARK: - Render

  if (isMobile && isLoading) {
    return (
      <FlexCol>
        <LabelLarge style={{ marginTop: 16 }}>{t('features')}</LabelLarge>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
        <Separator
          margin={0}
          style={{ opacity: 0.5, marginTop: 12, width: 'auto', marginLeft: -20, marginRight: -20 }}
        />
      </FlexCol>
    )
  }

  if (!isMobile || components.length === 0) return null

  return (
    <FlexCol>
      <LabelLarge style={{ marginTop: 16 }}>{t('features')}</LabelLarge>
      {components.map(component => (
        <RoomHomeComponentItem
          key={component.id}
          component={component}
          onComponentClick={handleComponentClick(component)}
        />
      ))}
      <Separator
        margin={0}
        style={{ opacity: 0.5, marginTop: 12, width: 'auto', marginLeft: -20, marginRight: -20 }}
      />
    </FlexCol>
  )
}

const RoomHomeComponentItem: React.FC<{
  component: Component
  onComponentClick: () => void
}> = ({ component, onComponentClick }) => (
  <FlexRow
    style={{ alignItems: 'center', paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}
    onClick={onComponentClick}>
    <FlexRow style={{ alignItems: 'center' }}>
      <img style={{ width: 24, height: 24, marginRight: 0 }} src={component.image_url} alt="" />
      <FlexCol style={{ marginLeft: 12, flex: 1 }}>
        <LabelMedium>{component.title}</LabelMedium>
        <LabelSmall style={{ marginTop: 2 }}>{component.overview}</LabelSmall>
      </FlexCol>
    </FlexRow>

    <RightArrowIcon style={{ height: 12, width: 8, marginLeft: 8 }} />
  </FlexRow>
)

// MARK: - Styles

const LoaderContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
`
