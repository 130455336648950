import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelect } from 'src/utils/hooks/useSelect'
import { Col, FadeIn, LabelSmall } from 'src/features/common/CommonStyles'
import { getEntity } from 'src/redux/reducers/entity'
import { User } from 'src/repository/types'
import config from 'src/utils/config'
import styled, { css } from 'styled-components'
import { updateMeUser } from 'src/redux/reducers/profile'
import { ProfileChangeCoverModal } from './ProfileChangeCoverModal'

type Props = {
  userId: string
  editEnabled: boolean
}

export const ProfileCoverImage: React.FC<Props> = ({ userId, editEnabled }) => {
  // MARK: - Hooks

  const containerHeight = 280
  const defaultOffset = 0
  const imageRef = useRef<HTMLImageElement | null>(null)
  const [imageHeight, setImageHeight] = useState(0)
  const origin = useRef<number>(0)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cover = React.useRef<HTMLDivElement | null>(null)
  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId))

  const [dragEnabled, setDragEnabled] = useState(false)
  const [isTarget, setIsTarget] = useState(false)
  const [originalOffset, setOriginalOffset] = useState(user?.cover_image_offset ?? defaultOffset)
  const [offset, setOffset] = useState(originalOffset)
  const [showChangeCoverModal, setShowChangeCoverModal] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    // Remove ghost image on drag
    // Please see: https://stackoverflow.com/questions/38655964/how-to-remove-dragghost-image
    document.addEventListener(
      'dragstart',
      event => {
        const img = new Image()
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='
        event.dataTransfer?.setDragImage(img, 0, 0)
      },
      false,
    )

    document.addEventListener('click', handleRepositionCloseClick)
    return () => {
      document.removeEventListener('click', handleRepositionCloseClick)
    }
  })

  // MARK: - Actions

  const handleRepositionCloseClick = (event: MouseEvent | any) => {
    if (!event.target?.closest(`.${cover.current?.className}`)) {
      event.stopPropagation()
      if (dragEnabled) {
        dispatch(updateMeUser({ cover_image_offset: offset }))
        setDragEnabled(false)
      }
    }
  }

  const handleDragStart = (point: number, targetOffset: number) => {
    if (dragEnabled && point) origin.current = point - targetOffset
  }

  const handleDrag = (point: number, targetOffset: number) => {
    if (dragEnabled && point) {
      const pointOffset = point - targetOffset

      let translation = originalOffset * imageHeight + pointOffset - origin.current
      translation = Math.min(0, translation)
      translation = Math.max(containerHeight - imageHeight, translation)
      setOffset(translation / imageHeight)
    }
  }

  const handleCancelReposition = () => {
    setOffset(user?.cover_image_offset ?? defaultOffset)
    setOriginalOffset(user?.cover_image_offset ?? defaultOffset)
    setDragEnabled(false)
  }

  const handleSaveReposition = () => {
    dispatch(updateMeUser({ cover_image_offset: offset }))
    setDragEnabled(false)
  }

  const handleImageSelect = (image: string) => {
    dispatch(updateMeUser({ cover_image_offset: 0, cover_image: image }))
    setOffset(0)
    setOriginalOffset(0)
  }

  const handleImageRemove = () => {
    dispatch(updateMeUser({ cover_image_offset: 0, cover_image: '' }))
  }

  // MARK: - Render

  return (
    <Container
      ref={cover}
      onClick={event => !showChangeCoverModal && event.stopPropagation()}
      onPointerEnter={() => setIsTarget(true)}
      onPointerLeave={() => setIsTarget(false)}
      onDragStart={event => handleDragStart(event.clientY, event.currentTarget.offsetTop)}
      onDragEnd={() => setOriginalOffset(offset)}
      onDrag={event => handleDrag(event.clientY, event.currentTarget.offsetTop)}
      dragEnabled={dragEnabled}>
      <ImageContainer>
        <img
          style={{
            objectFit: 'cover',
            position: 'absolute',
            top: offset * imageHeight,
            width: '100%',
            userSelect: 'none',
          }}
          ref={imageRef}
          onLoad={event => setImageHeight((event.target as any).offsetHeight)}
          src={user?.cover_image || config.defaultCover}
          alt={config.defaultCover}
        />
      </ImageContainer>

      {editEnabled && dragEnabled && (
        <DragInstruction style={{ pointerEvents: 'none' }}>
          {t('dragImageToReposition')}
        </DragInstruction>
      )}

      {editEnabled && dragEnabled && (
        <ButtonContainer style={{ zIndex: 2 }}>
          <Button onClick={handleSaveReposition}>{t('savePosition')}</Button>
          <Button onClick={handleCancelReposition}>{t('cancel')}</Button>
        </ButtonContainer>
      )}

      {!showChangeCoverModal &&
        editEnabled &&
        (!dragEnabled && isTarget ? (
          <ButtonContainer>
            <Button onClick={() => setShowChangeCoverModal(true)}>{t('changeCover')}</Button>
            <Button onClick={() => setDragEnabled(true)}>{t('reposition')}</Button>
          </ButtonContainer>
        ) : null)}

      {showChangeCoverModal && (
        <ProfileChangeCoverModal
          onImageSelect={handleImageSelect}
          onImageRemove={handleImageRemove}
          onClose={() => setShowChangeCoverModal(false)}
        />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)<{ dragEnabled: boolean }>`
  cursor: ${({ dragEnabled }) => (dragEnabled ? 'move' : 'default')};
  height: 280px;
  position: relative;
  user-select: none;
  width: 100%;
`

const ImageContainer = styled.div`
  height: 100%;
  min-width: 1080px;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 100%;
`

const ButtonContainer = styled(FadeIn)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 6px;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  right: 15%;
  user-select: none;
`

const Button = styled(LabelSmall)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  padding: 6px 18px;
  user-select: none;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.tertiary};
      }
    `}
`

const DragInstruction = styled(LabelSmall)`
  background-color: ${({ theme }) => theme.palette.inverted.background.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.text.primary};
  left: 50%;
  padding: 6px 20px;
  position: absolute;
  top: 45%;
  transform: translate(-50%, 0);
  user-select: none;
`
