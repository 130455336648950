import styled from 'styled-components'

const Label = styled.div<{
  size?: number
  align?: 'left' | 'right' | 'center'
  color?: string
}>`
  color: ${({ theme, color }) => color ?? theme.palette.text.primary};
  font-size: ${({ size }) => size || 18}px;
  letter-spacing: 0.2px;
  text-align: ${({ align }) => align || 'left'};
`

export const LabelExtraBold = styled(Label)`
  font-weight: 800;
`

export const LabelBold = styled(Label)`
  font-weight: 600;
`

export const LabelSemiBold = styled(Label)<{ size?: number }>`
  font-weight: 500;
`

export const LabelRegular = styled(Label)<{ size?: number }>`
  font-weight: 400;
`

export const LabelLight = styled(Label)<{ size?: number }>`
  font-weight: 300;
`
