import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
// @ts-ignore
import { enUS, tr } from 'react-date-range/src/locale/index'
import { useTranslation } from 'react-i18next'
import { useSelect } from 'src/utils/hooks/useSelect'
import TimeKeeper from 'react-timekeeper'
import { Input, InputForwardRef } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { getLocalization } from 'src/redux/reducers/app'
import { format24, formatAMPM } from 'src/utils/helpers/dateHelper'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'
import { Select } from './Select'
import { SingleDatePicker } from './SingleDatePicker'

type Props = {
  style?: React.CSSProperties
  date: Date
  visible?: boolean
  title?: string
  forceHide?: boolean
  isOptional?: boolean
  isRequired?: boolean
  disabled?: boolean
  hint?: string
  onPickerOpen?: () => void
  onPickerClose?: () => void
  onClearPress?: () => void
  onDateChange: (timestamp: number) => void
}

export const DateTimePicker: React.FC<Props> = ({
  style,
  title,
  visible: isVisible = false,
  date: rawDate,
  forceHide = false,
  isOptional = false,
  isRequired = false,
  disabled = false,
  hint = undefined,
  onDateChange,
  onPickerOpen,
  onPickerClose,
  onClearPress,
}) => {
  // MARK: - Hooks

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { isDark, palette } = useTheme()
  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()
  const localization = useSelect(state => getLocalization(state.app))
  const [visible, setVisible] = useState(isVisible)

  // Manage TimeZone
  const [timeZone, setTimeZone] = useState(moment.tz.guess())
  const currentTimeZoneOffset =
    (moment.tz.zone(moment.tz.guess())?.utcOffset(rawDate.getTime()) ?? 0) * 60
  const selectedTimeZoneOffset = (moment.tz.zone(timeZone)?.utcOffset(rawDate.getTime()) ?? 0) * 60
  const timeZoneOffset = selectedTimeZoneOffset - currentTimeZoneOffset
  const date = new Date(rawDate.getTime() - timeZoneOffset * 1000)

  // Format Date
  const { year, month, day, hour, minute } = {
    minute: date.getMinutes(),
    hour: date.getHours(),
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  }
  const formattedValue = () => {
    const pad = (value: number) => value.toString().padStart(2, '0')
    return `${year}-${pad(month)}-${pad(day)}T${pad(hour)}:${pad(minute)}`
  }
  const humanizedValue = () => {
    const timeString = ', ' + format24(date) + ` - ${timeZone}`
    const dateString = date.toLocaleDateString(localization, {
      month: 'long',
      year: 'numeric',
      day: '2-digit',
    })
    return date < new Date(1000) ? '' : dateString + timeString
  }

  // MARK: - Effects

  useEffect(() => {
    if (visible) onPickerOpen?.()
    else onPickerClose?.()
  }, [visible])

  useEffect(() => {
    if (forceHide) setVisible(false)
  }, [forceHide])

  // MARK: - Render

  if (isMobile) {
    return (
      <Input
        style={{ position: 'relative', ...style }}
        isRequired={isRequired}
        disabled={disabled}
        label={title}
        titleHint={hint}
        // @ts-ignore
        tabIndex={-1}
        value={humanizedValue()}
        onChange={text => {}}
        onFocus={() => {
          setVisible(true)
          inputRef.current?.focus()
        }}>
        <InputForwardRef
          ref={inputRef}
          size="sm"
          style={{
            opacity: 0,
            top: 24,
            right: 0,
            left: 0,
            position: 'absolute',
            zIndex: -10,
          }}
          disabled={disabled}
          // @ts-ignore
          tabIndex={-1}
          type={'datetime-local'}
          titleHint={hint}
          value={formattedValue()}
          onChange={text => {
            const localized = moment.tz(text, timeZone)
            onDateChange(localized.toDate().getTime() / 1000)
          }}
        />
      </Input>
    )
  }

  return (
    <Input
      style={{ position: 'relative', ...style }}
      isRequired={isRequired}
      disabled={disabled}
      label={title}
      titleHint={hint}
      value={humanizedValue()}
      onChange={text => {}}
      onFocus={() => setVisible(true)}>
      <InnerContainer visible={visible && !forceHide}>
        <PickerContainer>
          <DatePickerContainer>
            <SingleDatePicker
              weekStartsOn={1}
              date={date}
              minDate={new Date()}
              locale={localization === 'tr' ? tr : enUS}
              onChange={newDate => {
                const currenDate = date
                currenDate.setDate(newDate.getDate())
                currenDate.setMonth(newDate.getMonth())
                currenDate.setFullYear(newDate.getFullYear())
                onDateChange(currenDate.getTime() / 1000 + timeZoneOffset)
              }}
              color={palette.primary}
            />
            <TimeZoneContainer>
              <Select value={timeZone} onChange={value => setTimeZone(value)}>
                {moment.tz.names().map(zone => (
                  <option key={zone} value={zone} label={zone} />
                ))}
              </Select>
            </TimeZoneContainer>
          </DatePickerContainer>

          <TimePickerContainer className={isDark ? 'time-picker' : 'time-picker-light'}>
            <TimeKeeper
              time={formatAMPM(date)}
              hour24Mode
              switchToMinuteOnHourDropdownSelect
              switchToMinuteOnHourSelect
              onChange={data => {
                const currenDate = date
                currenDate.setHours(data.hour)
                currenDate.setMinutes(data.minute)
                onDateChange(currenDate.getTime() / 1000 + timeZoneOffset)
              }}
            />
          </TimePickerContainer>
        </PickerContainer>

        <ButtonContainer>
          {onClearPress && (
            <SecondaryButton
              title={t('clear')}
              style={{ width: 125, marginTop: -2, marginRight: 10 }}
              onClick={onClearPress}
            />
          )}
          <PrimaryButton
            title={t('done')}
            style={{ width: onClearPress ? 125 : 260, marginTop: -2 }}
            onClick={() => setVisible(false)}
          />
        </ButtonContainer>
      </InnerContainer>
    </Input>
  )
}

// MARK: - Styles

const InnerContainer = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  margin-top: 6px;
  position: absolute;
  z-index: 100;
`

const PickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 312px;
  max-height: 340px;
`

const TimeZoneContainer = styled.div`
  left: 10px;
  position: absolute;
  right: 10px;
  top: 32px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  width: 101%;
`

const DatePickerContainer = styled.div`
  flex-basis: 60%;
  margin-top: -22px;
  max-height: 336px;
  position: relative;
`

const TimePickerContainer = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.background.separator};
  border-radius: 8px;
  margin-left: 1%;
  max-height: 340px;
  min-width: 260px;
  overflow: hidden;
`
