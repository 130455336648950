import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, FlexCol, LabelMedium, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { EmptyState } from 'src/features/common/EmptyState'
import { Loader } from 'src/features/common/Loader'
import { UserImage } from 'src/features/common/UserImage'
import { getEntity } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { fetchUserFriends, getIsLoading, getUserFriends } from 'src/redux/reducers/profile'
import { Friendship, User } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css } from 'styled-components'

export const ProfileFriendsList: React.FC<{
  userId: string
}> = ({ userId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId))
  const friendships = useSelect(state => getUserFriends(state.profile, userId))
  const isLoading = useSelect(state => getIsLoading(state.profile, 'friends'))

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchUserFriends(userId, 10, 0))
  }, [userId])

  // MARK: - Render

  return (
    <Container>
      {isLoading && !friendships.length && <Loader style={{ marginTop: 150, marginLeft: '48%' }} />}

      {friendships.map(friendship => (
        <ProfileFriendsListItem key={friendship.id} userId={userId} friendship={friendship} />
      ))}

      {!isLoading && !friendships.length && (
        <EmptyState
          title={t('noFriends', { name: user?.first_name })}
          subtitle={t('noFriendsDescription', { name: user?.first_name })}
        />
      )}
    </Container>
  )
}

const ProfileFriendsListItem: React.FC<{
  userId: string
  friendship: Friendship
}> = ({ userId, friendship }) => {
  // MARK: - Hooks

  const { source_user_id, target_user_id } = friendship

  const { t } = useTranslation()
  const { navigate, routes } = useNavigation()
  const meUserId = useSelect(state => getMeUser(state.me)?.id)

  const friendUserId = source_user_id === userId ? target_user_id : source_user_id
  const user = useSelect(state => getEntity<User>(state.entity, 'user', friendUserId))
  const isMeUser = meUserId === friendUserId

  // MARK: - Render

  return (
    <ItemContainer onClick={() => navigate(routes.profile(friendUserId).main)}>
      <UserImage url={user?.image_url} size={44} />

      <Col style={{ marginLeft: 12 }}>
        <LabelMedium>
          {user?.first_name + ' ' + user?.last_name + (isMeUser ? ` (${t('you')})` : '')}
        </LabelMedium>
        <LabelSmall>{'@' + user?.user_name}</LabelSmall>
      </Col>
    </ItemContainer>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  align-self: center;
  margin: 20px 16px;
  min-width: calc(100% - 32px);
`

const ItemContainer = styled(Row)`
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-out;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  position: relative;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `}
`
