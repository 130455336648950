import { ConnectionsSideBarItem } from './ConnectionsSideBarItem'
import { head, orderBy, uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { Col, FadeIn } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { getConnectionDrawerCollapsed, setConnectionDrawerCollapsed } from 'src/redux/reducers/chat'
import { getEntities } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { Chat, User } from 'src/repository/types'
import { reduceById } from 'src/utils/helpers/arrayHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const ChatSideBar: React.FC<{
  onChatSelect: (chat: Chat & { user: User }) => void
}> = ({ onChatSelect }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { isMobile } = useWindowDimensions()

  const drawerCollapsed = useSelect(state => getConnectionDrawerCollapsed(state.chat))
  const rawChats = useSelect(state => getEntities<Chat>(state.entity, 'chat'))
  const users = useSelect(state => getEntities<User>(state.entity, 'user'))
  const meUser = useSelect(state => getMeUser(state.me))

  const [searchText, setSearchText] = useState('')

  const chats = useMemo(() => {
    const userRecord = reduceById(users)
    let items = rawChats.map(chat => {
      const memberId = chat.member_ids.find(id => id !== meUser?.id)
      return { ...chat, user: memberId ? userRecord[memberId] : undefined }
    })
    items = uniqBy(items, 'id')
    return orderBy(items, ['updated_at'], ['desc'])
  }, [rawChats, users])

  // MARK: - Effects

  useEffect(() => {
    const chat = head(chats)
    if (chat && chat.user) onChatSelect(chat as Chat & { user: User })
  }, [!!chats.length])

  // MARK: - Handlers

  const handleChatListItemClick = useCallback(
    (user: User, chat: Chat) => {
      onChatSelect({ ...chat, user })
      dispatch(setConnectionDrawerCollapsed(true))
    },
    [onChatSelect],
  )

  // MARK: - Render

  return (
    <>
      {isMobile && !drawerCollapsed && (
        <FadeIn style={{ zIndex: 10 }}>
          <Col
            onClick={() => dispatch(setConnectionDrawerCollapsed(true))}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.2)',
              height: 'calc(100vh - 72px)',
              width: '100vw',
              zIndex: 10,
            }}
          />
        </FadeIn>
      )}

      <Container
        visible={!isMobile || !drawerCollapsed}
        style={{
          position: isMobile ? 'absolute' : 'relative',
          height: isMobile ? 'calc(100vh - 72px)' : undefined,
        }}>
        <SearchContainer>
          <Input
            style={{ height: 40 }}
            inputStyle={inputStyle}
            hasIcon="right"
            placeholder={t('searchConnections')}
            value={searchText}
            onChange={setSearchText}>
            <SearchIcon fill={palette.text.secondary} />
          </Input>
        </SearchContainer>

        {chats.map(chat => (
          <ConnectionsSideBarItem
            key={chat.id}
            chat={chat}
            user={chat.user}
            onClick={handleChatListItemClick}
          />
        ))}
      </Container>
    </>
  )
}

// MARK: - Styles

const Container = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  max-width: 30%;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.25s ease-out;
  width: 420px;
  z-index: 10;

  @media ${device.smallerThan.laptop} {
    max-width: 80%;
  }
`
const SearchContainer = styled.div`
  padding: 10px 16px;
  width: 100%;
`

const inputStyle = {
  height: 40,
  borderRadius: 8,
  paddingLeft: 16,
  borderWidth: 0,
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
}
