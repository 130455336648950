import { pushError } from 'src/redux/reducers/app'
import { MergeAction, mergeEntities } from 'src/redux/reducers/entity'
import { Thunk } from 'src/redux/store'
import { subscriptionService } from 'src/repository/services/subscriptionService'

type ActionType = MergeAction

// MARK: - Actions

export const fetchSubscriptions = (): Thunk<ActionType> => dispatch =>
  subscriptionService
    .fetchSubscriptions()
    .loading('subscriptions')
    .done(
      ({ rooms, publishers, subscriptions }) =>
        dispatch(mergeEntities({ room: rooms, user: publishers, subscription: subscriptions })),
      error => dispatch(pushError(error)),
    )
