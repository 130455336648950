import { Priority, realtime } from 'src/repository/realtime'
import { api } from '../api'
import { ChatChannel, ChatChannelMessage, ChatChannelMessageType, User } from '../types'

export const chatChannelService = {
  fetchChannels: async (roomId: string, limit: number) =>
    api.value<{
      chat_channels: ChatChannel[]
      direct_message_channels: ChatChannel[]
      users: User[]
    }>({
      request: {
        method: 'GET',
        endPoint: '/chatChannel',
        parameters: { room_id: roomId, limit: limit },
      },
    }),

  fetchMoreChannels: async (roomId: string, offset: number, limit: number) =>
    api.value<{ direct_message_channels: ChatChannel[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/chatChannel/direct',
        parameters: { room_id: roomId, limit: limit, offset: offset },
      },
    }),

  createThread: async (messageId: string) =>
    api.prop<{ thread: ChatChannel }>('thread', {
      request: {
        method: 'POST',
        endPoint: '/chatChannel/thread',
        parameters: { message_id: messageId },
      },
    }),

  fetchMessages: async (channelId: string, offset: number, limit: number) =>
    api.value<{ chat_messages: ChatChannelMessage[]; users: User[]; threads: ChatChannel[] }>({
      request: {
        method: 'GET',
        endPoint: '/chatMessage',
        parameters: { channel_id: channelId, offset: offset, limit: limit },
      },
    }),

  sendMessage: async (
    channelId: string,
    localId: string,
    text: string,
    isThread: boolean,
    type: ChatChannelMessageType,
  ) =>
    api.prop<{ chat_message: ChatChannelMessage }>('chat_message', {
      request: {
        method: 'POST',
        endPoint: '/chatMessage',
        parameters: {
          channel_id: channelId,
          local_id: localId,
          text: text,
          type: type,
          is_thread: isThread,
        },
      },
    }),

  updateMessage: async (chatMessageId: string, text: string) =>
    api.prop<{ chat_message: ChatChannelMessage }>('chat_message', {
      request: {
        method: 'PUT',
        endPoint: '/chatMessage',
        parameters: { chat_message_id: chatMessageId, text: text },
      },
    }),

  deleteMessage: async (chatMessageId: string) =>
    api.prop<{ chat_message: ChatChannelMessage }>('chat_message', {
      request: {
        method: 'DELETE',
        endPoint: '/chatMessage',
        parameters: { chat_message_id: chatMessageId },
      },
    }),

  observeChannel: (
    channelId: string,
    lastUpdatedAt: number,
    onMessageUpdate: (message: ChatChannelMessage) => void,
    onMessageDelete: (message: ChatChannelMessage) => void,
  ) => {
    const path = `chat/${channelId}/messages/set/single`
    realtime.observe<ChatChannelMessage & { action: number }>(
      path,
      lastUpdatedAt + 1,
      { priority: Priority.veryHigh, types: ['child_added', 'child_changed'] },
      payload => {
        if (payload?.action === 1) onMessageUpdate(payload)
        else if (payload?.action === 2) onMessageDelete(payload)
      },
    )
    return () => realtime.dispose(path)
  },

  toggleReaction: async (chatMessageId: string, reaction: string, isActive: boolean) =>
    api.prop<{ chat_message: ChatChannelMessage }>('chat_message', {
      request: {
        method: 'PUT',
        endPoint: '/chatMessage/react',
        parameters: { chat_message_id: chatMessageId, reaction: reaction, is_active: isActive },
      },
    }),
}
