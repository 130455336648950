import { ChatChannelMessageListDateSeparator } from './ChatChannelMessageListDateSeparator'
import { ChatChannelMessageListItem } from './ChatChannelMessageListItem'
import { head, last } from 'lodash'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol } from 'src/features/common/CommonStyles'
import { EmptyState } from 'src/features/common/EmptyState'
import { Loader } from 'src/features/common/Loader'
import { PageLoader } from 'src/features/common/PageLoader'
import { getLoading, getLocalization } from 'src/redux/reducers/app'
import {
  fetchChannelMessages,
  getChannelCanPaginate,
  getChannelMessages,
  openThread,
  toggleReaction,
} from 'src/redux/reducers/chatChannel'
import { ChatChannel, ChatChannelMessage, ChatMessageListDate } from 'src/repository/types'
import { insertTimeHeadersToMessages, isChannelMessageListDate } from 'src/utils/helpers/chatHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const ChatChannelMessageList: React.FC<{
  channel: ChatChannel
  header?: React.ReactNode
}> = ({ channel, header }) => {
  // MARK: - Hooks

  const isThread = channel.type === 'thread'
  const { t } = useTranslation()
  const localization = useSelect(state => getLocalization(state.app))
  const dispatch = useDispatch()
  const messages = useSelect(state => getChannelMessages(state.chatChannel, channel.id))
  const isLoading = useSelect(state => getLoading(state.app, 'chat_channel_' + channel.id))
  const canPaginate = useSelect(state => getChannelCanPaginate(state.chatChannel, channel.id))
  const messagesWithHeaders: (ChatMessageListDate | ChatChannelMessage)[] = isThread
    ? messages.map(message => ({ ...message, group_type: 'single' }))
    : (insertTimeHeadersToMessages(messages, localization) as (
        | ChatMessageListDate
        | ChatChannelMessage
      )[])

  const [anchoredBottom, setAnchoredBottom] = useState<boolean>(true)

  const listRef = useRef<HTMLDivElement | null>(null)
  const listContentHeight = useRef<number>(0)
  const listContentOffset = useRef<number>(0)

  // MARK: - Effects

  useLayoutEffect(() => {
    if (listRef.current) {
      const diff = listRef.current.scrollHeight - listContentHeight.current
      const target = listContentOffset.current + diff
      // isMobile
      // ? window.scrollTo({ top: target, behavior: "auto" })
      listRef.current.scrollTo(0, target)
    }
  }, [head(messages)?.id])

  useLayoutEffect(() => {
    if (anchoredBottom && listRef.current) {
      const target = listRef.current.scrollHeight
      listRef.current.scrollTo({ behavior: 'smooth', top: target })
    }
  }, [last(messages)?.id, anchoredBottom])

  useLayoutEffect(() => {
    if (anchoredBottom && listRef.current) {
      const target = listRef.current.scrollHeight
      listRef.current.scrollTo({ behavior: 'auto', top: target })
    }
  }, [channel.id])

  // MARK: - Actions

  const handleFetchMoreClick = useCallback(() => {
    if (!isLoading) {
      listContentHeight.current = listRef.current?.scrollHeight ?? 0
      dispatch(fetchChannelMessages(channel.id))
    }
  }, [isLoading, channel.id])

  const handleScroll = useCallback(() => {
    const messageList = listRef.current
    if (messageList) {
      const { scrollTop, offsetHeight, scrollHeight } = messageList
      listContentOffset.current = scrollTop

      const offset = 50
      const shouldAnchor = scrollHeight < scrollTop + offsetHeight + offset
      setAnchoredBottom(shouldAnchor)

      if (scrollTop < 400 && canPaginate) handleFetchMoreClick()
    }
  }, [canPaginate, isLoading])

  const handleThreadOpen = useCallback((message: ChatChannelMessage, open: boolean) => {
    if (open) dispatch(openThread(message.id))
  }, [])

  const handleReactionToggle = useCallback((message: ChatChannelMessage, reaction: string) => {
    dispatch(toggleReaction(message.channel_id, message.id, reaction))
  }, [])

  // MARK: - Render

  if (isLoading && !messages.length) {
    return (
      <ListContainer>
        <PageLoader style={{ paddingTop: 80 }} />
      </ListContainer>
    )
  }

  if (!messages.length) {
    return isThread ? (
      isLoading ? (
        <PageLoader style={{ paddingTop: 80 }} />
      ) : (
        <>{header}</>
      )
    ) : (
      <EmptyState
        title={t('noMessagesSent')}
        subtitle={t('noMessagesSentDescription')}
        style={{ marginLeft: 32, marginRight: 32 }}
      />
    )
  }

  return (
    <ListContainer ref={listRef} onScroll={handleScroll}>
      {canPaginate ? (
        <FetchMoreContainer onClick={handleFetchMoreClick}>
          {isLoading && messages.length ? <Loader size={24} thickness={3} /> : null}
        </FetchMoreContainer>
      ) : null}

      {header ?? <FlexCol />}

      {messagesWithHeaders.map(item =>
        isChannelMessageListDate(item) ? (
          <ChatChannelMessageListDateSeparator key={item.timestamp} date={item} />
        ) : (
          <ChatChannelMessageListItem
            key={item.id}
            message={item}
            isThread={isThread}
            onThreadOpen={open => handleThreadOpen(item, open)}
            onReactionToggle={reaction => handleReactionToggle(item, reaction)}
          />
        ),
      )}
    </ListContainer>
  )
}

// MARK: - Styles

const ListContainer = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 12px;

  @media ${device.smallerThan.laptop} {
    padding-bottom: 72px;
    max-height: calc(100vh - 114px);
  }
`

const FetchMoreContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 12px 0px 8px 0px;
  min-height: 34px;
  width: 100%;
`
