import React from 'react'
import { FlexCol, FlexRow, LabelLarge, LabelNormal } from 'src/features/common/CommonStyles'
import { Component, Room } from 'src/repository/types'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const ComponentHeader: React.FC<{
  room: Room
  style?: React.CSSProperties
  component: Pick<Component, 'image_url' | 'title' | 'overview'>
  disableEditing?: boolean
}> = ({ room, style, component, disableEditing }) => {
  // MARK: - Render

  return (
    <Container style={style}>
      <FlexRow style={{ alignItems: 'center' }}>
        <HeaderImage src={component.image_url} />

        <FlexCol>
          <LabelLarge>{component.title}</LabelLarge>
          <LabelNormal>{component.overview}</LabelNormal>
        </FlexCol>
      </FlexRow>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 40px 16px 12px 20px;

  @media ${device.smallerThan.tablet} {
    padding-top: 0px;
    padding-left: 12px;
  }
`

const HeaderImage = styled.img`
  border-radius: 8px;
  height: 48px;
  margin: 4px 16px 0px 0px;
  width: 48px;
`
