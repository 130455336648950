import React, { useCallback } from 'react'
import { Col, LabelMedium, LabelNormal } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled from 'styled-components'

export const HomeListItem: React.FC<{
  room: Room
  onRoomSelect: (roomId: string) => void
}> = ({ room, onRoomSelect }) => {
  // MARK: - Hooks

  const startTime = room?.start_date ?? 0

  // MARK: - Handlers

  const handleRoomSelect = useCallback(() => {
    onRoomSelect(room.id)
  }, [room.id])

  // MARK: - Render

  return (
    <Container onClick={handleRoomSelect}>
      <Image src={room.main_media?.url} />

      <Col>
        <LabelMedium style={{ ...numberOfLines(2), marginTop: 8, marginBottom: 6 }}>
          {room.title}
        </LabelMedium>
        <LabelNormal>{prettyDateString(startTime, true)}</LabelNormal>
      </Col>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  border-color: transparent;
  cursor: pointer;
  margin: 0px 0.8% 20px 0.8%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  width: 280px;
`

const Image = styled.img`
  border-radius: 12px;
  height: 165px;
  object-fit: cover;
  width: 100%;
`
