import React from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from 'src/assets/images/svg/CloseIcon'
import { LabelLarge, LabelNormal, Row } from 'src/features/common/CommonStyles'
import { ChatChannel } from 'src/repository/types'
import styled, { css } from 'styled-components'

export const ChatChannelThreadHeader: React.FC<{
  channel: ChatChannel
  onClose: () => void
}> = ({ channel, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <Container>
      <Row style={{ alignItems: 'flex-end' }}>
        <LabelLarge style={{ marginRight: 12 }}>{t('thread')}</LabelLarge>
        <LabelNormal style={{ marginBottom: 1 }}>{t(channel.name)}</LabelNormal>
      </Row>

      <ButtonContainer onClick={onClose}>
        <CloseIcon />
      </ButtonContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1);
  height: 52px;
  justify-content: space-between;
  padding: 8px 12px;
`

const ButtonContainer = styled(Row)`
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  justify-content: center;
  padding: 4px;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `};
`
