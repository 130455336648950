import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import { Col } from 'src/features/common/CommonStyles'
import styled, { useTheme } from 'styled-components'

export const RoomChatChannelListLoader: React.FC<
  IContentLoaderProps & {
    count: number
  }
> = ({ count, ...props }) => {
  // MARK: - Hooks

  const { isDark, palette } = useTheme()

  // MARK: - Render

  return (
    <Container>
      {Array.from({ length: count }).map((_, index) => (
        <ContentLoader
          style={{ marginRight: 10, marginLeft: 10, minWidth: 420 }}
          key={index}
          viewBox="0 0 420 40"
          height={30}
          width={'100%'}
          backgroundColor={isDark ? palette.background.tertiary : '#DDDDDD'}
          foregroundColor={isDark ? palette.background.secondary : palette.background.primary}
          {...props}>
          <rect x="6" y="4" rx="4" ry="4" width="26" height="26" />
          <rect x="40" y="5" rx="4" ry="4" width="180" height="8" />
          <rect x="40" y="21" rx="5" ry="5" width="120" height="8" />
        </ContentLoader>
      ))}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin: 8px 0px 0px 0px;
  overflow-x: hidden;
`
