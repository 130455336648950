import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexRow, LabelMedium, RoundedImage } from 'src/features/common/CommonStyles'
import { ChatChannel } from 'src/repository/types'
import { imageUrls } from 'src/utils/config'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled from 'styled-components'
import { RoomChatChannelListProfileModal } from './RoomChatChannelListProfileModal'

export const RoomChatChannelListItem: React.FC<{
  channel: ChatChannel
  isSelected: boolean
  onChannelClick: (channel: ChatChannel) => void
}> = ({ channel, isSelected, onChannelClick }) => {
  // MARK: - Hooks

  const timer = useRef<NodeJS.Timeout | null>(null)

  const { t } = useTranslation()

  const [showProfileModal, setShowProfileModal] = useState(false)
  const isTopicChannel = channel.type === 'general' || channel.type === 'topic'

  // MARK: - Render

  return (
    <Container
      onPointerEnter={() => {
        if (timer.current) clearTimeout(timer.current)
        timer.current = setTimeout(() => setShowProfileModal(true), 600)
      }}
      onPointerLeave={() => {
        if (timer.current) clearTimeout(timer.current)
        timer.current = setTimeout(() => setShowProfileModal(false), 200)
      }}
      onClick={() => onChannelClick(channel)}
      isSelected={isSelected}>
      <FlexRow style={{ alignItems: 'center', marginLeft: isSelected ? -4 : 0 }}>
        {channel.image_url ? (
          <RoundedImage
            style={{ borderRadius: 4, marginRight: 6 }}
            size={24}
            src={channel.image_url}
          />
        ) : (
          <Image src={isTopicChannel ? imageUrls.chatChannel : imageUrls.userPlaceholder} />
        )}

        <Title isSelected={isSelected} style={{ ...numberOfLines(1) }}>
          {t(channel.name)}
        </Title>
      </FlexRow>

      {showProfileModal && !isTopicChannel && (
        <RoomChatChannelListProfileModal userId={channel.id} />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexRow)<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.background.secondary : theme.palette.background.primary};
  border-left: ${({ isSelected }) => (isSelected ? 4 : 0)}px solid
    ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  margin: 1px 0px;
  padding: 6px 12px 6px 18px;
  position: relative;
`

const Title = styled(LabelMedium)<{ isSelected: boolean }>`
  align-items: center;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.text.primary : theme.palette.text.secondary};
  cursor: pointer;
  position: relative;
`

const Image = styled.img`
  height: 24px;
  margin-left: 1px;
  margin-right: 7px;
  object-fit: contain;
  width: 22px;
`
