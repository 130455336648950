import produce from 'immer'
import { MergeAction, mergeEntities } from 'src/redux/reducers/entity'
import { Action, Thunk } from 'src/redux/store'
import { invitationService } from 'src/repository/services/audienceService'
import { roomService } from 'src/repository/services/roomService'
import { InvitationRequest } from 'src/repository/types'

type State = {
  invitationRequest: InvitationRequest | null
  shouldRedirect: boolean
}

type ActionType =
  | { type: 'roomAccess/setShouldRedirect'; shouldRedirect: boolean }
  | { type: 'roomAccess/setInvitationRequest'; invitationRequest: InvitationRequest }
  | { type: 'roomAccess/flush' }
  | MergeAction
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  invitationRequest: null,
  shouldRedirect: false,
}

// MARK: - Reducer

export const roomAccessReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'roomAccess/setShouldRedirect':
      return produce(state, draft => {
        draft.shouldRedirect = action.shouldRedirect
        return draft
      })

    case 'roomAccess/setInvitationRequest':
      return produce(state, draft => {
        draft.invitationRequest = action.invitationRequest
        return draft
      })

    case 'me/logout':
    case 'roomAccess/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const requestInvitation =
  (request: Partial<InvitationRequest>): Thunk<ActionType> =>
  dispatch =>
    invitationService
      .requestInvitation(request)
      .loading('room_access')
      .done(invitationRequest =>
        dispatch({ type: 'roomAccess/setInvitationRequest', invitationRequest }),
      )

export const requestWithAccessCode =
  (roomId: string, accessCode: string): Thunk<ActionType> =>
  dispatch =>
    roomService
      .fetchRoom(roomId, accessCode)
      .loading('room_access')
      .done(({ room, publishers }) => {
        dispatch(mergeEntities({ room: [room], user: publishers }))
        dispatch({ type: 'roomAccess/setShouldRedirect', shouldRedirect: true })
      })

export const cleanRoomAccess = (): ActionType => ({
  type: 'roomAccess/flush',
})

// MARK: - Selectors

export const getInvitationRequest = (state: State): InvitationRequest | null => {
  return state.invitationRequest
}

export const getShouldRedirect = (state: State): boolean => {
  return state.shouldRedirect
}
