import React from 'react'
import { useTranslation } from 'react-i18next'
import { InvitationIcon } from 'src/assets/images/svg/InvitationIcon'
import { Col, LabelMedium, Row, ShadowBox } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'
import styled, { css, useTheme } from 'styled-components'

type Props = {
  room: Room
}

export const RoomCoverHostInfo: React.FC<Props> = ({ room }) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()

  // MARK: - Render

  return (
    <Col style={{ marginTop: 16, marginBottom: 24 }}>
      <ContactContainer onClick={() => {}}>
        <Row>
          <InvitationIcon size={24} fill={palette.text.primary} style={{ marginRight: 8 }} />
          <LabelMedium>{t('contactToModerator')}</LabelMedium>
        </Row>
      </ContactContainer>
    </Col>
  )
}

// MARK: - Styles

const ContactContainer = styled(ShadowBox)`
  background-color: ${({ theme }) =>
    theme.isDark ? theme.palette.background.primary : 'transparent'};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};

  border-radius: 6px;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.primary : 'transparent')};
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      }
    `};
`
