import { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { css } from 'styled-components'

export const LabelHeaderLarge = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 34px;
`

export const LabelHeader = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 26px;
`

export const LabelLarge = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 24px;
`

export const LabelMedium = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 21px;
`

export const LabelNormal = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
`

export const LabelSmall = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 19px;
`

export const RoundedImage = styled.img<{ size: number }>`
  border-radius: 8px;
  height: ${({ size }) => size}px;
  margin-right: 16px;
  min-width: ${({ size }) => size}px;
  overflow: hidden;
  width: ${({ size }) => size}px;

  @media ${device.smallerThan.laptop} {
    margin-right: 12px;
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexCol = styled(Col)`
  flex: 1;
`

export const FlexRow = styled(Row)`
  flex: 1;
`

export const ShadowBox = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 6px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.05);
  max-width: 800px;

  ${() =>
    css`
      &:hover {
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
      }
    `}
`

export const Box = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 8px;
  border-width: 1px;
  cursor: pointer;
  flex: 1;
`

export const HoverBox = styled(Box)`
  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => theme.palette.primary};
      }
    `}
`

export const RowSeparator = styled.div<{ inset?: number }>`
  background-color: ${({ theme }) => theme.palette.background.separator};
  bottom: 0px;
  height: 1px;
  left: ${({ inset }) => inset ?? 0}px;
  min-height: 1px;
  position: absolute;
  right: 0px;
  width: auto;
`

export const Separator = styled.div<{ margin?: number }>`
  background-color: ${({ theme }) => theme.palette.background.separator};
  height: 1px;
  margin-bottom: ${({ margin }) => margin ?? 16}px;
  margin-top: ${({ margin }) => margin ?? 16}px;
  min-height: 1px;
  width: 100%;
`

export const FadeIn = styled.div<{ duration?: number; ease?: 'ease-in' | 'ease-out' }>`
  animation-duration: ${({ duration }) => duration ?? 0.2}s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ${({ ease }) => ease ?? 'ease-out'};
`
