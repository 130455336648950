import React from 'react'
import { ChatMessageListDate } from 'src/repository/types'
import styled from 'styled-components'
import { LabelNormal, Row } from '../common/CommonStyles'

export const ChatMessageListDateSeparator: React.FC<{
  date: ChatMessageListDate
}> = ({ date }) => (
  <Container>
    <InnerContainer>
      <Title>{date.title}</Title>
    </InnerContainer>
  </Container>
)

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
  position: relative;
  width: 100%;
`

const InnerContainer = styled(Row)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 20px;
  cursor: pointer;
  padding: 1px 16px 1px 16px;
`

const Title = styled(LabelNormal)`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
`
