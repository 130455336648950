import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LabelBold, LabelLight, LabelRegular, LabelSemiBold } from 'src/features/common/Label'
import { getMeUser } from 'src/redux/reducers/me'
import { Chat, User } from 'src/repository/types'
import { formatDurationFrom, localTime } from 'src/utils/helpers/dateHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css, useTheme } from 'styled-components'
import { FlexCol, FlexRow, Row } from '../common/CommonStyles'
import { UserImage } from '../common/UserImage'

export const ConnectionsSideBarItem: React.FC<{
  chat: Chat
  user?: User
  onClick: (user: User, chat: Chat) => void
}> = ({ chat, user, onClick }) => {
  // MARK: - Hooks

  const timer = useRef<NodeJS.Timer | null>(null)

  const { palette } = useTheme()

  const meUser = useSelect(state => getMeUser(state.me))
  const [countUpTimestamp, setCountUpTimestamp] = useState(localTime())

  const unreadCount = meUser ? chat.unread_message_ids?.[meUser.id]?.length ?? 0 : 0

  // MARK: - Effect

  useEffect(() => {
    if (chat.last_message && localTime() - chat.last_message.created_at < 3660) {
      if (timer.current) clearTimeout(timer.current)
      const interval = () => {
        const diff = localTime() - (chat.last_message?.created_at ?? 0)
        if (diff < 3600 + 60) return 1000 * 60
        else return Number.MAX_SAFE_INTEGER
      }
      timer.current = setTimeout(() => setCountUpTimestamp(localTime()), interval())
    }

    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [countUpTimestamp, chat.last_message?.created_at])

  // MARK: - Handlers

  const handleItemPress = useCallback(() => {
    if (user) onClick(user, chat)
  }, [chat.id, user?.id])

  // MARK: - Render

  return (
    <Container onClick={handleItemPress}>
      <FlexRow style={{ alignItems: 'center' }}>
        <UserImage url={user?.image_url} size={50} />

        <FlexCol style={{ marginLeft: 12, flex: 1 }}>
          <Row style={{ justifyContent: 'space-between' }}>
            <LabelSemiBold size={16} style={{ marginBottom: 1, height: 24 }}>
              {user?.first_name + ' ' + user?.last_name}
            </LabelSemiBold>
          </Row>

          <Row style={{ marginRight: 60, height: 26 }}>
            {unreadCount ? (
              <LabelBold size={14}>{`${unreadCount} new messages`}</LabelBold>
            ) : (
              <LabelLight size={14} color={palette.text.secondary} style={{ ...numberOfLines(1) }}>
                {chat.last_message?.text}
              </LabelLight>
            )}

            <LabelLight
              color={palette.text.placeholder}
              size={10}
              style={{ marginLeft: 8, marginRight: 8 }}>
              {' • '}
            </LabelLight>

            <LabelRegular color={palette.text.placeholder} size={14}>
              {chat.last_message?.created_at
                ? formatDurationFrom(chat.last_message.created_at)
                : ''}
            </LabelRegular>
          </Row>
        </FlexCol>

        {unreadCount ? (
          <LabelLight color={palette.orange} size={24}>
            {' • '}
          </LabelLight>
        ) : null}
      </FlexRow>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `}
`
