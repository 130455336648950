import React, { useCallback } from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  Col,
  FlexRow,
  LabelHeader,
  LabelMedium,
  LabelSmall,
  Row,
  ShadowBox,
} from 'src/features/common/CommonStyles'
import { FetchMoreButton } from 'src/features/common/FetchMoreButton'
import { getCanPaginate, getIsLoading, searchMoreUsers } from 'src/redux/reducers/search'
import { User } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const SearchUserSection: React.FC<{
  users: User[]
  query: string | null
}> = ({ users, query }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { navigate, routes } = useNavigation()
  const dispatch = useDispatch()

  const isLoading = useSelect(state => getIsLoading(state.search, 'user'))
  const canPaginate = useSelect(state => getCanPaginate(state.search, query, 'user'))
  const { width } = useWindowDimensions()
  const columnCount = Math.floor(Math.min(width, 1080) / 350)

  // MARK: - Actions

  const handleUserClick = useCallback((user: User) => {
    navigate(routes.profile(user.id).main)
  }, [])

  const handlePaginateClick = useCallback(() => {
    if (query) dispatch(searchMoreUsers(query))
  }, [query])

  // MARK: - Render

  if (!isLoading && !users.length) return null

  return (
    <Container>
      <LabelHeader style={{ marginBottom: 20, width: columnCount * 345, alignSelf: 'center' }}>
        {t('accounts')}
      </LabelHeader>

      {isLoading && !users.length ? <Loader count={columnCount} /> : null}

      {!!users.length ? (
        <InnerContainer
          style={{
            justifyContent: users.length < columnCount ? 'flex-start' : 'center',
            width: columnCount * 345 + 20,
            alignSelf: 'center',
          }}>
          {users.map(user => (
            <ListItem key={user.id} user={user} onUserClick={handleUserClick} />
          ))}
        </InnerContainer>
      ) : null}

      {!!users.length && canPaginate ? (
        <FetchMoreButton onClick={handlePaginateClick} isLoading={isLoading} />
      ) : null}
    </Container>
  )
}

const ListItem: React.FC<{
  user: User
  onUserClick: (user: User) => void
}> = ({ user, onUserClick }) => (
  <ItemContainer onClick={() => onUserClick(user)}>
    <FlexRow style={{ alignItems: 'center' }}>
      <PublisherContainer>
        <PublisherImage
          src={user?.image_url || require('src/assets/images/profile_placeholder.png')}
        />
      </PublisherContainer>

      <Col style={{ marginLeft: 12, flex: 1 }}>
        <LabelMedium>{user.first_name + ' ' + user.last_name}</LabelMedium>
        <LabelSmall style={{ marginTop: 4 }}>{'@' + user.user_name}</LabelSmall>
      </Col>
    </FlexRow>
  </ItemContainer>
)

const Loader: React.FC<
  IContentLoaderProps & {
    count: number
  }
> = ({ count, ...props }) => {
  // MARK: - Hooks

  const { isDark, palette } = useTheme()

  // MARK: - Render

  return (
    <Row style={{ marginRight: 10, marginLeft: 10, marginBottom: 20, overflowX: 'hidden' }}>
      {Array.from({ length: count }).map((_, index) => (
        <ContentLoader
          style={{ marginRight: 10, marginLeft: 10, minWidth: 330 }}
          key={index}
          viewBox="0 0 330 100"
          height={100}
          width={330}
          backgroundColor={isDark ? palette.background.tertiary : '#DDDDDD'}
          foregroundColor={isDark ? palette.background.secondary : palette.background.primary}
          {...props}>
          <circle cx="30" cy="36" r="30" />
          <rect x="75" y="10" rx="4" ry="4" width="50" height="10" />
          <rect x="75" y="30" rx="5" ry="5" width="250" height="10" />
          <rect x="75" y="50" rx="4" ry="4" width="100" height="10" />
        </ContentLoader>
      ))}
    </Row>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin: 30px 0px 0px 0px;
  padding-left: auto;
  padding-right: auto;
`

const InnerContainer = styled(Row)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -10px;
`

const ItemContainer = styled(ShadowBox)`
  animation-duration: 0.18s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-out;
  background-color: ${({ theme }) => theme.palette.background.primary};
  cursor: pointer;
  margin: 0px 10px 20px 10px;
  max-width: 330px;
  min-width: 330px;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
`

const PublisherContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.background.primary};
  border-radius: 29px;
  height: 58px;
  left: 8px;
  overflow: hidden;
  width: 58px;
  z-index: 2;
`

const PublisherImage = styled.img`
  height: 100%;
  width: 100%;
`
