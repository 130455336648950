import React from 'react'
import { ChatChannelPage } from 'src/features/chatChannel'
import { getEntity } from 'src/redux/reducers/entity'
import { ChatChannel } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'

export const RoomChatChannel: React.FC<{
  channelId: string
  onBackClick: () => void
}> = ({ channelId, onBackClick }) => {
  // MARK: - Hooks

  const channel = useSelect(state =>
    getEntity<ChatChannel>(state.entity, 'chat_channel', channelId),
  )

  // MARK: - Render

  return channel && <ChatChannelPage channel={channel} onBackClick={onBackClick} />
}
