import React, { useEffect, useState } from 'react'
import { BottomSheet as Sheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'
import 'react-spring-bottom-sheet/dist/style.css'

type Props = {
  visible: boolean
  onClose: () => void
}

export const BottomSheet: React.FC<Props> = ({ visible, onClose, children }) => {
  // MARK: - Hooks

  const [showBackground, setShowBackground] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    setTimeout(() => setShowBackground(visible), 100)
  }, [visible])

  return (
    <Background onClick={event => event.stopPropagation()} visible={showBackground}>
      <Sheet expandOnContentDrag open={visible} onDismiss={onClose}>
        {children}
      </Sheet>
    </Background>
  )
}

// MARK: - Styles

const Background = styled.div<{ visible: boolean }>`
  bottom: 0px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
`
