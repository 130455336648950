type Config = {
  keys: {
    firebaseToken: string
    firebaseWebToken: string
    livePreview: string
    localization: string
    theme: string
  }
  sizes: {
    thumbnailImage: number
    mainImage: number
    webBannerImage: number
  }
  envName: string
  title: string
  lobbiUrl: string
  platformUrl: string
  apiUrl: string
  firebase_api_key: string
  google_maps_api_key: string
  icons_8_api_key: string
  flat_icon_api_key: string
  bugsnag_api_key: string
  segment_write_key: string

  firebaseConfig: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
  defaultCover: string
}

export const imageUrls = {
  chatChannel:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F1299946?alt=media&token=b2fa1f68-1bb4-4388-871a-709179f4f679',
  userPlaceholder:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F166246?alt=media&token=f9757cb2-7c2f-44df-a2d3-7aef41821f52',
}

const local: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BJp2tOwA7ZHj_H3DtAxlLUokMDzb0FLr4O1GrIl9vrosZSSeM7IGxqn1ICpxGNEXXY4Hc3ny1cTcia_NwWw19PA',
    livePreview: 'LivePreview',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'local',
  title: 'LOBBI - Local',
  lobbiUrl: 'http://localhost:3001',
  platformUrl: 'http://localhost:3000',
  apiUrl: 'https://api-lzs5auurnq-uc.a.run.app/api',
  firebase_api_key: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  bugsnag_api_key: 'ce798a9474acd2647202b612136e1cc6',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  segment_write_key: 'AIt9tYYN8y7T2AcEHk3NaOLxuOHfdG8b',

  firebaseConfig: {
    apiKey: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
    authDomain: 'oliver-development-585f8.firebaseapp.com',
    databaseURL: 'https://oliver-development-585f8-default-rtdb.firebaseio.com',
    projectId: 'oliver-development-585f8',
    storageBucket: 'oliver-development-585f8.appspot.com',
    messagingSenderId: '379822110505',
    appId: '1:379822110505:web:03b10e816e27adc57aa938',
    measurementId: 'G-EVNK220WN2',
  },
  defaultCover:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_default.jpg?alt=media&token=85588efe-53b6-4689-8473-4e7e287e6a22',
}

const dev: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BJp2tOwA7ZHj_H3DtAxlLUokMDzb0FLr4O1GrIl9vrosZSSeM7IGxqn1ICpxGNEXXY4Hc3ny1cTcia_NwWw19PA-33RSwa89AAfkY',
    livePreview: 'LivePreview',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'development',
  title: 'Lobbi - Development',
  lobbiUrl: 'https://development.app.lobbi.events',
  platformUrl: 'https://development.lobbi.events',
  apiUrl: 'https://api-lzs5auurnq-uc.a.run.app/api',
  firebase_api_key: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  bugsnag_api_key: 'ce798a9474acd2647202b612136e1cc6',
  segment_write_key: 'AIt9tYYN8y7T2AcEHk3NaOLxuOHfdG8b',

  firebaseConfig: {
    apiKey: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
    authDomain: 'oliver-development-585f8.firebaseapp.com',
    databaseURL: 'https://oliver-development-585f8-default-rtdb.firebaseio.com',
    projectId: 'oliver-development-585f8',
    storageBucket: 'oliver-development-585f8.appspot.com',
    messagingSenderId: '379822110505',
    appId: '1:379822110505:web:03b10e816e27adc57aa938',
    measurementId: 'G-EVNK220WN2',
  },
  defaultCover:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_default.jpg?alt=media&token=85588efe-53b6-4689-8473-4e7e287e6a22',
}

const staging: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BA_9IvTVoipbgIMMzjSPG_R7nY4nroTWogtSze8yctKmjgFthaX9_JfwUM3FcSRzckaYlzY8L-33RSwa89AAfkY',
    livePreview: 'LivePreview',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'staging',
  title: 'Lobbi - Staging',
  lobbiUrl: 'https://staging.app.lobbi.events',
  platformUrl: 'https://staging.lobbi.events',
  apiUrl: 'https://api-a2zbawhcpq-uc.a.run.app/api',
  firebase_api_key: 'AIzaSyBH6CTwl9gg10qZBK78O7Hpy7Twa3tpum4',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  bugsnag_api_key: 'd941f28683bb33c2a470ed663b2309be',
  segment_write_key: 'omsd2pCnB7MqtlRaweqe6QwUMds6mMAf',

  firebaseConfig: {
    apiKey: 'AIzaSyBH6CTwl9gg10qZBK78O7Hpy7Twa3tpum4',
    authDomain: 'oliver-staging-e5ef3.firebaseapp.com',
    databaseURL: 'https://oliver-staging-e5ef3-default-rtdb.firebaseio.com',
    projectId: 'oliver-staging-e5ef3',
    storageBucket: 'oliver-staging-e5ef3.appspot.com',
    messagingSenderId: '201617735461',
    appId: '1:201617735461:web:3109c5315aa82f2e6be4cc',
    measurementId: 'G-XR28RFLEBH',
  },
  defaultCover:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_default.jpg?alt=media&token=85588efe-53b6-4689-8473-4e7e287e6a22',
}

const prod: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BMi-J3GaEGIbGbWOCAUMhkVwXS4H063zW4xLZzAsh3SjF873fjxYPEJKeBjeyDR15_Ksz0Pxf9yJiZqsi31tZ_Y',
    livePreview: 'LivePreview',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'production',
  title: 'Lobbi',
  lobbiUrl: 'https://app.lobbi.events',
  platformUrl: 'https://lobbi.events',
  apiUrl: 'https://api-5rckteu34q-uc.a.run.app/api',
  firebase_api_key: 'AIzaSyAiIguNzKol13aaCsbO8pb3yTsoFTY42gM',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  bugsnag_api_key: '082cb5c03bf0e33f16eba7b41d832021',
  segment_write_key: 'RjHkzd8XP99GEe1BeEOQOH4qqqfNLldD',

  firebaseConfig: {
    apiKey: 'AIzaSyAiIguNzKol13aaCsbO8pb3yTsoFTY42gM',
    authDomain: 'oliver-826d8.firebaseapp.com',
    databaseURL: 'https://oliver-826d8-default-rtdb.firebaseio.com',
    projectId: 'oliver-826d8',
    storageBucket: 'oliver-826d8.appspot.com',
    messagingSenderId: '522547224886',
    appId: '1:522547224886:web:956a29cc0dec183e7c5397',
    measurementId: 'G-N0BLX0VP7Z',
  },
  defaultCover:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_default.jpg?alt=media&token=85588efe-53b6-4689-8473-4e7e287e6a22',
}

const currentConfig = () => {
  if (window.location.origin === prod.lobbiUrl) {
    return prod
  } else if (window.location.origin === staging.lobbiUrl) {
    return staging
  } else if (window.location.origin === dev.lobbiUrl) {
    return dev
  } else {
    return dev ?? local
  }
}

export default currentConfig()
