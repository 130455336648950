import classNames from 'classnames'
import React from 'react'

type Props = {
  status: string
}

export const FormHint: React.FC<Props> = ({ children, status }) => {
  const classes = classNames('form-hint', status && `text-color-${status}`)

  return (
    <div className={classes} style={{ textAlign: 'left', marginTop: -12 }}>
      {children}
    </div>
  )
}
