import { mergeEntities } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { AsyncThunk } from 'src/redux/store'
import { IError } from 'src/repository/Error'
import { roomService } from 'src/repository/services/roomService'
import { subscriptionService } from 'src/repository/services/subscriptionService'
import { Invitation } from 'src/repository/types'

type State = {
  isLoading: { cover: boolean; subscription: boolean }
  isModerator: boolean
  error: IError | null
  referralInvitation: Invitation | null
}

// MARK: - State

export const initialState: State = {
  isLoading: { cover: false, subscription: false },
  isModerator: false,
  error: null,
  referralInvitation: null,
}

// MARK: - Reducer

export const roomCoverReducer = (
  state = initialState,
  action:
    | SetLoadingAction
    | SetErrorAction
    | SetReferralInvitationAction
    | SetIsModerator
    | FlushAction,
): State => {
  switch (action.type) {
    case 'roomCover/setLoading':
      return { ...state, isLoading: { ...state.isLoading, [action.key]: action.isLoading } }

    case 'roomCover/setError':
      return { ...state, error: action.error }

    case 'roomCover/setIsModerator':
      return { ...state, isModerator: action.isModerator }

    case 'roomCover/setReferralInvitation':
      return { ...state, referralInvitation: action.referralInvitation }

    case 'roomCover/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchRoomCover =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('cover', true))
    dispatch(setError(null))

    const response = await roomService.fetchRoomCover(roomId)

    if (response.success) {
      const { room, publisher, collaborator, subscription, components } = response.value
      dispatch(
        mergeEntities({
          room: room ? [room] : [],
          user: publisher ? [publisher] : [],
          subscription: subscription ? [subscription] : [],
          component: components ?? [],
        }),
      )
      dispatch(setIsModerator(!!collaborator))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('cover', false))
  }

export const fetchSubscription =
  (roomId: string): AsyncThunk =>
  async (dispatch, getState) => {
    const meUser = getMeUser(getState().me)
    if (!meUser) return

    dispatch(setLoading('subscription', true))
    dispatch(setError(null))
    const response = await subscriptionService.fetchSubscription(roomId)

    if (response.success) dispatch(mergeEntities({ subscription: [response.value] }))
    else dispatch(setError(response.error))

    dispatch(setLoading('subscription', false))
  }

export const createSubscription =
  (roomId: string): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading('subscription', true))

    const refererId = getReferralInvitation(getState().roomCover)?.invited_by_id
    const response = await subscriptionService.createSubscription(roomId, refererId ?? '')

    if (response.success) dispatch(mergeEntities({ subscription: [response.value] }))
    else dispatch(setError(response.error))

    dispatch(setLoading('subscription', false))
  }

export const setReferralInvitation = (
  referralInvitation: Invitation | null,
): SetReferralInvitationAction => ({
  type: 'roomCover/setReferralInvitation',
  referralInvitation: referralInvitation,
})

export const flush = (): FlushAction => ({
  type: 'roomCover/flush',
})

export const setError = (error: IError | null): SetErrorAction => ({
  type: 'roomCover/setError',
  error: error,
})

// MARK: - Selectors

export const getIsLoading = (state: State, key: keyof State['isLoading']): boolean => {
  return state.isLoading[key]
}

export const getError = (state: State): IError | null => {
  return state.error
}

export const getReferralInvitation = (state: State): Invitation | null => {
  return state.referralInvitation
}

// MARK: - Action Types

type SetLoadingAction = {
  type: 'roomCover/setLoading'
  key: keyof State['isLoading']
  isLoading: boolean
}

type SetErrorAction = {
  type: 'roomCover/setError'
  error: IError | null
}

type SetIsModerator = {
  type: 'roomCover/setIsModerator'
  isModerator: boolean
}

type FlushAction = {
  type: 'roomCover/flush'
}

type SetReferralInvitationAction = {
  type: 'roomCover/setReferralInvitation'
  referralInvitation: Invitation | null
}

// MARK: - Internal Actions

const setLoading = (key: keyof State['isLoading'], isLoading: boolean): SetLoadingAction => ({
  type: 'roomCover/setLoading',
  key: key,
  isLoading: isLoading,
})

const setIsModerator = (isModerator: boolean): SetIsModerator => ({
  type: 'roomCover/setIsModerator',
  isModerator: isModerator,
})
