import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, ShadowBox } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading, setShowAuthentication } from 'src/redux/reducers/app'
import { getIsLoading } from 'src/redux/reducers/cover'
import { getFirstEntity } from 'src/redux/reducers/entity'
import { getIsAuthenticated, getMeUser } from 'src/redux/reducers/me'
import { Room, Subscription } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'

type Props = {
  room: Room
  onJoinRoomClick: () => void
  onGoToRoomClick: () => void
}

export const RoomCoverJoinRoom: React.FC<Props> = ({ room, onJoinRoomClick, onGoToRoomClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const isAuthLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const isLoading = useSelect(state => getIsLoading(state.roomCover, 'subscription'))
  const meUser = useSelect(state => getMeUser(state.me))
  const subscription = useSelect(state =>
    getFirstEntity<Subscription>(
      state.entity,
      'subscription',
      ({ user_id, room_id }) => user_id === meUser?.id && room_id === room.id,
    ),
  )

  // MARK: - Render

  return (
    <Col>
      {isAuthenticated ? (
        <ShadowBox style={{ marginTop: 24 }}>
          <PrimaryButton
            style={{ width: '100%' }}
            title={t('goToRoom')}
            onClick={() => (subscription ? onGoToRoomClick() : onJoinRoomClick())}
            isLoading={isLoading}
          />
        </ShadowBox>
      ) : (
        <PrimaryButton
          style={{ marginTop: 24 }}
          title={t('logInOrSignUp')}
          onClick={() => dispatch(setShowAuthentication(true))}
          isLoading={isAuthLoading}
        />
      )}
    </Col>
  )
}
