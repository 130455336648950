import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { FlexCol, LabelHeader } from 'src/features/common/CommonStyles'
import { InvitationForm } from 'src/features/common/InvitationForm'
import { getMeUser } from 'src/redux/reducers/me'
import { getInvitationRequest, requestInvitation } from 'src/redux/reducers/roomAccess'
import { Error } from 'src/repository/Error'
import config from 'src/utils/config'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'

export const RoomAccessRequestInvitation: React.FC<{
  roomId: string
}> = ({ roomId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const meUser = useSelect(state => getMeUser(state.me))
  const { navigate, routes } = useNavigation()
  const { t } = useTranslation()
  const [stage, setStage] = useState<'alert' | 'request' | 'completed'>('alert')
  const invitationRequest = useSelect(state => getInvitationRequest(state.access))

  // MARK: - Effects

  useEffect(() => {
    if (invitationRequest) setStage('completed')
  }, [invitationRequest])

  // MARK: - Actions

  const handleSendInvite = (firstName: string, lastName: string, email: string, notes: string) => {
    dispatch(
      requestInvitation({
        room_id: roomId,
        first_name: meUser?.first_name ?? '',
        last_name: meUser?.last_name ?? '',
        image_url: meUser?.image_url ?? '',
        email: meUser?.email ?? '',
        notes: notes,
      }),
    )
  }

  // MARK: - Render

  return (
    <>
      <AlertModal
        visible={stage === 'completed'}
        error={Error.displayable(t('invitationRequestSent'), t('invitationRequestSentDescription'))}
        onClose={() => window.location.replace(config.platformUrl)}
      />

      <AlertModal
        visible={stage === 'alert'}
        error={Error.displayable(
          t('inviteOnlyRoom'),
          t('inviteOnlyRoomDescription'),
          success => {
            if (success) setStage('request')
          },
          t('sendRequest'),
        )}
        onClose={success => {
          if (!success) navigate(routes.room(roomId).cover)
        }}
      />

      {stage === 'request' && (
        <ModalWrapper size="tiny" height="dynamic" onClose={() => setStage('alert')}>
          <FlexCol style={{ margin: 16 }}>
            <LabelHeader style={{ marginBottom: 16 }}>{t('invitationRequest')}</LabelHeader>

            <InvitationForm
              userFirstName={meUser?.first_name}
              userLastName={meUser?.last_name}
              userEmail={meUser?.email}
              style={{ margin: 0, padding: 0, paddingBottom: 100 }}
              onClose={() => setStage('alert')}
              onSend={handleSendInvite}
            />
          </FlexCol>
        </ModalWrapper>
      )}
    </>
  )
}
