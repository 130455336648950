import { map, orderBy } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getLoading, setShowAuthentication } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { fetchSubscriptions } from 'src/redux/reducers/subscription'
import { Room, Subscription } from 'src/repository/types'
import { localTime } from 'src/utils/helpers/dateHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { Col, FlexCol, LabelHeader, LabelNormal, Row } from '../common/CommonStyles'
import { PageLoader } from '../common/PageLoader'
import { LayoutWrapper } from '../layout/LayoutWrapper'
import { HomeListItem } from './HomeListItem'

export const Home: React.FC = () => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { routes, navigate } = useNavigation()

  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const isLoading = useSelect(state => getLoading(state.app, 'subscriptions'))
  const columnCount = Math.floor(Math.min(width, 1080) / 280)
  const itemWidth = 290
  const containerWidth = columnCount * itemWidth

  const subscriptions = useSelect(state => getEntities<Subscription>(state.entity, 'subscription'))
  const rooms = useSelect(state => {
    const rawRooms = getEntities<Room>(state.entity, 'room', ({ id }) =>
      map(subscriptions, 'room_id').includes(id),
    )
    return orderBy(rawRooms, 'start_date', 'desc')
  })

  const upcomingRooms = useMemo(() => {
    const rawRooms = rooms.filter(({ start_date }) => start_date >= localTime())
    return orderBy(rawRooms, 'start_date', 'asc')
  }, [rooms])

  // MARK: - Effects

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchSubscriptions())
    else dispatch(setShowAuthentication(true))
  }, [isAuthenticated])

  // MARK: - Handlers

  const handleRoomSelect = useCallback((roomId: string) => {
    navigate(routes.room(roomId).home)
  }, [])

  // MARK: - Render

  return (
    <LayoutWrapper>
      {isAuthenticated && (
        <Container>
          {upcomingRooms.length ? (
            <Col style={{ marginBottom: 24, alignItems: 'center' }}>
              <LabelHeader style={{ marginBottom: 20, width: containerWidth }}>
                {t('upcomingEvents')}
              </LabelHeader>

              <Row style={{ width: columnCount * (itemWidth + 6), overflow: 'scroll' }}>
                {upcomingRooms.map(room => (
                  <HomeListItem key={room.id} room={room} onRoomSelect={handleRoomSelect} />
                ))}
              </Row>
            </Col>
          ) : null}

          <LabelHeader style={{ marginBottom: 20, width: containerWidth }}>
            {t('allEvents')}
          </LabelHeader>

          <Row style={{ width: columnCount * (itemWidth + 6), flexWrap: 'wrap' }}>
            {isLoading && !rooms.length ? (
              <PageLoader style={{ height: 200, width: '100%' }} />
            ) : rooms.length ? (
              rooms.map(room => (
                <HomeListItem key={room.id} room={room} onRoomSelect={handleRoomSelect} />
              ))
            ) : (
              <EmptyViewContainer>
                <LabelHeader style={{ marginBottom: 20 }}>{t('letsCreateRoom')}</LabelHeader>
                <LabelNormal style={{ fontSize: 16, marginBottom: 30 }}>
                  {t('letsCreateRoomDescription')}
                </LabelNormal>
              </EmptyViewContainer>
            )}
          </Row>
        </Container>
      )}
    </LayoutWrapper>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  align-items: center;
  flex: 1;
  margin: 30px auto;
  max-width: 1080px;
  width: 100%;
`

const EmptyViewContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 200px;
  width: 100%;
`
