import React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { CopyIcon } from 'src/assets/images/svg/CopyIcon'
import { InvitationIcon } from 'src/assets/images/svg/InvitationIcon'
import { QRCodeIcon } from 'src/assets/images/svg/QRCodeIcon'
import { TwitterIcon } from 'src/assets/images/svg/TwitterIcon'
import { WhatsappIcon } from 'src/assets/images/svg/WhatsappIcon'
import { Room } from 'src/repository/types'
import { dayString, hourString } from 'src/utils/helpers/dateHelper'
import i18n from 'src/utils/localization'

export type ShareMethod =
  | 'copyLink'
  | 'invite'
  | 'qrCode'
  | 'Whatsapp'
  | 'Facebook'
  | 'Twitter'
  | 'Telegram'
  | 'Linkedin'
  | 'Pinterest'
  | 'Reddit'
  | 'Tumblr'
  | 'Pocket'

export const shareMethods: ShareMethod[] = [
  'copyLink',
  'qrCode',
  'invite',
  'Pinterest',
  'Facebook',
  'Whatsapp',
  'Linkedin',
  'Telegram',
  'Twitter',
  'Reddit',
]

export const shareMethodIcons = (room: Room, referralLink: string): Record<ShareMethod, any> => {
  const shareInfo = () => {
    const timestamp = room.start_date
    const startString = `${dayString(timestamp)}, ${hourString(timestamp)}`
    const timeText = `${i18n.t('start')}: ${startString}`
    const shareText = `${room.title}\n${room.overview}\n\n${timeText}`
    const urlKey = i18n.t('lobby')

    return {
      text: shareText,
      url: referralLink,
      full: `${shareText}\n${urlKey}: ${referralLink}`,
    }
  }
  const style = {
    padding: 10,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }

  return {
    copyLink: CopyIcon,
    invite: InvitationIcon,
    qrCode: QRCodeIcon,
    Twitter: () => (
      <TwitterShareButton
        tabIndex={-1}
        style={style}
        url={shareInfo().url}
        title={shareInfo().text}>
        <TwitterIcon size={32} />
      </TwitterShareButton>
    ),
    Facebook: () => (
      <FacebookShareButton
        tabIndex={-1}
        style={style}
        url={shareInfo().url}
        quote={shareInfo().text}>
        <FacebookIcon size={30} round />
      </FacebookShareButton>
    ),
    Whatsapp: () => (
      <WhatsappShareButton
        tabIndex={-1}
        url={shareInfo().url}
        title={shareInfo().text}
        separator=":: ">
        <WhatsappIcon size={30} />
      </WhatsappShareButton>
    ),
    Telegram: () => (
      <TelegramShareButton
        tabIndex={-1}
        style={style}
        url={shareInfo().url}
        title={shareInfo().text}>
        <TelegramIcon size={30} round />
      </TelegramShareButton>
    ),
    Linkedin: () => (
      <LinkedinShareButton tabIndex={-1} style={style} url={shareInfo().url}>
        <LinkedinIcon size={30} round />
      </LinkedinShareButton>
    ),
    Pinterest: () => (
      <PinterestShareButton
        tabIndex={-1}
        style={style}
        url={shareInfo().url}
        media={room.main_media.url}>
        <PinterestIcon size={30} round />
      </PinterestShareButton>
    ),
    Reddit: () => (
      <RedditShareButton
        tabIndex={-1}
        style={style}
        url={shareInfo().url}
        title={shareInfo().text}
        windowWidth={440}
        windowHeight={440}>
        <RedditIcon size={30} round />
      </RedditShareButton>
    ),
    Tumblr: () => (
      <TumblrShareButton tabIndex={-1} style={style} url={shareInfo().url} title={shareInfo().text}>
        <TumblrIcon size={30} round />
      </TumblrShareButton>
    ),
    Pocket: () => (
      <PocketShareButton tabIndex={-1} style={style} url={shareInfo().url} title={shareInfo().text}>
        <PocketIcon size={30} round />
      </PocketShareButton>
    ),
  }
}
