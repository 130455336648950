import React, { useLayoutEffect, useRef, useState } from 'react'
import { AddReactionIcon } from 'src/assets/images/svg/AddReactionIcon'
import { ReplyIcon } from 'src/assets/images/svg/ReplyIcon'
import { ThreadReplyIcon } from 'src/assets/images/svg/ThreadReplyIcon'
import { ThreeDotsIcon } from 'src/assets/images/svg/ThreeDotsIcon'
import { ChatChannelMessage, ChatChannelMessageGroupType } from 'src/repository/types'
import styled, { css, useTheme } from 'styled-components'
import { Row } from '../common/CommonStyles'
import { EmojiPicker } from '../common/EmojiPicker'

export const ChatChannelMessageListItemActions: React.FC<{
  message: ChatChannelMessage
  isThread: boolean
  onReactionToggle: (reaction: string) => void
  onThreadReply: (message: ChatChannelMessage) => void
  onReply: (message: ChatChannelMessage) => void
  onEdit: (message: ChatChannelMessage) => void
  onDelete: (message: ChatChannelMessage) => void
}> = ({ message, isThread, onReactionToggle, onThreadReply, onReply, onEdit, onDelete }) => {
  // MARK: - Hooks

  const containerRef = useRef<HTMLDivElement | null>(null)
  const { palette } = useTheme()
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [bottomOffset, setBottomOffset] = useState(0)

  // MARK: - Effects

  useLayoutEffect(() => {
    const offset = containerRef.current?.getBoundingClientRect().bottom ?? 0
    const [headerOffset, pickerHeight] = [160, 360]
    setBottomOffset(Math.min(0, offset - headerOffset - pickerHeight))
  }, [])

  // MARK: - Render

  return (
    <Container ref={containerRef} groupType={message.group_type}>
      <ButtonContainer style={{ paddingLeft: 10 }} onClick={() => setShowEmojiPicker(true)}>
        <AddReactionIcon size={20} fill={palette.text.secondary} />

        {showEmojiPicker && (
          <PickerContainer top={bottomOffset} isThread={isThread}>
            <EmojiPicker
              width={340}
              setEmoji={(emoji: any) => {
                onReactionToggle(emoji.native)
                setShowEmojiPicker(false)
              }}
              onClose={() => setShowEmojiPicker(false)}
            />
          </PickerContainer>
        )}
      </ButtonContainer>

      {!isThread && (
        <ButtonContainer onClick={() => onThreadReply(message)}>
          <ThreadReplyIcon size={22} fill={palette.text.secondary} />
        </ButtonContainer>
      )}

      <ButtonContainer>
        <ReplyIcon size={22} fill={palette.text.secondary} />
      </ButtonContainer>

      <ButtonContainer style={{ paddingRight: 10 }}>
        <ThreeDotsIcon size={15} fill={palette.text.secondary} />
      </ButtonContainer>
    </Container>
  )
}

const Container = styled(Row)<{ groupType: ChatChannelMessageGroupType }>`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 40px;
  box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1);
  height: 34px;
  position: absolute;
  right: 16px;
  top: ${({ groupType }) => (groupType === 'top' || groupType === 'single' ? -12 : -27)}px;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.primary};
        box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);
      }
    `};
`

const ButtonContainer = styled(Row)`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  padding: 7px;
  position: relative;
`

const PickerContainer = styled.div<{ top: number; isThread: boolean }>`
  bottom: ${({ top }) => -6 + top}px;
  padding-bottom: 36px;
  position: absolute;
  right: ${({ isThread }) => (isThread ? -69 : -104)}px;
  width: 340px;
  z-index: 10;
`
