import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { firebaseStorage } from './firebase'

export const uploadBlobToFirebaseStorage = async (
  blobUrl: string,
  child: string,
): Promise<string> => {
  const getFileBlob = async (url: string): Promise<any> => {
    return new Promise((resolve, _) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.addEventListener('load', () => resolve(xhr.response))
      xhr.send()
    })
  }

  const fileBlob = await getFileBlob(blobUrl)
  const snapshot = await uploadBytes(ref(firebaseStorage, child), fileBlob)
  const downloadURL = await getDownloadURL(snapshot.ref)
  return downloadURL
}
