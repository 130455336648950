import { RoomChatChannelSection } from './RoomChatChannelSection'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { EmptyState } from 'src/features/common/EmptyState'
import { getLoading } from 'src/redux/reducers/app'
import {
  getMemberChannels,
  getMemberSearchResults,
  getTopicChannels,
  searchMember,
} from 'src/redux/reducers/roomChat'
import { ChatChannel, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RoomChatChannelList: React.FC<{
  room: Room
  selectedChannelId: string | null
  onChannelClick: (channel: ChatChannel) => void
}> = ({ room, selectedChannelId, onChannelClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [memberSearchText, setMemberSearchText] = useState('')

  const isLoading = useSelect(state => getLoading(state.app, 'channels'))
  const isSearchLoading = useSelect(state => getLoading(state.app, 'room_chat_search'))
  const channels = useSelect(state => getTopicChannels(state.roomChat))
  const memberChannels = useSelect(state =>
    memberSearchText
      ? getMemberSearchResults(state.roomChat, memberSearchText)
      : getMemberChannels(state.roomChat),
  )

  // MARK: - Effects

  useEffect(() => {
    if (memberSearchText) dispatch(searchMember(room.id, memberSearchText, 0, 20))
  }, [memberSearchText])

  // MARK: - Render

  if (!channels.length && !isLoading) {
    return (
      <EmptyState
        title={t('noChannelsCreated')}
        subtitle={t('noChannelsCreatedDescription')}
        style={{ marginLeft: 32, marginRight: 32 }}
      />
    )
  }

  return (
    <Container>
      <RoomChatChannelSection
        title={t('channels')}
        channels={channels}
        type="topic"
        selectedChannelId={selectedChannelId}
        onChannelClick={onChannelClick}
        isLoading={isLoading}
      />

      <RoomChatChannelSection
        title={t('members')}
        channels={memberChannels}
        type="oneToOne"
        selectedChannelId={selectedChannelId}
        onChannelClick={onChannelClick}
        isLoading={isLoading || isSearchLoading}
        onChannelSearch={text => setMemberSearchText(text)}
      />
    </Container>
  )
}

// MARK: - Render

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};

  @media ${device.smallerThan.laptop} {
    padding: 0px 0px;
  }

  @media ${device.largerThan.laptop} {
    height: calc(100vh - 72px);
    max-height: calc(100vh - 72px);
    overflow: scroll;
  }
`
