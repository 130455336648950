import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import {
  Col,
  FlexCol,
  FlexRow,
  LabelHeaderLarge,
  LabelMedium,
  LabelSmall,
  RoundedImage,
  Row,
} from 'src/features/common/CommonStyles'
import { ComponentWrapper } from 'src/features/common/ComponentWrapper'
import { EmptyState } from 'src/features/common/EmptyState'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { fetchAnnouncements } from 'src/redux/reducers/room'
import { Announcement, Component, Room } from 'src/repository/types'
import { openURL } from 'src/utils/helpers/linkHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const AnnouncementList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { palette } = useTheme()
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<Announcement | null>(null)
  const isLoading = useSelect(state => getLoading(state.app, 'announcement'))
  const announcements = useSelect(state =>
    getEntities<Announcement>(
      state.entity,
      'announcement',
      announcement => announcement.component_id === component.id,
    ).sort((first, second) => second.created_at - first.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchAnnouncements(component.id))
  }, [component, dispatch])

  // MARK: - Render

  const renderItem = useCallback(
    (announcement: Announcement, index: number) => (
      <ItemContainer
        key={announcement.id}
        onPointerEnter={() => setHoverIndex(index)}
        index={index}
        onClick={() => setSelectedAnnouncement(announcement)}>
        <FlexRow>
          {announcement.image_url && (
            <RoundedImage style={{ marginTop: 2 }} size={48} src={announcement.image_url} />
          )}
          <FlexCol>
            <LabelMedium
              style={{ color: hoverIndex === index ? palette.primary : palette.text.primary }}>
              {announcement.title}
            </LabelMedium>
            <LabelSmall style={{ marginTop: 2, ...numberOfLines(3) }}>
              {announcement.overview}
            </LabelSmall>
          </FlexCol>
        </FlexRow>

        {hoverIndex === index ? (
          <RightArrowIcon style={{ height: 12, width: 8, marginLeft: 8 }} />
        ) : (
          <div style={{ width: 16 }} />
        )}
      </ItemContainer>
    ),
    [hoverIndex],
  )

  return (
    <ComponentWrapper room={room} component={component}>
      <Container onPointerLeave={() => setHoverIndex(null)}>
        <EmptyState
          hide={!!announcements.length}
          isLoading={isLoading}
          title={t('noAnnouncementsPostedYet')}
          subtitle={t('oncePublisherPostedAnnouncements')}
        />

        {announcements.length > 0 && announcements.map(renderItem)}
      </Container>

      {selectedAnnouncement && (
        <AnnouncementModal
          announcement={selectedAnnouncement}
          onClose={() => setSelectedAnnouncement(null)}
        />
      )}
    </ComponentWrapper>
  )
}

export const AnnouncementModal: React.FC<{
  announcement: Announcement
  onClose: () => void
}> = ({ announcement, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const imageRef = useRef<HTMLImageElement | null>(null)
  const imageHeight = imageRef.current?.offsetHeight ?? 1000

  // MARK: - Render

  return (
    <ModalWrapper
      size="tiny"
      onClose={onClose}
      closeOnBackgroundClick
      disableClose
      height="dynamic">
      <FlexCol>
        <ImageContainer>
          <HeaderImage
            style={{ top: (announcement.image_offset ?? 0) * imageHeight }}
            ref={imageRef}
            src={announcement.image_url}
            alt={''}
          />
        </ImageContainer>
        <FlexCol style={{ padding: 24 }}>
          <LabelHeaderLarge style={{ marginTop: -8, marginBottom: 8 }}>
            {announcement.title}
          </LabelHeaderLarge>
          <LabelMedium style={{ marginBottom: 16 }}>{announcement.overview}</LabelMedium>

          <Row>
            {announcement.link && (
              <PrimaryButton
                style={{ minWidth: 180 }}
                title={t(announcement.action_name)}
                onClick={() => openURL(announcement.link)}
              />
            )}
          </Row>
        </FlexCol>
      </FlexCol>
    </ModalWrapper>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
`

const HeaderImage = styled.img`
  object-fit: cover;
  position: absolute;
  user-select: none;
  width: 100%;
`

const ItemContainer = styled(FlexRow)<{ index: number }>`
  align-items: center;
  background-color: ${({ theme, index }) =>
    index % 2 === 0 ? theme.palette.background.primary : theme.palette.background.secondary};
  cursor: pointer;
  justify-content: space-between;
  padding: 18px 20px;
`

const ImageContainer = styled.div`
  overflow: hidden;
  padding-bottom: 45.4%;
  position: relative;
  width: calc(100% px);
`
