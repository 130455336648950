import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { deleteEntities, getEntities } from 'src/redux/reducers/entity'
import { fetchComponent, fetchRoom } from 'src/redux/reducers/room'
import { Priority, realtime } from 'src/repository/realtime'
import { Component } from 'src/repository/types'
import { maxPlusNone } from 'src/utils/helpers/arrayHelper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const useComponentObserver = (roomId: string) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const components = useSelect(state =>
    getEntities<Component>(state.entity, 'component', ({ room_id }) => room_id === roomId),
  )

  const singlePath = `realtime_updates/room/${roomId}/component/set/single`
  const allPath = `realtime_updates/room/${roomId}/component/set/all`
  const timestamp = maxPlusNone(components, 'updated_at')

  // MARK: - Effects

  useEffect(() => {
    realtime.observe(
      singlePath,
      timestamp,
      { priority: Priority.veryHigh, types: ['child_added', 'child_changed'] },
      ({ action, component_id }) => {
        if (component_id) {
          if (action === 1) dispatch(fetchComponent(component_id))
          else if (action === 2) dispatch(deleteEntities({ component: [component_id] }))
        }
      },
    )

    realtime.observe(
      allPath,
      timestamp,
      { priority: Priority.veryHigh, types: ['child_added', 'child_changed'] },
      ({ action, room_id }) => {
        if (room_id && action === 1) dispatch(fetchRoom(room_id))
      },
    )

    return () => {
      realtime.dispose(singlePath)
      realtime.dispose(allPath)
    }
  }, [singlePath, allPath, timestamp])
}
