import cryptLib from '@skavinvarnan/cryptlib'
import { flatten, map } from 'lodash'
import moment from 'moment'
import 'moment/locale/tr'
import {
  AnyChatMessage,
  AnyChatMessageWithDate,
  ChatChannelMessage,
  ChatMessageListDate,
} from 'src/repository/types'
import i18n from '../localization'
import { Localization } from '../types/i18n'

export const assignGroupsToMessages = (messages: AnyChatMessage[]): AnyChatMessage[] => {
  const mappedMessages: (AnyChatMessage & { user_id: string })[] = messages.map(message => {
    // @ts-ignore
    if (!message.user_id) return { ...message, user_id: message.user._id }
    else return message as ChatChannelMessage
  })

  const messageGroups: {
    groupId: number
    createdAt: number
    userId: string
    messages: AnyChatMessage[]
  }[] = []

  // Alternate groups by owner id;
  let groupId: number = 0
  let messageGroupOwnerId: string | null = null

  mappedMessages.forEach(message => {
    const groupingInterval = 60 * 5 // 5 minutes
    const coefficient = 1000 * groupingInterval
    const date = message.created_at * 1000
    const rounded = new Date(Math.floor(date / coefficient) * coefficient)
    const createdAtMinutes = Math.floor(rounded.getTime() / 1000)

    if (message.user_id !== messageGroupOwnerId) {
      messageGroupOwnerId = message.user_id
      groupId += 1
    }

    // Try to get existing message group
    const currentMessageGroup = messageGroups.find(
      messageGroup =>
        messageGroup.createdAt === createdAtMinutes &&
        messageGroup.userId === message.user_id &&
        messageGroup.groupId === groupId,
    )

    // If we've got the existing group, add the message, otherwise create a new group
    if (currentMessageGroup) {
      currentMessageGroup.messages.push(message)
    } else {
      messageGroups.push({
        groupId: groupId,
        createdAt: createdAtMinutes,
        userId: message.user_id,
        messages: [message],
      })
    }

    messageGroups.forEach(messageGroup => {
      const messageGroupLength = messageGroup.messages.length
      const isSingle = messageGroupLength === 1
      for (let index = 0; index < messageGroupLength; index++) {
        const newMessage = messageGroup.messages[index]
        if (isSingle) {
          newMessage.group_type = 'single'
        } else if (index === messageGroupLength - 1) {
          newMessage.group_type = 'bottom'
        } else if (index === 0) {
          newMessage.group_type = 'top'
        } else {
          newMessage.group_type = 'middle'
        }
      }
    })
  })
  return flatten(map(messageGroups, 'messages'))
}

export const isChannelMessageListDate = (
  toBeDetermined: AnyChatMessageWithDate,
): toBeDetermined is ChatMessageListDate => {
  if ((toBeDetermined as ChatMessageListDate).timestamp) return true
  return false
}

export const insertTimeHeadersToMessages = (
  messages: AnyChatMessage[],
  lng: Localization,
): AnyChatMessageWithDate[] => {
  const messageGroups: Record<number, AnyChatMessageWithDate[]> = {}

  messages.forEach(message => {
    const milliSeconds = message.created_at * 1000
    const startOfDay = moment(milliSeconds).startOf('day').unix()

    if (!messageGroups[startOfDay]) messageGroups[startOfDay] = []
    messageGroups[startOfDay].push(message)
  })

  let messagesWithHeaders: AnyChatMessageWithDate[] = []

  for (const key of Object.keys(messageGroups)) {
    const startOfDay = parseInt(key, 10)
    const header: ChatMessageListDate = {
      timestamp: startOfDay,
      title: moment(startOfDay * 1000).calendar(null, {
        sameDay: `[${i18n.t('today', { lng })}]`,
        lastDay: `[${i18n.t('yesterday', { lng })}]`,
        lastWeek: 'dddd',
        sameElse: 'MMMM d, YYYY',
      }),
    }
    messagesWithHeaders.push(header)
    messagesWithHeaders = [...messagesWithHeaders, ...messageGroups[startOfDay]]
  }

  return messagesWithHeaders
}

// Encryption

const makeKey = (channelId: string, userId: string) => {
  const prefix = 'devs_house_rocks_2022'
  return `${prefix}_${channelId}_${userId}`
}

export const encryptMessage = (
  message: ChatChannelMessage,
  channelId: string,
): ChatChannelMessage => {
  const text = cryptLib.encryptPlainTextWithRandomIV(
    message.text,
    makeKey(channelId, message.user_id),
  )
  return { ...message, text }
}

export const decryptMessage = (
  message: ChatChannelMessage,
  channelId: string,
): ChatChannelMessage => {
  const text = cryptLib.decryptCipherTextWithRandomIV(
    message.text,
    makeKey(channelId, message.user_id),
  )
  return { ...message, text }
}
