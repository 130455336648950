import React from 'react'
import styled, { keyframes, useTheme } from 'styled-components'

type Props = {
  style?: React.CSSProperties
  visible?: boolean
  thickness?: number
  size?: number
  color?: string
}

export const Loader: React.FC<Props> = ({
  style,
  visible = true,
  size = 32,
  thickness = 4,
  color,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Container
      size={size}
      thickness={thickness}
      style={{
        ...style,
        display: visible ? 'block' : 'none',
        borderLeftColor: color ?? palette.primary,
        borderTopColor: color ?? palette.primary,
      }}
    />
  )
}

// MARK: - Styles

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Container = styled.div<{ size: number; thickness: number }>`
  animation: ${rotate} 0.6s linear infinite;
  border: ${({ thickness }) => thickness}px solid transparent;
  border-left: ${({ thickness }) => thickness}px solid ${({ theme }) => theme.palette.primary};
  border-radius: 50%;
  border-top: ${({ thickness }) => thickness}px solid ${({ theme }) => theme.palette.primary};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`
