import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getLoading } from 'src/redux/reducers/app'
import { setReminder } from 'src/redux/reducers/room'
import { Room, Subscription } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import { SetReminderDesktop } from './SetReminderDesktop'
import { SetReminderMobile } from './SetReminderMobile'

export const ReminderModal: React.FC<{
  room: Room
  subscription: Subscription | null
  visible: boolean
  onClose: () => void
}> = props => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { subscription, visible, onClose } = props
  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()
  const isLoading = useSelect(state => getLoading(state.app, 'reminder'))
  const reminderOptions: { key: string; value: number }[] = [
    { key: t('minutes', { minute: 5 }), value: 5 * 60 },
    { key: t('minutes', { minute: 10 }), value: 10 * 60 },
    { key: t('minutes', { minute: 30 }), value: 30 * 60 },
    { key: t('hour', { hour: 1 }), value: 1 * 60 * 60 },
    { key: t('hours', { hour: 2 }), value: 2 * 60 * 60 },
    { key: t('hours', { hour: 6 }), value: 6 * 60 * 60 },
    { key: t('hours', { hour: 12 }), value: 12 * 60 * 60 },
    { key: t('day', { day: 1 }), value: 1 * 24 * 60 * 60 },
    { key: t('days', { day: 2 }), value: 2 * 24 * 60 * 60 },
    { key: t('days', { day: 4 }), value: 4 * 24 * 60 * 60 },
    { key: t('week', { week: 1 }), value: 7 * 24 * 60 * 60 },
  ]

  // MARK: - Actions

  const handleSetReminder = (time: number) => {
    if (subscription) {
      const isUpdated = !!subscription?.push_date
      dispatch(setReminder(subscription, time))
      setTimeout(() => {
        onClose()
        toast.success(isUpdated ? t('reminderUpdateMessage') : t('reminderSetMessage'))
      }, 400)
    }
  }

  const handleRemoveReminder = () => {
    if (subscription) {
      dispatch(setReminder(subscription, null))
      setTimeout(() => {
        onClose()
        toast.success(t('reminderRemoveMessage'))
      }, 400)
    }
  }

  // MARK: - Render

  return isMobile ? (
    <SetReminderMobile
      {...props}
      isLoading={isLoading}
      reminderOptions={reminderOptions}
      onSetReminder={handleSetReminder}
      onRemoveReminder={handleRemoveReminder}
    />
  ) : visible ? (
    <SetReminderDesktop
      {...props}
      isLoading={isLoading}
      reminderOptions={reminderOptions}
      onSetReminder={handleSetReminder}
      onRemoveReminder={handleRemoveReminder}
    />
  ) : null
}
