import produce from 'immer'
import { pushError } from 'src/redux/reducers/app'
import {
  DeleteAction,
  deleteEntities,
  MergeAction,
  mergeEntities,
  ReplaceAction,
} from 'src/redux/reducers/entity'
import { Action, AsyncThunk, Thunk } from 'src/redux/store'
import { componentService } from 'src/repository/services/componentService'
import { invitationService } from 'src/repository/services/audienceService'
import { roomService } from 'src/repository/services/roomService'
import { subscriptionService } from 'src/repository/services/subscriptionService'
import { Invitation, QAQuestion, RoomPrivacyType, Subscription } from 'src/repository/types'
import { setReferralInvitation } from './cover'

type State = {
  restrictedPrivacyType: RoomPrivacyType | null
  accessCode: string | null
  referralLink: string | null
  shouldRedirect: boolean
  drawerCollapsed: boolean
}

type ActionType =
  | { type: 'room/setRestrictedRoomPrivacyType'; privacyType: RoomPrivacyType | null }
  | { type: 'room/setAccessCode'; accessCode: string | null }
  | { type: 'room/setReferralLink'; referralLink: string | null }
  | { type: 'room/setShouldRedirect'; shouldRedirect: boolean }
  | { type: 'room/setDrawerCollapsed'; collapsed: boolean }
  | { type: 'roomCover/setReferralInvitation'; referralInvitation: Invitation | null }
  | MergeAction
  | ReplaceAction
  | DeleteAction
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  restrictedPrivacyType: null,
  accessCode: null,
  referralLink: null,
  shouldRedirect: false,
  drawerCollapsed: true,
}

// MARK: - Reducer

export const roomReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'room/setRestrictedRoomPrivacyType':
      return produce(state, draft => {
        draft.restrictedPrivacyType = action.privacyType
        return draft
      })

    case 'room/setDrawerCollapsed':
      return produce(state, draft => {
        draft.drawerCollapsed = action.collapsed
        return draft
      })

    case 'room/setAccessCode':
      return produce(state, draft => {
        draft.accessCode = action.accessCode
        return draft
      })

    case 'room/setReferralLink':
      return produce(state, draft => {
        draft.referralLink = action.referralLink
        return draft
      })

    case 'room/setShouldRedirect':
      return produce(state, draft => {
        draft.shouldRedirect = action.shouldRedirect
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const setDrawerCollapsed = (collapsed: boolean): ActionType => ({
  type: 'room/setDrawerCollapsed',
  collapsed,
})

export const setCalendarEvent =
  (subscription: Subscription, eventId: string | null): Thunk<ActionType> =>
  dispatch =>
    subscriptionService
      .updateSubscription({
        id: subscription.id,
        event_id: eventId ?? '',
      })
      .loading('calendar')
      .done(
        value => dispatch(mergeEntities({ subscription: [value] })),
        error => dispatch(pushError(error)),
      )

export const setReminder =
  (subscription: Subscription, pushDate: number | null): Thunk<ActionType> =>
  dispatch =>
    subscriptionService
      .updateSubscription({
        id: subscription.id,
        push_date: pushDate ?? 0,
      })
      .loading('reminder')
      .done(
        value => dispatch(mergeEntities({ subscription: [value] })),
        error => dispatch(pushError(error)),
      )

export const fetchRoom =
  (roomId: string, accessCode?: string): AsyncThunk<ActionType> =>
  async dispatch => {
    const response = await roomService.fetchRoom(roomId, accessCode)

    if (response.success && response.value.room) {
      dispatch(mergeEntities({ room: [response.value.room], user: response.value.publishers }))
    } else {
      if (response.retCode === 200004) dispatch(setRestrictedRoomPrivacyType('invite-only'))
      else if (response.retCode === 200005) dispatch(setRestrictedRoomPrivacyType('private'))
      else if (response.retCode === 200006) dispatch(setRestrictedRoomPrivacyType('managed'))
    }
  }

export const fetchComponent =
  (componentId: string): Thunk<ActionType> =>
  dispatch => {
    componentService.fetchComponent(componentId).done(
      component => dispatch(mergeEntities({ component: [component] })),
      error => dispatch(pushError(error)),
    )
  }

export const fetchSubscription =
  (roomId: string): Thunk<ActionType> =>
  dispatch =>
    subscriptionService
      .fetchSubscription(roomId)
      .loading('subscription')
      .done(subscription => {
        if (subscription?.is_active) dispatch(mergeEntities({ subscription: [subscription] }))
      })

export const removeSubscription =
  (subscription: Subscription): Thunk<ActionType> =>
  dispatch =>
    subscriptionService
      .removeSubscription(subscription.id)
      .first(() => dispatch(deleteEntities({ subscription: [subscription.id] })))
      .done(undefined, error => dispatch(pushError(error)))

export const fetchComponents =
  (roomId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchComponents(roomId)
      .loading('components')
      .done(
        ({ component }) => dispatch(mergeEntities({ component })),
        error => dispatch(pushError(error)),
      )

export const setSubscriptionNotifications =
  (subscription: Subscription, notificationsEnabled: boolean): Thunk<ActionType> =>
  dispatch =>
    subscriptionService
      .updateSubscription({
        id: subscription.id,
        notification_enabled: notificationsEnabled,
      })
      .first(() => {
        dispatch(
          mergeEntities({
            subscription: [{ ...subscription, notification_enabled: notificationsEnabled }],
          }),
        )
      })

      .loading('subscription')
      .done(
        value => dispatch(mergeEntities({ subscription: [value] })),
        error => {
          dispatch(mergeEntities({ subscription: [subscription] }))
          dispatch(pushError(error))
        },
      )

export const fetchInvitation =
  (invitationId: string): Thunk<ActionType> =>
  dispatch =>
    invitationService
      .fetchInvitation(invitationId)
      .loading('invitation')
      .done(
        invitation => {
          dispatch(setReferralInvitation(invitation))
          dispatch({ type: 'room/setShouldRedirect', shouldRedirect: true })
        },
        error => dispatch(pushError(error)),
      )

export const setRestrictedRoomPrivacyType = (privacyType: RoomPrivacyType | null): ActionType => ({
  type: 'room/setRestrictedRoomPrivacyType',
  privacyType: privacyType,
})

export const setAccessCode = (accessCode: string | null): ActionType => ({
  type: 'room/setAccessCode',
  accessCode: accessCode,
})

export const setReferralLink = (referralLink: string | null): ActionType => ({
  type: 'room/setReferralLink',
  referralLink: referralLink,
})

export const fetchFAQs =
  (componentId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchFAQs(componentId)
      .loading('faqs')
      .done(
        faqs => dispatch(mergeEntities({ faq: faqs })),
        error => dispatch(pushError(error)),
      )

export const fetchAnnouncements =
  (componentId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchAnnouncements(componentId)
      .loading('announcement')
      .done(
        announcement => dispatch(mergeEntities({ announcement })),
        error => dispatch(pushError(error)),
      )

export const fetchPolls =
  (componentId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchPolls(componentId)
      .loading('polling')
      .done(
        ({ votes, polls }) => dispatch(mergeEntities({ poll: polls, poll_vote: votes })),
        error => dispatch(pushError(error)),
      )

export const votePoll =
  (pollId: string, optionId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .votePoll(pollId, optionId)
      .loading('polling_vote_' + pollId)
      .done(
        ({ poll, vote }) => dispatch(mergeEntities({ poll: [poll], poll_vote: [vote] })),
        error => dispatch(pushError(error)),
      )

export const fetchWebListItems =
  (componentId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchWebListItems(componentId)
      .loading('web_list')
      .done(
        list => dispatch(mergeEntities({ web_list_item: list })),
        error => dispatch(pushError(error)),
      )

export const fetchQAQuestions =
  (componentId: string): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchQAQuestions(componentId)
      .loading('qa')
      .done(
        ({ qa_questions, users }) =>
          dispatch(mergeEntities({ qa_question: qa_questions, user: users })),
        error => dispatch(pushError(error)),
      )

export const askQAQuestions =
  (componentId: string, question: string, isAnonymous: boolean): Thunk<ActionType> =>
  dispatch =>
    componentService.askQAQuestion(componentId, question, isAnonymous).done(
      value => dispatch(mergeEntities({ qa_question: [value] })),
      error => dispatch(pushError(error)),
    )

export const deleteQAQuestion =
  (question: QAQuestion): Thunk<ActionType> =>
  dispatch =>
    componentService
      .deleteQAQuestion(question.id)
      .first(() => dispatch(deleteEntities({ qa_question: [question.id] })))
      .done(undefined, error => {
        dispatch(mergeEntities({ qa_question: [question] }))
        dispatch(pushError(error))
      })

export const fetchNotifications =
  (roomId: string, offset: number, limit: number): Thunk<ActionType> =>
  dispatch =>
    componentService
      .fetchNotifications(roomId, offset, limit)
      .loading('notification')
      .done(
        notifications => dispatch(mergeEntities({ notification: notifications })),
        error => dispatch(pushError(error)),
      )

export const inviteUser =
  (
    roomId: string,
    firstName: string,
    lastName: string,
    email: string,
    notes: string,
  ): Thunk<ActionType> =>
  dispatch =>
    invitationService
      .createInvitation({
        room_id: roomId,
        first_name: firstName,
        last_name: lastName,
        email: email,
        notes: notes,
      })
      .done(
        invitation => dispatch(mergeEntities({ invitation: [invitation] })),
        error => dispatch(pushError(error)),
      )

export const fetchReferralLink =
  (roomId: string): Thunk<ActionType> =>
  dispatch =>
    roomService.fetchRoomDynamicLink(roomId).done(
      link => dispatch(setReferralLink(link)),
      error => dispatch(pushError(error)),
    )

// MARK: - Selectors

export const getRestrictedPrivacyType = (state: State): RoomPrivacyType | null => {
  return state.restrictedPrivacyType
}

export const getAccessCode = (state: State): string | null => {
  return state.accessCode
}

export const getReferralLink = (state: State): string | null => {
  return state.referralLink
}

export const getShouldRedirect = (state: State): boolean => {
  return state.shouldRedirect
}

export const getDrawerCollapsed = (state: State): boolean => {
  return state.drawerCollapsed
}
