import { api } from '../api'
import { ChatChannel, Room, User } from '../types'

export const searchService = {
  search: async (query: string, roomLimit: number, userLimit: number) =>
    api.value<{ rooms: Room[]; users: User[]; publishers: User[] }>({
      request: {
        method: 'POST',
        endPoint: '/search',
        parameters: { query, room_limit: roomLimit, user_limit: userLimit },
      },
    }),

  searchRoom: async (query: string, offset: number, limit: number) =>
    api.value<{ rooms: Room[]; publishers: User[] }>({
      request: {
        method: 'POST',
        endPoint: '/search/room',
        parameters: { query, offset, limit },
      },
    }),

  searchUser: async (query: string, offset: number, limit: number) =>
    api.prop<{ users: User[] }>('users', {
      request: {
        method: 'POST',
        endPoint: '/search/user',
        parameters: { query, offset, limit },
      },
    }),

  searchMember: async (roomId: string, query: string, offset: number, limit: number) =>
    api.value<{ users: User[]; channels: ChatChannel[] }>({
      request: {
        method: 'POST',
        endPoint: '/search/member',
        parameters: { room_id: roomId, query, offset, limit },
      },
    }),
}
