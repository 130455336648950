import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { validateEmail } from 'src/utils/helpers/emailHelper'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

type Props = {
  style?: React.CSSProperties
  userFirstName?: string
  userLastName?: string
  userEmail?: string
  onClose: () => void
  onSend: (firstName: string, lastName: string, email: string, notes: string) => void
}

export const InvitationForm: React.FC<Props> = ({
  userFirstName,
  userLastName,
  userEmail,
  style,
  onClose,
  onSend,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()

  const [firstName, setFistName] = useState(userFirstName ?? '')
  const [lastName, setLastName] = useState(userLastName ?? '')
  const [email, setEmail] = useState(userEmail ?? '')
  const [notes, setNotes] = useState('')
  const [error, setError] = useState<Record<string, string>>({})

  // MARK: - Actions

  const handleSendInvite = () => {
    if (!firstName || !lastName || !validateEmail(email)) {
      setError({
        first_name: firstName ? '' : t('invalidName'),
        last_name: lastName ? '' : t('invalidLastName'),
        email: validateEmail(email) ? '' : t('invalidEmailAddress'),
      })
      return
    }
    onSend(firstName, lastName, email, notes)
  }

  // MARK: - Render

  return (
    <Container style={style}>
      <HorizontalContainer style={{ marginBottom: 6 }}>
        <Input
          label={t('firstName')}
          status={error.first_name ? 'error' : 'default'}
          hint={error.first_name}
          disabled={!!userFirstName}
          value={firstName}
          onChange={setFistName}
          capitalize="capitalize"
          autoFocus
        />
        <div style={{ minWidth: 8 }} />
        <Input
          label={t('lastName')}
          status={error.last_name ? 'error' : 'default'}
          hint={error.last_name}
          disabled={!!userLastName}
          value={lastName}
          onChange={setLastName}
          capitalize="capitalize"
        />
      </HorizontalContainer>

      <Input
        style={{ marginBottom: -10, width: '100%' }}
        label={t('email')}
        type="email"
        disabled={!!userEmail}
        status={error.email ? 'error' : 'default'}
        hint={error.email}
        value={email}
        onChange={setEmail}
      />

      <Input
        style={{ marginBottom: 8, width: '100%', minHeight: 60 }}
        label={t('notes')}
        type="textarea"
        value={notes}
        onChange={setNotes}
      />

      <FooterWrapper>
        <SecondaryButton
          style={{
            marginRight: isMobile ? 8 : 12,
            flex: 1,
            backgroundColor: palette.background.primary,
            maxWidth: isMobile ? 240 : 180,
          }}
          title={t('back')}
          onClick={onClose}
        />

        <PrimaryButton
          style={{ flex: 1, maxWidth: isMobile ? 240 : 180 }}
          title={t('send')}
          onClick={handleSendInvite}
        />
      </FooterWrapper>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  align-items: center;
  margin: 0px 5%;
  max-width: 100vw;
  padding: 12px 16px 90px 16px;

  @media ${device.largerThan.laptop} {
    margin: 0px;
    max-width: 100vw;
    padding: 12px 0px 90px 0px;
  }
`

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`
