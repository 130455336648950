import { popLoading, pushLoading } from './app'
import { flatten, map, orderBy, sortBy, take, uniq, uniqBy } from 'lodash'
import { getEntity, mergeEntities } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { AsyncThunk, Thunk } from 'src/redux/store'
import { Error, IError } from 'src/repository/Error'
import { chatChannelService } from 'src/repository/services/chatChannelService'
import { userService } from 'src/repository/services/userService'
import { ChatChannel, ChatChannelMessage, ChatChannelMessageType, User } from 'src/repository/types'
import {
  assignGroupsToMessages,
  decryptMessage,
  encryptMessage,
} from 'src/utils/helpers/chatHelper'
import { localTime } from 'src/utils/helpers/dateHelper'
import { v4 } from 'uuid'

type State = {
  error: IError | null
  channelCanPaginate: Record<string, boolean>
  channelSendingMessages: Record<string, boolean>
  disposeBag: Record<string, (() => void) | null>
  messages: Record<string, ChatChannelMessage[]>
  threads: Record<string, ChatChannel>
  activeThreadId: string | null
}

// MARK: - State

export const initialState: State = {
  error: null,
  disposeBag: {},
  channelCanPaginate: {},
  messages: {},
  channelSendingMessages: {},
  threads: {},
  activeThreadId: null,
}

// MARK: - Reducer

export const chatChannelReducer = (
  state = initialState,
  action:
    | SetErrorAction
    | SetMessagesAction
    | SetChannelCanPaginateAction
    | SetChannelSendingMessageAction
    | SetActiveThreadIdAction
    | SetDisposableAction
    | SetThreadsAction
    | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'chat/setError':
      return { ...state, error: action.error }

    case 'chat/setChannelSendingMessage':
      return {
        ...state,
        channelSendingMessages: {
          ...state.channelSendingMessages,
          [action.channelId]: action.isSending,
        },
      }

    case 'chat/setChannelCanPaginate':
      return {
        ...state,
        channelCanPaginate: {
          ...state.channelCanPaginate,
          [action.channelId]: action.canPaginate,
        },
      }

    case 'chat/setMessages':
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.channelId]: assignGroupsToMessages(
            sortBy(uniqBy(action.messages, 'id'), 'created_at'),
          ) as ChatChannelMessage[],
        },
      }

    case 'chat/setDisposable':
      return {
        ...state,
        disposeBag: {
          ...state.disposeBag,
          [action.channelId]: action.disposable,
        },
      }

    case 'chat/setActiveThreadId':
      return { ...state, activeThreadId: action.threadId }

    case 'chat/setThreads':
      return { ...state, threads: { ...state.threads, ...action.threads } }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchChannelMessages =
  (channelId: string): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setError(null))

    const messagePageSize = 20
    const state = getState().chatChannel
    const currentMessages = getChannelMessages(state, channelId)

    dispatch(pushLoading('chat_channel_' + channelId))
    const response = await chatChannelService.fetchMessages(
      channelId,
      currentMessages.length,
      messagePageSize,
    )

    if (response.success) {
      const { chat_messages, users, threads } = response.value
      const messages = chat_messages.map(message => decryptMessage(message, channelId))
      dispatch(setThreads(threads.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})))
      dispatch(setMessages(channelId, [...currentMessages, ...messages]))
      dispatch(mergeEntities({ user: users }))
      dispatch(observeChannelMessages(channelId))
      dispatch(setChannelCanPaginate(channelId, messages.length === messagePageSize))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(popLoading('chat_channel_' + channelId))
  }

export const observeChannelMessages =
  (channelId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState().chatChannel

    // Remove existing observer.
    const currentDisposable = getDisposable(state, channelId)
    currentDisposable?.()
    dispatch(setDisposable(channelId, null))

    // Create new observer
    const messages = getChannelMessages(state, channelId)
    const updatedAt = messages.length
      ? Math.max(...messages.map(({ updated_at }) => updated_at))
      : 0

    const disposable = chatChannelService.observeChannel(
      channelId,
      updatedAt,
      async encryptedMessage => {
        const message = decryptMessage(encryptedMessage, encryptedMessage.channel_id)
        const entityState = getState().entity
        const userIdsToFetch: string[] = []
        const newMessages = getChannelMessages(getState().chatChannel, channelId)

        const sortedReplies = orderBy(
          Object.values(message.replies ?? {}),
          ['created_at'],
          ['desc'],
        )
        const uniqueUsers = uniqBy(sortedReplies, 'user_id')
        const replierUserIds = take(map(uniqueUsers, 'user_id'), 5)

        for (const userId of uniq([...replierUserIds, message.user_id])) {
          const user = getEntity<User>(entityState, 'user', userId)
          if (!user) userIdsToFetch.push(message.user_id)
        }

        if (userIdsToFetch.length) {
          const response = await userService.fetchUserBatch(userIdsToFetch)
          if (response.success) dispatch(mergeEntities({ user: response.value }))
          else dispatch(setError(response.error))
        }

        // Remove `local_id` duplicates, note that we optimistically save message to store on send.
        const uniqueLocalMessages = uniqBy([message, ...newMessages], 'local_id')
        dispatch(setMessages(channelId, uniqueLocalMessages))
      },
      deletedMessage => {
        const newMessages = getChannelMessages(getState().chatChannel, channelId)
        const filteredMessages = newMessages.filter(message => message.id !== deletedMessage.id)
        dispatch(setMessages(channelId, filteredMessages))
      },
    )
    dispatch(setDisposable(channelId, disposable))
  }

export const sendChannelMessage =
  (
    channelId: string,
    text: string,
    chatMessageType: ChatChannelMessageType,
    isThread: boolean,
  ): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setChannelSendingMessage(channelId, true))

    const localId = v4()
    const meUserId = getMeUser(getState().me)?.id ?? ''
    let message = makeLocalMessage(meUserId, localId, text, channelId)
    const currentMessages = getChannelMessages(getState().chatChannel, channelId)
    dispatch(setMessages(channelId, [message, ...currentMessages]))

    message = encryptMessage(message, channelId)
    const response = await chatChannelService.sendMessage(
      channelId,
      localId,
      message.text,
      isThread,
      chatMessageType,
    )

    if (response.success) {
      message = decryptMessage(response.value, channelId)
      dispatch(setMessages(channelId, [message, ...currentMessages]))
    } else {
      dispatch(setError(response.error))
      dispatch(setMessages(channelId, currentMessages))
    }
    dispatch(setChannelSendingMessage(channelId, false))
  }

export const toggleReaction =
  (channelId: string, messageId: string, reaction: string): AsyncThunk =>
  async (dispatch, getState) => {
    const meUserId = getMeUser(getState().me)?.id
    let message = getSingleMessage(getState().chatChannel, channelId, messageId)
    if (!message || !meUserId) {
      dispatch(setError(Error.someThingWentWrong()))
      return
    }

    const existingReaction = (message.reactions ?? []).find(
      item => item.user_id === meUserId && item.reaction === reaction,
    )
    message = {
      ...message,
      reactions: existingReaction
        ? (message.reactions ?? []).filter(
            item => item.user_id !== meUserId || item.reaction !== reaction,
          )
        : [
            ...(message.reactions ?? []),
            { user_id: meUserId, reaction: reaction, created_at: localTime() },
          ],
    }
    const currentMessages = getChannelMessages(getState().chatChannel, channelId)
    dispatch(setMessages(channelId, [message, ...currentMessages]))

    message = encryptMessage(message, channelId)
    const response = await chatChannelService.toggleReaction(messageId, reaction, !existingReaction)

    if (response.success) {
      message = decryptMessage(response.value, channelId)
      dispatch(setMessages(channelId, [message, ...currentMessages]))
    } else {
      dispatch(setError(response.error))
      dispatch(setMessages(channelId, currentMessages))
    }
  }

export const flushChannel =
  (channelId: string): Thunk =>
  async (dispatch, getState) => {
    const state = getState().chatChannel

    const disposable = getDisposable(state, channelId)
    disposable?.()
    dispatch(setDisposable(channelId, null))
    dispatch(setMessages(channelId, []))
  }

export const openThread =
  (messageId: string): AsyncThunk =>
  async (dispatch, getState) => {
    const thread = getThread(getState().chatChannel, messageId)
    dispatch(setActiveThreadId(messageId))

    dispatch(pushLoading('chat_channel_' + thread?.id))
    const response = await chatChannelService.createThread(messageId)

    if (response.success) dispatch(setThreads({ [messageId]: response.value }))
    else dispatch(setError(response.error))

    dispatch(popLoading('chat_channel_' + thread?.id))
  }

export const closeThread = (): SetActiveThreadIdAction => ({
  type: 'chat/setActiveThreadId',
  threadId: null,
})

export const setError = (error: IError | null): SetErrorAction => ({
  type: 'chat/setError',
  error: error,
})

// MARK: - Selectors

export const getError = (state: State): IError | null => {
  return state.error
}

export const getChannelMessages = (state: State, channelId: string): ChatChannelMessage[] => {
  return state.messages[channelId] ?? []
}

export const getSingleMessage = (
  state: State,
  channelId: string,
  messageId: string,
): ChatChannelMessage | null => {
  return state.messages[channelId].find(({ id }) => id === messageId) ?? null
}

export const getSingleMessageById = (
  state: State,
  messageId: string,
): ChatChannelMessage | null => {
  const allMessages = flatten(Object.values(state.messages))
  return allMessages.find(({ id }) => id === messageId) ?? null
}

export const getChannelCanPaginate = (state: State, channelId: string): boolean => {
  return state.channelCanPaginate[channelId] ?? false
}

export const getChannelSendingMessage = (state: State, channelId: string): boolean => {
  return state.channelSendingMessages[channelId] ?? false
}

export const getDisposable = (state: State, channelId: string): (() => void) | null => {
  return state.disposeBag[channelId] ?? null
}

export const getIsThreadOpen = (state: State): boolean => {
  return !!state.activeThreadId
}

export const getActiveThread = (state: State): ChatChannel | null => {
  const activeThreadId = getActiveThreadId(state)
  if (!activeThreadId) return null
  return state.threads[activeThreadId] ?? null
}

export const getActiveThreadId = (state: State): string | null => {
  return state.activeThreadId
}

export const getThread = (state: State, messageId: string): ChatChannel | null => {
  return state.threads[messageId] ?? null
}

// MARK: - Action Types

type SetErrorAction = {
  type: 'chat/setError'
  error: IError | null
}

type SetChannelCanPaginateAction = {
  type: 'chat/setChannelCanPaginate'
  channelId: string
  canPaginate: boolean
}

type SetChannelSendingMessageAction = {
  type: 'chat/setChannelSendingMessage'
  channelId: string
  isSending: boolean
}

type SetMessagesAction = {
  type: 'chat/setMessages'
  channelId: string
  messages: ChatChannelMessage[]
}

type SetThreadsAction = {
  type: 'chat/setThreads'
  threads: Record<string, ChatChannel>
}

type SetDisposableAction = {
  type: 'chat/setDisposable'
  channelId: string
  disposable: (() => void) | null
}

type SetActiveThreadIdAction = {
  type: 'chat/setActiveThreadId'
  threadId: string | null
}

// MARK: - Internal Actions

const setChannelCanPaginate = (
  channelId: string,
  canPaginate: boolean,
): SetChannelCanPaginateAction => ({
  type: 'chat/setChannelCanPaginate',
  channelId: channelId,
  canPaginate: canPaginate,
})

const setChannelSendingMessage = (
  channelId: string,
  isSending: boolean,
): SetChannelSendingMessageAction => ({
  type: 'chat/setChannelSendingMessage',
  channelId: channelId,
  isSending: isSending,
})

const setMessages = (channelId: string, messages: ChatChannelMessage[]): SetMessagesAction => ({
  type: 'chat/setMessages',
  channelId: channelId,
  messages: messages,
})

const setDisposable = (
  channelId: string,
  disposable: (() => void) | null,
): SetDisposableAction => ({
  type: 'chat/setDisposable',
  channelId: channelId,
  disposable: disposable,
})

const setActiveThreadId = (threadId: string | null): SetActiveThreadIdAction => ({
  type: 'chat/setActiveThreadId',
  threadId: threadId,
})

const setThreads = (threads: Record<string, ChatChannel>): SetThreadsAction => ({
  type: 'chat/setThreads',
  threads: threads,
})

const makeLocalMessage = (
  userId: string,
  localId: string,
  text: string,
  channelId: string,
): ChatChannelMessage => {
  return {
    id: v4(),
    user_id: userId,
    local_id: localId,
    channel_id: channelId,
    group_type: 'bottom',
    is_thread: false,
    text: text,
    type: 'text',
    parent_id: null,
    quoted_id: null,
    replies: {},
    reactions: [],
    mentions: [],
    attachments: [],
    created_at: localTime(),
    updated_at: localTime(),
  }
}
