import { EmojiData, Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import React, { memo, useEffect } from 'react'
import { useTheme } from 'styled-components'

export const EmojiPicker = memo(
  ({
    width = 420,
    setEmoji,
    onClose,
  }: {
    width?: number
    setEmoji: (emoji: EmojiData) => void
    onClose: () => void
  }) => {
    // MARK: - Hooks

    const picker = React.useRef<any | null>(null)
    const { isDark, palette } = useTheme()

    // MARK: - Effects

    useEffect(() => {
      document.addEventListener('click', handlePickerCloseClick)
      return () => {
        document.removeEventListener('click', handlePickerCloseClick)
      }
    }, [])

    // MARK: - Actions

    const filterEmojis = ({ added_in }: EmojiData & { added_in: string }): boolean => {
      return parseInt(added_in, 10) < 11
    }

    const handlePickerCloseClick = (event: MouseEvent | any) => {
      if (!event.target?.closest(`.${picker.current?.className}`)) {
        event.stopPropagation()
        onClose()
      }
    }

    // MARK: - Render

    return (
      <div
        onClick={event => event.stopPropagation()}
        style={{
          width: '100%',
          maxWidth: width,
          zIndex: 20,
        }}>
        <Picker
          onClick={(emoji, event) => event.stopPropagation()}
          ref={picker}
          native
          theme={isDark ? 'dark' : 'light'}
          emojisToShowFilter={filterEmojis}
          style={{
            width: '100%',
            maxWidth: width,
            borderWidth: 0,
            borderRadius: 10,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
            zIndex: 20,
          }}
          emojiSize={24}
          color={palette.primary}
          showSkinTones={false}
          showPreview={false}
          onSelect={emoji => {
            setEmoji(emoji)
            onClose()
          }}
          useButton
        />
      </div>
    )
  },
  (prev, next) => prev.width === next.width,
)
