import React from 'react'
import { Helmet } from 'react-helmet'
import { Room } from 'src/repository/types'

type Props = {
  room: Room | null
}

export const MetaHeader: React.FC<Props> = props => {
  // MARK: Properties

  const { room } = props

  // MARK: - Render

  return (
    <Helmet>
      <meta
        name="apple-itunes-app"
        content="app-id=1582032539, app-clip-bundle-id=house.devs.lobbi.Clip, app-clip-display=card"
      />
      <meta name="og:title" content={room?.title} />
      <meta name="og:description" content={room?.overview} />
      <meta name="og:url" content={room?.short_link} />
      <meta name="og:image" content={room?.main_media?.url} />
      <title>{room?.title}</title>
    </Helmet>
  )
}
