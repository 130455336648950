import { ComponentHeader } from '../ComponentHeader'
import { RoomChatChannelList } from './RoomChatChannelList'
import { RoomChatChannelListHeader } from './RoomChatChannelListHeader'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol } from 'src/features/common/CommonStyles'
import { getIsThreadOpen } from 'src/redux/reducers/chatChannel'
import { fetchChatChannels, cleanRoomChat } from 'src/redux/reducers/roomChat'
import { ChatChannel, Room } from 'src/repository/types'
import { sidebarOptionsImage } from 'src/utils/helpers/sidebarHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RoomChat: React.FC<{
  room: Room
  selectedChannelId: string | null
  setSelectedChannel: (channel: ChatChannel | null) => void
}> = ({ room, selectedChannelId, setSelectedChannel }) => {
  // MARK: - Hooks

  const directMessagePageSize = 20

  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()
  const { t } = useTranslation()

  const isThreadOpen = useSelect(state => getIsThreadOpen(state.chatChannel))

  const [collapsed, setCollapsed] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    dispatch(cleanRoomChat())
    dispatch(fetchChatChannels(room.id, directMessagePageSize))
  }, [room.id, dispatch])

  // MARK: - Render

  if (!room.config.chat_enabled) return null

  return (
    <Container collapsed={collapsed} isThreadOpen={isThreadOpen}>
      {!isMobile ? (
        <RoomChatChannelListHeader
          room={room}
          collapsed={collapsed}
          onCollapseClick={setCollapsed}
        />
      ) : (
        <ComponentHeader
          room={room}
          style={{ marginTop: 12 }}
          component={{ title: t('chats'), image_url: sidebarOptionsImage('chats'), overview: '' }}
        />
      )}

      {!collapsed && (
        <RoomChatChannelList
          room={room}
          selectedChannelId={selectedChannelId}
          onChannelClick={setSelectedChannel}
        />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)<{ collapsed: boolean; isThreadOpen: boolean }>`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  display: ${({ isThreadOpen }) => (isThreadOpen ? 'none' : 'flex')};
  flex: 1;
  z-index: 3;

  @media ${device.smallerThan.laptop} {
    height: calc(100vh - 72px);
  }

  @media ${device.largerThan.laptop} {
    height: calc(100vh - 72px);
    min-width: ${({ collapsed }) => (collapsed ? '56px' : '260px')};
    width: ${({ collapsed }) => (collapsed ? '56px' : '20vw')};
    max-width: ${({ collapsed }) => (collapsed ? '56px' : '20vw')};
  }
`
