import { groupBy, map, orderBy, take, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import { Col, LabelMedium, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { UserImage } from 'src/features/common/UserImage'
import { getLocalization } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { ChatChannelMessage, User } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css } from 'styled-components'

export const ChatChannelMessageListItemFooter: React.FC<{
  message: ChatChannelMessage
  hideThreadFooter: boolean
  onThreadClick: () => void
  onReactionClick: (reaction: string) => void
}> = ({ message, hideThreadFooter, onThreadClick, onReactionClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const localization = useSelect(state => getLocalization(state.app))
  const meUserId = useSelect(state => getMeUser(state.me))?.id
  const [focused, setFocused] = useState(false)

  const replyCount = Object.keys(message.replies ?? {}).length
  const reactionCount = Object.keys(message.reactions ?? {}).length
  const replyKey = replyCount > 1 ? 'repliesCount' : 'replyCount'

  const lastReply = Math.max(
    ...Object.values(message.replies ?? {}).map(({ created_at }) => created_at),
  )
  const lastReplyTime = moment
    .duration(new Date().getTime() - lastReply * 1000)
    .locale(localization)
    .humanize()

  const sortedReplies = orderBy(Object.values(message.replies ?? {}), 'created_at', 'desc')
  const uniqueUsers = uniqBy(sortedReplies, 'user_id')
  const userIds = new Set(take(map(uniqueUsers, 'user_id'), 5))
  const users = useSelect(state =>
    getEntities<User>(state.entity, 'user', ({ id }) => userIds.has(id)),
  )
  const reactions = groupBy(message.reactions, 'reaction')

  // MARK: - Render

  if (!replyCount && !reactionCount) return null

  return (
    <Container>
      <Row style={{ flexWrap: 'wrap' }}>
        {Object.keys(reactions).map(reaction => (
          <ReactionContainer
            onClick={() => onReactionClick(reaction)}
            owned={!!reactions[reaction].find(({ user_id }) => user_id === meUserId)}
            key={reaction}>
            <>
              <Reaction>{reaction}</Reaction>
              <ReactionCount>{reactions[reaction].length}</ReactionCount>
            </>
          </ReactionContainer>
        ))}
      </Row>

      {replyCount && !hideThreadFooter ? (
        <ThreadContainer
          onClick={onThreadClick}
          onPointerEnter={() => setFocused(true)}
          onPointerLeave={() => setFocused(false)}>
          <Row style={{ alignItems: 'center' }}>
            {users.map(({ image_url, id }) => (
              <UserImage
                style={{ borderRadius: 4, marginRight: 6 }}
                key={id}
                url={image_url}
                size={24}
              />
            ))}
            <ThreadReplyTitle>{t(replyKey, { count: replyCount })}</ThreadReplyTitle>
            {focused && <ThreadDescription> {t('viewThread')}</ThreadDescription>}
            {!focused && (
              <ThreadDescription> {t('lastReplyTime', { time: lastReplyTime })}</ThreadDescription>
            )}
          </Row>

          {focused && <RightArrowIcon size={6} style={{ marginRight: 6 }} />}
        </ThreadContainer>
      ) : null}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  width: 100%;
`

const ThreadReplyTitle = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 13px;
  font-weight: 600;
  margin-left: 2px;
  margin-right: 8px;
`

const ThreadDescription = styled(LabelSmall)`
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  font-size: 13px;
  font-weight: 500;
`

const ThreadContainer = styled(Row)`
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;
  margin: 3px 0px;
  max-width: 600px;
  padding: 4px 6px;
  width: 80%;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.primary};
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
    `};
`

const ReactionContainer = styled(Row)<{ owned: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border: 1px solid
    ${({ theme, owned }) => (owned ? theme.palette.orange : theme.palette.background.separator)};
  border-radius: 16px;
  cursor: pointer;
  margin: 5px 0px 3px 0px;
  margin-right: 6px;
  padding: 2px 8px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.primary};
      }
    `};
`

const Reaction = styled(LabelSmall)`
  font-size: 13px;
`

const ReactionCount = styled(LabelSmall)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
`
