import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pushError } from 'src/redux/reducers/app'
import { mergeEntities } from 'src/redux/reducers/entity'
import { getMeUser, logout, setMe } from 'src/redux/reducers/me'
import { userService } from 'src/repository/services/userService'
import { User } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'

export const AccountContext = React.createContext<{
  user: Partial<User> | null
  edited: boolean
  isLoading: boolean
  setUser: (user: Partial<User>) => void
  updateMeUser: () => Promise<void>
  deleteAccount: () => Promise<void>
}>({
  user: null,
  edited: false,
  isLoading: false,
  setUser: () => {},
  deleteAccount: () => new Promise(r => r()),
  updateMeUser: () => new Promise(r => r()),
})

export const AccountContextProvider: React.FC = ({ children }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const meUser: Partial<User> = useSelect(state => getMeUser(state.me)!)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(meUser)

  const edited = useMemo(() => {
    if (!user) return false
    return (
      meUser?.first_name !== user.first_name ||
      meUser?.last_name !== user.last_name ||
      meUser?.user_name !== user.user_name ||
      meUser?.image_url !== user?.image_url ||
      meUser?.bio !== user?.bio ||
      meUser?.cover_image !== user?.cover_image ||
      JSON.stringify(meUser?.links?.map(({ name, url, type }) => name + url + type)) !==
        JSON.stringify(user?.links?.map(({ name, url, type }) => name + url + type))
    )
  }, [user, meUser])

  // MARK: - Handlers

  const deleteAccount = useCallback(async () => {
    userService.deleteUser().done(undefined, error => dispatch(pushError(error)))
    dispatch(logout())
  }, [])

  const updateMeUser = useCallback(async () => {
    setIsLoading(true)
    const response = await userService.updateUser(user)

    if (response.success) {
      dispatch(
        mergeEntities({
          user: [response.value.user],
          user_settings: [response.value.settings],
        }),
      )
      dispatch(setMe(response.value.user))
    } else {
      dispatch(pushError(response.error))
    }

    setIsLoading(false)
  }, [user])

  // MARK: - Render

  return (
    <AccountContext.Provider
      value={{ user, setUser, edited, updateMeUser, deleteAccount, isLoading }}>
      {children}
    </AccountContext.Provider>
  )
}
