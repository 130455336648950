import styled from 'styled-components'
import { Calendar } from 'react-date-range'

export const SingleDatePicker = styled(Calendar)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-color: ${({ theme }) => theme.palette.background.separator};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  min-width: 98%;
  overflow: hidden;

  .rdrWeekDays {
    background-color: ${({ theme }) => theme.palette.background.primary};
    border-color: ${({ theme }) => theme.palette.background.separator};
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }

  .rdrWeekDay {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .rdrMonth {
    min-width: 100%;
  }

  .rdrDay {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 16px;
    max-height: 40px;
    padding: 0px;
  }

  .rdrSelected {
    border-radius: 2px;
    min-height: 40px;
    margin-top: -4px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: 0;
    border: 0;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: ${({ theme }) => theme.palette.primary};
  }

  .rdrDayNumber span {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 400;
  }

  .rdrDayNumber {
    &:after {
      color: transparent;
    }
  }

  .rdrDayPassive span {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .rdrDayDisabled {
    color: ${({ theme }) => theme.palette.text.secondary};
    background: ${({ theme }) => theme.palette.background.disabled};
  }

  // Header
  .rdrMonthAndYearWrapper {
    padding-top: 0px;
    margin: 42px 0px -8px 0px;
  }

  .rdrMonthAndYearPickers select {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 500;
    font-size: 16px;
  }

  .rdrYearPicker,
  .rdrMonthPicker {
    color: ${({ theme }) => theme.palette.text.primary};
    border-radius: 4px;
    &:hover {
      background: ${({ theme }) => theme.palette.background.tertiary};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  // Buttons
  .rdrNextPrevButton {
    background-color: ${({ theme }) => theme.palette.background.primary};
    border-radius: 100;
  }

  .rdrNextButton i {
    border-left-color: ${({ theme }) => theme.palette.text.primary};
    margin-right: 10px;
  }

  .rdrPprevButton i {
    border-right-color: ${({ theme }) => theme.palette.text.primary};
    margin-left: 10px;
  }
`
