import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AccountModal } from 'src/features/account'
import { AlertModal } from 'src/features/common/AlertModal'
import { FlexRow } from 'src/features/common/CommonStyles'
import { ToastContainer } from 'src/features/common/Toast'
import { logout } from 'src/redux/reducers/me'
import { Error } from 'src/repository/Error'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { LayoutHeader } from './LayoutHeader'

export const LayoutWrapper: React.FC<{
  query?: string | null
  roomId?: string
}> = ({ query = null, roomId, children }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { routes, navigate, match } = useNavigation()
  const { isMobile } = useWindowDimensions()

  const [searchText, setSearchText] = useState(query ?? '')
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [showLogout, setShowLogout] = useState(false)

  const headerRoomId = useMemo(() => {
    if (roomId) return !match(routes.room(roomId).cover) ? roomId : undefined
    else return undefined
  }, [roomId, match])

  // MARK: - Actions

  const handleLogoutClick = useCallback(() => {
    dispatch(logout())
    navigate(roomId ? routes.room(roomId).cover : routes.home)
  }, [roomId])

  const handleEnterPress = useCallback(() => {
    if (searchText) navigate(routes.search(searchText))
    else navigate(routes.home)
  }, [searchText])

  const handleSearchIconPress = useCallback(() => {
    setSearchText('')
    navigate(routes.home)
  }, [])

  // MARK: - Render

  return (
    <div>
      <LayoutHeader
        roomId={headerRoomId}
        onEnterPress={handleEnterPress}
        searchText={searchText}
        setSearchText={setSearchText}
        onSearchPress={handleSearchIconPress}
        onAccountPress={() => setShowAccountModal(true)}
        onLogoutPress={() => setShowLogout(true)}
      />

      <ToastContainer
        style={{
          marginTop: 62,
          marginBottom: 100,
          marginRight: isMobile ? 16 : '21.5vw',
          marginLeft: 16,
          maxWidth: 'calc(100vw - 32px)',
          maxHeight: isMobile ? 32 : 40,
        }}
        position={isMobile ? 'bottom-center' : 'top-right'}
        autoClose={1800}
        hideProgressBar={false}
        pauseOnFocusLoss={false}
        closeOnClick
        rtl={false}
      />

      <Container>{children}</Container>

      {showAccountModal && <AccountModal onClose={() => setShowAccountModal(false)} />}

      {showLogout && (
        <AlertModal
          error={Error.displayable(
            t('seeYouAround'),
            t('logoutMessage'),
            success => {
              if (success) handleLogoutClick()
            },
            t('logout'),
          )}
          visible={showLogout}
          onClose={() => setShowLogout(false)}
        />
      )}
    </div>
  )
}

// MARK: - Styles

const Container = styled(FlexRow)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  flex: 1;

  @media ${device.smallerThan.laptop} {
    flex-direction: column;
  }

  @media ${device.largerThan.tablet} {
    min-height: calc(100vh - 72px);
  }
`
