import { getAuth } from 'firebase/auth'
import produce from 'immer'
import { IError } from 'src/repository/Error'
import { userService } from 'src/repository/services/userService'
import { Me, User } from 'src/repository/types'
import config from 'src/utils/config'
import { AsyncThunk, Thunk } from '../store'
import { pushError } from './app'
import { MergeAction, mergeEntities } from './entity'

// MARK: - Types

type State = {
  error: IError | null
  me: Me | null
  idToken: string | null
}

type ActionType =
  | { type: 'me/setMe'; me: Me }
  | { type: 'me/setIdToken'; idToken: string }
  | { type: 'me/setError'; error: IError | null }
  | { type: 'me/logout' }
  | MergeAction

// MARK: - State

export const initialState: State = {
  error: null,
  me: null,
  idToken: null,
}

// MARK: - Reducer

export const meReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'me/setMe':
      return produce(state, draft => {
        draft.me = action.me
        return draft
      })

    case 'me/setIdToken':
      return produce(state, draft => {
        draft.idToken = action.idToken
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: Actions

export const setMe =
  (user: User): AsyncThunk<ActionType> =>
  async dispatch => {
    analytics.identify(user.id, {
      mobile: window.screen.width < 1024,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    })
    dispatch({ type: 'me/setMe', me: { id: user.id, user } })
  }

export const setIdToken = (idToken: string): ActionType => ({
  type: 'me/setIdToken',
  idToken: idToken,
})

export const setNotificationToken =
  (token: string): AsyncThunk<ActionType> =>
  async dispatch => {
    const response = await userService.updateUserNotificationToken(token)

    if (response.success) {
      const user = response.value
      dispatch(setMe(user))
      dispatch(mergeEntities({ user: [user] }))
    } else {
      dispatch(pushError(response.error))
    }
  }

export const logout = (): Thunk<ActionType> => async dispatch => {
  await getAuth().signOut()
  localStorage.removeItem(config.keys.firebaseToken)
  dispatch({ type: 'me/logout' })
}

// MARK: - Selectors

export const getMe = (state: State): Me | null => {
  return state.me
}

export const getIsAuthenticated = (state: State): boolean => {
  return !!state.idToken
}

export const getMeId = (state: State): string | null => {
  return state.me?.id ?? null
}

export const getIdToken = (state: State): string | null => {
  return state.idToken
}

export const getMeUser = (state: State): User | null => {
  return state.me?.user ?? null
}

// MARK: - Internal Actions

// const setLoading = (isLoading: boolean): SetSetLoadingAction => ({
//   type: 'me/setLoading',
//   isLoading: isLoading,
// })

// export const setError = (error: IError | null): SetSetErrorAction => ({
//   type: 'me/setError',
//   error: error,
// })

// const setMeUser = (user: User): SetMeAction => ({
//   type: 'me/setMe',
//   me: {
//     id: user.id,
//     user: user,
//   },
// })
