import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Col, FadeIn, FlexCol, LabelHeader, LabelMedium } from 'src/features/common/CommonStyles'
import { Separator } from 'src/features/common/Separator'
import { getLocalization } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { getDrawerCollapsed, setDrawerCollapsed } from 'src/redux/reducers/room'
import { Component, Room } from 'src/repository/types'
import { openURL } from 'src/utils/helpers/linkHelper'
import { homeSidebarOption } from 'src/utils/helpers/sidebarHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RoomSideBar: React.FC<{
  room: Room
  selectedItem: number
  onSideBarItemClick: (item: number) => void
}> = ({ room, selectedItem, onSideBarItemClick }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()

  const localization = useSelect(state => getLocalization(state.app))
  const drawerCollapsed = useSelect(state => getDrawerCollapsed(state.room))
  const components = useSelect(state =>
    getEntities<Component>(
      state.entity,
      'component',
      component => component.room_id === room.id,
    ).sort((first, second) => first.order - second.order),
  )

  const shouldRenderBanners = room.config.banners.length > 0

  // MARK: - Effects

  useEffect(() => {
    dispatch(setDrawerCollapsed(isMobile))
  }, [isMobile])

  // MARK: - Render

  return (
    <>
      {isMobile && !drawerCollapsed && (
        <FadeIn style={{ zIndex: 10 }}>
          <Col
            onClick={() => dispatch(setDrawerCollapsed(true))}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.2)',
              height: 'calc(100vh - 72px)',
              width: '100vw',
              zIndex: 10,
            }}
          />
        </FadeIn>
      )}

      <Container
        visible={!isMobile || !drawerCollapsed}
        style={{
          position: isMobile ? 'absolute' : 'relative',
          height: isMobile ? 'calc(100vh - 72px)' : undefined,
        }}>
        <Header>
          <SideBarImage src={room.main_media.url} />
          <HeaderBackground>
            <HeaderTitle>{room.title}</HeaderTitle>
          </HeaderBackground>
        </Header>

        <FlexCol>
          {[homeSidebarOption(localization), ...components].map((route, index) => (
            <RoomSideBarItem
              key={index}
              isSelected={index === selectedItem}
              option={route}
              onOptionSelect={() => onSideBarItemClick(index)}
            />
          ))}
        </FlexCol>

        {shouldRenderBanners && (
          <Footer>
            <Separator margin={0} />
            <Carousel
              autoPlay
              infiniteLoop
              swipeable={false}
              interval={5000}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              onClickItem={index => openURL(room.config.banners[index]?.link)}>
              {room.config.banners.map((banner, index) => (
                <div key={index} style={{ cursor: 'pointer' }}>
                  <SideBarImage style={{ cursor: 'pointer' }} src={banner.image_url} />
                </div>
              ))}
            </Carousel>
          </Footer>
        )}
      </Container>
    </>
  )
}

const RoomSideBarItem: React.FC<{
  option: { title: string; image_url: string }
  isSelected: boolean
  onOptionSelect: () => void
}> = ({ option, isSelected, onOptionSelect }) => (
  <ItemContainer isSelected={isSelected} onClick={onOptionSelect}>
    <img style={{ width: 22, height: 22, marginRight: 12 }} src={option.image_url} alt="" />
    <Title isSelected={isSelected}>{option.title}</Title>
  </ItemContainer>
)

// MARK: - Styles

const Container = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.25s ease-out;
  width: 300px;
  z-index: 10;
`

const Header = styled.div`
  height: 140px;
  position: relative;
  width: 100%;
`

const SideBarImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const HeaderBackground = styled.div`
  align-items: flex-end;
  background-color: #00000090;
  bottom: 0px;
  display: flex;
  justify-content: center;
  left: 0px;
  padding-bottom: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
`

const HeaderTitle = styled(LabelHeader)`
  color: ${({ theme }) => theme.palette.white};
  margin: 0px;
  padding: 0px 8px;
  text-align: center;
`

const Footer = styled.div`
  align-self: flex-end;
  height: 130px;
  width: 100%;
`

const ItemContainer = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.background.secondary : theme.palette.background.primary};
  border-left: ${({ isSelected }) => (isSelected ? 4 : 0)}px solid
    ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  display: flex;
  height: 40px;
  min-height: 40px;
  padding: 0px 16px;
  padding-left: ${({ isSelected }) => (isSelected ? 12 : 16)}px;
  position: relative;
`

const Title = styled(LabelMedium)<{ isSelected: boolean }>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.text.primary : theme.palette.text.secondary};
`
