import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AddIcon } from 'src/assets/images/svg/AddIcon'
import { MinusIcon } from 'src/assets/images/svg/MinusIcon'
import {
  FlexCol,
  FlexRow,
  HoverBox,
  LabelMedium,
  LabelNormal,
} from 'src/features/common/CommonStyles'
import { ComponentWrapper } from 'src/features/common/ComponentWrapper'
import { EmptyState } from 'src/features/common/EmptyState'
import { PageLoader } from 'src/features/common/PageLoader'
import { getLoading } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { fetchFAQs } from 'src/redux/reducers/room'
import { Component, FAQ, Room } from 'src/repository/types'
import { openURL } from 'src/utils/helpers/linkHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const FAQList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { palette } = useTheme()
  const { t } = useTranslation()
  const isLoading = useSelect(state => getLoading(state.app, 'faqs'))
  const faqs = useSelect(state =>
    getEntities<FAQ>(state.entity, 'faq', faq => faq.component_id === component.id).sort(
      (first, second) => second.created_at - first.created_at,
    ),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchFAQs(component.id))
  }, [component.id, dispatch])

  // MARK: - Render

  return (
    <ComponentWrapper
      style={{ backgroundColor: palette.background.primary }}
      room={room}
      component={component}>
      {faqs.length === 0 &&
        (isLoading ? (
          <PageLoader />
        ) : (
          <EmptyState title={t('noFAQsPostedYet')} subtitle={t('oncePublisherPostedFAQs')} />
        ))}

      {faqs.length > 0 &&
        faqs.map(faq => <FAQListItem key={faq.id} faq={faq} onFAQLinkClick={openURL} />)}
    </ComponentWrapper>
  )
}

export const FAQListItem: React.FC<{
  faq: FAQ
  onFAQLinkClick: (link: string) => void
}> = ({ faq, onFAQLinkClick }) => {
  // MARK: - Hooks

  const [showAnswer, setShowAnswer] = useState(false)
  const { isMobile } = useWindowDimensions()
  const { palette } = useTheme()

  // MARK: - Actions

  const handleFAQLinkClick = useCallback(
    (link: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      if (link) onFAQLinkClick(link)
    },
    [],
  )

  // MARK: - Render

  return (
    <HoverBox
      style={{
        backgroundColor: palette.background.tertiary,
        marginBottom: 12,
        marginLeft: isMobile ? 16 : 0,
        marginRight: isMobile ? 16 : 0,
      }}
      onClick={() => setShowAnswer(prev => !prev)}>
      <InnerContainer>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <LabelMedium>{faq.question}</LabelMedium>
          {showAnswer ? (
            <MinusIcon style={{ minWidth: 26 }} size={26} />
          ) : (
            <AddIcon style={{ minWidth: 26 }} size={26} />
          )}
        </FlexRow>

        {showAnswer ? (
          <FlexRow style={{ marginTop: 8 }}>
            <AnswerIndicator />
            <Answer>
              {faq.answer}
              {faq.links.map((link, index) => (
                <LinkContainer
                  key={link + index}
                  onClick={event => handleFAQLinkClick(link, event)}>
                  {link}
                </LinkContainer>
              ))}
            </Answer>
          </FlexRow>
        ) : null}
      </InnerContainer>
    </HoverBox>
  )
}

// MARK: - Styles

const InnerContainer = styled(FlexCol)`
  padding: 20px 16px;

  @media ${device.smallerThan.laptop} {
    padding: 16px 8px 16px 12px;
  }
`

const AnswerIndicator = styled.div`
  background-color: ${({ theme }) => theme.palette.green};
  border-radius: 2px;
  margin-right: 12px;
  min-height: 100%;
  min-width: 4px;
  width: 4px;
`

const Answer = styled(LabelNormal)`
  color: ${({ theme }) => theme.palette.text.primary};
  width: 90%;
`

const LinkContainer = styled.div`
  color: ${({ theme }) => theme.palette.blue};
  cursor: pointer;
  margin-top: 8px;
  text-decoration-line: underline;
  width: 90%;
  word-break: break-all;
`
