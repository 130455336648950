import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, FlexCol, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import {
  fetchRandomCoverImages,
  getCoverImages,
  queryCoverImages,
  trackCoverImageDownload,
} from 'src/redux/reducers/profile'
import { CoverImage } from 'src/repository/types'
import { openURL } from 'src/utils/helpers/linkHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

type Props = {
  onImageSelect: (image: string) => void
}

export const ProfileChangeCoverModalUnsplash: React.FC<Props> = ({ onImageSelect }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [currentText, setCurrentText] = useState('')
  const [query, setQuery] = useState('')
  const images = useSelect(state => getCoverImages(state.profile, query) ?? [])

  // MARK: - Effects

  useEffect(() => {
    if (!images.length) dispatch(fetchRandomCoverImages(15, 'landscape'))
  }, [])

  useEffect(() => {
    if (query && !images.length) dispatch(queryCoverImages(query, 27, 'landscape'))
  }, [query])

  // MARK: - Actions

  const handleImageSelect = (image: CoverImage) => {
    onImageSelect(image.url)
    dispatch(trackCoverImageDownload(image))
  }

  // MARK: - Hooks

  return (
    <Container>
      <SearchContainer />
      <Input
        style={{ position: 'absolute', left: 10, right: 10 }}
        inputStyle={{ fontSize: 14 }}
        value={currentText}
        onChange={setCurrentText}
        onEnterPress={() => setQuery(currentText)}
        placeholder={t('searchAnImage')}
      />

      <Gallery>
        {images.map(image => (
          <GalleryItemContainer key={image.url}>
            <GalleryImageContainer>
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                src={image.thumb}
                alt=""
                onClick={() => handleImageSelect(image)}
              />
            </GalleryImageContainer>

            <Row style={{ width: '100%' }}>
              <LabelSmall style={{ fontSize: 12 }}>{'by'}</LabelSmall>
              <LabelSmall
                style={{
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                  fontSize: 12,
                  ...numberOfLines(1),
                  marginLeft: 2,
                }}
                onClick={() => openURL(image.ref.portfolio)}>
                {image.ref.name}
              </LabelSmall>
            </Row>
          </GalleryItemContainer>
        ))}
      </Gallery>
    </Container>
  )
}

// MARK: - Style

const Container = styled(FlexCol)`
  align-items: center;
  min-height: 362px;
  padding: 10px;
  position: relative;
`

const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  height: 60px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
`

const GalleryItemContainer = styled(Col)`
  align-items: center;
  border-radius: 4px;
  height: 92px;
  justify-content: center;
  margin: 3px 0.6%;
  overflow: hidden;
  width: 23.8%;
`

const GalleryImageContainer = styled.div`
  height: 72px;
  width: 100%;
`

const Gallery = styled(Row)`
  flex-wrap: wrap;
  margin: 60px -0.6% 0px -0.6%;
  overflow: scroll;
`
