import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CaretRightIcon } from 'src/assets/images/svg/CaretRightIcon'
import { DownArrowIcon } from 'src/assets/images/svg/DownArrowIcon'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { Col, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { getMeId } from 'src/redux/reducers/me'
import { ChatChannel, ChatChannelType } from 'src/repository/types'
import config from 'src/utils/config'
import { openURL } from 'src/utils/helpers/linkHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css, useTheme } from 'styled-components'
import { RoomChatChannelListItem } from './RoomChatChannelListItem'
import { RoomChatChannelListLoader } from './RoomChatChannelListLoader'

export const RoomChatChannelSection: React.FC<{
  style?: React.CSSProperties
  title: string
  type: ChatChannelType
  channels: ChatChannel[]
  isLoading?: boolean
  selectedChannelId: string | null
  initialSearchText?: string
  onChannelClick?: (channel: ChatChannel) => void
  onChannelSearch?: (text: string) => void
}> = ({
  style,
  title,
  type,
  channels,
  selectedChannelId,
  initialSearchText = '',
  isLoading = false,
  onChannelSearch,
  onChannelClick,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const { routes } = useNavigation()

  const meUserId = useSelect(state => getMeId(state.me))

  const [searchEnabled, setSearchEnabled] = useState(false)
  const [focused, setFocused] = useState(false)
  const [searchText, setSearchText] = useState(initialSearchText)
  const [expanded, setExpanded] = useState(true)

  // MARK: - Effects

  useEffect(() => {
    if (!expanded) setSearchEnabled(false)
  }, [expanded])

  useEffect(() => {
    if (searchEnabled) setExpanded(true)
  }, [searchEnabled])

  useEffect(() => {
    if (!searchText) onChannelSearch?.('')
  }, [searchText])

  // MARK: - Handlers

  const handleChannelClick = useCallback((channel: ChatChannel) => {
    if (channel.type === 'oneToOne') {
      const memberId = channel.member_ids.find(id => id !== meUserId)
      if (memberId) openURL(config.lobbiUrl + routes.profile(memberId).main)
    } else {
      onChannelClick?.(channel)
    }
  }, [])

  // MARK: - Render

  return (
    <Col style={style}>
      <Container
        onPointerEnter={() => setFocused(true)}
        onPointerLeave={() => setFocused(false)}
        onClick={() => setExpanded(prev => !prev)}>
        <Row>
          {expanded ? <DownArrowIcon size={22} /> : <CaretRightIcon size={22} />}
          <LabelMedium style={{ fontSize: 16, marginLeft: 6 }}>{title}</LabelMedium>
        </Row>
        {focused && type === 'oneToOne' && !searchEnabled && (
          <ButtonContainer
            onClick={event => {
              event.stopPropagation()
              setSearchEnabled(true)
            }}>
            <SearchIcon size={14} />
          </ButtonContainer>
        )}
      </Container>

      {searchEnabled && (
        <Input
          style={{ height: 36, paddingLeft: 16, paddingRight: 16 }}
          inputStyle={{
            height: 36,
            marginBottom: 4,
            borderRadius: 8,
            paddingLeft: 8,
            fontSize: 14,
          }}
          hasIcon="right"
          autoFocus
          placeholder={t('searchMembers')}
          value={searchText}
          onChange={setSearchText}
          onEnterPress={() => onChannelSearch?.(searchText)}>
          <SearchIcon size={16} fill={palette.text.secondary} />
        </Input>
      )}

      {isLoading && <RoomChatChannelListLoader count={type === 'oneToOne' ? 8 : 4} />}

      {!isLoading &&
        channels
          .filter(({ id }) => searchEnabled || expanded || id === selectedChannelId)
          .map((chatChannel, index) => (
            <RoomChatChannelListItem
              key={index}
              isSelected={chatChannel.id === selectedChannelId}
              channel={chatChannel}
              onChannelClick={handleChannelClick}
            />
          ))}
    </Col>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  cursor: pointer;
  height: 36px;
  justify-content: space-between;
  padding: 0px 16px 0px 2px;
`

const ButtonContainer = styled(Row)`
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1px;
  padding: 8px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
        border: 1px solid ${({ theme }) => theme.palette.background.separator};
        margin-right: 0px;
      }
    `}
`
