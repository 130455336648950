import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol, FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { User } from 'src/repository/types'
import styled from 'styled-components'
import { ImageUploader } from '../common/ImageUploader'

export const AccountInfo: React.FC<{
  user: Partial<User>
  onImageChange: (image: string) => void
  onNameChange: (name: string) => void
  onSurnameChange: (surname: string) => void
  onUserNameChange: (username: string) => void
  onEmailChange: (email: string) => void
  onEnterPress: () => void
}> = ({
  user,
  onImageChange,
  onEmailChange,
  onNameChange,
  onSurnameChange,
  onUserNameChange,
  onEnterPress,
}) => {
  // MARK: - Properties

  const { t } = useTranslation()
  const profileImageSize = 1000

  // MARK: - Render

  return (
    <FlexCol style={{ marginBottom: 0 }}>
      <FlexRow>
        <UploaderContainer>
          <ImageUploader
            style={{ height: 197 }}
            sizes={[profileImageSize]}
            initialImageUrl={user.image_url}
            title={t('profileImage')}
            description={t('dragDrop', { ratio: '(1:1)' })}
            restriction={t('imageSizeLimit')}
            aspectRatio={1}
            onImageUploaded={urls => onImageChange(urls[profileImageSize])}
            onImageRemoved={() => onImageChange('')}
          />
        </UploaderContainer>

        <FlexCol>
          <Input
            label={t('name')}
            isRequired
            value={user.first_name}
            onChange={text => onNameChange(text)}
            onEnterPress={onEnterPress}
          />
          <Input
            label={t('lastName')}
            isRequired
            value={user.last_name}
            onChange={text => onSurnameChange(text)}
            onEnterPress={onEnterPress}
          />
          <Input
            label={t('userName')}
            value={user.user_name}
            onChange={text => onUserNameChange(text)}
            onEnterPress={onEnterPress}
          />
        </FlexCol>
      </FlexRow>

      <Input
        label={t('email')}
        disabled
        placeholder={t('enterYourEmail')}
        type="email"
        value={user.email}
        onChange={onEmailChange}
      />

      <Input
        label={t('phoneNumber')}
        placeholder={t('enterYourPhoneNumber')}
        type="phone"
        disabled
        value={user.phone_number}
        onChange={() => {}}
      />
    </FlexCol>
  )
}

// MARK: - Styles

const UploaderContainer = styled.div`
  margin-right: 20px;
  width: 226px;
`
