import { getApps } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { head } from 'lodash'

export type NotificationStatus = typeof window.Notification.permission

export const messaging = {
  getToken: async () => {
    const app = head(getApps())
    if (!app) return ''
    const messagingApp = getMessaging(app)
    return await getToken(messagingApp)
  },
  supported: async () => {
    return !!window.Notification && (await isSupported())
  },
  permission: (): NotificationStatus => window.Notification.permission,
}
