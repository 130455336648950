import React from 'react'
import { Col } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { RoomCoverHeaderDate } from './RoomCoverHeaderDate'

export const RoomCoverHeader: React.FC<{
  room: Room
}> = ({ room }) => {
  return (
    <Container>
      <ImageContainer>
        <Image src={room.main_media?.url} />
      </ImageContainer>

      <RoomCoverHeaderDate room={room} />
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin-bottom: 20px;
  position: relative;
`

const ImageContainer = styled.div`
  border-radius: 8px;
  display: flex;
  height: 460px;
  margin: 0px auto;
  overflow: hidden;
  position: relative;

  @media ${device.smallerThan.laptop} {
    height: 400px;
    border-radius: 0px;
  }

  @media ${device.smallerThan.tablet} {
    height: 320px;
  }
`

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: calc(460px * 106 / 46);
`
