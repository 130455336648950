import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { InvitationForm } from 'src/features/common/InvitationForm'
import { inviteUser } from 'src/redux/reducers/room'
import { Room } from 'src/repository/types'

export const ShareInvite: React.FC<{
  room: Room
  onClose: () => void
  onSend: () => void
}> = ({ room, onClose, onSend }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  // MARK: - Actions

  const handleSendInvite = (firstName: string, lastName: string, email: string, notes: string) => {
    dispatch(inviteUser(room.id, firstName, lastName, email, notes))
    toast.success(t('invitationSent'))
    onSend()
  }

  // MARK: - Render

  return <InvitationForm onClose={onClose} onSend={handleSendInvite} />
}
