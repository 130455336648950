import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelect } from 'src/utils/hooks/useSelect'
import { ProfileIcon } from 'src/assets/images/svg/ProfileIcon'
import { FlexCol, FlexRow, LabelMedium, LabelSmall } from 'src/features/common/CommonStyles'
import { getEntity } from 'src/redux/reducers/entity'
import { Room, User } from 'src/repository/types'
import { dayString, hourString } from 'src/utils/helpers/dateHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import styled from 'styled-components'

export const RoomHomePublisher: React.FC<{
  room: Room
}> = ({ room }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { navigate, routes } = useNavigation()
  const user = useSelect(state => getEntity<User>(state.entity, 'user', room.publisher_id))

  // MARK: - Properties

  const time = `${dayString(room.created_at, true)}, ${hourString(room.created_at)}`
  const name = `${user?.first_name} ${user?.last_name}`

  // MARK: - Render

  return (
    <FlexCol style={{ paddingTop: 20, paddingBottom: 12 }}>
      <FlexRow style={{ alignItems: 'center', paddingBottom: 8 }}>
        {user?.image_url ? <Avatar src={user?.image_url} /> : <ProfileIcon size={40} />}

        <FlexCol style={{ marginLeft: 12, flex: 1 }}>
          <LabelMedium>{name}</LabelMedium>
          <LabelSmall style={{ marginTop: 0 }}>{time}</LabelSmall>
        </FlexCol>

        <SeeMoreButton onClick={() => navigate(routes.profile(room.publisher_id).main)}>
          {t('viewProfile')}
        </SeeMoreButton>
      </FlexRow>
    </FlexCol>
  )
}

// MARK: - Styles

const Avatar = styled.img`
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 22px;
  height: 40px;
  width: 40px;
`

const SeeMoreButton = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 1px 16px;
`
