import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setCalendarEvent } from 'src/redux/reducers/room'
import { Room, Subscription } from 'src/repository/types'
import {
  buildCalendarUrl,
  CalendarEvent,
  CalendarType,
  isCalendarMobile,
} from 'src/utils/helpers/calendarHelper'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import { v4 } from 'uuid'
import { CalendarDesktop } from './CalendarDesktop'
import { CalendarMobile } from './CalendarMobile'

export const CalendarModal: React.FC<{
  room: Room
  subscription: Subscription | null
  visible: boolean
  onClose: () => void
}> = props => {
  // MARK: - Hooks

  const { isMobile } = useWindowDimensions()
  const dispatch = useDispatch()
  const { room } = props

  const event: CalendarEvent = {
    title: room.title,
    description: room.overview,
    location: room.location?.address ?? '',
    startTime: moment.utc(new Date(room.start_date * 1000)).toISOString(),
    endTime: room.end_date
      ? moment.utc(new Date(room.start_date * 1000)).toISOString()
      : moment.utc(new Date((room.start_date + 60 * 60) * 1000)).toISOString(),
  }

  // MARK: - Actions

  const handleCalendarTypeClick = (calendarType: CalendarType) => {
    let isCrappyIE = false
    if (
      typeof window !== 'undefined' &&
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob &&
      window.Blob
    ) {
      isCrappyIE = true
    }

    const url = buildCalendarUrl(event, calendarType, isCrappyIE)
    if (!isCalendarMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
      const filename = `${event.title}.ics`
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

      if (isCrappyIE) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } else {
      window.open(url, '_blank')
    }

    if (props.subscription) dispatch(setCalendarEvent(props.subscription, v4()))
  }

  // MARK: - Render

  return isMobile ? (
    <CalendarMobile {...props} onCalendarTypeClick={handleCalendarTypeClick} />
  ) : props.visible ? (
    <CalendarDesktop {...props} onCalendarTypeClick={handleCalendarTypeClick} />
  ) : null
}
