import { api } from '../api'

import { Collaborator, Component, Room, Subscription, User } from '../types'

export const roomService = {
  fetchRoom: async (roomId: string, accessCode?: string) =>
    api.value<{ room: Room; publishers: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/room',
        parameters: { id: roomId, access_code: accessCode },
      },
    }),

  fetchRoomCover: async (roomId: string, userId?: string) =>
    api.value<{
      room: Room
      publisher: User
      collaborator: Collaborator
      subscription: Subscription
      components: Component[]
    }>({
      request: {
        method: 'GET',
        endPoint: '/room/cover',
        parameters: { room_id: roomId, user_id: userId },
      },
    }),

  fetchRooms: async (publisherId: string, ignoreStatus: boolean) =>
    api.value<{ rooms: Room[]; publishers: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/rooms',
        parameters: { publisher_id: publisherId, ignore_status: ignoreStatus },
      },
    }),

  fetchRoomDynamicLink: async (roomId: string) =>
    api.prop<{ dynamic_link: string }>('dynamic_link', {
      request: {
        method: 'GET',
        endPoint: '/dynamicLink/member',
        parameters: { room_id: roomId },
      },
    }),
}
