import React from 'react'
import { Activity } from 'src/repository/types'
import { UpdateListAcceptFriendshipRequestItem } from './ActivityAcceptFriendshipRequestItem'
import { UpdateListRequestFriendshipItem } from './ActivityRequestFriendshipItem'

export const ActivityListItem: React.FC<{
  activity: Activity
}> = ({ activity }) => {
  switch (activity.payload.type) {
    case 'accept_friendship_request':
      return (
        <UpdateListAcceptFriendshipRequestItem activity={activity} payload={activity.payload} />
      )
    case 'request_friendship':
      return <UpdateListRequestFriendshipItem activity={activity} payload={activity.payload} />
  }
}
