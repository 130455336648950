import React from 'react'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import { Col, FlexRow, LabelMedium, LabelSmall } from 'src/features/common/CommonStyles'
import { Link } from 'src/repository/types'
import { linkImage } from 'src/utils/helpers/linkHelper'
import styled from 'styled-components'

export const RoomHomeLinkItem: React.FC<{
  link: Link
  onLinkClick: (link: Link) => void
}> = ({ link, onLinkClick }) => (
  <Container onClick={() => onLinkClick(link)}>
    <FlexRow style={{ alignItems: 'center' }}>
      <Image src={linkImage(link.type)} />
      <Col>
        <LabelMedium>{link.name}</LabelMedium>
        <LabelSmall>{link.url}</LabelSmall>
      </Col>
    </FlexRow>

    <RightArrowIcon style={{ height: 12, width: 8, marginLeft: 8 }} />
  </Container>
)

// MARK: - Styles

const Container = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 8px 0px;
`

const Image = styled.img`
  border-radius: 4px;
  height: 24px;
  margin-right: 12px;
  min-width: 24px;
  overflow: hidden;
  width: 24px;
`
