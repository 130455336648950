import React, { useEffect } from 'react'
import { Component, Room } from 'src/repository/types'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { ComponentHeader } from '../room/ComponentHeader'
import { FlexCol } from './CommonStyles'
import { MessageInput } from './MessageInput'

type Props = {
  room: Room
  component: Pick<Component, 'image_url' | 'title' | 'overview'>
  style?: React.CSSProperties
  showInput?: boolean
  isLoading?: boolean
  inputPlaceholder?: string
  handleSendMessage?: (text: string) => void
}

export const ComponentWrapper: React.FC<Props> = ({
  room,
  component,
  style,
  children,
  showInput,
  isLoading = false,
  inputPlaceholder = '',
  handleSendMessage = () => {},
}) => {
  // MARK: - Effects

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  // MARK: - Render

  return (
    <Container style={style}>
      <InnerContainer>
        <ComponentHeader component={component} room={room} />
        {children}
      </InnerContainer>

      {showInput ? (
        <InputContainer>
          <MessageInput
            inputStyle={{ maxWidth: 1036 }}
            isLoading={isLoading}
            placeholder={inputPlaceholder}
            onSendMessage={handleSendMessage}
          />
        </InputContainer>
      ) : null}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  flex: 1;
  height: calc(100vh - 72px);
  overflow: scroll;
  position: relative;

  @media ${device.largerThan.laptop} {
    max-height: 100vh;
  }
`

const InnerContainer = styled.div`
  flex: 1;
  margin: 0px auto;
  max-width: 1060px;
  width: 100%;

  @media ${device.smallerThan.laptop} {
    padding: 0px 8px;
    margin-top: 20px;
    margin-bottom: 80px;
  }

  @media ${device.largerThan.laptop} {
    padding: 0px 16px;
    margin: 0px auto;
    height: calc(100vh - 72px);
    overflow: scroll;
  }
`

const InputContainer = styled.div`
  position: relative;

  @media ${device.smallerThan.laptop} {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
`
