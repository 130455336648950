import React, { useCallback } from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  Col,
  FlexCol,
  FlexRow,
  LabelHeader,
  LabelMedium,
  LabelNormal,
  Row,
  ShadowBox,
} from 'src/features/common/CommonStyles'
import { EmptyState } from 'src/features/common/EmptyState'
import { FetchMoreButton } from 'src/features/common/FetchMoreButton'
import { getEntity } from 'src/redux/reducers/entity'
import { getCanPaginate, getIsLoading, searchMoreRooms } from 'src/redux/reducers/search'
import { Room, User } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const SearchRoomSection: React.FC<{
  query: string | null
  rooms: Room[]
}> = ({ rooms, query }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { navigate, routes } = useNavigation()

  const isLoading = useSelect(state => getIsLoading(state.search, 'room'))
  const canPaginate = useSelect(state => getCanPaginate(state.search, query, 'room'))
  const columnCount = Math.floor(Math.min(width, 1080) / 350)

  // MARK: - Actions

  const handleRoomClick = useCallback((room: Room) => {
    navigate(routes.room(room.id).cover)
  }, [])

  const handlePaginateClick = useCallback(() => {
    if (query) dispatch(searchMoreRooms(query))
  }, [query])

  // MARK: - Render

  return (
    <Container>
      <LabelHeader style={{ marginBottom: 20, width: columnCount * 345, alignSelf: 'center' }}>
        {t('events')}
      </LabelHeader>

      {isLoading && !rooms.length ? <Loader count={columnCount} /> : null}

      {!!rooms.length ? (
        <InnerContainer
          style={{
            justifyContent: rooms.length < columnCount ? 'flex-start' : 'center',
            width: columnCount * 345 + 20,
            alignSelf: 'center',
          }}>
          {rooms.map(room => (
            <ListItem key={room.id} room={room} onRoomSelect={handleRoomClick} />
          ))}
        </InnerContainer>
      ) : null}

      {!isLoading && !rooms.length ? (
        <EmptyState
          title={t('noRoomFound')}
          subtitle={t('noRoomFoundDescription', { query: query })}
        />
      ) : null}

      {!!rooms.length && canPaginate ? (
        <FetchMoreButton onClick={handlePaginateClick} isLoading={isLoading} />
      ) : null}
    </Container>
  )
}

export const ListItem: React.FC<{
  room: Room
  onRoomSelect: (room: Room) => void
}> = ({ room, onRoomSelect }) => {
  // MARK: - Hooks

  const publisher = useSelect(state => getEntity<User>(state.entity, 'user', room.publisher_id))

  // MARK: - Render

  return (
    <ItemContainer onClick={() => onRoomSelect(room)}>
      <Image src={room.main_media?.url} />

      <FlexCol style={{ padding: 12, flex: 1, justifyContent: 'space-between' }}>
        <LabelMedium style={numberOfLines(2)}>{room.title}</LabelMedium>
        <LabelNormal>{prettyDateString(room?.start_date ?? 0)}</LabelNormal>
      </FlexCol>

      <PublisherContainer>
        <PublisherImage
          src={publisher?.image_url || require('src/assets/images/profile_placeholder.png')}
        />
      </PublisherContainer>
    </ItemContainer>
  )
}

const Loader: React.FC<
  {
    count: number
  } & IContentLoaderProps
> = ({ count, ...props }) => {
  // MARK: - Hooks

  const { palette, isDark } = useTheme()

  // MARK: - Render

  return (
    <Row style={{ marginRight: 10, marginLeft: 10, marginBottom: 20, overflowX: 'hidden' }}>
      {Array.from({ length: count }).map((_, index) => (
        <ContentLoader
          key={index}
          style={{ marginRight: 10, marginLeft: 10, minWidth: 330 }}
          viewBox="0 0 330 330"
          height={330}
          width={330}
          backgroundColor={isDark ? palette.background.tertiary : '#DDDDDD'}
          foregroundColor={isDark ? palette.background.secondary : palette.background.primary}
          {...props}>
          <rect x="0" y="233" rx="4" ry="4" width="100" height="12" />
          <rect x="0" y="260" rx="4" ry="4" width="50" height="12" />
          <rect x="0" y="210" rx="5" ry="5" width="330" height="12" />
          <rect x="0" y="0" rx="5" ry="5" width="330" height="200" />
        </ContentLoader>
      ))}
    </Row>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin: 30px 0px;
`

const InnerContainer = styled(FlexRow)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -10px;
`

const ItemContainer = styled(ShadowBox)`
  animation-duration: 0.18s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-out;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 24px 10px;
  max-width: 330px;
  min-height: 260px;
  min-width: 330px;
  overflow: hidden;
  position: relative;
`

const Image = styled.img`
  border-radius: 8px;
  height: 165px;
  object-fit: cover;
  width: 100%;
`

const PublisherContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.background.primary};
  border-radius: 32.5px;
  height: 42px;
  overflow: hidden;
  position: absolute;
  right: 8px;
  top: 118px;
  width: 42px;
  z-index: 2;
`

const PublisherImage = styled.img`
  height: 100%;
  width: 100%;
`
