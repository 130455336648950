import styled from 'styled-components'

export const FooterWrapper = styled.div`
  align-items: center;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: footerTransition;
  animation-timing-function: ease-out;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border: 0px solid ${({ theme }) => theme.palette.background.separator};
  border-top-width: 1px;
  bottom: 0px;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  left: 0px;
  padding-right: 22px;
  position: absolute;
  right: 0px;
  z-index: 2;
`
