import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  HoverBox,
  LabelMedium,
  LabelNormal,
  LabelSmall,
  Row,
} from 'src/features/common/CommonStyles'
import { ComponentWrapper } from 'src/features/common/ComponentWrapper'
import { EmptyState } from 'src/features/common/EmptyState'
import { Loader } from 'src/features/common/Loader'
import { getLoading } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { fetchPolls, votePoll } from 'src/redux/reducers/room'
import { Component, Poll, PollOption, PollVote, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const Polling: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const polls = useSelect(state =>
    getEntities<Poll>(state.entity, 'poll', ({ component_id }) => component_id === component.id),
  )
  const votes = useSelect(state =>
    getEntities<PollVote>(
      state.entity,
      'poll_vote',
      ({ component_id }) => component_id === component.id,
    ),
  )
  const isLoading = useSelect(state => getLoading(state.app, 'polling'))

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchPolls(component.id))
  }, [component.id])

  // MARK: - Render

  return (
    <ComponentWrapper room={room} component={component}>
      <EmptyState
        isLoading={isLoading}
        hide={!!polls.length}
        title={t('noPollingPostedYet')}
        subtitle={t('oncePublisherPostedPolling')}
      />

      {polls.length > 0 &&
        polls.map(poll => (
          <PollingItem
            key={poll.id}
            poll={poll}
            vote={votes.find(({ poll_id }) => poll_id === poll.id)}
            onOptionClick={option => dispatch(votePoll(poll.id, option.id))}
          />
        ))}
    </ComponentWrapper>
  )
}

const PollingItem: React.FC<{
  poll: Poll
  vote?: PollVote
  onOptionClick: (option: PollOption) => void
}> = ({ poll, vote, onOptionClick }) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null)
  const isLoading = useSelect(state => getLoading(state.app, 'polling_vote_' + poll.id))
  const getPercentage = (optionId: string): number => {
    const totalVotes = Object.values(poll.votes ?? {}).reduce((acc, item) => acc + item, 0)
    if (!totalVotes) return 0

    const currentVote = poll.votes?.[optionId] ?? 0
    return (currentVote / totalVotes) * 100
  }

  // MARK: - Actions

  const handleOptionClick = useCallback(
    (option: PollOption) => {
      if (!isLoading && vote?.option_id !== option.id) {
        setSelectedOptionId(option.id)
        onOptionClick(option)
      }
    },
    [vote?.option_id, isLoading],
  )

  const options = useMemo(() => {
    const unsortedOptions = [...poll.options]
    return unsortedOptions.sort((a, b) => a.order - b.order)
  }, [poll.options])

  // MARK: - Render

  const SelectContainer = vote ? AnswerContainer : OptionContainer

  return (
    <Container>
      <InnerContainer>
        <LabelMedium style={{ marginTop: 6, marginBottom: 8, fontSize: 15 }}>
          {poll.question}
        </LabelMedium>
        {options.map(option => (
          <SelectContainer
            key={option.id}
            style={{ flexDirection: 'row', display: 'flex' }}
            onClick={() => handleOptionClick(option)}>
            {vote && (
              <AnswerPercentage
                ownVote={vote.option_id === option.id}
                percentage={Math.max(0, getPercentage(option.id))}
              />
            )}

            <LabelNormal style={{ color: palette.text.primary, zIndex: 1, fontSize: 15 }}>
              {option.answer}
            </LabelNormal>

            {isLoading && selectedOptionId === option.id && <Loader size={18} thickness={2} />}

            {!isLoading && vote && (
              <AnswerPercentageText>{`${getPercentage(option.id).toFixed(
                0,
              )}%`}</AnswerPercentageText>
            )}
          </SelectContainer>
        ))}
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-radius: 6px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  margin: 2px 0px 16px 0px;
  position: relative;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 16px;
  width: 100%;
`

const OptionContainer = styled(HoverBox)`
  border-radius: 6px;
  cursor: pointer;
  justify-content: space-between;
  margin: 4px 0px;
  padding: 8px 12px;
  position: relative;
`

const AnswerContainer = styled(Row)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 30px;
  cursor: pointer;
  justify-content: space-between;
  margin: 4px 0px;
  padding: 8px 12px;
  position: relative;
`

const AnswerPercentage = styled(Row)<{ percentage: number; ownVote: boolean }>`
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  background-color: ${({ theme }) => theme.palette.primary}20;
  border: 1px solid ${({ theme, ownVote }) => theme.palette.primary + (ownVote ? '' : '60')};
  border-radius: 6px;
  display: ${({ percentage }) => (!!percentage ? 'block' : 'none')};
  height: 100%;
  justify-content: space-between;
  left: 0px;
  padding: 10px 0px;
  position: absolute;
  top: 0px;
  transition: width 0.25s ease-out;
  width: ${({ percentage }) => percentage}%;
`

const AnswerPercentageText = styled(LabelSmall)`
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  font-size: 13px;
  z-index: 1;
`
