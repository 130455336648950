import { head, orderBy, sortBy } from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol, LabelSmall, Row } from 'src/features/common/CommonStyles'
import {
  clearMessages,
  fetchChatMessages,
  fetchChatMessageUpdates,
  readChatMessage,
  sendChatMessage,
  setReplyingMessage,
} from 'src/redux/reducers/chat'
import { getEntities, getEntity } from 'src/redux/reducers/entity'
import { getMeId } from 'src/redux/reducers/me'
import { ChatMessage, User } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { LabelBold } from '../common/Label'
import { MessageInput } from '../common/MessageInput'
import { UserImage } from '../common/UserImage'
import { ChatMessageList } from './ChatMessageList'

export const ChatPage: React.FC<{
  userId: string
  onBackClick?: () => void
}> = ({ userId, onBackClick }) => {
  // MARK: - Hooks

  const readTimer = useRef<NodeJS.Timer | null>(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId)!)
  const meUserId = useSelect(state => getMeId(state.me)!)

  const chatId = sortBy([userId, meUserId], item => item).join('-')
  const messages = useSelect(state => {
    return orderBy(
      getEntities<ChatMessage>(state.entity, 'message', ({ chat_id }) => chat_id === chatId),
      ['created_at'],
      ['desc'],
    )
  })

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchChatMessages(userId))
    dispatch(fetchChatMessageUpdates(userId))

    return () => {
      dispatch(clearMessages(userId))
      dispatch(setReplyingMessage(null))
    }
  }, [userId])

  useEffect(() => {
    const firstMessage = head(messages)
    if (firstMessage) {
      readTimer.current = setTimeout(() => {
        dispatch(readChatMessage(userId, firstMessage.id))
      }, 300)
    }
    return () => {
      if (readTimer.current) clearTimeout(readTimer.current)
    }
  }, [head(messages)?.id])

  // MARK: - Actions

  const handleSendMessage = useCallback(
    (text: string) => {
      if (text.length) {
        dispatch(sendChatMessage(userId, text))
      }
    },
    [userId],
  )

  // MARK: - Render

  return (
    <Container>
      <HeaderContainer>
        <UserImage url={user.image_url} size={44} />

        <FlexCol>
          <LabelBold size={16} style={{ marginLeft: 12, height: 24 }}>
            {user.first_name + ' ' + user.last_name}
          </LabelBold>
          <LabelSmall style={{ ...numberOfLines(1), flex: 1, marginLeft: 12 }}>
            {user.user_name}
          </LabelSmall>
        </FlexCol>
      </HeaderContainer>

      <InnerContainer>
        <ChatMessageList userId={userId} />

        <InputContainer>
          <MessageInput onSendMessage={handleSendMessage} placeholder={t('typeAMessage')} />
        </InputContainer>
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  overflow: hidden;
`

const InnerContainer = styled(FlexCol)`
  margin: 0px 6% 0px 6%;

  @media ${device.smallerThan.laptop} {
    margin: 0px 16px 0px 16px;
  }
`

const HeaderContainer = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: space-between;
  padding: 0px 2% 0px 2%;
  position: relative;
  width: 100%;
  z-index: 2;

  @media ${device.smallerThan.laptop} {
    padding: 0px 16px 0px 16px;
  }
`

const InputContainer = styled.div`
  position: relative;

  @media ${device.smallerThan.laptop} {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
`
