import { api } from '../api'

import { Friendship, User } from '../types'

export const friendshipService = {
  fetchFriends: async (offset: number, limit: number) =>
    api.value<{ friends: Friendship[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/friendship',
        parameters: { offset, limit },
      },
    }),

  fetchUserFriends: async (userId: string, offset: number, limit: number) =>
    api.value<{ friends: Friendship[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/friendship/user',
        parameters: { user_id: userId, offset, limit },
      },
    }),

  fetchFriendRequests: async (offset: number, limit: number) =>
    api.value<{ friends: Friendship[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/friendship/pending',
        parameters: { offset, limit },
      },
    }),

  sendFriendRequest: async (targetUserId: string) =>
    api.prop<{ friend: Friendship }>('friend', {
      request: {
        method: 'POST',
        endPoint: '/friendship',
        parameters: { target_user_id: targetUserId },
      },
    }),

  respondFriendRequest: async (friendRequestId: string, accepted: boolean) =>
    api.prop<{ friend: Friendship }>('friend', {
      request: {
        method: 'PUT',
        endPoint: '/friendship',
        parameters: { friend_request_id: friendRequestId, response: accepted },
      },
    }),

  removeFriend: async (friendshipId: string) =>
    api.prop<{ friend: Friendship }>('friend', {
      request: {
        method: 'DELETE',
        endPoint: '/friendship',
        parameters: { friendship_id: friendshipId },
      },
    }),
}
