import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { CalendarIcon } from 'src/assets/images/svg/CalendarIcon'
import { OverviewIcon } from 'src/assets/images/svg/OverviewIcon'
import {
  FlexCol,
  FlexRow,
  LabelLarge,
  LabelMedium,
  Separator,
} from 'src/features/common/CommonStyles'
import { getFirstEntity } from 'src/redux/reducers/entity'
import { setNotificationToken } from 'src/redux/reducers/me'
import { Component, Room, Subscription } from 'src/repository/types'
import { dayString, hourString } from 'src/utils/helpers/dateHelper'
import { openLink } from 'src/utils/helpers/linkHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import { messaging } from 'src/utils/messaging'
import styled, { useTheme } from 'styled-components'
import { RoomHomeComponents } from './RoomHomeComponents'
import { RoomHomHeader } from './RoomHomeHeader'
import { RoomHomeLinkItem } from './RoomHomeLinkItem'
import { RoomHomePublisher } from './RoomHomePublisher'

type Action = 'addToCalendar' | 'setReminder' | 'share' | 'chat'

export const RoomHome: React.FC<{
  room: Room
  onActionClick: (action: Action) => void
  onComponentClick: (component: Component) => void
}> = ({ room, onActionClick, onComponentClick }) => {
  // MARK: - Hooks

  const imageRef = useRef<HTMLImageElement | null>(null)
  const imageHeight = imageRef.current?.offsetHeight ?? 1000

  const dispatch = useDispatch()
  const { palette } = useTheme()
  const { t } = useTranslation()

  const subscription = useSelect(state =>
    getFirstEntity<Subscription>(
      state.entity,
      'subscription',
      ({ room_id }) => room_id === room.id,
    ),
  )

  const [notificationsSupported, setNotificationsSupported] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    checkNotificationStatus()
  }, [])

  useEffect(() => {
    if (
      notificationsSupported &&
      subscription?.notification_enabled &&
      messaging.permission() === 'granted'
    ) {
      setToken()
    }
  }, [subscription?.notification_enabled, notificationsSupported])

  // MARK: - Actions

  const checkNotificationStatus = async () => {
    const supported = await messaging.supported()
    setNotificationsSupported(supported)
  }

  const setToken = async () => {
    const token = await messaging.getToken()
    dispatch(setNotificationToken(token))
    console.info('Notification token registered:', token)
  }

  // MARK: - Render

  return (
    <Container>
      <InnerContainer>
        <RoomImageContainer>
          <img
            style={{
              objectFit: 'cover',
              position: 'absolute',
              top: (room.main_media.offset ?? 0) * imageHeight,
              width: '100%',
              userSelect: 'none',
            }}
            ref={imageRef}
            src={room.main_media.url}
            alt={''}
          />
        </RoomImageContainer>

        <RoomHomHeader room={room} subscription={subscription} onActionClick={onActionClick} />

        <FlexCol style={{ marginTop: 24 }}>
          <FlexRow style={{ paddingTop: 6, paddingBottom: 6 }}>
            <IconContainer color={palette.blue}>
              <CalendarIcon size={22} fill={palette.white} />
            </IconContainer>
            <Info>{dayString(room.start_date) + ', ' + hourString(room.start_date)}</Info>
          </FlexRow>

          {room.overview && (
            <FlexRow style={{ paddingTop: 6, paddingBottom: 6 }}>
              <IconContainer color={palette.green} style={{ marginTop: 2 }}>
                <OverviewIcon size={22} fill={palette.white} />
              </IconContainer>
              <Info>{room.overview}</Info>
            </FlexRow>
          )}

          <RoomHomeSeparator />
        </FlexCol>

        <RoomHomeComponents room={room} onComponentClick={onComponentClick} />

        <FlexCol>
          <LabelLarge style={{ marginTop: 16 }}>{t('links')}</LabelLarge>
          {room.links.map(link => (
            <RoomHomeLinkItem key={link.id} link={link} onLinkClick={() => openLink(link)} />
          ))}
          <RoomHomeSeparator />
        </FlexCol>

        {room.config.see_more_enabled ? <RoomHomePublisher room={room} /> : null}
      </InnerContainer>
    </Container>
  )
}

export const RoomHomeSeparator: React.FC = () => (
  <Separator
    margin={0}
    style={{
      opacity: 0.5,
      marginTop: 12,
      height: 2,
      minHeight: 2,
      width: 'auto',
      marginLeft: -20,
      marginRight: -20,
    }}
  />
)

// MARK: - Styles

const Container = styled(FlexCol)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  max-height: calc(100vh - 72px);
  overflow: scroll;
  padding: 12px 24px 40px 24px;

  @media ${device.smallerThan.laptop} {
    padding: 0px 0px 60px 0px;
  }
`

const InnerContainer = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  margin: 0px auto;
  max-width: 1060px;
  padding: 0px 20px;
  width: 100%;

  @media ${device.smallerThan.laptop} {
    margin: 0px auto;
    padding: 0px 16px;
  }
`

const RoomImageContainer = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin: 0px -20px;
  overflow: hidden;
  padding-bottom: 45.4%;
  position: relative;
  width: calc(100% + 40px);
`

const IconContainer = styled(FlexCol)<{ color: string }>`
  align-items: center;
  align-self: center;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  flex: 0;
  height: 28px;
  justify-content: center;
  min-width: 28px;
`

const Info = styled(LabelMedium)`
  align-self: center;
  color: ${({ theme }) => theme.palette.text.primary};
  flex: 1;
  margin-left: 12px;
`
