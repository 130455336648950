import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatChannel } from 'src/repository/types'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { MessageInput } from '../common/MessageInput'

export const ChatChannelMessageInput: React.FC<{
  channel: ChatChannel
  onSendMessage: (text: string) => void
}> = ({ channel, onSendMessage }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const placeholderKey = channel.type === 'thread' ? 'replyPlaceholder' : 'chatInputPlaceholder'

  // MARK: - Handlers

  const handleSendMessage = useCallback(
    (text: string) => {
      onSendMessage(text)
    },
    [channel.id],
  )

  // MARK: - Render

  return (
    <Container>
      <MessageInput onSendMessage={handleSendMessage} placeholder={t(placeholderKey)} />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  position: relative;

  @media ${device.smallerThan.laptop} {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
  }
`
