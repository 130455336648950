import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelect } from 'src/utils/hooks/useSelect'
import { CloseIcon } from 'src/assets/images/svg/CloseIcon'
import { Col, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { UserImage } from 'src/features/common/UserImage'
import { archiveUpdate, getIsSingleUpdateLoading, readUpdate } from 'src/redux/reducers/activity'
import { getEntity } from 'src/redux/reducers/entity'
import { Friendship, Activity, User } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import styled, { css } from 'styled-components'

export const UpdateListAcceptFriendshipRequestItem: React.FC<{
  activity: Activity
  payload: { type: 'accept_friendship_request'; friendship_id: string }
}> = ({ activity, payload }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate, routes } = useNavigation()
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [focused, setFocused] = useState(false)
  const friendship = useSelect(state =>
    getEntity<Friendship>(state.entity, 'friendship', payload.friendship_id),
  )
  const user = useSelect(state => getEntity<User>(state.entity, 'user', friendship?.target_user_id))
  const isLoading = useSelect(state => getIsSingleUpdateLoading(state.activity, activity.id))

  // MARK: - Effects

  useEffect(() => {
    if (focused && activity.status === 'unread' && activity.section === 'inbox') {
      timer.current = setTimeout(() => dispatch(readUpdate(activity)), 1000)
    } else {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [focused, isLoading])

  // MARK: - Actions

  const handleArchiveUpdateClick = () => {
    if (activity.section === 'inbox') {
      dispatch(archiveUpdate(activity))
    }
  }

  // MARK: - Render

  if (!user || !friendship) return null

  return (
    <Container
      onPointerEnter={() => setFocused(true)}
      onPointerLeave={() => setFocused(false)}
      onClick={() => navigate(routes.profile(user.id).main)}>
      <UserImage style={{ marginTop: 2 }} url={user?.image_url} size={35} />

      <Col style={{ marginLeft: 12 }}>
        <TitleBold>
          {`${user?.first_name} ${user?.last_name} `}
          <Title>{t('acceptedYourFriendRequest')}</Title>
        </TitleBold>

        <LabelSmall style={{ fontSize: 13 }}>{prettyDateString(activity.created_at)}</LabelSmall>
      </Col>

      {focused && activity.section === 'inbox' && (
        <ArchiveButton
          onClick={event => {
            event.stopPropagation()
            handleArchiveUpdateClick()
          }}>
          <CloseIcon size={16} />
        </ArchiveButton>
      )}

      {!focused && activity.status === 'unread' && <UnreadIndicator />}
    </Container>
  )
}

// MARK: - Hooks

const Container = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  cursor: pointer;
  padding: 18px 16px 20px 16px;
  position: relative;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `}
`

const TitleBold = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`

const Title = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
`

const ArchiveButton = styled(Col)`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 10px;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 19px;
  width: 20px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.tertiary};
      }
    `}
`

const UnreadIndicator = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 24px;
  width: 10px;
`
