import { AnalyticsBrowser } from '@segment/analytics-next'
import { getApps, initializeApp } from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { PageLoader } from './features/common/PageLoader'
import config from './utils/config'

export const AppInitializationProvider: React.FC<{}> = ({ children }) => {
  // MARK: - Hooks

  const [ready, setReady] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    // Initialize Firebase
    if (!getApps().length) initializeApp(config.firebaseConfig)

    // Initialize Segment
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey: config.segment_write_key })
      window.analytics = response as any
      setReady(true)
    }
    loadAnalytics()
  }, [])

  // MARK: - Render

  return <>{ready ? children : <PageLoader />}</>
}
