import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertModal } from 'src/features/common/AlertModal'
import { FlexCol, LabelMedium, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { Separator } from 'src/features/common/Separator'
import { Error } from 'src/repository/Error'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useTheme } from 'styled-components'
import { AccountContext } from './AccountContext'

export const AccountDangerZone: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { navigate, routes } = useNavigation()
  const { palette } = useTheme()
  const { deleteAccount } = useContext(AccountContext)

  const [showDeleteAccountButton, setShowDeleteAccountButton] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)

  // MARK: - Handlers

  const handleDeleteAccountClick = useCallback(() => {
    setShowDeleteAccount(true)
  }, [])

  const handleDeleteAccount = useCallback(() => {
    deleteAccount()
    onClose()
    navigate(routes.home)
  }, [])

  // MARK: - Render

  return (
    <FlexCol style={{ marginTop: 40, marginBottom: 30 }}>
      <LabelMedium style={{ fontSize: 13, fontWeight: 600, marginBottom: -14 }}>
        {t('dangerZone')}
      </LabelMedium>

      <Separator />

      <Row>
        <LabelSmall style={{ fontSize: 13, fontWeight: 500, marginBottom: 16 }}>
          {t('deleteAccountWarning')}
        </LabelSmall>

        <LabelSmall
          style={{
            marginLeft: 8,
            fontSize: 13,
            fontWeight: 400,
            marginBottom: 16,
            cursor: 'pointer',
            color: palette.red,
          }}
          onClick={() => {
            if (showDeleteAccountButton) handleDeleteAccountClick()
            else setShowDeleteAccountButton(true)
          }}>
          {showDeleteAccountButton ? t('deleteAccount') : t('yes')}
        </LabelSmall>
      </Row>

      {showDeleteAccount && (
        <AlertModal
          error={Error.displayable(
            t('seeYouAround'),
            t('deleteMessage'),
            success => success && handleDeleteAccount(),
            t('delete'),
          )}
          visible={showDeleteAccount}
          onClose={() => setShowDeleteAccount(false)}
        />
      )}
    </FlexCol>
  )
}
