import { ToastContainer as _ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const ToastContainer = styled(_ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  width: 400px;

  .toast {
    background-color: ${({ theme }) => theme.palette.background.primary};
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.palette.background.separator};
  }

  button[aria-label='close'] {
    display: none;
  }

  .body {
    font-size: 17px;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary};
    line-height: 24px;
    margin-bottom: 6px;
    margin-top: 6px;
    height: 40px;
  }

  .progress {
  }
`
