import React from 'react'
import { CommentIcon } from 'src/assets/images/svg/CommentIcon'
import { DownVoteIcon } from 'src/assets/images/svg/DownVoteIcon'
import { UpVoteIcon } from 'src/assets/images/svg/UpVoteIcon'
import { Col, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { QAQuestion } from 'src/repository/types'
import styled, { css, useTheme } from 'styled-components'

export const QAListItemFooter: React.FC<{
  qaQuestion: QAQuestion
}> = ({ qaQuestion }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Col style={{ marginTop: 8 }}>
      <Row>
        <LabelSmall style={{ fontSize: 12, marginRight: 3 }}>{'Answered by'}</LabelSmall>
        <Author>{'John Doe'}</Author>
        <LabelSmall style={{ fontSize: 9, marginLeft: 6, marginRight: 6 }}>{'•'}</LabelSmall>
        <ViewUpvote>{'View 1 upvote'}</ViewUpvote>
      </Row>

      <Row style={{ marginTop: 4 }}>
        <VoteContainer>
          <VoteOptionContainer>
            <UpVoteIcon size={24} fill={palette.primary} />
          </VoteOptionContainer>

          <VoteSeparator />

          <VoteOptionContainer>
            <DownVoteIcon size={24} fill={palette.text.secondary} />
          </VoteOptionContainer>
        </VoteContainer>

        <ButtonContainer>
          <CommentIcon style={{ cursor: 'pointer' }} size={20} fill={palette.text.secondary} />
          <LabelSmall style={{ marginLeft: 4, fontSize: 14 }}>{3}</LabelSmall>
        </ButtonContainer>
      </Row>
    </Col>
  )
}

// MARK: - Styles

const VoteContainer = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 30px;
  margin-right: 6px;
  overflow: hidden;
`

const VoteSeparator = styled.div`
  background-color: ${({ theme }) => theme.palette.background.separator};
  height: 70%;
  width: 1px;
`

const VoteOptionContainer = styled(Row)`
  cursor: pointer;
  padding: 2px 8px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.primary};
      }
    `};
`

const ButtonContainer = styled(Row)`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.background.tertiary};
  border-radius: 20px;
  cursor: pointer;
  justify-content: center;
  padding: 4px 8px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
        border: 1px solid ${({ theme }) => theme.palette.background.separator};
      }
    `};
`

const Author = styled(LabelSmall)`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;

  ${() =>
    css`
      &:hover {
        color: ${({ theme }) => theme.palette.primary};
        text-decoration-line: underline;
      }
    `};
`

const ViewUpvote = styled(LabelSmall)`
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;

  ${() =>
    css`
      &:hover {
        text-decoration-line: underline;
      }
    `};
`
