import React from 'react'
import { useTranslation } from 'react-i18next'
import { BackIcon } from 'src/assets/images/svg/BackIcon'
import { MoveLeftIcon } from 'src/assets/images/svg/MoveLeftIcon'
import { MoveRightIcon } from 'src/assets/images/svg/MoveRightIcon'
import { LabelLarge, Row } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { css } from 'styled-components'

export const RoomChatChannelListHeader: React.FC<{
  room: Room
  collapsed: boolean
  onCollapseClick: (collapsed: boolean) => void
}> = ({ room, collapsed, onCollapseClick }) => {
  // MARK: - Hooks

  const { goBack } = useNavigation()
  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()

  // MARK: - Render

  return (
    <Container>
      {isMobile ? (
        <BackButton onClick={goBack}>
          <BackIcon />
        </BackButton>
      ) : null}

      {!collapsed && <LabelLarge>{t('audience')}</LabelLarge>}

      <ButtonContainer onClick={() => onCollapseClick(!collapsed)}>
        {collapsed ? <MoveLeftIcon /> : <MoveRightIcon />}
      </ButtonContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  justify-content: space-between;
  padding: 8px;
  position: relative;
  z-index: 2;

  @media ${device.smallerThan.laptop} {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    padding: 0px 16px;
  }
`

const ButtonContainer = styled(Row)`
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  justify-content: center;
  padding: 4px;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `};
`

const BackButton = styled.div`
  cursor: pointer;
  height: 32px;
  left: 8px;
  padding-top: 4px;
  position: absolute;
  top: 9px;
  width: 32px;
`
