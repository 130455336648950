import { FlexCol } from '../common/CommonStyles'
import { LayoutWrapper } from '../layout/LayoutWrapper'
import { SearchRoomSection } from './SearchRoomSection'
import { SearchUserSection } from './SearchUserSection'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getResults, getShouldSendQuery, search } from 'src/redux/reducers/search'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const Search: React.FC<{
  query: string | null
}> = ({ query }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const results = useSelect(state => getResults(state.search, query))
  const shouldSendQuery = useSelect(state => getShouldSendQuery(state.search, query))

  // MARK: - Effects

  useEffect(() => {
    if (query && shouldSendQuery) dispatch(search(query))
  }, [query])

  // MARK: - Render

  return (
    <LayoutWrapper query={query}>
      <Container>
        <SearchUserSection query={query} users={results?.user ?? []} />
        <SearchRoomSection query={query} rooms={results?.room ?? []} />
      </Container>
    </LayoutWrapper>
  )
}

// MARK: - Style

const Container = styled(FlexCol)`
  flex: 1;
  margin: 0px auto;
  max-width: 1080px;
  width: 100%;
  @media ${device.smallerThan.laptop} {
    align-items: center;
  }
`
