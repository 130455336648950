import { AgendaType } from 'src/features/profile/types'
import { api } from '../api'
import { Room, Subscription, User } from '../types'

export const subscriptionService = {
  fetchSubscriptions: async () =>
    api.value<{ rooms: Room[]; publishers: User[]; subscriptions: Subscription[] }>({
      request: {
        method: 'GET',
        endPoint: '/subscriptions',
        parameters: {},
      },
    }),

  fetchSubscription: async (roomId: string) =>
    api.prop<{ subscription: Subscription }>('subscription', {
      request: {
        method: 'GET',
        endPoint: '/subscription/room',
        parameters: { room_id: roomId },
      },
    }),

  createSubscription: async (
    roomId: string,
    refererId: string = '',
    pushDate: number = 0,
    eventId: string = '',
  ) =>
    api.prop<{ subscription: Subscription }>('subscription', {
      request: {
        method: 'POST',
        endPoint: '/subscription',
        parameters: {
          room_id: roomId,
          event_id: eventId,
          push_date: pushDate,
          referrer_id: refererId,
        },
      },
    }),

  updateSubscription: async (subscription: Partial<Subscription>) =>
    api.prop<{ subscription: Subscription }>('subscription', {
      request: {
        method: 'PUT',
        endPoint: '/subscription',
        parameters: { subscription_id: subscription.id, ...subscription },
      },
    }),

  removeSubscription: async (subscriptionId: string) =>
    api.prop<{ subscription: Subscription }>('subscription', {
      request: {
        method: 'DELETE',
        endPoint: '/subscription',
        parameters: { subscription_id: subscriptionId },
      },
    }),

  fetchProfileRooms: async (userId: string, offset: number, limit: number, tab: AgendaType) =>
    api.value<{ rooms: Room[]; publishers: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/subscription/room/' + tab,
        parameters: { user_id: userId, offset, limit },
      },
    }),

  fetchSubscriptionAgenda: async (userId: string, start: number, end: number) =>
    api.value<{ rooms: Room[]; publishers: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/subscription/room/agenda',
        parameters: { user_id: userId, start, end },
      },
    }),
}
