import { getAuth } from '@firebase/auth'
import config from '../utils/config'
import { log } from '../utils/logger'
import { Error } from './Error'
import currentPackage from '../../package.json'
import { failure, success } from './types'

export type ApiTarget = {
  request: ApiRequest
  retryCount?: number
}

type ApiRequest = {
  method: Method
  endPoint: string
  parameters: Record<string, any>
  encoding?: ParameterEncoding
}

export type Method = 'POST' | 'GET' | 'PUT' | 'DELETE'

export enum ParameterEncoding {
  body,
  query,
}

type ApiResponse = {
  success: boolean
  retCode: number
  data?: Record<string, any>
  error?: ApiError
}

export interface ApiError extends Error {
  title: string
  message: string
  type: ErrorType
}

enum ErrorType {
  soft = 0,
  hard = 1,
}

const client = (
  baseUrl: () => string,
  accessToken: () => string,
  customHeaders: () => Record<string, string>,
  tokenUpdateHandler: (target: ApiTarget) => Promise<string>,
  invalidAuthenticationHandler: (target: ApiTarget) => Promise<void>,
) => {
  const request = async (target: ApiTarget) => {
    return sendRequest(target, target.retryCount ?? 8)
  }

  const prop = async <T>(key: keyof T, target: ApiTarget) => {
    const response = await sendRequest<T>(target, target.retryCount ?? 8)
    if (response.type === 'success') return success(response.value[key])
    else return response
  }

  const map = async <T, U>(transform: (value: T) => U, target: ApiTarget) => {
    const response = await sendRequest<T>(target, target.retryCount ?? 8)
    if (response.type === 'success') return success(transform(response.value))
    else return response
  }

  const value = async <T>(target: ApiTarget) => {
    const response = await sendRequest<T>(target, target.retryCount ?? 8)
    if (response.type === 'success') return success(response.value)
    else return response
  }

  const sendRequest = async <T>(target: ApiTarget, retryCount: number): Promise<Result<T>> => {
    logRequest(target)

    const req = target.request
    const url = baseUrl() + req.endPoint

    const headers: Record<string, string> = customHeaders()
    headers['content-type'] = 'application/json'
    headers['client'] = `${config.envName} v${currentPackage.version}`
    headers['Access-Control-Allow-Origin'] = '*'
    if (accessToken()) {
      headers['Authorization'] = `Bearer ${accessToken()}`
    }

    const queryMethods = ['GET', 'DELETE']
    const encoding =
      req.encoding ?? queryMethods.includes(req.method)
        ? ParameterEncoding.query
        : ParameterEncoding.body

    try {
      const result = await (encoding === ParameterEncoding.query
        ? fetch(url + '?' + new URLSearchParams(req.parameters), {
            method: req.method,
            headers: headers,
          })
        : fetch(url, {
            method: req.method,
            headers: headers,
            body: JSON.stringify(req.parameters),
          }))

      const statusCode = result.status

      if (statusCode === 401) {
        if (retryCount < 1) {
          invalidAuthenticationHandler(target)
          throw Error.apiDisplayable({
            title: 'Authentication',
            message: 'Access Denied',
            type: ErrorType.hard,
          })
        } else {
          await tokenUpdateHandler(target)
          return await sendRequest(target, retryCount - 1)
        }
      }

      const data = await result.json()
      logResponse(target, data, { statusCode })
      return parse<T>(data)
    } catch (error) {
      return failure(Error.someThingWentWrong())
    }
  }

  const logRequest = (target: ApiTarget) => {
    log('Api Request', [
      `URL: ${baseUrl() + target.request.endPoint}`,
      `Method: ${target.request.method}`,
      `Target: ${JSON.stringify(target, null, 2)}`,
      `Parameters: ${JSON.stringify(target.request.parameters, null, 2)}`,
    ])
  }

  const logResponse = (
    target: ApiTarget,
    data?: Record<string, any>,
    response?: { statusCode: number },
  ) => {
    log('Api Response', [
      `URL: ${baseUrl() + target.request.endPoint}`,
      `Method: ${target.request.method}`,
      `Status: ${response?.statusCode ?? 0}`,
      `Target: ${JSON.stringify(target, null, 2)}`,
      `Parameters: ${JSON.stringify(target.request.parameters, null, 2)}`,
      `Data: ${JSON.stringify(data, null, 2)}`,
    ])
  }

  const parse = <T>(response?: ApiResponse): Result<T> => {
    if (!response) {
      throw Error.apiDisplayable({
        title: 'Parse error',
        message: 'Empty Data',
        type: ErrorType.hard,
      })
    }

    const data = response['data']
    const error = response['error']
    const isSuccess = response['success'] ?? false

    if (isSuccess && data) {
      return success(data as T)
    } else if (!isSuccess && error && error.type === ErrorType.hard) {
      return failure(Error.apiDisplayable(error))
    } else if (!isSuccess && error && error.type === ErrorType.soft) {
      const updatedData = data ?? {}
      updatedData['error'] = error
      updatedData['ret_code'] = response.retCode
      return success(updatedData as T)
    } else {
      return failure(Error.someThingWentWrong())
    }
  }

  return { request, prop, value, map }
}

export const api = client(
  () => config.apiUrl,
  () => localStorage.getItem(config.keys.firebaseToken) ?? '',
  () => ({}),
  async _ => {
    const idToken = await getAuth().currentUser?.getIdToken()
    if (idToken) {
      localStorage.setItem(config.keys.firebaseToken, idToken)
      return idToken
    }
    return ''
  },
  async _ => {
    await getAuth().signOut()
    localStorage.removeItem(config.keys.firebaseToken)
  },
)
