import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z"
      stroke={fill}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 15H12"
      stroke={fill}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.64018 9.23178C7.21591 8.87821 6.58534 8.93554 6.23178 9.35982C5.87821 9.78409 5.93554 10.4147 6.35982 10.7682L7.64018 9.23178ZM10 12.5L10.6402 13.2682C10.8682 13.0782 11 12.7968 11 12.5C11 12.2032 10.8682 11.9218 10.6402 11.7318L10 12.5ZM6.35982 14.2318C5.93554 14.5853 5.87821 15.2159 6.23178 15.6402C6.58534 16.0645 7.21591 16.1218 7.64018 15.7682L6.35982 14.2318ZM6.35982 10.7682L9.35982 13.2682L10.6402 11.7318L7.64018 9.23178L6.35982 10.7682ZM9.35982 11.7318L6.35982 14.2318L7.64018 15.7682L10.6402 13.2682L9.35982 11.7318Z"
      fill={fill}
    />
  </svg>
)

export const ModeratorIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
