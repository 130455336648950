import React, { useCallback, useEffect, useState } from 'react'
import { FlexCol, ShadowBox } from 'src/features/common/CommonStyles'
import styled from 'styled-components'
import { CoverUpdateMethod } from '../types'
import { ProfileChangeCoverModalGallery } from './ProfileChangeCoverModalGallery'
import { ProfileChangeCoverModalLink } from './ProfileChangeCoverModalLink'
import { ProfileChangeCoverModalTabBar } from './ProfileChangeCoverModalTabBar'
import { ProfileChangeCoverModalUnsplash } from './ProfileChangeCoverModalUnsplash'
import { ProfileChangeCoverModalUpload } from './ProfileChangeCoverModalUpload'

type Props = {
  onImageRemove: () => void
  onImageSelect: (image: string) => void
  onClose: () => void
}

export const ProfileChangeCoverModal: React.FC<Props> = ({
  onImageRemove,
  onImageSelect,
  onClose,
}) => {
  // MARK: - Hooks

  const modal = React.useRef<HTMLDivElement | null>(null)
  const [selectedMethod, setSelectedMethod] = useState<CoverUpdateMethod>('gallery')

  // MARK: - Effects

  useEffect(() => {
    document.addEventListener('click', handleDropdownCloseClick)
    return () => {
      document.removeEventListener('click', handleDropdownCloseClick)
    }
  }, [])

  // MARK: - Actions

  const handleDropdownCloseClick = (event: MouseEvent | any) => {
    if (modal.current && !modal.current.contains(event.target)) {
      event.stopPropagation()
      onClose()
    }
  }

  // MARK: - Render

  const renderTab = useCallback(() => {
    switch (selectedMethod) {
      case 'gallery':
        return <ProfileChangeCoverModalGallery onImageSelect={onImageSelect} />
      case 'unsplash':
        return <ProfileChangeCoverModalUnsplash onImageSelect={onImageSelect} />
      case 'upload':
        return <ProfileChangeCoverModalUpload onImageSelect={onImageSelect} />
      case 'link':
        return (
          <ProfileChangeCoverModalLink
            onImageSelect={image => {
              onImageSelect(image)
              onClose()
            }}
          />
        )
    }
  }, [selectedMethod])

  return (
    <Container ref={modal} onClick={event => event.stopPropagation()}>
      <ProfileChangeCoverModalTabBar
        selectedMethod={selectedMethod}
        onMethodSelect={setSelectedMethod}
        onCoverImageRemove={onImageRemove}
      />
      <InnerContainer>{renderTab()}</InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  max-height: 400px;
  max-width: 480px;
  position: absolute;
  right: 15%;
  top: 275px;
  width: 100%;
  z-index: 5;
`

const InnerContainer = styled(FlexCol)`
  max-height: 362px;
  overflow: scroll;
`
