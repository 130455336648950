import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { LabelMedium } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading } from 'src/redux/reducers/app'
import {
  getAuthUser,
  getCredentials,
  login,
  sendResetPasswordEmail,
} from 'src/redux/reducers/authentication'
import { AuthUser } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const AuthenticationEnterPassword: React.FC = () => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const authUser = useSelect(state => getAuthUser(state.authentication))

  const [password, setPassword] = useState('')
  const [isLocalLoading, setIsLocalLoading] = useState(false)

  // MARK: - Handlers

  const handleLoginClick = async () => {
    setIsLocalLoading(true)
    const email = authUser.email ?? ''
    const user: AuthUser = { ...authUser, authType: { type: 'email', email, password } }

    const { token, updatedUser } = await getCredentials(dispatch, user)
    setIsLocalLoading(false)
    if (!token || !updatedUser?.email) return
    dispatch(login(email, undefined))
  }

  const handleForgotPassword = () => {
    if (authUser.email) {
      dispatch(sendResetPasswordEmail(authUser.email))
      toast.success(t('passwordResetEmailSent', { email: authUser.email }))
    }
  }

  // MARK: - Render

  return (
    <InnerContainer>
      <Input
        type="password"
        isRequired
        label={t('password')}
        value={password}
        onChange={setPassword}
        onEnterPress={handleLoginClick}
        autoFocus
      />

      <ForgotPassword onClick={handleForgotPassword}>{t('forgotPassword')}</ForgotPassword>

      <PrimaryButton
        style={{ width: '100%' }}
        isLoading={isLoading || isLocalLoading}
        title={t('login')}
        onClick={handleLoginClick}
      />
    </InnerContainer>
  )
}

// MARK: - Styles

const InnerContainer = styled.div`
  flex: 1;
  margin: 0px auto;
  max-width: 480px;
  padding: 32px 0px;
  width: 100%;
  z-index: 10;
`

const ForgotPassword = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.orange};
  cursor: pointer;
  margin: 8px 0px 20px 0px;
`
