import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BottomSheet } from 'src/features/common/BottomSheet'
import { Col, LabelLarge, LabelMedium, LabelNormal } from 'src/features/common/CommonStyles'
import { DateTimePicker } from 'src/features/common/DateTimePicker'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { Room, Subscription } from 'src/repository/types'
import { formatDuration } from 'src/utils/helpers/dateHelper'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import styled, { useTheme } from 'styled-components'

type Props = {
  room: Room
  subscription: Subscription | null
  visible: boolean
  isLoading: boolean
  reminderOptions: { key: string; value: number }[]
  onSetReminder: (time: number) => void
  onRemoveReminder: () => void
  onClose: () => void
}

export const SetReminderMobile: React.FC<Props> = ({
  room,
  subscription,
  visible,
  isLoading,
  reminderOptions,
  onSetReminder,
  onRemoveReminder,
  onClose,
}) => {
  // MARK: - Hooks

  const defaultTime = subscription?.push_date || room.start_date - 30 * 60
  const { t } = useTranslation()
  const { palette } = useTheme()
  const [time, setTime] = useState(defaultTime)
  const duration = room.start_date - time

  // MARK: - Effects

  useEffect(() => {
    setTime(defaultTime)
  }, [visible])

  // MARK: - Render

  return (
    <BottomSheet visible={visible} onClose={onClose}>
      <Container>
        <LabelLarge style={{ marginBottom: 0, textAlign: 'center' }}>{t('setReminder')}</LabelLarge>

        <DateTimePicker
          style={{ marginTop: 8, marginBottom: 16 }}
          title={t('reminderTime')}
          date={new Date(time * 1000)}
          onDateChange={newTime => setTime(newTime)}
        />

        <SubTitle>
          {t(duration >= 0 ? 'beforeStartDate' : 'afterStartDate', {
            time: formatDuration(duration),
          })}
        </SubTitle>
        <OptionContainer>
          {reminderOptions.map(option => (
            <Option
              key={option.key}
              selected={duration === option.value}
              onClick={() => setTime(room.start_date - option.value)}>
              {option.key}
            </Option>
          ))}
        </OptionContainer>

        <FooterWrapper>
          {subscription?.push_date && !isLoading && (
            <SecondaryButton
              style={{
                marginRight: 8,
                flex: 1,
                backgroundColor: palette.background.primary,
              }}
              title={t('remove')}
              isLoading={isLoading}
              onClick={onRemoveReminder}
            />
          )}

          <PrimaryButton
            style={{
              flex: 1,
            }}
            title={subscription?.push_date ? t('update') : t('set')}
            isLoading={isLoading}
            onClick={() => onSetReminder(time)}
          />
        </FooterWrapper>
      </Container>
    </BottomSheet>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  flex: 1;
  max-width: 100%;
  padding: 0px 16px;
  padding-bottom: 120px;
`

const SubTitle = styled(LabelNormal)`
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.text.secondary};
`

const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -4px;
  width: 100%;
`

const Option = styled(LabelMedium)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : theme.palette.background.primary};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.primary : theme.palette.background.separator};
  border-radius: 8px;
  color: ${({ theme, selected }) => (selected ? theme.palette.white : theme.palette.text.primary)};
  cursor: pointer;
  margin: 4px;
  max-width: 33%;
  padding: 6px 18px;
  text-align: center;
  white-space: nowrap;
`
