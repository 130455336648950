import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { withProviders } from 'src/utils/hocs/Providers'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import { AccountContext, AccountContextProvider } from './AccountContext'
import { AccountDangerZone } from './AccountDangerZone'
import { AccountInfo } from './AccountInfo'
import { AccountLanguage } from './AccountLanguage'

const Modal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()
  const { user, edited, setUser, updateMeUser, isLoading } = useContext(AccountContext)

  // MARK: - Handlers

  const handleEnterPress = useCallback(() => {
    if (edited) updateMeUser()
  }, [edited, updateMeUser])

  // MARK: - Render

  return (
    <ModalWrapper title={t('settings')} size="tiny" onClose={onClose}>
      {!user && <PageLoader />}

      {user && (
        <>
          <AccountInfo
            user={user}
            onImageChange={imageUrl => setUser({ ...user, image_url: imageUrl })}
            onNameChange={firstName => setUser({ ...user, first_name: firstName })}
            onSurnameChange={lastName => setUser({ ...user, last_name: lastName })}
            onUserNameChange={userName => setUser({ ...user, user_name: userName })}
            onEmailChange={email => setUser({ ...user, email: email })}
            onEnterPress={handleEnterPress}
          />

          <AccountLanguage />

          <AccountDangerZone onClose={onClose} />

          {edited && (
            <FooterWrapper>
              <PrimaryButton
                size="sm"
                style={{ minWidth: 180, marginRight: isMobile ? 0 : 40 }}
                title={t('save')}
                isLoading={isLoading}
                onClick={updateMeUser}
              />
            </FooterWrapper>
          )}
        </>
      )}
    </ModalWrapper>
  )
}

export const AccountModal = withProviders(AccountContextProvider)(Modal)
