import { PageLoader } from '../common/PageLoader'
import { getAuth } from 'firebase/auth'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getReferralInvitation } from 'src/redux/reducers/cover'
import { getIsAuthenticated, logout } from 'src/redux/reducers/me'
import { fetchInvitation, getShouldRedirect } from 'src/redux/reducers/room'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'

export const InvitationRedirect: React.FC<{
  invitationId: string
}> = ({ invitationId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { routes, navigate } = useNavigation()
  const invitation = useSelect(state => getReferralInvitation(state.roomCover))
  const shouldRedirect = useSelect(state => getShouldRedirect(state.room))
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))

  // MARK: - Effects

  useEffect(() => {
    if (!shouldRedirect || !invitation) return

    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (isAuthenticated) {
        unsubscribe()
        return
      }
      if (user?.email && invitation?.email === user.email) {
        navigate(routes.room(invitation.room_id).cover + `?invitation_id=${invitationId}`)
      } else {
        dispatch(logout())
        navigate(routes.room(invitation.room_id).cover + `?invitation_id=${invitationId}`)
      }
      unsubscribe()
    })

    return () => {
      unsubscribe()
    }
  }, [dispatch, shouldRedirect, invitation?.id])

  useEffect(() => {
    dispatch(fetchInvitation(invitationId))
  }, [invitationId])

  // MARK: - Render

  return <PageLoader />
}
