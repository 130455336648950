import { useHistory } from 'react-router-dom'
import { RoomPrivacyType } from 'src/repository/types'

export const useNavigation = () => {
  // MARK: - Hooks

  const history = useHistory()

  // MARK: - Properties

  const routes = {
    access: (roomId: string, privacyType: RoomPrivacyType) => `/access/${roomId}/${privacyType}`,
    room: (roomId: string) => ({
      home: `/room/${roomId}`,
      notifications: `/room/${roomId}/notifications`,
      chat: {
        home: `/room/${roomId}/chat`,
        channel: (channelId: string) => `/room/${roomId}/chat/${channelId}`,
      },
      component: (componentId: string) => `/room/${roomId}/component/${componentId}`,
      cover: `/room/${roomId}/cover`,
    }),
    home: '/home',
    chat: '/chat',
    profile: (userId: string) => ({ main: `/profile/${userId}` }),
    search: (query: string) => `/search?query=${query}`,
  }

  // MARK: - Return

  return {
    replace: (route: string) => history.replace(route),
    reload: () => window.location.reload(),
    replaceRoot: (route: string) => window.location.replace(route),
    location: history.location.pathname,
    routes: routes,
    goBack: history.goBack,
    match: (route: string) => history.location.pathname === route,
    navigate: (route: string) => history.push(route),
  }
}
