import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelect } from 'src/utils/hooks/useSelect'
import { getEntity } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { Room, User } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import styled from 'styled-components'
import { FlexCol, Row } from '../common/CommonStyles'
import { PageLoader } from '../common/PageLoader'
import { LayoutWrapper } from '../layout/LayoutWrapper'
import { AccountModal } from '../account'
import { ProfileCoverImage } from './ProfileCoverImage'
import { ProfileFriendsList } from './ProfileFriendList'
import { ProfileRoomList } from './ProfileRoomList'
import { ProfileTabBar } from './ProfileTabBar'
import { ProfileUserCard } from './ProfileUserCard'
import { fetchUser } from 'src/redux/reducers/profile'
import { ProfileTab } from './types'

type Props = {
  userId: string
}

export const Profile: React.FC<Props> = ({ userId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { navigate, routes } = useNavigation()
  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId))
  const meUser = useSelect(state => getMeUser(state.me))

  const [selectedTab, setSelectedTab] = useState<ProfileTab>('agenda')
  const [showAccountModal, setShowAccountModal] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchUser(userId))
  }, [userId])

  // MARK: - Actions

  const handleRoomClick = (room: Room) => {
    navigate(routes.room(room.id).cover)
  }

  // MARK: - Render

  const renderTab = useCallback(() => {
    switch (selectedTab) {
      case 'agenda':
        return (
          <ProfileRoomList agendaType={'upcoming'} userId={userId} onRoomClick={handleRoomClick} />
        )
      case 'friends':
        return <ProfileFriendsList userId={userId} />
      case 'followings':
        return null
      case 'moderated':
        return (
          <ProfileRoomList agendaType={'moderated'} userId={userId} onRoomClick={handleRoomClick} />
        )
    }
  }, [selectedTab, userId])

  if (!user) return <PageLoader />

  return (
    <LayoutWrapper>
      <FlexCol>
        <ProfileCoverImage userId={userId} editEnabled={userId === meUser?.id} />
        <Container>
          <ProfileUserCard onProfileEditClick={() => setShowAccountModal(true)} userId={userId} />

          <FlexCol style={{ paddingLeft: 320, paddingTop: 20 }}>
            <ProfileTabBar selectedTab={selectedTab} onTabClick={setSelectedTab} />

            {renderTab()}
          </FlexCol>
        </Container>
      </FlexCol>

      {showAccountModal && <AccountModal onClose={() => setShowAccountModal(false)} />}
    </LayoutWrapper>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  margin: 0px auto;
  max-width: 1120px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
  z-index: 2;
`
