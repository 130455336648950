import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, LabelMedium } from 'src/features/common/CommonStyles'
import { ComponentWrapper } from 'src/features/common/ComponentWrapper'
import { EmptyState } from 'src/features/common/EmptyState'
import { PageLoader } from 'src/features/common/PageLoader'
import { getLoading } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { askQAQuestions, deleteQAQuestion, fetchQAQuestions } from 'src/redux/reducers/room'
import { Component, QAQuestion, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { QAListItemAnswer } from './QAListItemAnswer'
import { QAListItemFooter } from './QAListItemFooter'
import { QAListItemHeader } from './QAListItemHeader'

export const QAQuestionList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'qa'))
  const qaQuestions = useSelect(state =>
    getEntities<QAQuestion>(
      state.entity,
      'qa_question',
      qaQuestion => qaQuestion.component_id === component.id,
    ).sort((first, second) => second.created_at - first.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchQAQuestions(component.id))
  }, [component, dispatch])

  // MARK: - Actions

  const handleSendMessage = useCallback(
    (message: string) => {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500)
      dispatch(askQAQuestions(component.id, message, false))
    },
    [component.id],
  )

  const handleQAQuestionDelete = useCallback((qaQuestion: QAQuestion) => {
    dispatch(deleteQAQuestion(qaQuestion))
  }, [])

  // MARK: - Render

  return (
    <ComponentWrapper
      room={room}
      component={component}
      isLoading={isLoading}
      showInput
      inputPlaceholder={t('askQuestion')}
      handleSendMessage={handleSendMessage}>
      {qaQuestions.length === 0 &&
        (isLoading && !qaQuestions.length ? (
          <PageLoader />
        ) : (
          <EmptyState
            title={t('noQAQuestionsPostedYet')}
            subtitle={t('oncePublisherPostedQAQuestions')}
          />
        ))}

      {qaQuestions.length > 0 &&
        qaQuestions.map(qaQuestion => (
          <QAListItem
            key={qaQuestion.id}
            qaQuestion={qaQuestion}
            onQAQuestionDelete={handleQAQuestionDelete}
          />
        ))}
    </ComponentWrapper>
  )
}

const QAListItem: React.FC<{
  qaQuestion: QAQuestion
  onQAQuestionDelete: (qaQuestion: QAQuestion) => void
}> = ({ qaQuestion, onQAQuestionDelete }) => {
  // MARK: - Hooks

  const answered = qaQuestion.state === 'answered' && !!qaQuestion.answer

  // MARK: - Render

  return (
    <Container>
      <QAListItemHeader qaQuestion={qaQuestion} onQAQuestionDelete={onQAQuestionDelete} />
      <Question>{qaQuestion.question}</Question>
      {answered && <QAListItemAnswer qaQuestion={qaQuestion} />}
      {answered && <QAListItemFooter qaQuestion={qaQuestion} />}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-radius: 6px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  margin: 4px 0px 8px 0px;
  padding: 12px 16px;
  position: relative;

  @media ${device.smallerThan.laptop} {
    padding: 20px 12px;
  }
`

const Question = styled(LabelMedium)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  margin-top: 8px;
`
