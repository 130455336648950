import { ChatChannelMessageInput } from './ChatChannelMessageInput'
import { ChatChannelMessageList } from './ChatChannelMessageList'
import { ChatChannelMessageListHeader } from './ChatChannelMessageListHeader'
import { ChatChannelThread } from './ChatChannelThread'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FlexCol, FlexRow } from 'src/features/common/CommonStyles'
import {
  closeThread,
  fetchChannelMessages,
  getActiveThread,
  getActiveThreadId,
  getChannelMessages,
  getIsThreadOpen,
  sendChannelMessage,
} from 'src/redux/reducers/chatChannel'
import { ChatChannel } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const ChatChannelPage: React.FC<{
  channel: ChatChannel
  onBackClick?: () => void
}> = ({ channel, onBackClick }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const activeThreadId = useSelect(state => getActiveThreadId(state.chatChannel))
  const activeThread = useSelect(state => getActiveThread(state.chatChannel))
  const isThreadOpen = useSelect(state => getIsThreadOpen(state.chatChannel))
  const messages = useSelect(state => getChannelMessages(state.chatChannel, channel.id))

  // MARK: - Effects

  useEffect(() => {
    if (!messages.length) dispatch(fetchChannelMessages(channel.id))
  }, [channel.id, dispatch])

  // MARK: - Actions

  const handleSendMessage = useCallback(
    (text: string) => {
      dispatch(sendChannelMessage(channel.id, text, 'text', false))
    },
    [channel.id],
  )

  const handleSendThreadMessage = useCallback(
    (text: string) => {
      if (activeThreadId) dispatch(sendChannelMessage(activeThreadId, text, 'text', true))
    },
    [activeThreadId],
  )

  const handleCloseThread = useCallback(() => {
    dispatch(closeThread())
  }, [])

  // MARK: - Render

  return (
    <Container>
      <Wrapper>
        <ChatChannelMessageListHeader channel={channel} onBackClick={() => onBackClick?.()} />
        <InnerContainer>
          <ChatChannelMessageList channel={channel} />
          <ChatChannelMessageInput channel={channel} onSendMessage={handleSendMessage} />
        </InnerContainer>
      </Wrapper>

      {isThreadOpen && (
        <ChatChannelThread
          thread={activeThread}
          onClose={handleCloseThread}
          onSendMessage={handleSendThreadMessage}
        />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexRow)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  overflow: hidden;
  position: relative;
`

const Wrapper = styled(FlexCol)`
  height: calc(100vh - 72px);
  overflow: hidden;
`

const InnerContainer = styled(FlexCol)`
  height: calc(100vh - 72px);
  margin: 0px 6% 0px 6%;
  overflow: hidden;

  @media ${device.smallerThan.laptop} {
    margin: 0px 12px 0px 12px;
  }
`
