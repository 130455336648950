import React from 'react'
import { Error } from 'src/repository/Error'
import { RoomPrivacyType } from 'src/repository/types'
import config from 'src/utils/config'
import { AlertModal } from '../common/AlertModal'
import { RoomAccessEnterAccessCode } from './RoomAccessEnterAccessCode'
import { RoomAccessNavigateThirdParty } from './RoomAccessNavigateThirdParty'
import { RoomAccessRequestInvitation } from './RoomAccessRequestInvitation'

type Props = {
  roomId: string
  privacyType: RoomPrivacyType
}

export const RoomAccess: React.FC<Props> = ({ roomId, privacyType }) => {
  switch (privacyType) {
    case 'invite-only':
      return <RoomAccessRequestInvitation roomId={roomId} />
    case 'private':
      return <RoomAccessEnterAccessCode roomId={roomId} />
    case 'managed':
      return <RoomAccessNavigateThirdParty roomId={roomId} />
    case 'public':
      return (
        <AlertModal
          error={Error.someThingWentWrong()}
          visible
          onClose={() => window.location.replace(config.platformUrl)}
        />
      )
  }
}
