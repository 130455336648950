import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { deleteEntities, getEntity } from 'src/redux/reducers/entity'
import { fetchRoom } from 'src/redux/reducers/room'
import { Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import { Priority, realtime } from 'src/repository/realtime'

export const useRoomObserver = (roomId: string) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const room = useSelect(state => getEntity<Room>(state.entity, 'room', roomId))

  const singlePath = `realtime_updates/room/${room?.id}/overview/set/single`
  const timestamp = room?.updated_at ?? 0

  // MARK: - Effects

  useEffect(() => {
    realtime.observe(
      singlePath,
      timestamp,
      { priority: Priority.veryHigh, types: ['child_added', 'child_changed'] },
      ({ action, room_id }) => {
        if (room_id) {
          if (action === 1) dispatch(fetchRoom(room_id))
          else if (action === 2) dispatch(deleteEntities({ room: [room_id] }))
        }
      },
    )

    return () => {
      realtime.dispose(singlePath)
    }
  }, [singlePath, timestamp])
}
