import produce from 'immer'
import { map, sortBy, uniqBy } from 'lodash'
import { pushError } from 'src/redux/reducers/app'
import {
  DeleteAction,
  deleteEntities,
  getEntities,
  getEntity,
  MergeAction,
  mergeEntities,
  ReplaceAction,
} from 'src/redux/reducers/entity'
import { getMeId } from 'src/redux/reducers/me'
import { Action, Thunk } from 'src/redux/store'
import { chatService } from 'src/repository/services/chatService'
import { Chat, ChatMessage } from 'src/repository/types'
import { maxPlusNone, maxPlusOne } from 'src/utils/helpers/arrayHelper'
import { localTime } from 'src/utils/helpers/dateHelper'
import { v4 } from 'uuid'

type State = {
  messagesCanPaginate: Record<string, boolean>
  replyingMessage: ChatMessage | null
  drawerCollapsed: boolean
}

type ActionType =
  | { type: 'chat/setMessagesCanPaginate'; userId: string; canPaginate: boolean }
  | { type: 'chat/setReplyingMessage'; message: ChatMessage | null }
  | { type: 'chat/setDrawerCollapsed'; collapsed: boolean }
  | Action<'me/logout'>
  | MergeAction
  | DeleteAction
  | ReplaceAction

// MARK: - State

export const initialState: State = {
  messagesCanPaginate: {},
  replyingMessage: null,
  drawerCollapsed: false,
}

// MARK: - Reducer

export const chatReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'chat/setReplyingMessage':
      return produce(state, draft => {
        draft.replyingMessage = action.message
        return draft
      })

    case 'chat/setMessagesCanPaginate':
      return produce(state, draft => {
        draft.messagesCanPaginate[action.userId] = action.canPaginate
        return draft
      })

    case 'chat/setDrawerCollapsed':
      return produce(state, draft => {
        draft.drawerCollapsed = action.collapsed
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const setConnectionDrawerCollapsed = (collapsed: boolean): ActionType => ({
  type: 'chat/setDrawerCollapsed',
  collapsed,
})

export const fetchChat =
  (chatId: string): Thunk<ActionType> =>
  dispatch => {
    chatService.fetchSingleChat(chatId).done(
      ({ chat }) => dispatch(mergeEntities({ chat: [chat] })),
      error => dispatch(pushError(error)),
    )
  }

export const fetchChats =
  (pageSize: number = 20): Thunk<ActionType> =>
  (dispatch, getState) => {
    const existingChats = getEntities<Chat>(getState().entity, 'chat')
    const lastUpdated = maxPlusOne(existingChats, 'updated_at')

    chatService.fetchChats(0, pageSize, lastUpdated).done(
      ({ users, chats }) => dispatch(mergeEntities({ user: users, chat: chats })),
      error => dispatch(pushError(error)),
    )
  }

export const fetchChatsNextPage = (): Thunk<ActionType> => (dispatch, getState) => {
  const existingChats = getEntities<Chat>(getState().entity, 'chat')
  const pageSize = 20

  chatService.fetchChats(existingChats.length, pageSize).done(
    ({ chats, users }) => dispatch(mergeEntities({ user: users, chat: chats })),
    error => dispatch(pushError(error)),
  )
}

export const fetchChatMessageUpdates =
  (userId: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const meUserId = getMeId(getState().me)!
    const chatId = sortBy([userId, meUserId], item => item).join('-')
    const existingMessages = getEntities<ChatMessage>(
      getState().entity,
      'message',
      ({ chat_id }) => chat_id === chatId,
    )

    const lastCreatedAt = maxPlusNone(existingMessages, 'created_at')
    const lastUpdatedAt = maxPlusNone(existingMessages, 'updated_at')

    chatService.fetchMessageUpdates(userId, lastCreatedAt, lastUpdatedAt).done(
      ({ messages }) => {
        if (messages.length) dispatch(mergeEntities({ message: messages }))
      },
      error => dispatch(pushError(error)),
    )
  }

export const fetchChatMessages =
  (userId: string, pageSize: number = 16): Thunk<ActionType> =>
  dispatch => {
    chatService
      .fetchMessages(userId, 0, pageSize)
      .loading('chat_messages_' + userId)
      .done(
        ({ messages }) => {
          if (messages.length) dispatch(mergeEntities({ message: messages }))
          dispatch({
            type: 'chat/setMessagesCanPaginate',
            userId,
            canPaginate: messages.length === pageSize,
          })
        },
        error => dispatch(pushError(error)),
      )
  }

export const fetchChatNextMessages =
  (userId: string, pageSize: number = 16): Thunk<ActionType> =>
  (dispatch, getState) => {
    const canPaginate = getCanPaginate(getState().chat, userId)
    if (!canPaginate) return

    const meUserId = getMeId(getState().me)!
    const chatId = sortBy([userId, meUserId], item => item).join('-')
    const existingMessages = getEntities<ChatMessage>(
      getState().entity,
      'message',
      ({ chat_id }) => chat_id === chatId,
    )

    chatService
      .fetchMessages(userId, existingMessages.length, pageSize)
      .loading('chat_messages_' + userId)
      .done(
        ({ messages }) => {
          if (messages.length) dispatch(mergeEntities({ message: messages }))
          dispatch({
            type: 'chat/setMessagesCanPaginate',
            userId,
            canPaginate: messages.length === pageSize,
          })
        },
        error => dispatch(pushError(error)),
      )
  }

export const fetchChatMessage =
  (chatId: string, messageId: string): Thunk<ActionType> =>
  dispatch => {
    chatService
      .fetchMessage(chatId, [messageId])
      .done(value => dispatch(mergeEntities({ message: value.messages })))
  }

export const clearMessages =
  (userId: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const pageSize = 16
    const meUserId = getMeId(getState().me)!
    const chatId = sortBy([userId, meUserId], item => item).join('-')
    let discardedMessages = getEntities<ChatMessage>(
      getState().entity,
      'message',
      ({ chat_id }) => chat_id === chatId,
    )
    discardedMessages = sortBy(discardedMessages, ({ created_at }) => -created_at)
    discardedMessages = discardedMessages.slice(pageSize)

    dispatch(deleteEntities({ message: map(discardedMessages, 'id') }))
    dispatch({ type: 'chat/setMessagesCanPaginate', userId, canPaginate: true })
  }

export const sendChatMessage =
  (userId: string, text: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const meUserId = getMeId(getState().me)!
    const chatId = sortBy([userId, meUserId], item => item).join('-')
    const repliedMessage = getReplyingMessage(getState().chat)
    const id = v4()

    const message: ChatMessage = {
      text,
      createdAt: new Date(),
      _id: id,
      id: id,
      type: 'text',
      chat_id: chatId,
      user: { _id: meUserId },
      created_at: localTime(),
      group_type: 'single',
      updated_at: localTime(),
      replied: repliedMessage,
      reactions: [],
      attachments: [],
    }

    chatService
      .sendMessage(userId, [message])
      .first(() => {
        dispatch(mergeEntities({ message: [message] }))
        dispatch(setReplyingMessage(null))
      })
      .done(
        ({ chat }) => dispatch(mergeEntities({ chat: [chat] })),
        error => {
          dispatch(deleteEntities({ message: [message.id] }))
          dispatch(pushError(error))
        },
      )
  }

export const toggleReaction =
  (messageId: string, reaction: string, isActive: boolean): Thunk<ActionType> =>
  (dispatch, getState) => {
    const existingMessage = getEntity<ChatMessage>(getState().entity, 'message', messageId)
    const meUserId = getMeId(getState().me)
    if (!existingMessage || !meUserId) return

    chatService
      .toggleReaction(messageId, reaction, isActive)
      .first(() => {
        const message = { ...existingMessage }
        if (isActive) {
          const newReaction = { user_id: meUserId, reaction, created_at: localTime() }
          const reactions = [newReaction].concat(message.reactions)
          message.reactions = uniqBy(reactions, 'user_id')
          message.updated_at = localTime()
        } else {
          message.reactions = message.reactions.filter(({ user_id }) => user_id !== meUserId)
          message.updated_at = localTime()
        }
        dispatch(mergeEntities({ message: [message] }))
      })
      .done(undefined, error => {
        dispatch(pushError(error))
        dispatch(mergeEntities({ message: [existingMessage] }))
      })
  }

export const readChatMessage =
  (userId: string, messageId: string): Thunk<ActionType> =>
  dispatch => {
    chatService.read(userId, messageId).done(
      chat => dispatch(mergeEntities({ chat: [chat] })),
      error => dispatch(pushError(error)),
    )
  }

export const setReplyingMessage = (message: ChatMessage | null): ActionType => ({
  type: 'chat/setReplyingMessage',
  message: message,
})

// MARK: - Selectors

export const getCanPaginate = (state: State, userId: string): boolean => {
  return state.messagesCanPaginate[userId] ?? true
}

export const getMessagesCanPaginate = (state: State, userId: string): boolean => {
  return state.messagesCanPaginate[userId] ?? true
}

export const getReplyingMessage = (state: State): ChatMessage | null => {
  return state.replyingMessage
}

export const getConnectionDrawerCollapsed = (state: State): boolean => {
  return state.drawerCollapsed
}
