import { PrivacyCheckBox } from './PrivacyCheckBox'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading } from 'src/redux/reducers/app'
import {
  getAuthSetup,
  getAuthUser,
  registerByEmail,
  registerByPhone,
  registerThirdParty,
} from 'src/redux/reducers/authentication'
import { AuthUser } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const AuthenticationRegisterForm: React.FC = () => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const authUser = useSelect(state => getAuthUser(state.authentication))
  const setup = useSelect(state => getAuthSetup(state.authentication))

  const isThirdParty = authUser.authType.type !== 'email' && authUser.authType.type !== 'phone'
  const isPhone = authUser.authType.type === 'phone'

  const [firstName, setFistName] = useState(setup?.first_name || authUser.firstName || '')
  const [lastName, setLastName] = useState(setup?.last_name || authUser.lastName || '')
  const [username, setUsername] = useState(setup?.user_name || '')
  const [password, setPassword] = useState('')

  // MARK: - Handlers

  const handleContinueClick = () => {
    if (isThirdParty) {
      const user: AuthUser = { ...authUser, userName: username }
      dispatch(registerThirdParty(user))
    } else {
      const user: AuthUser = {
        ...authUser,
        firstName: firstName,
        lastName: lastName,
        userName: username,
        // @ts-ignores
        authType: { ...authUser.authType, password },
      }
      if (authUser.authType.type === 'email') dispatch(registerByEmail(user))
      else if (authUser.authType.type === 'phone') dispatch(registerByPhone(user))
    }
  }

  // MARK: - Render

  return (
    <InnerContainer>
      {!isThirdParty && (
        <HorizontalContainer style={{ marginBottom: 8 }}>
          <Input
            label={t('firstName')}
            value={firstName}
            onChange={setFistName}
            capitalize="capitalize"
            autoFocus={!setup}
          />
          <div style={{ minWidth: 12 }} />
          <Input
            label={t('lastName')}
            value={lastName}
            onChange={setLastName}
            capitalize="capitalize"
          />
        </HorizontalContainer>
      )}

      <Input
        style={{ marginBottom: 8 }}
        label={t('userName')}
        value={username}
        onChange={setUsername}
        autoFocus={isThirdParty || !!setup}
        onEnterPress={() => isPhone && handleContinueClick()}
      />

      {!isThirdParty && !isPhone && (
        <Input
          type="password"
          label={t('password')}
          value={password}
          onChange={setPassword}
          onEnterPress={handleContinueClick}
          autoFocus={!isPhone && !!setup}
        />
      )}

      <PrivacyCheckBox onCheckBoxClick={isOn => {}} />

      <PrimaryButton
        style={{ width: '100%', marginTop: 8 }}
        isLoading={isLoading}
        onClick={handleContinueClick}
        title={t('agreeAndContinue')}
      />
    </InnerContainer>
  )
}

// MARK: - Styles

const InnerContainer = styled.div`
  flex: 1;
  margin: 0px auto;
  max-width: 480px;
  padding: 32px 0px;
  z-index: 10;
`

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`
