import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import config from 'src/utils/config'

export const firebaseApp = initializeApp(config.firebaseConfig)
export const firebaseFirestore = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
export const firebaseDatabase = getDatabase(firebaseApp)
