import { Identifiable } from './mapId'

export const padArrayStart = <T>(arr: T[], len: number, padding: T) => {
  return Array(len - arr.length)
    .fill(padding)
    .concat(arr)
}

export const padArrayEnd = <T>(arr: T[], len: number, padding: T) => {
  return arr.concat(Array(len - arr.length).fill(padding))
}

export const padArrayStartDecreasingTimestamps = (
  arr: { value: number; timestamp: number }[],
  len: number,
  paddingValue: number,
  paddingTimestampStep: number,
): { value: number; timestamp: number }[] => {
  const currentTimestamp = Date.now() / 1000
  const minTimestamp = arr.length ? Math.min(...arr.map(item => item.timestamp)) : currentTimestamp

  let out: { value: number; timestamp: number }[] = []
  for (let index = 0; index < len - arr.length; index++) {
    out.push({
      value: paddingValue,
      timestamp: minTimestamp - paddingTimestampStep * (index + 1),
    })
  }
  out = out.reverse()
  return [...out, ...arr]
}

export const maxPlusOne = <T>(array: T[], key: keyof T) => {
  return maxPlusNone(array, key) + 1
}

export const maxPlusNone = <T>(array: T[], key: keyof T) => {
  const max = array.length
    ? Math.max(...array.map(item => parseInt(item[key] ? `${item[key]}` : '0', 10)))
    : 0
  return max
}

export const minPlusNone = <T>(array: T[], key: keyof T, defaultValue: number = 0) => {
  const min = array.length
    ? Math.min(...array.map(item => parseInt(item[key] ? `${item[key]}` : `${defaultValue}`, 10)))
    : 1
  return min
}

export const reduceById = <T>(array: (T & Identifiable)[]): Record<string, T> => {
  return array.reduce((acc, element) => ({ ...acc, [element.id]: element }), {})
}
