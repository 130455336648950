import { ChatMessageListDateSeparator } from './ChatMessageListDateSeparator'
import { ChatMessageListItem } from './ChatMessageListItem'
import { head, last } from 'lodash'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol } from 'src/features/common/CommonStyles'
import { EmptyState } from 'src/features/common/EmptyState'
import { Loader } from 'src/features/common/Loader'
import { PageLoader } from 'src/features/common/PageLoader'
import { getLoading, getLocalization } from 'src/redux/reducers/app'
import {
  fetchChatNextMessages,
  getMessagesCanPaginate,
  toggleReaction,
} from 'src/redux/reducers/chat'
import { ChatMessage, ChatMessageListDate } from 'src/repository/types'
import { insertTimeHeadersToMessages, isChannelMessageListDate } from 'src/utils/helpers/chatHelper'
import { useChatMessageObserver } from 'src/utils/hooks/useChatMessageObserver'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const ChatMessageList: React.FC<{
  userId: string
}> = ({ userId }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  const localization = useSelect(state => getLocalization(state.app))
  const dispatch = useDispatch()
  const messages = useChatMessageObserver(userId)

  const isLoading = useSelect(state => getLoading(state.app, 'chat_messages_' + userId))
  const canPaginate = useSelect(state => getMessagesCanPaginate(state.chat, userId))
  const messagesWithHeaders: (ChatMessageListDate | ChatMessage)[] = insertTimeHeadersToMessages(
    messages,
    localization,
  ) as (ChatMessageListDate | ChatMessage)[]

  const [anchoredBottom, setAnchoredBottom] = useState<boolean>(true)

  const listRef = useRef<HTMLDivElement | null>(null)
  const listContentHeight = useRef<number>(0)
  const listContentOffset = useRef<number>(0)

  // MARK: - Effects

  useLayoutEffect(() => {
    if (listRef.current) {
      const diff = listRef.current.scrollHeight - listContentHeight.current
      const target = listContentOffset.current + diff
      // isMobile
      // ? window.scrollTo({ top: target, behavior: "auto" })
      listRef.current.scrollTo(0, target)
    }
  }, [head(messages)?.id])

  useLayoutEffect(() => {
    if (anchoredBottom && listRef.current) {
      const target = listRef.current.scrollHeight
      listRef.current.scrollTo({ behavior: 'smooth', top: target })
    }
  }, [last(messages)?.id, anchoredBottom])

  useLayoutEffect(() => {
    if (anchoredBottom && listRef.current) {
      const target = listRef.current.scrollHeight
      listRef.current.scrollTo({ behavior: 'auto', top: target })
    }
  }, [userId])

  // MARK: - Actions

  const handleFetchMoreClick = useCallback(() => {
    if (!isLoading) {
      listContentHeight.current = listRef.current?.scrollHeight ?? 0
      dispatch(fetchChatNextMessages(userId))
    }
  }, [isLoading, userId])

  const handleScroll = useCallback(() => {
    const messageList = listRef.current
    if (messageList) {
      const { scrollTop, offsetHeight, scrollHeight } = messageList
      listContentOffset.current = scrollTop

      const offset = 50
      const shouldAnchor = scrollHeight < scrollTop + offsetHeight + offset
      setAnchoredBottom(shouldAnchor)

      if (scrollTop < 400 && canPaginate) handleFetchMoreClick()
    }
  }, [canPaginate, isLoading])

  const handleReactionToggle = useCallback((message: ChatMessage, reaction: string) => {
    dispatch(toggleReaction(message.id, reaction, true))
  }, [])

  // MARK: - Render

  if (isLoading && !messages.length) {
    return (
      <ListContainer>
        <PageLoader style={{ paddingTop: 80, backgroundColor: palette.background.secondary }} />
      </ListContainer>
    )
  }

  if (!messagesWithHeaders.length) {
    return (
      <EmptyState
        title={t('noMessagesSent')}
        subtitle={t('noMessagesSentDescription')}
        style={{ marginLeft: 32, marginRight: 32 }}
      />
    )
  }

  return (
    <ListContainer ref={listRef} onScroll={handleScroll}>
      {canPaginate ? (
        <FetchMoreContainer onClick={handleFetchMoreClick}>
          {isLoading && messages.length ? <Loader size={24} thickness={3} /> : null}
        </FetchMoreContainer>
      ) : null}

      <FlexCol />

      {messagesWithHeaders.map(item =>
        isChannelMessageListDate(item) ? (
          <ChatMessageListDateSeparator key={item.timestamp} date={item} />
        ) : (
          <ChatMessageListItem
            key={item.id}
            message={item}
            onReactionToggle={reaction => handleReactionToggle(item, reaction)}
          />
        ),
      )}
    </ListContainer>
  )
}

// MARK: - Styles

const ListContainer = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  flex-direction: column;
  max-height: calc(100vh - 180px);
  overflow: scroll;
  padding-bottom: 12px;
  position: relative;

  @media ${device.smallerThan.laptop} {
    padding-bottom: 72px;
    max-height: calc(100vh - 114px);
  }
`

const FetchMoreContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 12px 0px 8px 0px;
  min-height: 34px;
  width: 100%;
`
