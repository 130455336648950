import React from 'react'
import { useTranslation } from 'react-i18next'
import { BottomSheet } from 'src/features/common/BottomSheet'
import { FlexCol, FlexRow, LabelLarge } from 'src/features/common/CommonStyles'
import styled from 'styled-components'
import { CalendarType, calendarTypeIcons, calendarTypes } from 'src/utils/helpers/calendarHelper'
import { CalendarTypeListItem } from './CalendarTypeListItem'

type Props = {
  visible: boolean
  onCalendarTypeClick: (type: CalendarType) => void
  onClose: () => void
}

export const CalendarMobile: React.FC<Props> = ({ visible, onCalendarTypeClick, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <BottomSheet visible={visible} onClose={onClose}>
      <Container>
        <LabelLarge style={{ marginBottom: 0, textAlign: 'center' }}>
          {t('addToCalendar')}
        </LabelLarge>

        <InnerContainer>
          {calendarTypes.map(type => (
            <CalendarTypeListItem
              key={type}
              title={t(type)}
              icon={calendarTypeIcons[type]}
              onCalendarTypeClick={() => onCalendarTypeClick(type)}
            />
          ))}
        </InnerContainer>
      </Container>
    </BottomSheet>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  flex-wrap: wrap;
  margin: 0px -5%;
  max-width: 110%;
  padding: 0px;
  padding-bottom: 20px;
`

const InnerContainer = styled(FlexRow)`
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0px 16px;
  padding-bottom: 20px;
`
