import React, { useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { LabelMedium, Row } from './CommonStyles'
import { Loader } from './Loader'

export const SecondaryButton: React.FC<{
  style?: React.CSSProperties
  title?: string
  isLoading?: boolean
  disabled?: boolean
  size?: 'default' | 'sm'
  onClick?: () => void
}> = ({ style, title = '', isLoading, disabled, size = 'sm', onClick, children }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Handlers

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled) return

      event.stopPropagation()
      onClick?.()
    },
    [disabled, onClick],
  )

  // MARK: - Render

  return (
    <Container
      style={{
        height: size === 'sm' ? 40 : 54,
        backgroundColor: disabled ? palette.background.disabled : palette.background.primary,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      onClick={handleButtonClick}>
      {isLoading ? (
        <Loader size={20} thickness={2} color={palette.text.secondary} />
      ) : title ? (
        <LabelMedium
          style={{
            color: palette.text.secondary,
            letterSpacing: 0.3,
            fontSize: 15,
            flexDirection: 'row',
            fontWeight: 600,
          }}>
          {title}
        </LabelMedium>
      ) : (
        children
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.text.primary};
  justify-content: center;
  padding: 0px 16px;
`
