import { EmptyState } from '../common/EmptyState'
import { FetchMoreButton } from '../common/FetchMoreButton'
import { LabelLight, LabelSemiBold } from '../common/Label'
import { ActivityListItem } from './ActivityListItem'
import React, { useEffect, useRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ArchiveIcon } from 'src/assets/images/svg/ArchiveIcon'
import { SettingsIcon } from 'src/assets/images/svg/SettingsIcon'
import { Col, Row, ShadowBox } from 'src/features/common/CommonStyles'
import {
  archiveAllUpdates,
  fetchUpdates,
  getCanPaginate,
  getIsLoading,
  getUpdates,
} from 'src/redux/reducers/activity'
import { ActivitySection } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css, useTheme } from 'styled-components'

export const ActivityList: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  // MARK: - Hooks

  const sections: ActivitySection[] = ['inbox', 'archived']

  const modal = useRef<HTMLDivElement | null>(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { palette } = useTheme()

  const [selectedSection, setSelectedSection] = useState<ActivitySection>('inbox')
  const isLoading = useSelect(state => getIsLoading(state.activity, selectedSection))
  const activities = useSelect(state => getUpdates(state.activity, selectedSection))
  const canPaginate = useSelect(state => getCanPaginate(state.activity, selectedSection))

  // MARK: - Effects

  useEffect(() => {
    document.addEventListener('click', handleHideUpdatesClick)
    return () => {
      document.removeEventListener('click', handleHideUpdatesClick)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchUpdates(selectedSection, 10, 0))
  }, [selectedSection])

  // MARK: - Actions

  const handleHideUpdatesClick = (event: MouseEvent | any) => {
    if (!event.target?.closest(`.${modal.current?.className}`)) {
      event.stopPropagation()
      onClose()
    }
  }

  // MARK: - Render

  return (
    <Container onClick={event => event.stopPropagation()}>
      <TabBarContainer>
        <Row>
          {sections.map(section => (
            <TabBarItemContainer
              isSelected={selectedSection === section}
              onClick={() => setSelectedSection(section)}>
              {t(section)}
            </TabBarItemContainer>
          ))}
        </Row>

        <SettingsIcon size={18} />
      </TabBarContainer>

      {!activities.length && isLoading && (
        <LoaderContainer>
          {Array.from({ length: 8 }).map((_, index) => (
            <ContentLoader
              style={{ marginRight: 10, marginLeft: 10, minWidth: 420 }}
              key={index}
              viewBox="0 0 420 66"
              height={80}
              width={420}
              backgroundColor={'#DDDDDD'}
              foregroundColor={palette.background.primary}>
              <circle cx="18" cy="20" r="18" />
              <rect x="50" y="4" rx="4" ry="4" width="300" height="8" />
              <rect x="50" y="22" rx="5" ry="5" width="180" height="8" />
              <rect x="50" y="40" rx="5" ry="5" width="250" height="8" />
              <rect x="50" y="57" rx="4" ry="4" width="80" height="8" />
            </ContentLoader>
          ))}
        </LoaderContainer>
      )}

      {!!activities.length && (
        <InnerContainer>
          {activities.map(activity => (
            <ActivityListItem key={activity.id} activity={activity} />
          ))}

          {canPaginate && (
            <FetchMoreButton
              onClick={() => dispatch(fetchUpdates(selectedSection, 10))}
              isLoading={isLoading}
            />
          )}
        </InnerContainer>
      )}

      {!activities.length && !isLoading && (
        <EmptyState
          style={{ minHeight: 200, paddingLeft: 16, paddingRight: 16 }}
          title={t(selectedSection === 'inbox' ? 'noUpdates' : 'noArchives')}
          subtitle={t(
            selectedSection === 'inbox' ? 'noUpdatesDescription' : 'noArchivesDescription',
          )}
        />
      )}

      {!!activities.length && selectedSection === 'inbox' && (
        <BottomBarContainer
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(archiveAllUpdates())}>
          <ArchiveIcon size={18} fill={palette.text.secondary} />
          <LabelLight style={{ fontSize: 14, marginLeft: 6 }}>{t('archiveAll')}</LabelLight>
        </BottomBarContainer>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)`
  align-items: center;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-out;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh-200px);
  overflow: hidden;
  position: absolute;
  right: 112px;
  top: 56px;
  width: 460px;
  z-index: 5;
`

const InnerContainer = styled(Col)`
  padding: 0px 0px;
  width: 100%;
`

const BottomBarContainer = styled(Row)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  padding: 6px 16px;
  width: 100%;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.tertiary};
      }
    `}
`

const LoaderContainer = styled(Col)`
  margin: 16px 0px;
  overflow-x: hidden;
`

const TabBarContainer = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  justify-content: space-between;
  padding: 0px 14px 0px 8px;
  width: 100%;
`

const TabBarItemContainer = styled(LabelSemiBold)<{ isSelected: boolean }>`
  border-bottom: 2px solid
    ${({ theme, isSelected }) => (isSelected ? theme.palette.text.primary : 'transparent')};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.text.primary : theme.palette.text.secondary};
  cursor: pointer;
  font-size: 15px;
  margin: 0px 8px;
  margin-bottom: -1px;
  padding: 8px 0px 6px 0px;
`
