import React from 'react'
import { useTranslation } from 'react-i18next'
import { BackIcon } from 'src/assets/images/svg/BackIcon'
import { FlexRow, LabelLarge, LabelSmall } from 'src/features/common/CommonStyles'
import { ChatChannel } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const ChatChannelMessageListHeader: React.FC<{
  channel: ChatChannel
  onBackClick: () => void
}> = ({ channel, onBackClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()

  // MARK: - Render

  return (
    <Container>
      {isMobile && (
        <BackButton onClick={onBackClick}>
          <BackIcon />
        </BackButton>
      )}

      <LabelLarge style={{ marginLeft: 12 }}>{'#' + t(channel.name)}</LabelLarge>
      <LabelSmall style={{ ...numberOfLines(1), flex: 1, marginLeft: 12, marginTop: 4 }}>
        {t(channel.description)}
      </LabelSmall>
    </Container>
  )
}
// MARK: - Styles

const Container = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: space-between;
  max-height: 52px;
  min-height: 52px;
  padding: 0px 4px 0px 8px;
  position: relative;
  z-index: 2;

  @media ${device.smallerThan.laptop} {
    left: 0px;
    right: 0px;
    top: -1px;
    padding: 0px 4px;
    height: 52px;
    max-height: 52px;
  }
`

const BackButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
`
