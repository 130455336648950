import React from 'react'
import { useTranslation } from 'react-i18next'
import { LabelMedium, Row } from 'src/features/common/CommonStyles'
import { profileTabIcon, profileTabTitle } from 'src/features/profile/types'
import styled, { useTheme } from 'styled-components'
import { ProfileTab, profileTabs } from './types'

export const ProfileTabBar: React.FC<{
  selectedTab: ProfileTab
  onTabClick: (tab: ProfileTab) => void
}> = ({ selectedTab, onTabClick }) => (
  <Container>
    {profileTabs.map(tab => (
      <ProfileTabBarItem
        key={tab}
        tab={tab}
        isSelected={selectedTab === tab}
        onTabClick={onTabClick}
      />
    ))}
  </Container>
)

const ProfileTabBarItem: React.FC<{
  tab: ProfileTab
  isSelected: boolean
  onTabClick: (tab: ProfileTab) => void
}> = ({ tab, isSelected, onTabClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  // MARK: - Title

  return (
    <ItemContainer isSelected={isSelected} onClick={() => onTabClick(tab)}>
      {profileTabIcon(tab, isSelected ? palette.white : palette.text.primary)}
      <LabelMedium
        style={{ marginLeft: 4, color: isSelected ? palette.white : palette.text.primary }}>
        {t(profileTabTitle(tab))}
      </LabelMedium>
    </ItemContainer>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  flex: 1;
  padding: 0px 30px;
`

const ItemContainer = styled(Row)<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.primary : theme.palette.background.tertiary};
  border: 1px solid
    ${({ theme, isSelected }) => (isSelected ? 'transparent' : theme.palette.background.separator)};
  border-radius: 26px;
  cursor: pointer;
  margin-right: 20px;
  padding: 10px 20px;
`
