import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { InfoIcon } from 'src/assets/images/svg/InfoIcon'

type Props = {
  style?: React.CSSProperties
  fill?: string
  alignment?: 'right' | 'left'
  hint: string
}

export const Hint: React.FC<Props> = ({ style, hint, fill, alignment = 'right' }) => {
  // MARK: - Hooks

  const [showHint, setShowHint] = useState(false)

  // MARK: - Render

  return (
    <Container
      style={style}
      onMouseEnter={() => setShowHint(true)}
      onMouseLeave={() => setShowHint(false)}>
      <InfoIcon fill={fill} />
      {showHint && alignment === 'right' && <RightHintContainer>{hint}</RightHintContainer>}
      {showHint && alignment === 'left' && <LeftHintContainer>{hint}</LeftHintContainer>}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.separator};
  border-radius: 1000px;
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.primary};
      }
    `}
`

const HintContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  line-height: 22px;
  min-width: 280px;
  padding: 12px 16px 12px 16px;
  position: absolute;
  text-align: center;
  top: -12px;
  z-index: 10;
`

const LeftHintContainer = styled(HintContainer)`
  right: 24px;
`

const RightHintContainer = styled(HintContainer)`
  left: 24px;
`
