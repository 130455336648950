import React from 'react'
import { CalendarIcon } from 'src/assets/images/svg/CalendarIcon'
import { Col, LabelHeaderLarge, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const RoomCoverTitle: React.FC<{
  room: Room
}> = ({ room }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Container>
      <Title>{room.title}</Title>

      <Row style={{ alignItems: 'center' }}>
        <CalendarIcon fill={palette.orange} />
        <DateText>{prettyDateString(room.start_date)}</DateText>
      </Row>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin-bottom: 32px;
  margin-top: 24px;
`

const Title = styled(LabelHeaderLarge)`
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 6px;

  @media ${device.smallerThan.laptop} {
    font-size: 32px;
    line-height: 40px;
  }
`

const DateText = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.orange};
  font-size: 17px;
  margin-left: 6px;
`
