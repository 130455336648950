import React, { useCallback, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { AddEmojiIcon } from 'src/assets/images/svg/AddEmojiIcon'
import { SendIcon } from 'src/assets/images/svg/SendIcon'
import { Loader } from 'src/features/common/Loader'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'
import { EmojiPicker } from './EmojiPicker'

export const MessageInput: React.FC<{
  style?: React.CSSProperties
  isLoading?: boolean
  inputStyle?: React.CSSProperties
  placeholder: string
  onSendMessage: (text: string) => void
}> = ({ style, inputStyle, onSendMessage, placeholder, isLoading = false }) => {
  // MARK: - Hooks

  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const { isMobile } = useWindowDimensions()
  const [message, setMessage] = useState('')
  const [focused, setFocused] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const { palette } = useTheme()

  // MARK: - Handlers

  const handleTextChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }, [])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!isMobile && event.key === 'Enter') {
        event.preventDefault()
        if (message.trim().length !== 0) {
          onSendMessage(message)
          setMessage('')
        }
      }
    },
    [message, isMobile],
  )

  const handleFocus = useCallback(
    (isFocus: boolean) => () => {
      setFocused(isFocus)
    },
    [],
  )

  const handleEmojiPick = useCallback((emoji: any) => {
    setMessage(prev => prev + emoji.native)
    setShowEmojiPicker(false)
    inputRef.current?.focus()
  }, [])

  const handleEmojiPickerToggle = useCallback(
    (isOn: boolean) => () => {
      setShowEmojiPicker(isOn)
    },
    [],
  )

  const handleSendButtonClick = useCallback(() => {
    if (message.trim().length !== 0 && !isLoading) {
      onSendMessage(message)
      setMessage('')
      inputRef.current?.focus()
    }
  }, [message, isLoading])

  // MARK: - Render

  return (
    <Container style={style}>
      <InnerContainer
        style={{
          borderColor: focused ? palette.primary : palette.background.separator,
          ...inputStyle,
        }}>
        <Input
          ref={inputRef}
          maxRows={5}
          value={message}
          placeholder={placeholder}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus(true)}
          onBlur={handleFocus(false)}
        />

        <AddEmojiContainer>
          <AddEmojiButton onClick={handleEmojiPickerToggle(!showEmojiPicker)}>
            <AddEmojiIcon size={24} fill={palette.text.secondary} />
          </AddEmojiButton>

          <EmojiContainer>
            {showEmojiPicker && (
              <EmojiPicker onClose={handleEmojiPickerToggle(false)} setEmoji={handleEmojiPick} />
            )}
          </EmojiContainer>
        </AddEmojiContainer>

        {!isMobile && isLoading ? (
          <LoaderContainer>{<Loader size={22} thickness={3} />}</LoaderContainer>
        ) : null}
      </InnerContainer>

      {isMobile && (message.length || isLoading) ? (
        <SendWrapper onClick={handleSendButtonClick}>
          {isLoading ? (
            <SendContainer isLoading={isLoading}>
              <Loader size={22} thickness={3} />
            </SendContainer>
          ) : (
            <SendContainer isLoading={isLoading}>
              <SendIcon size={22} fill={palette.white} />
            </SendContainer>
          )}
        </SendWrapper>
      ) : null}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
`

const InnerContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 12px;
  margin-top: 0px;
  padding: 8px 8px 8px 16px;
  position: relative;

  @media ${device.smallerThan.laptop} {
    padding: 4px;
  }
`

const Input = styled(TextareaAutosize)`
  border: none;
  color: ${({ theme }) => theme.palette.text.primary};
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  outline: none;
  resize: none;
`

const EmojiContainer = styled.div`
  position: absolute;
  right: 0px;
  top: -360px;
`

const SendWrapper = styled.div`
  padding-right: 12px;
  padding-top: 2px;
`

const SendContainer = styled.div<{ isLoading: boolean }>`
  align-items: center;
  background-color: ${({ theme, isLoading }) =>
    isLoading ? 'transparent' : theme.palette.primary};
  border-radius: 16px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`

const LoaderContainer = styled.div`
  bottom: 10px;
  position: absolute;
  right: 48px;

  @media ${device.smallerThan.laptop} {
    bottom: 7px;
    right: 48px;
  }
`

const AddEmojiContainer = styled.div`
  cursor: pointer;
  padding-left: 4px;
`

const AddEmojiButton = styled.div`
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
`
