import { orderBy, uniqBy } from 'lodash'
import { mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk } from 'src/redux/store'
import { IError } from 'src/repository/Error'
import { activityService } from 'src/repository/services/activityService'
import { Activity, ActivitySection } from 'src/repository/types'

type State = {
  isLoading: Record<ActivitySection, boolean>
  isSingleUpdateLoading: Record<string, boolean>
  error: IError | null
  canPaginate: Record<ActivitySection, boolean>
  activities: Record<ActivitySection, Activity[]>
  unreadUpdateExists: boolean
}

// MARK: - State

export const initialState: State = {
  isLoading: { inbox: false, archived: false },
  isSingleUpdateLoading: {},
  error: null,
  activities: { inbox: [], archived: [] },
  canPaginate: { inbox: true, archived: true },
  unreadUpdateExists: false,
}

// MARK: - Reducer

export const activityReducer = (
  state = initialState,
  action:
    | SetLoadingAction
    | SetErrorAction
    | SetUpdatesAction
    | SetUnreadUpdateCountAction
    | SetSingleUpdateLoadingAction
    | SetCanPaginateAction
    | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'activity/setLoading':
      return { ...state, isLoading: { ...state.isLoading, [action.key]: action.isLoading } }

    case 'activity/setSingleUpdateLoadingAction':
      return {
        ...state,
        isSingleUpdateLoading: {
          ...state.isSingleUpdateLoading,
          [action.actionId]: action.isLoading,
        },
      }

    case 'activity/setCanPaginate':
      return { ...state, canPaginate: { ...state.canPaginate, [action.key]: action.canPaginate } }

    case 'activity/setError':
      return { ...state, error: action.error }

    case 'activity/setUpdates':
      return { ...state, activities: { ...state.activities, [action.key]: action.activities } }

    case 'activity/setUnreadUpdateExists':
      return { ...state, unreadUpdateExists: action.exists }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchUpdates =
  (section: ActivitySection, count: number, offset?: number): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(section, true))
    dispatch(setError(null))

    const currentUpdates = getUpdates(getState().activity, section)
    const response = await activityService.fetchActivities(
      offset ?? currentUpdates.length,
      count,
      section,
    )

    if (response.success) {
      const { activities, friendships, users, unread_activity_exists } = response.value
      dispatch(mergeEntities({ user: users, friendship: friendships }))
      dispatch(setCanPaginate(section, activities.length >= count))
      dispatch(setUnreadUpdateExists(unread_activity_exists))
      dispatch(setUpdates(section, activities))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(section, false))
  }

export const readUpdate =
  (activity: Activity): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setSingleUpdateLoading(activity.id, true))

    const currentUpdates = getUpdates(getState().activity, 'inbox')
    const activities = orderBy(
      uniqBy([{ ...activity, status: 'read' as const }, ...currentUpdates], 'id'),
      'created_at',
      'desc',
    )

    dispatch(setUpdates('inbox', activities))

    const response = await activityService.readActivity(activity.id)

    if (response.success) {
      const { unread_activity_exists } = response.value
      dispatch(setUnreadUpdateExists(unread_activity_exists))
    } else {
      dispatch(setUpdates('inbox', currentUpdates))
      dispatch(setError(response.error))
    }
    dispatch(setSingleUpdateLoading(activity.id, false))
  }

export const archiveUpdate =
  (activity: Activity): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setSingleUpdateLoading(activity.id, true))

    const currentUpdates = getUpdates(getState().activity, 'inbox')
    const activities = currentUpdates.filter(({ id }) => id !== activity.id)
    dispatch(setUpdates('inbox', activities))

    const response = await activityService.archiveActivity(activity.id)

    if (response.success) {
      const { unread_activity_exists } = response.value
      dispatch(setUnreadUpdateExists(unread_activity_exists))
    } else {
      dispatch(setUpdates('inbox', currentUpdates))
      dispatch(setError(response.error))
    }
    dispatch(setSingleUpdateLoading(activity.id, false))
  }

export const archiveAllUpdates = (): AsyncThunk => async (dispatch, getState) => {
  const currentUpdates = getUpdates(getState().activity, 'inbox')
  dispatch(setUpdates('inbox', []))

  const response = await activityService.archiveAllActivities()

  if (response.success) {
    dispatch(setUnreadUpdateExists(false))
  } else {
    dispatch(setUpdates('inbox', currentUpdates))
    dispatch(setError(response.error))
  }
}

export const setError = (error: IError | null): SetErrorAction => ({
  type: 'activity/setError',
  error: error,
})

export const setUnreadUpdateExists = (exists: boolean): SetUnreadUpdateCountAction => ({
  type: 'activity/setUnreadUpdateExists',
  exists: exists,
})

// MARK: - Selectors

export const getIsLoading = (state: State, key: keyof State['isLoading']): boolean => {
  return state.isLoading[key] ?? false
}

export const getIsSingleUpdateLoading = (state: State, actionId: string): boolean => {
  return state.isSingleUpdateLoading[actionId] ?? false
}

export const getCanPaginate = (state: State, key: keyof State['canPaginate']): boolean => {
  return state.canPaginate[key] ?? false
}

export const getUpdates = (state: State, key: keyof State['activities']): Activity[] => {
  return state.activities[key] ?? []
}

export const getError = (state: State): IError | null => {
  return state.error
}

export const getUnreadUpdateExists = (state: State): boolean => {
  return state.unreadUpdateExists
}

// MARK: - Action Types

type SetLoadingAction = {
  type: 'activity/setLoading'
  key: keyof State['isLoading']
  isLoading: boolean
}

type SetSingleUpdateLoadingAction = {
  type: 'activity/setSingleUpdateLoadingAction'
  actionId: string
  isLoading: boolean
}

type SetCanPaginateAction = {
  type: 'activity/setCanPaginate'
  key: keyof State['canPaginate']
  canPaginate: boolean
}

type SetUpdatesAction = {
  type: 'activity/setUpdates'
  key: keyof State['activities']
  activities: Activity[]
}

type SetUnreadUpdateCountAction = {
  type: 'activity/setUnreadUpdateExists'
  exists: boolean
}

type SetErrorAction = {
  type: 'activity/setError'
  error: IError | null
}

// MARK: - Internal Actions

const setLoading = (key: keyof State['isLoading'], isLoading: boolean): SetLoadingAction => ({
  type: 'activity/setLoading',
  key: key,
  isLoading: isLoading,
})

const setSingleUpdateLoading = (
  actionId: string,
  isLoading: boolean,
): SetSingleUpdateLoadingAction => ({
  type: 'activity/setSingleUpdateLoadingAction',
  actionId: actionId,
  isLoading: isLoading,
})

const setCanPaginate = (
  key: keyof State['canPaginate'],
  canPaginate: boolean,
): SetCanPaginateAction => ({
  type: 'activity/setCanPaginate',
  key: key,
  canPaginate: canPaginate,
})

const setUpdates = (key: keyof State['activities'], activities: Activity[]): SetUpdatesAction => ({
  type: 'activity/setUpdates',
  key: key,
  activities: activities,
})
