import { AgendaType } from './types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, FlexRow, LabelMedium, LabelNormal } from 'src/features/common/CommonStyles'
import { EmptyState } from 'src/features/common/EmptyState'
import { Loader } from 'src/features/common/Loader'
import { getEntity } from 'src/redux/reducers/entity'
import { fetchProfileRooms, getAgendaTypeRooms, getIsLoading } from 'src/redux/reducers/profile'
import { Room, User } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const ProfileRoomList: React.FC<{
  userId: string
  agendaType: AgendaType
  onRoomClick: (room: Room) => void
}> = ({ agendaType, userId, onRoomClick }) => {
  // MARK: - Hooks

  const pageSize = 10
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId))
  const rooms = useSelect(state => getAgendaTypeRooms(state.profile, userId, agendaType))
  const isLoading = useSelect(state => getIsLoading(state.profile, agendaType))

  const emptyStateTitle = (() => {
    switch (agendaType) {
      case 'upcoming':
        return t('noRoomsJoined', { name: user?.first_name })
      case 'past':
        return t('noRoomsJoinedPast', { name: user?.first_name })
      case 'moderated':
        return t('noRoomsModerated', { name: user?.first_name })
    }
  })()

  const emptyStateSubTitle = (() => {
    switch (agendaType) {
      case 'upcoming':
        return t('noRoomsJoinedDescription', { name: user?.first_name })
      case 'past':
        return t('noRoomsJoinedPastDescription', { name: user?.first_name })
      case 'moderated':
        return t('noRoomsModeratedDescription', { name: user?.first_name })
    }
  })()

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchProfileRooms(userId, pageSize, agendaType))
  }, [userId, agendaType])

  // MARK: - Render

  return (
    <Col>
      <Container>
        {isLoading && !rooms.length && <Loader style={{ marginTop: 150, marginLeft: '48%' }} />}

        {!!rooms.length &&
          rooms.map(room => (
            <ProfileRoomListItem key={room.id} room={room} onRoomSelect={onRoomClick} />
          ))}

        {!isLoading && !rooms.length && (
          <EmptyState title={emptyStateTitle} subtitle={emptyStateSubTitle} />
        )}
      </Container>
    </Col>
  )
}

const ProfileRoomListItem: React.FC<{
  room: Room
  onRoomSelect: (room: Room) => void
}> = ({ room, onRoomSelect }) => (
  <ItemContainer onClick={() => onRoomSelect(room)}>
    <Image src={room.main_media?.url} />

    <Col>
      <LabelMedium style={{ ...numberOfLines(2), marginTop: 8, marginBottom: 6 }}>
        {room.title}
      </LabelMedium>
      <LabelNormal>{prettyDateString(room?.start_date ?? 0)}</LabelNormal>
    </Col>
  </ItemContainer>
)

// MARK: - Styles

const Container = styled(FlexRow)`
  align-self: center;
  flex-wrap: wrap;
  margin: 20px 20px;
  min-width: calc(100% - 40px);
`
const ItemContainer = styled.div`
  border-color: transparent;
  cursor: pointer;
  margin: 0px 1.5% 24px 1.5%;
  overflow: hidden;
  position: relative;
  width: 280px;
`

const Image = styled.img`
  border-radius: 12px;
  height: 165px;
  object-fit: cover;
  width: 100%;
`
