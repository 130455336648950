import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { FlagIcon } from 'src/assets/images/svg/FlagIcon'
import { LeaveIcon } from 'src/assets/images/svg/LeaveIcon'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import { ShareIcon } from 'src/assets/images/svg/ShareIcon'
import { ThreeDotsHorizontalIcon } from 'src/assets/images/svg/ThreeDotsHorizontalIcon'
import { AlertModal } from 'src/features/common/AlertModal'
import {
  FlexCol,
  FlexRow,
  LabelHeader,
  LabelMedium,
  LabelSmall,
  Row,
  Separator,
  ShadowBox,
} from 'src/features/common/CommonStyles'
import { DropdownWrapper } from 'src/features/common/DropdownWrapper'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { getEntity, getFirstEntity } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import {
  getIsLoading,
  removeFriend,
  respondFriendRequest,
  sendFriendRequest,
} from 'src/redux/reducers/profile'
import { Error, IError } from 'src/repository/Error'
import { Friendship, User } from 'src/repository/types'
import { dayString } from 'src/utils/helpers/dateHelper'
import { linkImage, openURL } from 'src/utils/helpers/linkHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const ProfileUserCard: React.FC<{
  userId: string
  onProfileEditClick: () => void
}> = ({ onProfileEditClick, userId }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  const profileCardOffset = -100
  const dispatch = useDispatch()

  const [scrollOffset, setScrollOffset] = useState(profileCardOffset)
  const [alert, setAlert] = useState<IError | null>(null)

  const isLoading = useSelect(
    state => getIsLoading(state.profile, 'friendship') || getIsLoading(state.profile, 'user'),
  )
  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId))
  const meUser = useSelect(state => getMeUser(state.me))

  const userIds = [meUser?.id, user?.id]
  const friendship = useSelect(state =>
    getFirstEntity<Friendship>(
      state.entity,
      'friendship',
      ({ target_user_id, source_user_id }) =>
        userIds.includes(target_user_id) && userIds.includes(source_user_id),
    ),
  )
  const isMeUser = user?.id === meUser?.id
  const mainButtonTitle = (() => {
    if (isMeUser) return t('edit')
    else if (!friendship || friendship.status === 'rejected') return t('addFriend')
    else if (friendship.status === 'pending' && friendship.target_user_id === meUser?.id)
      return t('accept')
    else if (friendship.status === 'pending' && friendship.source_user_id === meUser?.id)
      return t('pending')
    else return t('unfriend')
  })()

  const dropdownOptions = [{ title: t('report'), icon: FlagIcon }]
  if (friendship?.status === 'accepted') {
    dropdownOptions.push({ title: t('unfriend'), icon: LeaveIcon })
  }

  // MARK: - Effects

  useEffect(() => {
    const onScroll = () => setScrollOffset(Math.max(profileCardOffset, window.pageYOffset - 300))
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // MARK: - Actions

  const handleOptionsDropdownOptionClick = (option: number) => {
    if (option === 0) {
      toast.warning(t('reported'))
    } else if (option === 1 && friendship) {
      setAlert(
        Error.displayable(
          t('unfriend'),
          t('unfriendDescription', { name: user?.first_name }),
          success => {
            if (success) dispatch(removeFriend(friendship))
          },
          t('removeFriend'),
        ),
      )
    }
  }

  const handleMainButtonClick = () => {
    if (isMeUser) {
      onProfileEditClick()
    } else if (!friendship || friendship?.status === 'rejected') {
      dispatch(sendFriendRequest(userId))
    } else if (friendship.status === 'pending') {
      if (friendship.source_user_id === meUser?.id) dispatch(removeFriend(friendship))
      else if (friendship.target_user_id === meUser?.id)
        dispatch(respondFriendRequest(friendship, true))
    }
  }

  const handleSubscriptionButtonClick = () => {
    toast.success(t('subscribed'))
  }

  const handleShareButtonClick = () => {
    toast.success(t('copiedToClipboard'))
  }

  // MARK: - Render

  if (!user) return null

  const Button = isMeUser || friendship?.status === 'accepted' ? SecondaryButton : PrimaryButton

  return (
    <Container style={{ top: scrollOffset }}>
      <PublisherContainer>
        <PublisherImage
          src={user.image_url || require('src/assets/images/profile_placeholder.png')}
        />
      </PublisherContainer>

      <LabelHeader>{user.first_name + ' ' + user.last_name}</LabelHeader>
      <LabelMedium style={{ marginTop: 2 }}>{'@' + user.user_name}</LabelMedium>
      <LabelSmall style={{ marginTop: 12, textAlign: 'center' }}>{user.bio}</LabelSmall>

      <Row style={{ marginTop: 24, marginBottom: 18 }}>
        {friendship?.status !== 'accepted' && (
          <Button
            style={{ marginRight: 6 }}
            title={mainButtonTitle}
            onClick={handleMainButtonClick}
            isLoading={!friendship && isLoading && !isMeUser}
          />
        )}
        <ButtonContainer onClick={handleShareButtonClick}>
          <ShareIcon />
        </ButtonContainer>

        {!isMeUser && (
          <ButtonContainer onClick={handleSubscriptionButtonClick}>
            <AnnouncementIcon />
          </ButtonContainer>
        )}

        {!isMeUser && friendship?.status === 'accepted' ? (
          <DropdownWrapper
            style={{ width: 40, height: 40, marginLeft: 8 }}
            icon={
              <ButtonContainer style={{ minWidth: 40, minHeight: 40 }}>
                <ThreeDotsHorizontalIcon />
              </ButtonContainer>
            }
            position={{ right: -200, top: 46 }}
            options={dropdownOptions}
            handleOptionClick={handleOptionsDropdownOptionClick}
          />
        ) : null}
      </Row>

      {user.links.map(link => (
        <LinkContainer key={link.id} onClick={() => openURL(link.url)}>
          <FlexRow style={{ alignItems: 'center' }}>
            <LinkImage src={linkImage(link.type)} />
            <LabelMedium>{link.name}</LabelMedium>
          </FlexRow>

          <RightArrowIcon style={{ height: 12, width: 8, marginLeft: 8 }} />
        </LinkContainer>
      ))}

      <Separator margin={28} />

      <LabelSmall style={{ textAlign: 'center' }}>
        {t('memberSince', { date: dayString(user.created_at) })}
      </LabelSmall>

      {alert && <AlertModal visible error={alert} onClose={() => setAlert(null)} />}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  position: absolute;
  width: 320px;
`

const PublisherContainer = styled.div`
  border-radius: 60px;
  height: 120px;
  margin-bottom: 16px;
  overflow: hidden;
  width: 120px;
  z-index: 2;
`

const PublisherImage = styled.img`
  height: 100%;
  width: 100%;
`

const ButtonContainer = styled(FlexCol)`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0px 8px;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
`

const LinkContainer = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
`

const LinkImage = styled.img`
  border-radius: 4px;
  height: 24px;
  margin-right: 12px;
  min-width: 24px;
  overflow: hidden;
  width: 24px;
`
