import React, { useState } from 'react'
import { Col, FlexCol, LabelSmall } from 'src/features/common/CommonStyles'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const CalendarTypeListItem: React.FC<{
  title: string
  icon: any
  onCalendarTypeClick: () => void
}> = ({ title, icon: Icon, onCalendarTypeClick }) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { isMobile } = useWindowDimensions()
  const [pressed, setPressed] = useState(false)

  // MARK: - Render

  return (
    <Container>
      <ButtonContainer
        pressed={pressed}
        onPointerDown={() => setPressed(true)}
        onPointerUp={() => setPressed(false)}
        onClick={onCalendarTypeClick}>
        <Icon fill={palette.text.primary} size={isMobile ? 24 : 30} />
      </ButtonContainer>
      <Title>{title}</Title>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
  user-select: none;
  width: 33%;

  @media ${device.largerThan.laptop} {
    width: 25%;
    margin-top: 24px;
  }
`

const ButtonContainer = styled(FlexCol)<{ pressed: boolean }>`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  margin: 0px 0px 4px 0px;
  min-height: 48px;
  opacity: ${({ pressed }) => (pressed ? 0.4 : 1)};
  transition: opacity 0.3s ease-out;
  width: 48px;

  @media ${device.largerThan.laptop} {
    min-height: 60px;
    width: 60px;
  }
`

const Title = styled(LabelSmall)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
`
