import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { FAQIcon } from 'src/assets/images/svg/FAQIcon'
import { ListIcon } from 'src/assets/images/svg/ListIcon'
import { OverviewIcon } from 'src/assets/images/svg/OverviewIcon'
import { PollingIcon } from 'src/assets/images/svg/PollingIcon'
import { QAIcon } from 'src/assets/images/svg/QAIcon'
import { SpreadGameIcon } from 'src/assets/images/svg/SpreadGameIcon'
import { WebPageIcon } from 'src/assets/images/svg/WebPageIcon'
import { ComponentType } from 'src/repository/types'
import { SVG } from '../types/svg'

export const componentImage = (componentType: ComponentType): SVG => {
  switch (componentType) {
    case ComponentType.announcements:
      return AnnouncementIcon
    case ComponentType.webPage:
      return WebPageIcon
    case ComponentType.spreadGame:
      return SpreadGameIcon
    case ComponentType.faq:
      return FAQIcon
    case ComponentType.webLinks:
      return ListIcon
    case ComponentType.qa:
      return QAIcon
    case ComponentType.polling:
      return PollingIcon
    default:
      return OverviewIcon
  }
}

export const componentImageUrl = (componentType: ComponentType): string => {
  switch (componentType) {
    case ComponentType.announcements:
      return 'https://cdn-icons-png.flaticon.com/256/1156/1156949.png'
    case ComponentType.webPage:
      return 'https://cdn-icons-png.flaticon.com/256/3867/3867462.png'
    case ComponentType.spreadGame:
      return 'https://cdn-icons-png.flaticon.com/256/3629/3629514.png'
    case ComponentType.webLinks:
      return 'https://cdn-icons-png.flaticon.com/256/122/122939.png'
    case ComponentType.faq:
      return 'https://cdn-icons-png.flaticon.com/256/4403/4403555.png'
    case ComponentType.qa:
      return 'https://cdn-icons-png.flaticon.com/256/4509/4509758.png'
    case ComponentType.polling:
      return 'https://cdn-icons-png.flaticon.com/256/1533/1533932.png'
    default:
      return 'https://cdn-icons-png.flaticon.com/256/1156/1156949.png'
  }
}
