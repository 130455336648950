import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import config from '../config'
import * as resources from './resources'

const navigator = window.navigator as any
let lang = navigator.languages ? navigator.languages[0] : null
lang = lang || navigator.language || navigator.browserLanguage || navigator.userLanguage

let shortLang = lang ?? 'en'
if (shortLang?.indexOf('-') !== -1) shortLang = shortLang?.split('-')[0]
if (shortLang?.indexOf('_') !== -1) shortLang = shortLang?.split('_')[0]
const language = localStorage.getItem(config.keys.localization) ?? shortLang
localStorage.setItem(config.keys.localization, language)

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: language,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
