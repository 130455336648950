import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import {
  Col,
  FlexCol,
  FlexRow,
  LabelMedium,
  LabelSmall,
  RoundedImage,
} from 'src/features/common/CommonStyles'
import { ComponentWrapper } from 'src/features/common/ComponentWrapper'
import { EmptyState } from 'src/features/common/EmptyState'
import { getLoading } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import { fetchWebListItems } from 'src/redux/reducers/room'
import { Component, Room, WebListItem } from 'src/repository/types'
import { openURL } from 'src/utils/helpers/linkHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const WebList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { palette } = useTheme()
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const isLoading = useSelect(state => getLoading(state.app, 'web_list'))
  const webListItems = useSelect(state =>
    getEntities<WebListItem>(
      state.entity,
      'web_list_item',
      webListItem => webListItem.component_id === component.id,
    ).sort((first, second) => second.created_at - first.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchWebListItems(component.id))
  }, [component, dispatch])

  // MARK: - Render

  const renderItem = useCallback(
    (webListItem: WebListItem, index: number) => (
      <ItemContainer
        key={webListItem.id}
        onPointerEnter={() => setSelectedIndex(index)}
        index={index}
        onClick={() => openURL(webListItem.link)}>
        <FlexRow>
          {webListItem.image_url && (
            <RoundedImage style={{ marginTop: 2 }} size={48} src={webListItem.image_url} />
          )}
          <FlexCol>
            <LabelMedium
              style={{ color: selectedIndex === index ? palette.primary : palette.text.primary }}>
              {webListItem.title}
            </LabelMedium>
            <LabelSmall style={{ marginTop: 2 }}>{webListItem.overview}</LabelSmall>
          </FlexCol>
        </FlexRow>

        {selectedIndex === index ? (
          <RightArrowIcon style={{ height: 12, width: 8, marginLeft: 8 }} />
        ) : (
          <div style={{ width: 16 }} />
        )}
      </ItemContainer>
    ),
    [selectedIndex],
  )

  return (
    <ComponentWrapper room={room} component={component}>
      <Container onPointerLeave={() => setSelectedIndex(null)}>
        <EmptyState
          hide={!!webListItems.length}
          isLoading={isLoading}
          title={t('noAnnouncementsPostedYet')}
          subtitle={t('oncePublisherPostedAnnouncements')}
        />

        {webListItems.length > 0 && webListItems.map(renderItem)}
      </Container>
    </ComponentWrapper>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
`

const ItemContainer = styled(FlexRow)<{ index: number }>`
  align-items: center;
  background-color: ${({ theme, index }) =>
    index % 2 === 0 ? theme.palette.background.primary : theme.palette.background.secondary};
  cursor: pointer;
  justify-content: space-between;
  padding: 18px 20px;
`
