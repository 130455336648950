import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OverviewIcon } from 'src/assets/images/svg/OverviewIcon'
import { FlexCol, LabelHeader, LabelNormal, Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading } from 'src/redux/reducers/app'
import { setAccessCode } from 'src/redux/reducers/room'
import {
  cleanRoomAccess,
  getShouldRedirect,
  requestWithAccessCode,
} from 'src/redux/reducers/roomAccess'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import { useTheme } from 'styled-components'

export const RoomAccessEnterAccessCode: React.FC<{
  roomId: string
}> = ({ roomId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const { navigate, routes } = useNavigation()
  const { palette } = useTheme()
  const { isMobile } = useWindowDimensions()
  const isLoading = useSelect(state => getLoading(state.app, 'access'))
  const shouldRedirect = useSelect(state => getShouldRedirect(state.access))

  // MARK: - Effects

  useEffect(() => {
    if (shouldRedirect) {
      dispatch(cleanRoomAccess())
      dispatch(setAccessCode(code))
      navigate(routes.room(roomId).cover)
    }
  }, [shouldRedirect])

  // MARK: - Actions

  const handleSendAccessCode = () => {
    if (!code) return
    dispatch(requestWithAccessCode(roomId, code))
  }

  // MARK: - Render

  return (
    <ModalWrapper size="tiny" height="dynamic" disableClose>
      <FlexCol style={{ margin: 16, paddingBottom: 80 }}>
        <LabelHeader style={{ marginBottom: 16 }}>{t('privateRoom')}</LabelHeader>

        <Input
          label={t('accessCode')}
          placeholder={'e.g LOBBI2022'}
          value={code}
          autoFocus
          onEnterPress={handleSendAccessCode}
          onChange={text => setCode(text.toUpperCase())}
        />

        <Row>
          <OverviewIcon
            fill={palette.text.secondary}
            style={{
              minWidth: 22,
              alignSelf: 'flex-start',
              marginRight: 8,
              marginTop: 2,
            }}
          />
          <LabelNormal>{t('privateRoomDescription')}</LabelNormal>
        </Row>
      </FlexCol>

      <FooterWrapper>
        <PrimaryButton
          isLoading={isLoading}
          style={{ flex: 1, maxWidth: isMobile ? 240 : 180 }}
          title={t('send')}
          onClick={handleSendAccessCode}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}
