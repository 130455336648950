import { api } from 'src/repository/api'
import { Chat, ChatMessage, User } from 'src/repository/types'

export const chatService = {
  fetchChats: (offset: number, limit: number, lastUpdatedAt: number = 1) =>
    api.value<{ chats: Chat[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/chat',
        parameters: { limit, offset, last_updated_at: lastUpdatedAt },
      },
    }),

  fetchSingleChat: (chatId: string) =>
    api.value<{ chat: Chat; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/chat/single',
        parameters: { chat_id: chatId },
      },
    }),

  fetchMessages: (userId: string, offset: number, limit: number) =>
    api.value<{ messages: ChatMessage[] }>({
      request: {
        method: 'GET',
        endPoint: '/chat/messages',
        parameters: { user_id: userId, offset, limit },
      },
    }),

  fetchMessage: (chatId: string, messageIds: string[]) =>
    api.value<{ chat: Chat; messages: ChatMessage[] }>({
      request: {
        method: 'POST',
        endPoint: '/chat/message',
        parameters: { chat_id: chatId, message_ids: messageIds },
      },
    }),

  fetchMessageUpdates: (userId: string, lastCreatedAt: number, lastUpdatedAt: number) =>
    api.value<{ chat: Chat; messages: ChatMessage[] }>({
      request: {
        method: 'GET',
        endPoint: '/chat/messages/update',
        parameters: {
          user_id: userId,
          last_updated_at: lastUpdatedAt,
          last_created_at: lastCreatedAt,
        },
      },
    }),

  sendMessage: (
    userId: string,
    messages: Omit<
      ChatMessage,
      'created_at' | 'createdAt' | 'updated_at' | 'reactions' | '_id' | 'user' | 'chat_id'
    >[],
  ) =>
    api.value<{ messages: ChatMessage[]; chat: Chat }>({
      request: {
        method: 'POST',
        endPoint: '/chat/messages',
        parameters: { user_id: userId, messages },
      },
    }),

  ping: (online: boolean) =>
    api.value<{ user: User }>({
      request: {
        method: 'PUT',
        endPoint: '/chat/ping',
        parameters: { online },
      },
    }),

  read: (userId: string, messageId: string) =>
    api.prop<{ chat: Chat }>('chat', {
      request: {
        method: 'PUT',
        endPoint: '/chat/messages/read',
        parameters: { user_id: userId, message_id: messageId },
      },
    }),

  deleteMessage: (messageId: string) =>
    api.value<{ message: ChatMessage }>({
      request: {
        method: 'DELETE',
        endPoint: '/chat/messages',
        parameters: { message_id: messageId },
      },
    }),

  toggleReaction: async (
    messageId: string,
    reaction: string,
    isActive: boolean,
  ): Promise<Result<ChatMessage>> =>
    api.prop<{ message: ChatMessage }>('message', {
      request: {
        method: 'PUT',
        endPoint: '/chat/messages/react',
        parameters: { message_id: messageId, reaction, is_active: isActive },
      },
    }),
}
