import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 20, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.30722 11.5965L11.3827 3.68054C12.4217 2.66207 14.1069 2.66207 15.1459 3.68054L16.2245 4.73785C17.2635 5.75631 17.2635 7.40823 16.2245 8.42669L8.11717 16.3738C7.65359 16.8283 7.02506 17.0833 6.36901 17.0833H3.04878C2.74035 17.0833 2.49249 16.8342 2.50017 16.5319L2.58369 13.2479C2.6003 12.6272 2.85939 12.0355 3.30722 11.5965ZM15.4484 5.49862L14.3698 4.4413C13.7594 3.843 12.7691 3.843 12.1588 4.4413L11.5319 5.05583L14.8218 8.28012L15.4484 7.66594C16.0588 7.06763 16.0588 6.09693 15.4484 5.49862ZM4.08331 12.3572L10.7558 5.81659L14.0457 9.04088L7.34107 15.6131L7.25189 15.6931C7.00558 15.8954 6.69351 16.0074 6.36901 16.0074L3.6112 16.007L3.68089 13.2754C3.69011 12.9308 3.83438 12.6013 4.08331 12.3572ZM17.5 16.5454C17.5 16.2483 17.2543 16.0075 16.9512 16.0075H11.2258L11.1514 16.0124C10.8835 16.048 10.677 16.2731 10.677 16.5454C10.677 16.8425 10.9227 17.0833 11.2258 17.0833H16.9512L17.0257 17.0784C17.2935 17.0428 17.5 16.8177 17.5 16.5454Z"
      fill={fill}
    />
  </svg>
)

export const EditIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
