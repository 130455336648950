import i18n from '../localization'
import { Localization } from '../types/i18n'

export const homeSidebarOption = (lng: Localization) => ({
  title: i18n.t('home', { lng }),
  image_url: sidebarOptionsImage('home'),
})

export const sidebarOptionsImage = (section: 'home' | 'chats' | 'notifications'): string => {
  if (section === 'home') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F785822?alt=media&token=b03ec4f3-ad2a-4ce9-b07d-dfd7720577a8'
  } else if (section === 'notifications') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F1040218?alt=media&token=e582e5dd-c8a4-4248-ac77-d8d1af453b98'
  } else if (section === 'chats') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F3073390?alt=media&token=430f0e89-955c-42c3-845c-06faef073fa0'
  } else {
    return ''
  }
}
