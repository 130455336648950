import { api } from '../api'
import { Friendship, Activity, ActivitySection, User } from '../types'

export const activityService = {
  fetchActivities: async (offset: number, limit: number, section: ActivitySection) =>
    api.value<{
      activities: Activity[]
      friendships: Friendship[]
      users: User[]
      unread_activity_exists: boolean
    }>({
      request: {
        method: 'GET',
        endPoint: '/activity',
        parameters: { offset, limit, section },
      },
    }),

  readActivity: async (activityId: string) =>
    api.value<{ activity: Activity; unread_activity_exists: boolean }>({
      request: {
        method: 'PUT',
        endPoint: '/activity/read',
        parameters: { activity_id: activityId },
      },
    }),

  archiveActivity: async (activityId: string) =>
    api.value<{ activity: Activity; unread_activity_exists: boolean }>({
      request: {
        method: 'PUT',
        endPoint: '/activity/archive',
        parameters: { activity_id: activityId },
      },
    }),

  archiveAllActivities: async () =>
    api.prop<{ activities: Activity[] }>('activities', {
      request: {
        method: 'PUT',
        endPoint: '/activity/archive/all',
        parameters: {},
      },
    }),
}
