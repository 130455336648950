import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import { AccountModal } from 'src/features/account'
import {
  FlexRow,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  Row,
  Separator,
  ShadowBox,
} from 'src/features/common/CommonStyles'
import { PageLoader } from 'src/features/common/PageLoader'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { UserImage } from 'src/features/common/UserImage'
import { getLoading } from 'src/redux/reducers/app'
import { getEntity, getFirstEntity } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { removeFriend, respondFriendRequest, sendFriendRequest } from 'src/redux/reducers/profile'
import { fetchChatUser } from 'src/redux/reducers/roomChat'
import { Friendship, User } from 'src/repository/types'
import config from 'src/utils/config'
import { linkImage, openURL } from 'src/utils/helpers/linkHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const RoomChatChannelListProfileModal: React.FC<{
  userId: string
}> = ({ userId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { navigate, routes } = useNavigation()

  const [focused, setFocused] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)

  const user = useSelect(state => getEntity<User>(state.entity, 'user', userId))
  const meUser = useSelect(state => getMeUser(state.me))
  const isLoading = useSelect(state => getLoading(state.app, 'chat_user_loading_' + userId))
  const friendship = useSelect(state =>
    getFirstEntity<Friendship>(
      state.entity,
      'friendship',
      ({ target_user_id, source_user_id }) =>
        [meUser?.id, userId].includes(target_user_id) &&
        [meUser?.id, userId].includes(source_user_id),
    ),
  )

  const isMeUser = user?.id === meUser?.id

  const mainButtonTitle = (() => {
    if (isMeUser) return t('edit')
    else if (!friendship || friendship.status === 'rejected') return t('addFriend')
    else if (friendship.status === 'pending' && friendship.target_user_id === meUser?.id)
      return t('accept')
    else if (friendship.status === 'pending' && friendship.source_user_id === meUser?.id)
      return t('pending')
    else return t('unfriend')
  })()

  // MARK: - Effects

  useEffect(() => {
    if (!user || !friendship) dispatch(fetchChatUser(userId))
  }, [userId])

  // MARK: - Actions

  const handleMainButtonClick = () => {
    if (isMeUser) {
      setShowAccountModal(true)
    } else if (!friendship || friendship?.status === 'rejected') {
      dispatch(sendFriendRequest(userId))
    } else if (friendship.status === 'pending') {
      if (friendship.source_user_id === meUser?.id) dispatch(removeFriend(friendship))
      else if (friendship.target_user_id === meUser?.id)
        dispatch(respondFriendRequest(friendship, true))
    }
  }

  // MARK: - Render

  if (!user) {
    return (
      <Container onClick={event => event.stopPropagation()}>
        <InnerContainer>
          <PageLoader style={{ paddingTop: 32 }} />
        </InnerContainer>
      </Container>
    )
  }

  return (
    <Container onClick={event => event.stopPropagation()}>
      <InnerContainer>
        <CoverImageContainer>
          <img
            style={{
              objectFit: 'cover',
              position: 'absolute',
              top: user.cover_image_offset / 5,
              width: '100%',
              userSelect: 'none',
            }}
            src={user.cover_image || config.defaultCover}
            alt={config.defaultCover}
          />
        </CoverImageContainer>

        <UserImageContainer
          onPointerEnter={() => setFocused(true)}
          onPointerLeave={() => setFocused(false)}>
          <UserImage url={user.image_url} size={80} />
          {focused && (
            <ViewProfileCover onClick={() => navigate(routes.profile(userId).main)}>
              {t('viewProfile')}
            </ViewProfileCover>
          )}
        </UserImageContainer>

        <LabelLarge style={{ marginTop: 8 }}>{user.first_name + ' ' + user.last_name}</LabelLarge>
        <LabelSmall>{'@' + user.user_name}</LabelSmall>

        {friendship?.status !== 'accepted' && (
          <SecondaryButton
            style={{
              position: 'absolute',
              right: 16,
              top: 56,
              height: 38,
              fontSize: 14,
              paddingLeft: 16,
              paddingRight: 16,
            }}
            title={mainButtonTitle}
            isLoading={isLoading}
            onClick={handleMainButtonClick}
          />
        )}

        {!!user.bio && (
          <LabelSmall style={{ marginTop: 8, fontSize: 14, marginBottom: 8 }}>
            {user.bio}
          </LabelSmall>
        )}

        {!!user.links.length && <Separator margin={8} />}

        {user.links.map(link => (
          <LinkContainer key={link.id} onClick={() => openURL(link.url)}>
            <FlexRow style={{ alignItems: 'center' }}>
              <LinkImage src={linkImage(link.type)} />
              <LabelMedium>{link.name}</LabelMedium>
            </FlexRow>

            <RightArrowIcon style={{ height: 12, width: 8, marginLeft: 8 }} />
          </LinkContainer>
        ))}
      </InnerContainer>
      {isMeUser && showAccountModal && <AccountModal onClose={() => setShowAccountModal(false)} />}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-out;
  margin: 0px 0px 0px -350px;
  padding-right: 30px;
  position: fixed;
  z-index: 5;
`

const InnerContainer = styled(ShadowBox)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 320px;
`

const CoverImageContainer = styled.div`
  height: 76px;
  left: 0px;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  top: 0px;
  user-select: none;
  width: 320px;
`

const UserImageContainer = styled(Row)`
  overflow: hidden;
  position: relative;
  width: 80px;
  z-index: 2;
`

const ViewProfileCover = styled(LabelMedium)`
  background-color: #00000090;
  border-radius: 40px;
  bottom: 0px;
  color: ${({ theme }) => theme.palette.white};
  font-size: 13px;
  font-weight: 600;
  left: 0px;
  line-height: 80px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0px;
  z-index: 5;
`

const LinkContainer = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
`

const LinkImage = styled.img`
  border-radius: 4px;
  height: 24px;
  margin-right: 12px;
  min-width: 24px;
  overflow: hidden;
  width: 24px;
`
