import classNames from 'classnames'
import React from 'react'

type Props = {}

export const NotFound: React.FC<Props> = props => {
  return (
    <div className="container">
      <div className={innerClasses}>
        <h3>Page not found</h3>
      </div>
    </div>
  )
}

// MARK: - Styles

const innerClasses = classNames('hero-inner section-inner', 'has-top-divider', 'has-bottom-divider')
