import React, { useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { LabelMedium, Row } from './CommonStyles'
import { Loader } from './Loader'

export const PrimaryButton: React.FC<{
  style?: React.CSSProperties
  title: string
  onClick: () => void
  isLoading?: boolean
  disabled?: boolean
  size?: 'default' | 'sm'
}> = ({ style, title, onClick, isLoading, disabled, size = 'sm' }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Handlers

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled) return

      event.stopPropagation()
      onClick()
    },
    [disabled, onClick],
  )

  // MARK: - Render

  return (
    <Container
      style={{
        backgroundColor: disabled ? palette.background.disabled : palette.primary,
        cursor: disabled ? 'not-allowed' : 'pointer',
        height: size === 'sm' ? 40 : 54,
        ...style,
      }}
      onClick={handleButtonClick}>
      {isLoading ? (
        <Loader size={20} thickness={2} color={palette.white} />
      ) : (
        <LabelMedium
          style={{ color: palette.white, letterSpacing: 0.3, fontSize: 15, fontWeight: 600 }}>
          {title}
        </LabelMedium>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  padding: 0px 16px;
`
