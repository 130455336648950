import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchChats } from 'src/redux/reducers/chat'
import { Chat, User } from 'src/repository/types'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import { useTheme } from 'styled-components'
import { ChatPage } from '../chat'
import { FlexRow } from '../common/CommonStyles'
import { PageLoader } from '../common/PageLoader'
import { LayoutWrapper } from '../layout/LayoutWrapper'
import { ChatSideBar } from './ConnectionsSideBar'

export const Connections: React.FC = () => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()
  const { palette } = useTheme()
  const [selectedChat, setSelectedChat] = useState<(Chat & { user: User }) | null>(null)

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchChats())
  }, [])

  // MARK: - Render

  return (
    <LayoutWrapper>
      <FlexRow style={{ minHeight: isMobile ? 'calc(100vh - 72px)' : undefined }}>
        <ChatSideBar onChatSelect={setSelectedChat} />
        {selectedChat?.user.id ? (
          <ChatPage userId={selectedChat.user.id} />
        ) : (
          <PageLoader style={{ backgroundColor: palette.background.secondary }} />
        )}
      </FlexRow>
    </LayoutWrapper>
  )
}
