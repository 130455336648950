import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelect } from 'src/utils/hooks/useSelect'
import { Col, LabelHeader, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { DateTimePicker } from 'src/features/common/DateTimePicker'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { getLocalization } from 'src/redux/reducers/app'
import { Room, Subscription } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import styled, { css } from 'styled-components'

type Props = {
  room: Room
  subscription: Subscription | null
  visible: boolean
  isLoading: boolean
  reminderOptions: { key: string; value: number }[]
  onSetReminder: (time: number) => void
  onRemoveReminder: () => void
  onClose: () => void
}

export const SetReminderDesktop: React.FC<Props> = ({
  room,
  subscription,
  isLoading,
  reminderOptions,
  onSetReminder,
  onRemoveReminder,
  onClose,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const localization = useSelect(state => getLocalization(state.app))
  const [pickerOpen, setPickerOpen] = useState(false)
  const [time, setTime] = useState(subscription?.push_date || room.start_date - 30 * 60)
  const currentTimeDiff = room.start_date - time

  // MARK: - Render

  return (
    <ModalWrapper height="dynamic" size="tiny" closeOnBackgroundClick onClose={onClose}>
      <Container pickerOpen={pickerOpen}>
        <LabelHeader style={{ marginBottom: 16 }}>{t('setReminder')}</LabelHeader>
        <SubTitle>
          {t(room.start_date - time >= 0 ? 'beforeStartDate' : 'afterStartDate', {
            time: moment
              .duration((room.start_date - time) * 1000)
              .locale(localization)
              .humanize(),
          })}
        </SubTitle>

        <OptionContainer>
          {reminderOptions.map(option => (
            <Option
              key={option.key}
              selected={currentTimeDiff === option.value}
              onClick={() => setTime(room.start_date - option.value)}>
              {option.key}
            </Option>
          ))}
        </OptionContainer>

        <DateTimePicker
          style={{ marginTop: 16 }}
          onPickerOpen={() => setPickerOpen(true)}
          onPickerClose={() => setPickerOpen(false)}
          date={new Date(time * 1000)}
          onDateChange={newTime => setTime(newTime)}
        />

        <FooterWrapper>
          {!!subscription?.push_date && !isLoading && (
            <SecondaryButton
              style={{ marginRight: 16, minWidth: 140 }}
              title={t('remove')}
              isLoading={isLoading}
              onClick={onRemoveReminder}
            />
          )}

          <PrimaryButton
            style={{ marginRight: 16, minWidth: 140 }}
            title={subscription?.push_date ? t('update') : t('set')}
            isLoading={isLoading}
            onClick={() => onSetReminder(time)}
          />
        </FooterWrapper>
      </Container>
    </ModalWrapper>
  )
}

// MARK: - Styles

const Container = styled(Col)<{ pickerOpen: boolean }>`
  flex: 1;
  max-width: 100%;
  min-height: ${({ pickerOpen }) => (pickerOpen ? '690px' : '320px')};
  padding: 16px;
`

const SubTitle = styled(LabelMedium)`
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin: 16px 0px 8px 0px;
`

const OptionContainer = styled(Row)`
  max-width: 100%;
  overflow: scroll;
`

const Option = styled(LabelMedium)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : theme.palette.background.primary};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.primary : theme.palette.background.separator};
  border-radius: 8px;
  color: ${({ theme, selected }) => (selected ? theme.palette.white : theme.palette.text.primary)};
  cursor: pointer;
  margin-right: 8px;
  padding: 6px 18px;
  white-space: nowrap;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => theme.palette.primary};
      }
    `}
`
