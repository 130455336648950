import React from 'react'
import { Row } from 'src/features/common/CommonStyles'
import { getEntity } from 'src/redux/reducers/entity'
import { getMeId } from 'src/redux/reducers/me'
import { ChatChannelMessageGroupType, ChatMessage, User } from 'src/repository/types'
import { hourString } from 'src/utils/helpers/dateHelper'
import { containsOnlyEmojis } from 'src/utils/helpers/stringHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css } from 'styled-components'

export const ChatMessageListItem: React.FC<{
  message: ChatMessage
  onReactionToggle?: (reaction: string) => void
}> = ({ message, onReactionToggle }) => {
  // MARK: - Hooks

  const meUserId = useSelect(state => getMeId(state.me))
  const user = useSelect(state => getEntity<User>(state.entity, 'user', message.user._id))

  const onlyEmojis = containsOnlyEmojis(message.text) && message.text.length < 10
  const isOwned = !!meUserId && meUserId === user?.id
  const group = message.group_type

  // MARK: - Render

  return (
    <Container
      style={{ justifyContent: isOwned ? 'flex-end' : 'flex-start' }}
      groupType={message.group_type}>
      <MessageContainer
        isOwned={isOwned}
        style={{
          marginTop: group === 'top' || group === 'single' ? 6 : 2,
          marginBottom: group === 'bottom' || group === 'single' ? 6 : 2,
          borderTopRightRadius: isOwned && (group === 'bottom' || group === 'middle') ? 2 : 8,
          borderBottomRightRadius: isOwned && (group === 'top' || group === 'middle') ? 2 : 8,
          borderTopLeftRadius: !isOwned && (group === 'bottom' || group === 'middle') ? 2 : 8,
          borderBottomLeftRadius: !isOwned && (group === 'top' || group === 'middle') ? 2 : 8,
        }}>
        <Row style={{ alignItems: 'flex-end' }}>
          <MessageText isOwned={isOwned} onlyEmojis={onlyEmojis}>
            {message.text}
          </MessageText>
          <TimeContainer isOwned={isOwned}>{hourString(message.created_at)}</TimeContainer>
        </Row>

        {/* <ChatChannelMessageListItemFooter
          message={message}
          hideThreadFooter={hideThreadFooter}
          onThreadClick={() => onThreadOpen?.(true)}
          onReactionClick={reaction => onReactionToggle?.(reaction)}
        /> */}
      </MessageContainer>

      {/* {focused && (
        <ChatChannelMessageListItemActions
          message={message}
          isThread={isThread}
          onReactionToggle={reaction => onReactionToggle?.(reaction)}
          onThreadReply={() => onThreadOpen?.(true)}
          onReply={() => {}}
          onEdit={() => {}}
          onDelete={() => {}}
        />
      )} */}
    </Container>
  )
}

// MARK: - Style

const Container = styled(Row)<{ groupType: ChatChannelMessageGroupType }>`
  align-items: flex-start;
  margin-bottom: ${({ groupType }) => (groupType === 'bottom' || groupType === 'single') && 0}px;
  margin-top: ${({ groupType }) => (groupType === 'top' || groupType === 'single') && 0}px;
  padding: 0px 16px;
  position: relative;
  width: 100%;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `};
`

const MessageContainer = styled.div<{ isOwned: boolean }>`
  background-color: ${({ theme, isOwned }) =>
    isOwned ? theme.palette.orange : theme.palette.background.primary};
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  max-width: 85%;
  min-width: 40px;
  padding: 7.5px 12px;
  position: relative;
`

const MessageText = styled.div<{ onlyEmojis: boolean; isOwned: boolean }>`
  color: ${({ theme, isOwned }) => (isOwned ? theme.palette.white : theme.palette.text.primary)};
  font-size: ${({ onlyEmojis }) => (onlyEmojis ? 36 : 14.2)}px;
  font-weight: 400;
  line-height: ${({ onlyEmojis }) => (onlyEmojis ? 36 : 19)}px;
  word-break: break-word;
`

const TimeContainer = styled.div<{ isOwned: boolean }>`
  color: ${({ theme, isOwned }) => (isOwned ? theme.palette.white : theme.palette.text.secondary)};
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: -4px;
  margin-left: 8px;
  margin-right: -2px;
  opacity: 0.5;
  text-align: right;
`
