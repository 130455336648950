import React, { useLayoutEffect, useRef, useState } from 'react'
import { LabelMedium, LabelNormal, Row } from 'src/features/common/CommonStyles'
import { QAQuestion } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled, { useTheme } from 'styled-components'

export const QAListItemAnswer: React.FC<{
  qaQuestion: QAQuestion
}> = ({ qaQuestion }) => {
  // MARK: - Hooks

  const answerRef = useRef<HTMLDivElement | null>(null)
  const { isDark } = useTheme()
  const [collapsed, setCollapsed] = useState(false)
  const [focused, setFocused] = useState(false)

  // MARK: - Effects

  useLayoutEffect(() => {
    const totalHeight = answerRef.current?.offsetHeight ?? 60
    const lineHeight = 21
    const lineCount = totalHeight / lineHeight
    setCollapsed(lineCount > 3)
  }, [])

  // MARK: - Render

  return (
    <Container>
      <AnswerIndicator />
      <Answer
        ref={answerRef}
        onPointerEnter={() => collapsed && setFocused(true)}
        onPointerLeave={() => collapsed && setFocused(false)}
        onClick={() => setCollapsed(false)}
        collapsed={collapsed}
        style={numberOfLines(collapsed ? 3 : Number.MAX_SAFE_INTEGER)}>
        {qaQuestion.answer}
      </Answer>

      {collapsed && (
        <MoreButton
          onPointerEnter={() => collapsed && setFocused(true)}
          onPointerLeave={() => collapsed && setFocused(false)}
          onClick={() => setCollapsed(false)}>
          <MoreGradient className={isDark ? 'horizontal_grad_dark' : 'horizontal_grad'} />
          <MoreButtonText focused={focused}>{'more'}</MoreButtonText>
        </MoreButton>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  margin-top: 8px;
  position: relative;
`

const AnswerIndicator = styled.div`
  background-color: ${({ theme }) => theme.palette.green};
  border-radius: 2px;
  margin-right: 12px;
  min-height: 100%;
  min-width: 4px;
  width: 4px;
`

const Answer = styled(LabelNormal)<{ collapsed: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: ${({ collapsed }) => (collapsed ? 'pointer' : 'default')};
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  transition: height 0.25s ease-out;
`

const MoreButton = styled(Row)`
  bottom: 0px;
  cursor: pointer;
  position: absolute;
  right: 0px;
`

const MoreButtonText = styled(LabelMedium)<{ focused: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  color: ${({ theme }) => theme.palette.blue};
  font-size: 15px;
  font-weight: 400;
  text-decoration-line: ${({ focused }) => (focused ? 'underline' : 'none')};
`

const MoreGradient = styled.div`
  height: 22px;
  width: 50px;
`
