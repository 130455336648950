import { uniqBy } from 'lodash'
import { AgendaType } from 'src/features/profile/types'
import { deleteEntities, mergeEntities } from 'src/redux/reducers/entity'
import { setMe } from 'src/redux/reducers/me'
import { AsyncThunk } from 'src/redux/store'
import { IError } from 'src/repository/Error'
import { friendshipService } from 'src/repository/services/friendshipService'
import { imageService } from 'src/repository/services/imageService'
import { subscriptionService } from 'src/repository/services/subscriptionService'
import { userService } from 'src/repository/services/userService'
import { CoverImage, Friendship, ImageOrientation, Room, User } from 'src/repository/types'
import { v4 } from 'uuid'

const randomQuery = v4()

type State = {
  isLoading: {
    user: boolean
    upcoming: boolean
    past: boolean
    moderated: boolean
    coverImage: boolean
    friendship: boolean
    friends: boolean
  }
  error: IError | null
  coverImages: Record<string, CoverImage[]>
  rooms: Record<AgendaType, Record<string, Room[]>>
  friends: Record<string, Friendship[]>
}

// MARK: - State

export const initialState: State = {
  isLoading: {
    user: false,
    upcoming: false,
    past: false,
    moderated: false,
    coverImage: false,
    friendship: false,
    friends: false,
  },
  error: null,
  coverImages: {},
  friends: {},
  rooms: {
    upcoming: {},
    past: {},
    moderated: {},
  },
}

// MARK: - Reducer

export const profileReducer = (
  state = initialState,
  action:
    | SetLoadingAction
    | SetErrorAction
    | SetCoverImagesAction
    | SetRoomsAction
    | SetFriendsAction
    | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'profile/setLoading':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.key]: action.isLoading,
        },
      }

    case 'profile/setError':
      return { ...state, error: action.error }

    case 'profile/setCoverImages':
      return {
        ...state,
        coverImages: {
          ...state.coverImages,
          [action.query]: action.coverImages,
        },
      }

    case 'profile/setRooms':
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [action.tab]: {
            ...state.rooms[action.tab],
            [action.userId]: action.rooms,
          },
        },
      }

    case 'profile/setFriends':
      return {
        ...state,
        friends: {
          ...state.friends,
          [action.userId]: action.friends,
        },
      }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchUser =
  (userId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('user', true))
    dispatch(setError(null))

    const response = await userService.fetchUserById(userId)

    if (response.success) {
      const { user, friendship } = response.value
      dispatch(mergeEntities({ user: [user], friendship: friendship ? [friendship] : [] }))
    } else {
      dispatch(setError(response.error))
    }

    dispatch(setLoading('user', false))
  }

export const updateMeUser =
  (user: Partial<User>): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('user', true))
    dispatch(setError(null))

    const response = await userService.updateUser(user)

    if (response.success) {
      const { user: responseUser, settings } = response.value
      dispatch(mergeEntities({ user: [responseUser], user_settings: [settings] }))
      dispatch(setMe(responseUser))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('user', false))
  }

export const trackCoverImageDownload =
  (image: CoverImage): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('coverImage', true))
    dispatch(setError(null))

    const response = await imageService.trackCoverImageDownload(image)

    if (response.success) {
      // Do nothing
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('coverImage', false))
  }

export const fetchRandomCoverImages =
  (count: number, orientation: ImageOrientation): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('coverImage', true))
    dispatch(setError(null))

    const response = await imageService.fetchRandomCoverImages(count, orientation)

    if (response.success) dispatch(setCoverImages(randomQuery, response.value))
    else dispatch(setError(response.error))

    dispatch(setLoading('coverImage', false))
  }

export const queryCoverImages =
  (query: string, count: number, orientation: ImageOrientation): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('coverImage', true))
    dispatch(setError(null))

    const response = await imageService.queryCoverImages(query, 0, count, orientation)

    if (response.success) dispatch(setCoverImages(query, response.value))
    else dispatch(setError(response.error))

    dispatch(setLoading('coverImage', false))
  }

export const fetchProfileRooms =
  (userId: string, count: number, tab: AgendaType): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(tab, true))
    dispatch(setError(null))

    const stored = getAgendaTypeRooms(getState().profile, userId, tab)
    const response = await subscriptionService.fetchProfileRooms(userId, stored.length, count, tab)

    if (response.success) {
      const { rooms, publishers } = response.value
      dispatch(setRooms(userId, tab, uniqBy([...stored, ...rooms], 'id')))
      dispatch(mergeEntities({ user: publishers }))
    } else {
      dispatch(setError(response.error))
    }

    dispatch(setLoading(tab, false))
  }

export const sendFriendRequest =
  (userId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('friendship', true))
    dispatch(setError(null))

    const response = await friendshipService.sendFriendRequest(userId)

    if (response.success) dispatch(mergeEntities({ friendship: [response.value] }))
    else dispatch(setError(response.error))

    dispatch(setLoading('friendship', false))
  }

export const respondFriendRequest =
  (friendship: Friendship, accepted: boolean): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('friendship', true))
    dispatch(setError(null))

    const response = await friendshipService.respondFriendRequest(friendship.id, accepted)

    if (response.success) dispatch(mergeEntities({ friendship: [response.value] }))
    else dispatch(setError(response.error))

    dispatch(setLoading('friendship', false))
  }

export const removeFriend =
  (friendship: Friendship): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('friendship', true))
    dispatch(setError(null))

    dispatch(deleteEntities({ friendship: [friendship.id] }))
    const response = await friendshipService.removeFriend(friendship.id)

    if (response.success) {
      // Do nothing
    } else {
      dispatch(mergeEntities({ friendship: [friendship] }))
      dispatch(setError(response.error))
    }

    dispatch(setLoading('friendship', false))
  }

export const fetchUserFriends =
  (userId: string, count: number, offset?: number): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading('friends', true))
    dispatch(setError(null))

    const currentFriends = getState().profile.friends[userId] ?? []
    const response = await friendshipService.fetchUserFriends(
      userId,
      offset ?? currentFriends.length,
      count,
    )

    if (response.success) {
      const { friends, users } = response.value
      dispatch(mergeEntities({ user: users }))
      dispatch(setFriends(userId, uniqBy([...currentFriends, ...friends], 'id')))
    } else {
      dispatch(setError(response.error))
    }

    dispatch(setLoading('friends', false))
  }

export const setError = (error: IError | null): SetErrorAction => ({
  type: 'profile/setError',
  error: error,
})

// MARK: - Selectors

export const getIsLoading = (state: State, key: keyof State['isLoading']): boolean => {
  return state.isLoading[key]
}

export const getError = (state: State): IError | null => {
  return state.error
}

export const getCoverImages = (state: State, query: string): CoverImage[] => {
  return state.coverImages[query || randomQuery] ?? []
}

export const getAgendaTypeRooms = (state: State, userId: string, tab: AgendaType): Room[] => {
  const rooms = state.rooms[tab]?.[userId] ?? []
  return tab === 'upcoming'
    ? rooms.sort((a, b) => a.start_date - b.start_date)
    : rooms.sort((a, b) => b.start_date - a.start_date)
}

export const getUserFriends = (state: State, userId: string): Friendship[] => {
  return state.friends[userId] ?? []
}

// MARK: - Action Types

type SetLoadingAction = {
  type: 'profile/setLoading'
  key: keyof State['isLoading']
  isLoading: boolean
}

type SetErrorAction = {
  type: 'profile/setError'
  error: IError | null
}

type SetRoomsAction = {
  type: 'profile/setRooms'
  tab: AgendaType
  rooms: Room[]
  userId: string
}

type SetFriendsAction = {
  type: 'profile/setFriends'
  friends: Friendship[]
  userId: string
}

type SetCoverImagesAction = {
  type: 'profile/setCoverImages'
  query: string
  coverImages: CoverImage[]
}

// MARK: - Internal Actions

const setLoading = (key: keyof State['isLoading'], isLoading: boolean): SetLoadingAction => ({
  type: 'profile/setLoading',
  key: key,
  isLoading: isLoading,
})

const setCoverImages = (query: string, coverImages: CoverImage[]): SetCoverImagesAction => ({
  type: 'profile/setCoverImages',
  query: query,
  coverImages: coverImages,
})

const setRooms = (userId: string, tab: AgendaType, rooms: Room[]): SetRoomsAction => ({
  type: 'profile/setRooms',
  tab: tab,
  rooms: rooms,
  userId: userId,
})

const setFriends = (userId: string, friends: Friendship[]): SetFriendsAction => ({
  type: 'profile/setFriends',
  friends: friends,
  userId: userId,
})
