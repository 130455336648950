import React from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { Col } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { getReferralLink } from 'src/redux/reducers/room'
import { Room } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const ShareQRCode: React.FC<{
  room: Room
  onClose: () => void
}> = ({ room, onClose }) => {
  // MARK: - Hooks

  const { width, isMobile } = useWindowDimensions()
  const { palette } = useTheme()
  const { t } = useTranslation()
  const referralLink = useSelect(state => getReferralLink(state.room) ?? room.short_link)
  const Code = QRCode as any

  // MARK: - Actions

  const handleImageDownload = () => {
    const svg = document.getElementById('QRCode')
    if (!svg) return
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx?.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = room.title + '_qr_code'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  // MARK: - Render

  return (
    <Container>
      <Code
        id="QRCode"
        size={Math.min(width, 320)}
        value={referralLink}
        fgColor={palette.text.primary}
        bgColor={palette.background.primary}
      />

      <FooterWrapper>
        <SecondaryButton
          style={{
            marginRight: isMobile ? 8 : 12,
            flex: 1,
            backgroundColor: palette.background.primary,
            maxWidth: isMobile ? 240 : 180,
          }}
          title={t('back')}
          onClick={onClose}
        />

        <PrimaryButton
          style={{ flex: 1, maxWidth: isMobile ? 240 : 180 }}
          title={t('download')}
          onClick={handleImageDownload}
        />
      </FooterWrapper>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  align-items: center;
  padding-bottom: 100px;
  padding-top: 20px;
`
