import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelect } from 'src/utils/hooks/useSelect'
import { ProfileIcon } from 'src/assets/images/svg/ProfileIcon'
import { LabelSmall, Row } from 'src/features/common/CommonStyles'
import { getLocalization } from 'src/redux/reducers/app'
import { getEntity } from 'src/redux/reducers/entity'
import { ChatChannelMessage, ChatChannelMessageGroupType, User } from 'src/repository/types'
import { hourString } from 'src/utils/helpers/dateHelper'
import { containsOnlyEmojis } from 'src/utils/helpers/stringHelper'
import styled, { css } from 'styled-components'
import { ChatChannelMessageListItemActions } from './ChatChannelMessageListItemActions'
import { ChatChannelMessageListItemFooter } from './ChatChannelMessageListItemFooter'
import { device } from 'src/utils/hooks/useWindowDimensions'

export const ChatChannelMessageListItem: React.FC<{
  message: ChatChannelMessage
  isThread?: boolean
  hideThreadFooter?: boolean
  onThreadOpen?: (open: boolean) => void
  onReactionToggle?: (reaction: string) => void
}> = ({ message, isThread = false, hideThreadFooter = false, onThreadOpen, onReactionToggle }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const localization = useSelect(state => getLocalization(state.app))
  const user = useSelect(state => getEntity<User>(state.entity, 'user', message.user_id))
  const [focused, setFocused] = useState(false)
  const onlyEmojis = containsOnlyEmojis(message.text) && message.text.length < 10
  const showHeader = message.group_type === 'top' || message.group_type === 'single'
  const durationString = moment
    .duration(new Date().getTime() - message.created_at * 1000)
    .locale(localization)
    .humanize()

  // MARK: - Render

  return (
    <Container
      onPointerEnter={() => setFocused(true)}
      onPointerLeave={() => setFocused(false)}
      groupType={message.group_type}
      isThread={isThread}>
      <AvatarContainer>
        {showHeader ? (
          user?.image_url ? (
            <Avatar src={user?.image_url} />
          ) : (
            <ProfileIcon size={36} />
          )
        ) : (
          focused && (
            <TimeContainer style={{ position: 'absolute', right: 2, top: -3 }}>
              {hourString(message.created_at)}
            </TimeContainer>
          )
        )}
      </AvatarContainer>

      <MessageContainer>
        {showHeader ? (
          <Row style={{ alignItems: 'center', height: 20 }}>
            <MessageHeader>
              {user?.first_name ? `${user?.first_name} ${user?.last_name}` : t('unknownUser')}
            </MessageHeader>

            <TimeContainer>
              {isThread ? t('timeAgo', { time: durationString }) : hourString(message.created_at)}
            </TimeContainer>
          </Row>
        ) : null}

        <MessageText onlyEmojis={onlyEmojis}>{message.text}</MessageText>

        <ChatChannelMessageListItemFooter
          message={message}
          hideThreadFooter={hideThreadFooter}
          onThreadClick={() => onThreadOpen?.(true)}
          onReactionClick={reaction => onReactionToggle?.(reaction)}
        />
      </MessageContainer>

      {focused && (
        <ChatChannelMessageListItemActions
          message={message}
          isThread={isThread}
          onReactionToggle={reaction => onReactionToggle?.(reaction)}
          onThreadReply={() => onThreadOpen?.(true)}
          onReply={() => {}}
          onEdit={() => {}}
          onDelete={() => {}}
        />
      )}
    </Container>
  )
}

// MARK: - Style

const Container = styled(Row)<{ groupType: ChatChannelMessageGroupType; isThread: boolean }>`
  margin-bottom: ${({ groupType, isThread }) =>
    (groupType === 'bottom' || groupType === 'single') && !isThread ? 4 : 0}px;
  margin-top: ${({ groupType, isThread }) =>
    (groupType === 'top' || groupType === 'single') && !isThread ? 4 : 0}px;
  padding: 2px 16px;
  position: relative;
  width: 100%;

  @media ${device.smallerThan.laptop} {
    padding: 2px 0px;
  }

  ${() =>
    css`
      &:hover {
        box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1);
      }
    `};
`

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 6px;
  max-height: 36px;
  min-width: 36px;
  position: relative;
  width: 36px;
`

const Avatar = styled.img`
  border-radius: 4px;
  height: 34px;
  width: 34px;
`

const MessageContainer = styled.div`
  margin: 0px 4px;
  min-width: 80px;
  padding: 4px 4px;
  width: 100%;
`

const MessageHeader = styled(LabelSmall)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 13.4px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 1px;
  margin-top: -2px;
`

const MessageText = styled.div<{ onlyEmojis: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ onlyEmojis }) => (onlyEmojis ? 36 : 14.2)}px;
  font-size: ${({ onlyEmojis }) => (onlyEmojis ? 36 : 14.2)}px;
  font-weight: 400;
  line-height: ${({ onlyEmojis }) => (onlyEmojis ? 36 : 19)}px;
  word-break: break-word;
`

const TimeContainer = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2px;
  margin-left: 8px;
  opacity: 0.5;
  text-align: right;
`
