import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

export const ThirdPartyButton: React.FC<{
  type: 'apple' | 'facebook' | 'google'
  onClick: () => void
}> = ({ type, onClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Properties

  let title: string = ''
  let imageSrc: NodeRequire

  switch (type) {
    case 'apple':
      title = t('continueWith', { provider: 'Apple' })
      imageSrc = require('src/assets/images/apple.png')
      break

    case 'facebook':
      title = t('continueWith', { provider: 'Facebook' })
      imageSrc = require('src/assets/images/facebook.png')
      break

    case 'google':
      title = t('continueWith', { provider: 'Google' })
      imageSrc = require('src/assets/images/google.png')
      break
  }

  // MARK: - Render

  return (
    <ThirdPartyContinueButton onClick={onClick} className="button button-dark br-8">
      <ThirdPartyContinueButtonImage src={imageSrc as any} />
      {title}
    </ThirdPartyContinueButton>
  )
}

const ThirdPartyContinueButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-color: ${({ theme }) => theme.palette.background.separator};
  border-width: 1px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  margin-top: 16px;
  min-width: 100%;
  position: relative;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
        border-color: ${({ theme }) => theme.palette.orange};
      }
    `}
`

const ThirdPartyContinueButtonImage = styled.img`
  height: 24px;
  left: 16px;
  position: absolute;
  top: 10px;
  width: 24px;
`
