import { pushError } from './app'
import produce from 'immer'
import { orderBy, sortBy, uniqBy } from 'lodash'
import { Action } from 'redux'
import { MergeAction, mergeEntities } from 'src/redux/reducers/entity'
import { Thunk } from 'src/redux/store'
import { chatChannelService } from 'src/repository/services/chatChannelService'
import { searchService } from 'src/repository/services/searchService'
import { userService } from 'src/repository/services/userService'
import { ChatChannel } from 'src/repository/types'

type State = {
  channels: ChatChannel[]
  searchResults: Record<string, ChatChannel[]>
}

type ActionType =
  | { type: 'roomChat/setChannels'; channels: ChatChannel[] }
  | { type: 'roomChat/setSearchResult'; query: string; channels: ChatChannel[] }
  | MergeAction
  | Action<'roomChat/flush'>
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  channels: [],
  searchResults: {},
}

// MARK: - Reducer

export const roomChatReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'roomChat/setChannels':
      return produce(state, draft => {
        draft.channels = uniqBy([...state.channels, ...action.channels], 'id')
        return draft
      })

    case 'roomChat/setSearchResult':
      return produce(state, draft => {
        draft.searchResults[action.query] = uniqBy(
          [...(state.searchResults[action.query] ?? []), ...action.channels],
          'id',
        )
        return draft
      })

    case 'roomChat/flush':
    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchChatChannels =
  (roomId: string, limit: number): Thunk<ActionType> =>
  dispatch =>
    chatChannelService
      .fetchChannels(roomId, limit)
      .loading('channels')
      .done(value => {
        const channels = [...(value.chat_channels ?? []), ...(value.direct_message_channels ?? [])]
        dispatch(mergeEntities({ user: value.users ?? [], chat_channel: channels }))
        dispatch({ type: 'roomChat/setChannels', channels })
      })

export const fetchChatUser =
  (userId: string): Thunk<ActionType> =>
  dispatch =>
    userService
      .fetchUserById(userId)
      .loading('chat_user_loading_' + userId)
      .done(({ user, friendship }) =>
        dispatch(
          mergeEntities({ user: [user], friendship: friendship ? [friendship] : undefined }),
        ),
      )

export const searchMember =
  (roomId: string, query: string, offset: number, limit: number): Thunk<ActionType> =>
  dispatch =>
    searchService
      .searchMember(roomId, query, offset, limit)
      .loading('room_chat_search')
      .done(
        ({ users, channels }) => {
          dispatch(mergeEntities({ user: users, chat_channel: channels }))
          dispatch({ type: 'roomChat/setSearchResult', query, channels })
        },
        error => dispatch(pushError(error)),
      )

export const cleanRoomChat = (): ActionType => ({
  type: 'roomChat/flush',
})

// MARK: - Selectors

export const getTopicChannels = (state: State) => {
  const channels = state.channels.filter(({ type }) => type === 'topic' || type === 'general')
  return sortBy(channels, ({ type }) => type !== 'general')
}

export const getMemberChannels = (state: State) => {
  const channels = state.channels.filter(({ type, id }) => type === 'oneToOne')
  return orderBy(channels, 'name')
}

export const getMemberSearchResults = (state: State, query: string) => {
  const channels = (state.searchResults[query] ?? []).filter(({ type, id }) => type === 'oneToOne')
  return orderBy(channels, 'name')
}
