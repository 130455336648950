import { Redirect, Route, Switch } from 'react-router-dom'
import { Connections } from 'src/features/connections'
import { Home } from 'src/features/home'
import { NotFound } from 'src/features/notFound'
import { Profile } from 'src/features/profile'
import { InvitationRedirect } from 'src/features/invitationRedirect/InvitationRedirect'
import { RoomMain } from 'src/features/room'
import { RoomAccess } from 'src/features/roomAccess'
import { RoomCover } from 'src/features/roomCover'
import { Search } from 'src/features/search'
import { RoomPrivacyType } from 'src/repository/types'

export const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to={'/home'} />} />

    <Route
      exact
      path="/room"
      render={({ location }) => {
        const params = new URLSearchParams(location.search)
        const url = `/room/${params.get('room_id')}/cover`
        return <Redirect to={url} />
      }}
    />

    <Route
      path="/access/:roomId/:privacyType"
      render={({ match }) => (
        <RoomAccess
          roomId={match.params.roomId}
          privacyType={match.params.privacyType as RoomPrivacyType}
        />
      )}
    />

    <Route
      path="/invitation/:invitationId"
      render={({ match }) => <InvitationRedirect invitationId={match.params.invitationId} />}
    />

    <Route
      path="/search"
      render={({ location }) => {
        const params = new URLSearchParams(location.search)
        const query = params.get('query')
        return <Search query={query} />
      }}
    />

    <Route
      path="/profile/:userId"
      render={({ match }) => <Profile userId={match.params.userId} />}
    />

    <Route path="/home" render={() => <Home />} />

    <Route path="/chat" render={() => <Connections />} />

    <Route
      path="/room/:roomId/chat/:channelId"
      render={({ match }) => (
        <RoomMain roomId={match.params.roomId} channelId={match.params.channelId} />
      )}
    />

    <Route
      path="/room/:roomId/cover"
      exact
      render={({ match }) => <RoomCover roomId={match.params.roomId} />}
    />

    <Route
      path="/room/:roomId"
      render={({ match }) => <RoomMain roomId={match.params.roomId} channelId={null} />}
    />

    <Route component={NotFound} />
  </Switch>
)
