import produce from 'immer'
import { compact, head } from 'lodash'
import {
  Announcement,
  AnnouncementList,
  Broadcasting,
  Chat,
  ChatChannel,
  ChatMessage,
  Component,
  FAQ,
  FAQList,
  Friendship,
  Invitation,
  Link,
  Me,
  Notification,
  Poll,
  Polling,
  PollVote,
  QA,
  QAQuestion,
  Room,
  SpreadGame,
  SpreadGameScore,
  Subscription,
  TrackedUrl,
  User,
  UserSettings,
  WebList,
  WebListItem,
  WebPage,
} from 'src/repository/types'
import { ID, Identifiable } from 'src/utils/helpers/mapId'

type Entity = Identifiable &
  (
    | Announcement
    | AnnouncementList
    | Broadcasting
    | Component
    | Invitation
    | Room
    | Link
    | Me
    | Notification
    | SpreadGame
    | SpreadGameScore
    | TrackedUrl
    | User
    | UserSettings
    | WebPage
    | FAQ
    | FAQList
    | WebList
    | WebListItem
    | QA
    | QAQuestion
    | Subscription
    | ChatChannel
    | Polling
    | Poll
    | PollVote
    | Friendship
    | ChatMessage
    | Chat
  )

type State = {
  announcement: Record<ID, Announcement>
  announcement_list: Record<ID, AnnouncementList>
  broadcasting: Record<ID, Broadcasting>
  component: Record<ID, Component>
  invitation: Record<ID, Invitation>
  room: Record<ID, Room>
  link: Record<ID, Link>
  me: Record<ID, Me>
  notification: Record<ID, Notification>
  spread_game: Record<ID, SpreadGame>
  spread_game_score: Record<ID, SpreadGameScore>
  tracked_url: Record<ID, TrackedUrl>
  user: Record<ID, User>
  user_settings: Record<ID, UserSettings>
  web_page: Record<ID, WebPage>
  web_list: Record<ID, WebList>
  web_list_item: Record<ID, WebListItem>
  faq_list: Record<ID, FAQList>
  faq: Record<ID, FAQ>
  qa: Record<ID, QA>
  qa_question: Record<ID, QAQuestion>
  subscription: Record<ID, Subscription>
  chat_channel: Record<ID, ChatChannel>
  polling: Record<ID, Polling>
  poll: Record<ID, Poll>
  poll_vote: Record<ID, PollVote>
  friendship: Record<ID, Friendship>
  message: Record<ID, ChatMessage>
  chat: Record<ID, Chat>
}

type Entities = Partial<{
  announcement: Announcement[]
  announcement_list: AnnouncementList[]
  broadcasting: Broadcasting[]
  component: Component[]
  invitation: Invitation[]
  room: Room[]
  link: Link[]
  me: Me[]
  notification: Notification[]
  spread_game: SpreadGame[]
  spread_game_score: SpreadGameScore[]
  tracked_url: TrackedUrl[]
  user: User[]
  user_settings: UserSettings[]
  web_page: WebPage[]
  web_list: WebList[]
  web_list_item: WebListItem[]
  faq_list: FAQList[]
  faq: FAQ[]
  qa: QA[]
  qa_question: QAQuestion[]
  subscription: Subscription[]
  chat_channel: ChatChannel[]
  polling: Polling[]
  poll: Poll[]
  poll_vote: PollVote[]
  friendship: Friendship[]
  message: ChatMessage[]
  chat: Chat[]
}>

const initialState: State = {
  announcement: {},
  announcement_list: {},
  broadcasting: {},
  component: {},
  invitation: {},
  room: {},
  link: {},
  me: {},
  notification: {},
  spread_game: {},
  spread_game_score: {},
  tracked_url: {},
  user: {},
  user_settings: {},
  web_page: {},
  web_list: {},
  web_list_item: {},
  faq_list: {},
  faq: {},
  qa: {},
  qa_question: {},
  subscription: {},
  chat_channel: {},
  polling: {},
  poll: {},
  poll_vote: {},
  friendship: {},
  message: {},
  chat: {},
}

// MARK: - Reducer

export const entityReducer = (
  state = initialState,
  action: MergeAction | DeleteAction | ReplaceAction | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'entity/merge':
      return produce(state, draft => {
        for (const entry of Object.entries(action.entities)) {
          if (!entry[0] || !entry[1]?.length) continue

          const entityType = entry[0] as keyof State
          const entities = entry[1] as Identifiable[]
          const entityRecord = compact(entities).reduce(
            (acc, element) => ({ ...acc, [element.id]: element }),
            {},
          )
          draft[entityType] = { ...draft[entityType], ...entityRecord }
        }
        return draft
      })

    case 'entity/delete':
      return produce(state, draft => {
        for (const [key, entityIds] of Object.entries(action.entities)) {
          for (const entityId of entityIds) delete draft[key as keyof State][entityId]
        }
        return draft
      })

    case 'entity/replace':
      return produce(state, draft => {
        for (const entry of Object.entries(action.entities)) {
          const entityType = entry[0] as keyof State
          const entities = entry[1] as Identifiable[]

          if (action.replaceIf) {
            for (const existingEntry of Object.entries(draft[entityType] ?? {})) {
              const [key, item] = existingEntry
              if (action.replaceIf(item)) delete draft[entityType][key]
            }
            const entityRecord = entities.reduce(
              (acc, element) => ({ ...acc, [element.id]: element }),
              {},
            )
            draft[entityType] = { ...draft[entityType], ...entityRecord }
          } else {
            const entityRecord = entities.reduce(
              (acc, element) => ({ ...acc, [element.id]: element }),
              {},
            )
            draft[entityType] = entityRecord
          }
        }
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const mergeEntities = (entities: Entities): MergeAction => ({
  type: 'entity/merge',
  entities: entities,
})

export const deleteEntities = (entities: Partial<Record<keyof State, ID[]>>): DeleteAction => ({
  type: 'entity/delete',
  entities: entities,
})

export const replaceEntities = (
  entities: Entities,
  replaceIf?: (entity: any) => boolean,
): ReplaceAction => ({
  type: 'entity/replace',
  entities,
  replaceIf,
})

// MARK: - Selectors

export const getEntity = <T extends Entity>(
  state: State,
  entityType: keyof State,
  primaryId?: ID | null,
): T | null => {
  const entities = state[entityType] as Record<ID, T>
  return primaryId ? entities[primaryId] ?? null : null
}

export const getEntities = <T extends Entity>(
  state: State,
  entityType: keyof State,
  filter?: (entity: T) => boolean,
): T[] => {
  const entities = Object.values(state[entityType] ?? {}) as T[]
  return filter ? entities.filter(filter) : entities
}

export const getFirstEntity = <T extends Entity>(
  state: State,
  entityType: keyof State,
  filter: (entity: T) => boolean,
): T | null => {
  return head(getEntities(state, entityType, filter)) ?? null
}

// MARK: - Action Types

export type MergeAction = {
  type: 'entity/merge'
  entities: Entities
}

export type DeleteAction = {
  type: 'entity/delete'
  entities: Partial<Record<keyof State, ID[]>>
}

export type ReplaceAction = {
  type: 'entity/replace'
  entities: Entities
  replaceIf?: (entity: any) => boolean
}
