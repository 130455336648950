import { uniqBy } from 'lodash'
import { mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk } from 'src/redux/store'
import { IError } from 'src/repository/Error'
import { searchService } from 'src/repository/services/searchService'
import { Room, User } from 'src/repository/types'
import { localTime } from 'src/utils/helpers/dateHelper'

const roomPageSize = 9
const userPageSize = 6

type State = {
  isLoading: { room: boolean; user: boolean }
  error: IError | null
  results: Record<string, { room: Room[]; user: User[] }>
  lastQueryTime: Record<string, number>
  canPaginate: Record<string, { room: boolean; user: boolean }>
}

// MARK: - State

export const initialState: State = {
  isLoading: { room: false, user: false },
  error: null,
  results: {},
  canPaginate: {},
  lastQueryTime: {},
}

// MARK: - Reducer

export const searchReducer = (
  state = initialState,
  action: SetLoadingAction | SetCanPaginateAction | SetErrorAction | SetResultsAction,
): State => {
  switch (action.type) {
    case 'search/setLoading':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.key]: action.isLoading,
        },
      }

    case 'search/setCanPaginate':
      return {
        ...state,
        canPaginate: {
          ...state.canPaginate,
          [action.query]: {
            ...state.canPaginate[action.query],
            [action.key]: action.canPaginate,
          },
        },
      }

    case 'search/setError':
      return { ...state, error: action.error }

    case 'search/setResults':
      return {
        ...state,
        lastQueryTime: {
          ...state.lastQueryTime,
          [action.query]: localTime(),
        },
        results: {
          ...state.results,
          [action.query]: { room: action.rooms, user: action.users },
        },
      }

    default:
      return state
  }
}

// MARK: - Actions

export const search =
  (query: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('room', true))
    dispatch(setLoading('user', true))
    dispatch(setError(null))

    dispatch(setResults(query, [], []))

    const response = await searchService.search(query, roomPageSize, userPageSize)

    if (response.success) {
      const { rooms, publishers, users } = response.value
      dispatch(
        mergeEntities({ room: rooms, user: uniqBy([...users, ...publishers], ({ id }) => id) }),
      )
      dispatch(setCanPaginate(query, 'room', rooms.length >= roomPageSize))
      dispatch(setCanPaginate(query, 'user', users.length >= userPageSize))
      dispatch(setResults(query, rooms, users))
    } else {
      dispatch(setError(response.error))
    }

    dispatch(setLoading('room', false))
    dispatch(setLoading('user', false))
  }

export const searchMoreRooms =
  (query: string): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading('room', true))
    dispatch(setError(null))

    const results = getResults(getState().search, query) ?? { room: [], user: [] }
    const offset = results.room.length

    const response = await searchService.searchRoom(query, offset, roomPageSize)
    if (response.success) {
      const { rooms, publishers } = response.value
      dispatch(mergeEntities({ room: rooms, user: publishers }))
      dispatch(setCanPaginate(query, 'room', rooms.length >= roomPageSize))
      dispatch(setResults(query, [...results.room, ...rooms], results.user))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('room', false))
  }

export const searchMoreUsers =
  (query: string): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading('user', true))
    dispatch(setError(null))

    const results = getResults(getState().search, query) ?? { room: [], user: [] }
    const offset = results.user.length

    const response = await searchService.searchUser(query, offset, userPageSize)
    if (response.success) {
      const users = response.value
      dispatch(mergeEntities({ user: users }))
      dispatch(setCanPaginate(query, 'user', users.length >= userPageSize))
      dispatch(setResults(query, results.room, [...results.user, ...users]))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('user', false))
  }

export const setError = (error: IError | null): SetErrorAction => ({
  type: 'search/setError',
  error: error,
})

// MARK: - Selectors

export const getIsLoading = (state: State, key: keyof State['isLoading']): boolean => {
  return state.isLoading[key]
}

export const getError = (state: State): IError | null => {
  return state.error
}

export const getResults = (state: State, query: string | null): { room: Room[]; user: User[] } => {
  if (!query) return { room: [], user: [] }
  return state.results[query] ?? { room: [], user: [] }
}

export const getCanPaginate = (
  state: State,
  query: string | null,
  key: keyof State['canPaginate']['query'],
): boolean => {
  return !!query && (state.canPaginate[query]?.[key] ?? false)
}

export const getShouldSendQuery = (state: State, query: string | null): boolean => {
  if (!query) return false
  const oneMinuteInSeconds = 60
  const lastQueryTime = state.lastQueryTime[query] ?? 0
  return localTime() - lastQueryTime > oneMinuteInSeconds
}

// MARK: - Action Types

type SetLoadingAction = {
  type: 'search/setLoading'
  key: keyof State['isLoading']
  isLoading: boolean
}

type SetCanPaginateAction = {
  type: 'search/setCanPaginate'
  query: string
  key: keyof State['canPaginate']['query']
  canPaginate: boolean
}

type SetErrorAction = {
  type: 'search/setError'
  error: IError | null
}

type SetResultsAction = {
  type: 'search/setResults'
  query: string
  rooms: Room[]
  users: User[]
}

// MARK: - Internal Actions

const setResults = (query: string, rooms: Room[], users: User[]): SetResultsAction => ({
  type: 'search/setResults',
  query: query,
  rooms: rooms,
  users: users,
})

const setLoading = (key: keyof State['isLoading'], isLoading: boolean): SetLoadingAction => ({
  type: 'search/setLoading',
  key: key,
  isLoading: isLoading,
})

const setCanPaginate = (
  query: string,
  key: keyof State['canPaginate']['query'],
  canPaginate: boolean,
): SetCanPaginateAction => ({
  type: 'search/setCanPaginate',
  query: query,
  key: key,
  canPaginate: canPaginate,
})
