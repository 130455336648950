import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { Loader } from 'src/features/common/Loader'
import { ChatChannelMessage } from 'src/repository/types'
import styled from 'styled-components'
import { toggleReaction } from 'src/redux/reducers/chatChannel'
import { ChatChannelMessageListItem } from './ChatChannelMessageListItem'

export const ChatChannelThreadBody: React.FC<{
  message: ChatChannelMessage | null
}> = ({ message }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const replyCount = Object.keys(message?.replies ?? {}).length
  const replyKey = replyCount > 1 ? 'repliesCount' : 'replyCount'

  // MARK: - Actions

  const handleReactionToggle = useCallback((newMessage: ChatChannelMessage, reaction: string) => {
    dispatch(toggleReaction(newMessage.channel_id, newMessage.id, reaction))
  }, [])

  // MARK: - Render

  return (
    <Container>
      {message ? (
        <ChatChannelMessageListItem
          message={{ ...message, group_type: 'single' }}
          isThread
          hideThreadFooter
          onReactionToggle={reaction => handleReactionToggle(message, reaction)}
        />
      ) : (
        <Loader />
      )}

      {!!replyCount && (
        <Row style={{ width: '100%', alignItems: 'center', marginTop: 6 }}>
          <ReplyCount>{t(replyKey, { count: replyCount })}</ReplyCount>
          <Separator />
        </Row>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  justify-content: flex-end;
  padding: 16px 0px 4px 0px;
`

const ReplyCount = styled(LabelSmall)`
  font-size: 13px;
  font-weight: 500;
  padding: 0px 10px 0px 16px;
`

const Separator = styled.div`
  background-color: ${({ theme }) => theme.palette.text.secondary};
  flex: 1;
  height: 1px;
  margin-right: 16px;
  opacity: 0.5;
`
