import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { CalendarIcon } from 'src/assets/images/svg/CalendarIcon'
import { ChatIcon } from 'src/assets/images/svg/ChatIcon'
import { ShareIcon } from 'src/assets/images/svg/ShareIcon'
import { FlexCol, FlexRow, LabelLarge, LabelMedium } from 'src/features/common/CommonStyles'
import { Room, Subscription } from 'src/repository/types'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import { SVG } from 'src/utils/types/svg'
import styled, { css, useTheme } from 'styled-components'

type HeaderAction = 'addToCalendar' | 'setReminder' | 'share' | 'chat'
export const RoomHomHeader: React.FC<{
  room: Room
  subscription: Subscription | null
  onActionClick: (action: HeaderAction) => void
}> = ({ room, subscription, onActionClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()

  // MARK: - Properties

  let actions: HeaderAction[] = []
  if (room.config.chat_enabled && isMobile) actions.push('chat')
  actions = actions.concat(['addToCalendar', 'setReminder'])
  if (room.config.share_enabled) actions.push('share')

  const icons: Record<HeaderAction, SVG> = {
    addToCalendar: CalendarIcon,
    chat: ChatIcon,
    setReminder: AnnouncementIcon,
    share: ShareIcon,
  }

  const isSelected = (action: string) => {
    if (action === 'setReminder') return !!subscription?.push_date
    if (action === 'addToCalendar') return !!subscription?.event_id
    return false
  }

  const actionTitle = useCallback((action: HeaderAction) => {
    if (action === 'addToCalendar') return t('addToCalendar')
    if (action === 'setReminder') return t('setReminder')
    if (action === 'share') return t('share')
    if (action === 'chat') return t('chats')
  }, [])

  // MARK: - Render

  return (
    <FlexCol style={{ alignItems: 'center' }}>
      <Title>{room?.title.length ? room.title : 'Title'}</Title>

      <ActionWrapper>
        <ActionsContainer>
          {actions.map(action => (
            <RoomHomeActionItem
              key={action}
              action={action}
              title={actionTitle(action)}
              onActionClick={() => onActionClick(action as HeaderAction)}
              icon={icons[action]}
              isSelected={isSelected(action)}
            />
          ))}
        </ActionsContainer>
      </ActionWrapper>
    </FlexCol>
  )
}

export const RoomHomeActionItem: React.FC<{
  title: string
  action: string
  icon: SVG
  isSelected: boolean
  onActionClick: () => void
}> = ({ title, icon: Icon, isSelected, onActionClick }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return (
    <ItemContainer onClick={onActionClick}>
      <ButtonContainer isSelected={isSelected}>
        <Icon size={28} fill={isSelected ? palette.white : palette.text.primary} />
      </ButtonContainer>
      {title ? <LabelMedium style={{ fontSize: 13 }}>{title}</LabelMedium> : null}
    </ItemContainer>
  )
}

// MARK: - Styles

const ItemContainer = styled(FlexCol)`
  align-items: center;
  cursor: pointer;
  width: 20%;
`

const ButtonContainer = styled(FlexCol)<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.palette.primary
      : theme.isDark
      ? theme.palette.background.tertiary
      : 'transparent'};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};

  border-radius: 24px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  margin: 0px 0px 8px 0px;
  min-height: 48px;
  width: 48px;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.primary : 'transparent')};
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      }
    `};
`

const Title = styled(LabelLarge)`
  font-size: 26px;
  line-height: 30px;
  margin: 24px 0px 4px 0px;
  text-align: center;

  @media ${device.smallerThan.laptop} {
    margin-top: 12px;
    font-size: 22px;
    line-height: 28px;
  }
`

const ActionsContainer = styled(FlexRow)`
  flex: 0;
  margin: 16px auto 0px auto;
  max-width: 600px;
`

const ActionWrapper = styled.div`
  margin: 0px -20px;
  width: 100%;
`
