import React from 'react'
import styled from 'styled-components'
import { FadeIn } from './CommonStyles'
import { PageLoader } from './PageLoader'

export const EmptyState: React.FC<{
  style?: React.CSSProperties
  title: string
  isLoading?: boolean
  hide?: boolean
  subtitle: string
}> = ({ isLoading, hide, title, subtitle, style }) => {
  if (hide) return null

  return isLoading ? (
    <PageLoader style={{ minHeight: 400, paddingBottom: 0, justifyContent: 'center' }} />
  ) : (
    <Container ease={'ease-in'} style={style}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  )
}

// MARK: - Style

const Container = styled(FadeIn)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  opacity: 1;
  padding: 0px 24px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  text-align: center;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 15px;
  line-height: 21px;
  text-align: center;
`
