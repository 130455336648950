const en = {
  time: 'Thu, Oct 15, 2020, 10:23 AM',
  translation: {
    lobbi: 'Lobbi',
    search: 'Search',
    cancel: 'Cancel',
    ok: 'Okay',
    save: 'Save',
    oopps: 'Oopps!',
    somethingWentWrong: 'Something went wrong',
    profileImage: 'Profile Image',
    language: 'Language',
    name: 'Name',
    surname: 'Surname',
    userName: 'Username',
    email: 'Email',
    readMore: '...read more',
    showLess: 'show less',
    home: 'Home',
    theme: 'Theme',
    reportRoom: 'Report event',
    notifications: 'Notifications',
    settings: 'Settings',
    features: 'Features',
    account: 'Account',
    accountSettingsDescription: 'Manage your account settings.',
    youLookNiceToday: 'You look nice today.',
    youLookNiceTonight: 'You look nice tonight.',
    languageSettingsDescription: 'Change language of the event.',
    reportRoomDescription: 'Flag event or users with inappropriate behaviors.',
    seeYouAround: 'See you around 😢',
    logoutMessage: 'Are you sure to logout from your account?',
    logout: 'Logout',
    dragDrop: 'Drag & drop or click to add image. {{ratio}}',
    imageSizeLimit: 'JPEG or PNG, no larger than 10MB.',
    general: 'General',
    noAnnouncementsPostedYet: 'No announcement has been posted yet',
    oncePublisherPostedAnnouncements:
      'When moderators post announcements, you can see the announcements here',
    private: 'Private',
    channels: 'Channels',
    loadMore: 'Load more',
    noMessagesSent: 'No message has been sent to the channel yet',
    noMessagesSentDescription: 'You can send the first message to this channel',
    chatInputPlaceholder: 'Send a message...',
    noQAQuestionsPostedYet: 'No questions have been asked yet',
    oncePublisherPostedQAQuestions: "Let's ask the event moderators the first question",
    askQuestion: 'Ask a question...',
    delete: 'Delete',
    qaQuestionPending: 'The question is waiting to be answered as soon as possible',
    qaQuestionAnswered: 'The question was answered by the event moderators.',
    qaQuestionRejected: 'The question was rejected by the event moderators.',
    answer: 'Answer',
    calendar: 'Calendar',
    reminder: 'Reminder',
    share: 'Share',
    links: 'Links',
    seeMore: 'See More',
    continue: 'Continue',
    logInOrSignUp: 'Log in or sign up',
    welcomeToLobbi: 'Welcome to Lobbi',
    continueWith: 'Continue with {{provider}}',
    login: 'Login',
    chats: 'Chats',
    password: 'Password',
    forgotPassword: 'Forgot your password?',
    passwordResetEmailSent: 'Reset password email sent to {{email}}',
    invalidEmail: 'Invalid email entered, please double check your email',
    invalidPassword: 'Invalid password entered, please double check your password',
    tooManyTrials:
      'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
    termsOfService: 'Terms of Service',
    agreeAndContinue: 'Agree and continue',
    privacyPolicy: 'Privacy Policy',
    termsConditionsInfo:
      "By selecting Agree and continue, I agree to Lobbi's Terms of Service, and acknowledge the Privacy Policy.",
    firstName: 'First Name',
    lastName: 'Last Name',
    authContinue: 'Log in or sign up',
    authPassword: 'Enter your password',
    authRegister: 'Finish signing up',
    or: 'or',
    beforeStartDate: '{{time}} before start',
    afterStartDate: '{{time}} after start',
    dateTime: 'Date & Time',
    set: 'Set',
    setReminder: 'Set Reminder',
    update: 'Update',
    remove: 'Remove',
    done: 'Done',
    clear: 'Clear',
    minute: '{{minute}} minute',
    minutes: '{{minute}} minutes',
    hour: '{{hour}} hour',
    hours: '{{hour}} hours',
    day: '{{day}} day',
    days: '{{day}} days',
    week: '{{week}} week',
    weeks: '{{week}} weeks',
    allowNotifications: 'Allow notifications',
    allowNotificationsDescription:
      'Be the first to know about the latest news by allowing notifications.',
    reminderSetMessage: 'Your reminder is successfully created.',
    reminderUpdateMessage: 'Your reminder is successfully updated.',
    reminderRemoveMessage: 'Your reminder is successfully removed.',
    leaveRoom: 'Leave Room',
    leaveRoomDescription: 'We will miss you, see you around.',
    leaveRoomAlertDescription:
      'When you leave the event, your notification and calendar settings for the event will be lost. Are you sure you want to leave your event?',
    reminderTime: 'Date and time',
    copyLink: 'Copy Link',
    invite: 'Send Invite',
    qrCode: 'QR Code',
    twitter: 'Twitter',
    instagram: 'Instagram',
    whatsapp: 'Whatsapp',
    more: 'More',
    copiedToClipboard: 'Copied to clipboard!',
    start: 'Start',
    back: 'Back',
    download: 'Download',
    notes: 'Notes',
    send: 'Send',
    invitationSent: 'Your invitation sent successfully!',
    invalidName: 'Invalid name',
    invalidLastName: 'Invalid last name',
    invalidEmailAddress: 'Invalid email address',
    addToCalendar: 'Add to Calendar',
    invitationRequestSent: 'Invitation request sent',
    invitationRequest: 'Request invitation',
    sendRequest: 'Send request',
    inviteOnlyRoom: 'Invite Only Room',
    inviteOnlyRoomDescription:
      'You need to send invitation request to access event. Room moderators will send you invitation as soon as possible',
    invitationRequestSentDescription:
      'Your invitation request was sent successfully. You can continue when event moderators will send you an invitation.',
    privateRoom: 'Private Room',
    accessCode: 'Access Code',
    privateRoomDescription:
      'To join the private events, you must enter the access code. You can request the access code from the event moderators.',
    managedRoom: 'Managed Room',
    managedRoomDescription:
      'Access to managed events is only provided by the manager platform. Please contact the manager platform to join event.',
    noChannelsCreated: 'No channels created yet',
    noChannelsCreatedDescription: 'Once event moderators create channels, they will appear here',
    notificationPermission: 'Notification Permission',
    notificationPermissionDescription:
      'You need to give notification permission to Lobbi from browser settings.',
    configureNotifications: 'Notification preferences',
    configureNotificationsDescription:
      'You can keep your focus by adjusting your notification preferences.',
    components: 'Components',
    generalChatChannel: 'General',
    generalChatChannelDescription: 'Room-wide announcements and messaging',
    unknownUser: 'Unknown User',
    noNotificationsPostedYet: 'No notifications posted yet',
    oncePublisherPostedNotifications: 'Once moderators send notifications, they will appear here',
    help: 'Help',
    noActiveFeatures: 'No active features',
    noActiveFeaturesDescription:
      'There are no active features for notification configuration. You will receive only general notifications from the event.',
    resetPassword: 'Reset password',
    directMessages: 'Direct Messages',
    searchInLobbi: 'Search Lobbi',
    accounts: 'Accounts',
    rooms: 'Events',
    'invite-only': 'Invite only',
    public: 'Public',
    managed: 'Managed',
    noRoomFound: 'No events found',
    noRoomFoundDescription:
      'No results were found for {{query}}, please check your query or type another.',
    viewProfile: 'View Profile',
    type: 'Type',
    displayName: 'Display name',
    bio: 'Bio',
    information: 'Information',
    addNew: 'Add New',
    profile: 'Profile',
    editAccount: 'Edit Account',
    darkTheme: 'Dark Theme',
    lightTheme: 'Light Theme',
    changeCover: 'Change cover',
    reposition: 'Reposition',
    savePosition: 'Save position',
    dragImageToReposition: 'Drag image to reposition',
    gallery: 'Gallery',
    upload: 'Upload',
    link: 'Link',
    unsplash: 'Unsplash',
    chooseAnImage: 'Choose an image',
    imagesWiderThan: 'Images wider than 1500 pixels work best.',
    imagesLowerThan: 'The maximum size per file is 10 mb.',
    worksWithAnyImage: 'Works with any image from the web.',
    pasteAnImageLink: 'Paste an image link...',
    submit: 'Submit',
    searchAnImage: 'Search an image...',
    memberSince: 'Member since {{date}}',
    edit: 'Edit',
    upcomingEvents: 'Upcoming events',
    allEvents: 'All events',
    pastEvents: 'Past events',
    ownedRooms: 'Moderator',
    addFriend: 'Add friend',
    joinRoom: 'Join lobby',
    agenda: 'Agenda',
    unfriend: 'Unfriend',
    pending: 'Pending',
    following: 'Following',
    accept: 'Accept',
    noRoomsJoined: '{{name}} has not joined any events yet',
    noRoomsJoinedDescription: 'Once {{name}} joins events, they will appear here',
    noRoomsJoinedPast: '{{name}} has not joined a past event yet',
    noRoomsJoinedPastDescription: '{{name}} joins past events, they will appear here',
    noRoomsModerated: '{{name}} has not moderated any events yet',
    noRoomsModeratedDescription: 'Once {{name}} moderates any events, they will appear here',
    friends: 'Friends',
    followings: 'Followings',
    report: 'Report',
    reported: 'User is successfully reported.',
    unfriendDescription: 'Are you sure you want to unfriend {{name}}?',
    removeFriend: 'Remove',
    noFriends: '{{name}} has not added any friends yet',
    noFriendsDescription: 'Once {{name}} adds some friends, they will appear here',
    inbox: 'Inbox',
    archived: 'Archived',
    sentYouFriendRequest: 'sent you a friend request.',
    archiveAll: 'Archive All',
    noUpdates: "You're all caught up",
    noUpdatesDescription: "When someone sends friend request or invite, you'll be notified here.",
    noArchives: 'No archived updates',
    noArchivesDescription: 'When you archive updates, they will be stored here for future access.',
    acceptedYourFriendRequest: 'accepted your friend request.',
    startYourSearch: 'Start your search',
    startYourDiscovery: 'Start your discovery',
    becomeAPublisher: 'Become a creator',
    events: 'Events',
    meetings: 'Meetings',
    productLaunches: 'Product Launches',
    nftDrops: 'NFT Drops',
    other: 'Other',
    today: 'Today',
    yesterday: 'Yesterday',
    thread: 'Thread',
    replyPlaceholder: 'Reply...',
    repliesCount: '{{count}} replies',
    replyCount: '{{count}} reply',
    viewThread: 'View thread',
    lastReplyTime: 'Last reply {{time}} ago',
    timeAgo: '{{time}} ago',
    you: 'You',
    searchConnections: 'Search friends or chats...',
    moderators: 'Moderators',
    members: 'Members',
    searchMembers: 'Search members...',
    joinEvent: 'Join event',
    joinEventDescription: 'This event is live. Join now:',
    joinRoomDescription: 'Log in or sign up to enter the lobby',
    goToRoom: 'Enter Lobby',
    contactToModerator: 'Contact to moderator',
    joinDetails: 'Join details',
    joinDetailsDescription:
      '⏰ The event is starting in {{duration}}. When event starts, there will be a large join button on this page.',
    joinDetailsRoomDescription:
      '🚀 Enter event lobby to learn more about the event and interact with the community.',
    hostedBy: 'Moderated by',
    shareEvent: 'Share Event',
    publicEvent: 'Public Event',
    privateEvent: 'Private Event',
    inviteOnlyEvent: 'Invite Only Event',
    managedEvent: 'Managed Event',
    generalSettings: 'General Settings',
    addCalendar: 'Add Calendar',
    notificationsDescription: 'Information posted by event moderators',
    settingsDescription: 'Configure event settings',
    oncePublisherPostedFAQs:
      'Once moderators publish frequently asked questions, they will appear here.',
    phoneNumber: 'Phone number',
    enterYourEmail: 'hello@lobbi.events',
    enterYourPhoneNumber: '+1 111 111 1111',
    verificationCode: 'Verification code',
    typeAMessage: 'Type a message...',
    noWebListItemsPostedYet: 'No link has been added yet',
    oncePublisherPostedWebListItems:
      'When moderators add links to the list, the links will be listed here.',
    noFAQsPostedYet: 'No questions have been added yet',
    oncePublisherPostedFAQ:
      'When moderators add frequently asked questions, they will be listed here with the answers.',
    noPollingPostedYet: 'No questions have been added yet',
    oncePublisherPostedPolling:
      'When moderators add questions to the survey, you can fill out the survey here.',
    audience: 'Audience',
    deleteAccountWarning: 'Want to delete your account?',
    yes: 'Yes',
    deleteAccount: 'Delete account',
    deleteMessage: 'Are you sure to delete your account? This action is irreversible.',
  },
}

export default en
