import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 18, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 18 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9391 1.39039C12.6523 0.546275 11.9075 0 11.0639 0H6.93592L6.779 0.00633149C5.9492 0.0734931 5.24111 0.668966 5.02304 1.50533L4.77543 2.76813L4.74642 2.87929C4.62785 3.23928 4.29636 3.48913 3.91772 3.48913H0.73139L0.632145 3.49598C0.275153 3.54564 0 3.85945 0 4.23916C0 4.65338 0.327455 4.98918 0.73139 4.98918L3.91772 4.98918H14.0821L17.2686 4.98918L17.3679 4.98234C17.7248 4.93267 18 4.61887 18 4.23916C18 3.82493 17.6725 3.48913 17.2686 3.48913H14.0821L13.9701 3.48177C13.6025 3.4332 13.2987 3.14872 13.2243 2.76783L12.9874 1.55209L12.9391 1.39039ZM11.9143 3.48913C11.881 3.40445 11.8522 3.31721 11.8282 3.22768L11.79 3.06208L11.5636 1.8928C11.5107 1.68991 11.3473 1.54138 11.1502 1.50742L11.0639 1.50006H6.93592C6.73071 1.50006 6.54829 1.62322 6.47252 1.77803L6.44682 1.84604L6.20979 3.06238C6.18087 3.21048 6.13899 3.35311 6.08551 3.48913H11.9143ZM15.9784 6.72017C16.3475 6.75069 16.6304 7.05716 16.65 7.42605L16.6405 7.63174L16.326 11.483L15.9961 15.2414C15.9263 15.9917 15.8638 16.6245 15.8099 17.1227C15.6225 18.8588 14.4955 19.9323 12.7966 19.9641C10.1494 20.013 7.60477 20.0125 5.13373 19.9591C3.48398 19.9244 2.37366 18.8393 2.18955 17.1297L2.0623 15.8702L1.83994 13.427L1.61216 10.7461L1.35172 7.52788C1.31935 7.11498 1.61951 6.75335 2.02215 6.72016C2.39123 6.68973 2.7183 6.94584 2.79519 7.30677L2.82511 7.60173L3.06966 10.6187L3.33669 13.7459C3.45646 15.0996 3.56034 16.1952 3.64346 16.9648C3.74838 17.939 4.26138 18.4404 5.16411 18.4593C7.61585 18.5124 10.1415 18.5129 12.7701 18.4643C13.7277 18.4464 14.2489 17.9499 14.356 16.9574L14.4827 15.7046C14.5198 15.3185 14.5594 14.8923 14.6014 14.4293L14.8686 11.3538L15.1906 7.4075C15.2204 7.02902 15.5192 6.7389 15.879 6.71882L15.9784 6.72017Z"
      fill={fill}
    />
  </svg>
)

export const DeleteIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
