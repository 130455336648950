import moment from 'moment'
import 'moment/locale/tr'
import React, { useEffect } from 'react'
import { useSelect } from 'src/utils/hooks/useSelect'
import { ThemeProvider } from 'styled-components'
import './App.css'
import { AppAlertProvider } from './AppAlertProvider'
import { AppAuthenticationProvider } from './AppAuthenticationProvider'
import { AppInitializationProvider } from './AppInitializationProvider'
import { getLocalization, getTheme } from './redux/reducers/app'
import { Routes } from './utils/router'
import { appTheme } from './utils/theme/appTheme'

export const App: React.FC = () => {
  // MARK: - Hooks

  const currentTheme = useSelect(state => getTheme(state.app))
  const localization = useSelect(state => getLocalization(state.app))
  const theme = appTheme(currentTheme)

  // MARK: - Effects

  useEffect(() => {
    moment.locale(localization)
  }, [localization])

  // MARK: - Render

  return (
    <ThemeProvider theme={theme}>
      <AppAlertProvider>
        <AppInitializationProvider>
          <AppAuthenticationProvider>
            <Routes />
          </AppAuthenticationProvider>
        </AppInitializationProvider>
      </AppAlertProvider>
    </ThemeProvider>
  )
}
