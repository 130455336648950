import { ChatChannelMessageInput } from './ChatChannelMessageInput'
import { ChatChannelMessageList } from './ChatChannelMessageList'
import { ChatChannelThreadBody } from './ChatChannelThreadBody'
import { ChatChannelThreadHeader } from './ChatChannelThreadHeader'
import { Resizable } from 're-resizable'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FlexCol } from 'src/features/common/CommonStyles'
import { PageLoader } from 'src/features/common/PageLoader'
import {
  fetchChannelMessages,
  getChannelMessages,
  getSingleMessageById,
} from 'src/redux/reducers/chatChannel'
import { ChatChannel } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const ChatChannelThread: React.FC<{
  thread: ChatChannel | null
  onSendMessage: (text: string) => void
  onClose: () => void
}> = ({ thread, onSendMessage, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const [width, setWidth] = useState('35%')

  const message = useSelect(state =>
    thread ? getSingleMessageById(state.chatChannel, thread.id) : null,
  )
  const messages = useSelect(state =>
    thread?.id ? getChannelMessages(state.chatChannel, thread.id) : [],
  )

  // MARK: - Effects

  useEffect(() => {
    if (!messages.length && thread?.id) dispatch(fetchChannelMessages(thread.id))
  }, [thread?.id, dispatch])

  // MARK: - Render

  const renderHeader = useCallback(() => <ChatChannelThreadBody message={message} />, [message])

  return (
    <Resizable
      size={{ width, height: '100%' }}
      onResizeStop={(e, direction, ref, delta) => setWidth(prev => prev + delta.width)}
      maxWidth={'60%'}
      minWidth={365}
      enable={{
        left: true,
        top: false,
        right: false,
        bottom: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}>
      <Container>
        {thread ? (
          <>
            <ChatChannelThreadHeader channel={thread} onClose={onClose} />
            <ChatChannelMessageList channel={thread} header={renderHeader()} />
            <ChatChannelMessageInput channel={thread} onSendMessage={onSendMessage} />
          </>
        ) : (
          <PageLoader />
        )}
      </Container>
    </Resizable>
  )
}

// MARK: - Hooks

const Container = styled(FlexCol)`
  box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 72px);
  overflow: hidden;
  overflow-x: visible;
  width: 100%;
  z-index: 2;
`
