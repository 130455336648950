import React from 'react'
import { FlexCol, LabelMedium, Row } from 'src/features/common/CommonStyles'
import styled from 'styled-components'
import { coverImages } from '../types'

type Props = {
  onImageSelect: (image: string) => void
}

export const ProfileChangeCoverModalGallery: React.FC<Props> = ({ onImageSelect }) => {
  // MARK: - Properties

  const sections = Object.keys(coverImages)

  // MARK: - Render

  return (
    <Container>
      {sections.map(section => (
        <div key={section}>
          <Title>{section.toUpperCase()}</Title>
          <Gallery>
            {coverImages[section].images.map(image => (
              <GalleryItemContainer key={image}>
                <img
                  style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                  src={image}
                  alt=""
                  onClick={() => onImageSelect(image)}
                />
              </GalleryItemContainer>
            ))}
          </Gallery>
        </div>
      ))}
    </Container>
  )
}

// MARK: - Style

const Container = styled(FlexCol)`
  overflow: scroll;
  padding: 6px 10px;
`

const Title = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
  margin-top: 12px;
`

const GalleryItemContainer = styled(Row)`
  align-items: center;
  border-radius: 4px;
  height: 72px;
  justify-content: center;
  margin: 3px 0.6%;
  overflow: hidden;
  width: 23.8%;
`

const Gallery = styled(Row)`
  flex-wrap: wrap;
  margin: 0px -0.6% 8px -0.6%;
`
