import {
  onChildAdded,
  onChildChanged,
  orderByChild,
  query,
  ref,
  startAt,
  Unsubscribe,
} from 'firebase/database'
import { firebaseDatabase } from '../utils/firebase'

type Payload = {
  id: string
  room_id?: string
  component_id?: string | null
  action: 1 | 2 // 1: Update, 2: Delete
  type: 'chat' | 'chat_message' | 'component' | 'room'
  updated_at: number
  notify_user: boolean
}

export enum Priority {
  veryHigh = 2,
  high = 1,
  normal = 0,
  low = -1,
  veryLow = -2,
}

class OperationQueue {
  private queue: { priority: Priority; execute: () => void }[] = []
  private inProgress: boolean = false

  get operations() {
    return this.queue
  }

  get isExecuting() {
    return this.inProgress
  }

  constructor(readonly name: string) {}

  readonly push = (operation: { priority: Priority; execute: () => void }) => {
    /*
      Insert operation to an index, according to its priority.
      If lower priority operation exists, than insert in front of the first lower priority operation.
      If no lower priority operation exists, than push operation as last element of the queue.
    */
    const insertIndex = this.queue.findIndex(queued =>
      this.lowerPrioritiesThan(operation.priority).includes(queued.priority),
    )
    insertIndex !== -1
      ? (this.queue = this.queue.splice(insertIndex, 0, operation))
      : this.queue.push(operation)

    // Execute first operation if it is the only element in queue.
    if (this.queue.length === 1 && !this.inProgress) this.runNext()
  }

  readonly lowerPrioritiesThan = (higherPriority: Priority): Priority[] => {
    const priorities: Priority[] = [
      Priority.veryHigh,
      Priority.high,
      Priority.normal,
      Priority.low,
      Priority.veryLow,
    ]
    return priorities.filter(priority => priority < higherPriority)
  }

  readonly flushAllOperations = () => {
    this.queue = []
  }

  protected runNext = async () => {
    this.inProgress = false
    if (!this.queue.length) return

    this.inProgress = true
    const next = this.queue.shift()!
    try {
      await next.execute()
    } catch (error: any) {
      console.warn('Unhandled error in the queue for operation:')
      console.info(next)
    }

    await this.runNext()
  }
}

const disposeBag: Record<string, Unsubscribe | null> = {}
const queue = new OperationQueue('realtime')

export const realtime = {
  observe: <T = Payload>(
    path: string,
    timestamp: number,
    config: { priority: Priority; types: ('child_added' | 'child_changed')[] },
    execute: (payload: T) => void,
  ) => {
    if (disposeBag[path]) return
    const node = query(
      ref(firebaseDatabase, path),
      orderByChild('updated_at'),
      startAt(timestamp + 1),
    )

    if (config.types.includes('child_added')) {
      disposeBag[path + '_child_added'] = onChildAdded(node, snap =>
        queue.push({ execute: () => execute(snap.val()), priority: config.priority }),
      )
    }

    if (config.types.includes('child_changed')) {
      disposeBag[path + '_child_changed'] = onChildChanged(node, snap =>
        queue.push({ execute: () => execute(snap.val()), priority: config.priority }),
      )
    }
  },
  dispose: (path: string) => {
    disposeBag[path + '_child_added']?.()
    disposeBag[path + '_child_added'] = null

    disposeBag[path + '_child_changed']?.()
    disposeBag[path + '_child_changed'] = null
  },
}
