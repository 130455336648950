import i18n from '../localization'
import 'moment/locale/tr'
import moment from 'moment'

export const localTime = () => Math.floor(new Date().getTime() / 1000)

export const formatDurationFrom = (timestamp: number) => {
  const duration = localTime() - timestamp
  const [secs, minutes, hours, days, years] = [
    duration,
    Math.floor(duration / 60),
    Math.floor(duration / (60 * 60)),
    Math.floor(duration / (60 * 60 * 24)),
    Math.floor(duration / (60 * 60 * 24 * 265)),
  ]
  if (secs < 60) return 'now'
  else if (minutes < 60) return `${minutes}m`
  else if (hours < 24) return `${hours}h`
  else if (days < 365) return `${days}d`
  else return `${years}y`
}

export const dateString = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleTimeString(locale, {
    month: 'short',
    year: 'numeric',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const getDay = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleDateString(locale, {
    day: 'numeric',
  })
}

export const getWeekDay = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleDateString(locale, {
    weekday: 'long',
  })
}

export const getMonth = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleDateString(locale, {
    month: 'short',
  })
}

export const prettyDateString = (timestamp: number, short: boolean = false) => {
  return `${dayString(timestamp, short)}, ${hourString(timestamp)}`
}

export const dayString = (timestamp: number, short: boolean = false) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleDateString(locale, {
    month: short ? 'long' : 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: short ? undefined : 'long',
  })
}

export const hourString = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date
    .toLocaleTimeString(locale, {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace('24:', '00:')
}

export const isSameDay = (first: number, second: number) => {
  return dayString(first) === dayString(second)
}

export const isSameMonth = (first: number, second: number) => {
  const firstDate = new Date(first * 1000)
  const secondDate = new Date(second * 1000)
  return (
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  )
}

export const isToday = (timestamp: number) => {
  const currentDate = new Date()
  return dayString(timestamp) === dayString(Math.floor(currentDate.getTime() / 1000))
}

export const prettyRangeString = (start: number, end: number) => {
  const startDate = new Date(start * 1000)
  const endDate = new Date(end * 1000)
  const locale = i18n.language

  const startHourString = hourString(start)
  const startDateString = isToday(start) ? 'Today' : dayString(start)

  const endHourString = hourString(end)

  const sameDay = isSameDay(start, end)
  const sameMonth = isSameMonth(start, end)

  if (sameDay) {
    return startDateString + ', ' + startHourString + '-' + endHourString
  } else if (sameMonth) {
    const monthString = startDate.toLocaleDateString(locale, {
      month: 'long',
      year: 'numeric',
    })
    const startDayString = startDate.toLocaleDateString(locale, {
      day: 'numeric',
    })

    const endDayString = endDate.toLocaleDateString(locale, {
      day: 'numeric',
    })
    const splitted = monthString.split(' ')
    return [`${startDayString}-${endDayString} ${splitted[0]}, ` + splitted[1]]
  } else {
    const startDayString = startDate.toLocaleDateString(locale, {
      month: 'long',
      day: '2-digit',
    })

    const endDayString = startDate.toLocaleDateString(locale, {
      month: 'short',
      day: '2-digit',
    })

    return startDayString + '-' + endDayString + startDate.getFullYear()
  }
}

export const formatAMPM = (date: Date) => {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const minutesString = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutesString + ' ' + ampm
  return strTime
}

export const format24 = (date: Date) => {
  return `${(date.getHours() % 24).toString().padStart(2, '0')}:${(date.getMinutes() % 60)
    .toString()
    .padStart(2, '0')} `
}

export const formatDuration = (duration: number) => {
  const locale = i18n.language
  return moment
    .duration(duration * 1000)
    .locale(locale)
    .humanize()
}

export const globalTime = () => {
  return Math.ceil(Date.now() / 1000)
}
