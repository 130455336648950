import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setShowAuthentication } from 'src/redux/reducers/app'
import { createSubscription, fetchRoomCover, fetchSubscription } from 'src/redux/reducers/cover'
import { getEntity, getFirstEntity } from 'src/redux/reducers/entity'
import { getIsAuthenticated, getMeUser } from 'src/redux/reducers/me'
import { Room, Subscription } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'
import { Col, FlexCol, FlexRow } from '../common/CommonStyles'
import { PageLoader } from '../common/PageLoader'
import { LayoutWrapper } from '../layout/LayoutWrapper'
import { CalendarModal } from '../room/Calendar'
import { ReminderModal } from '../room/Reminder'
import { ShareModal } from '../room/Share'
import { RoomCoverFeatures } from './RoomCoverFeatures'
import { RoomCoverHeader } from './RoomCoverHeader'
import { RoomCoverHostInfo } from './RoomCoverHostInfo'
import { RoomCoverJoinRoom } from './RoomCoverJoinRoom'
import { Action, RoomCoverShare } from './RoomCoverShare'
import { RoomCoverTitle } from './RoomCoverTitle'

export const RoomCover: React.FC<{
  roomId: string
}> = ({ roomId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { navigate, routes } = useNavigation()
  const { isMobile } = useWindowDimensions()

  const room = useSelect(state => getEntity<Room>(state.entity, 'room', roomId))
  const meUserId = useSelect(state => getMeUser(state.me)?.id)
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const subscription = useSelect(state =>
    getFirstEntity<Subscription>(
      state.entity,
      'subscription',
      ({ user_id, room_id }) => user_id === meUserId && room_id === roomId,
    ),
  )
  const [selectedAction, setSelectedAction] = useState<Action | null>(null)
  const [shouldNavigateRoom, setShouldNavigateRoom] = useState(false)
  const [targetComponentId, setTargetComponentId] = useState<string | null>(null)

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchRoomCover(roomId))
  }, [roomId, meUserId])

  useEffect(() => {
    if (!subscription?.is_active) dispatch(fetchSubscription(roomId))
  }, [meUserId])

  useEffect(() => {
    if (subscription?.id && shouldNavigateRoom) navigate(routes.room(roomId).home)
    if (subscription?.id && targetComponentId)
      navigate(routes.room(roomId).component(targetComponentId))
  }, [shouldNavigateRoom, subscription?.id, targetComponentId])

  // MARK: - Render

  if (!room) return <PageLoader />

  return (
    <LayoutWrapper roomId={roomId}>
      <FlexCol style={{ alignItems: 'center', paddingBottom: '5vh', minHeight: '100vh' }}>
        <Container>
          <RoomCoverHeader room={room} />

          <Col style={{ paddingRight: 16, paddingLeft: 16 }}>
            <RoomCoverTitle room={room} />

            <Layout>
              <Col style={{ width: isMobile ? '100%' : '66%' }}>
                <RoomCoverFeatures
                  room={room}
                  onFeatureClick={component => {
                    if (isAuthenticated) {
                      if (!subscription?.id) dispatch(createSubscription(roomId))
                      setTargetComponentId(component.id)
                    } else {
                      dispatch(setShowAuthentication(true))
                    }
                  }}
                />
              </Col>

              <Col
                style={{
                  width: isMobile ? '100%' : '33%',
                  marginLeft: isMobile ? 0 : 20,
                  paddingLeft: isMobile ? 0 : 16,
                  minWidth: 300,
                }}>
                <RoomCoverShare
                  room={room}
                  onActionClick={action => {
                    if (action === 'setReminder' && !isAuthenticated)
                      dispatch(setShowAuthentication(true))
                    else setSelectedAction(action)
                  }}
                />

                <RoomCoverJoinRoom
                  room={room}
                  onGoToRoomClick={() => navigate(routes.room(roomId).home)}
                  onJoinRoomClick={() => {
                    dispatch(createSubscription(roomId))
                    setShouldNavigateRoom(true)
                  }}
                />

                <RoomCoverHostInfo room={room} />
              </Col>
            </Layout>
          </Col>
        </Container>
      </FlexCol>

      <ReminderModal
        subscription={subscription}
        visible={selectedAction === 'setReminder'}
        room={room}
        onClose={() => setSelectedAction(null)}
      />

      <CalendarModal
        subscription={subscription}
        visible={selectedAction === 'addToCalendar'}
        room={room}
        onClose={() => setSelectedAction(null)}
      />

      <ShareModal
        visible={selectedAction === 'share'}
        room={room}
        onClose={() => setSelectedAction(null)}
      />
    </LayoutWrapper>
  )
}

// MARK: - Hooks

const Container = styled(Col)`
  max-width: 1060px;
  padding: 0px auto;
  position: relative;
  width: 100%;
`

const Layout = styled(FlexRow)`
  @media ${device.smallerThan.tablet} {
    flex-direction: column-reverse;
  }
`
