import React from 'react'
import { LabelHeaderLarge, LabelMedium, ShadowBox } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'
import { getDay, getMonth, getWeekDay } from 'src/utils/helpers/dateHelper'
import styled from 'styled-components'

export const RoomCoverHeaderDate: React.FC<{
  room: Room
}> = ({ room }) => {
  return (
    <Container>
      <MonthText>{getMonth(room.start_date)}</MonthText>
      <DayText>{getDay(room.start_date)}</DayText>
      <WeekDayText>{getWeekDay(room.start_date)}</WeekDayText>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-radius: 6px;
  bottom: -30px;
  overflow: hidden;
  position: absolute;
  right: 16px;
  width: 110px;
`

const MonthText = styled(LabelMedium)`
  background-color: ${({ theme }) => theme.palette.orange};
  color: ${({ theme }) => theme.palette.white};
  padding: 4px 0px;
  text-align: center;
`

const DayText = styled(LabelHeaderLarge)`
  font-size: 44px;
  padding: 16px 0px 8px 0px;
  text-align: center;
`

const WeekDayText = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 4px 0px;
  padding-bottom: 12px;
  text-align: center;
`
