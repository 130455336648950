import React from 'react'
import { useTranslation } from 'react-i18next'
import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { CalendarIcon } from 'src/assets/images/svg/CalendarIcon'
import { ShareIcon } from 'src/assets/images/svg/ShareIcon'
import { Row } from 'src/features/common/CommonStyles'
import { getFirstEntity } from 'src/redux/reducers/entity'
import { Room, Subscription } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import { SVG } from 'src/utils/types/svg'
import { RoomHomeActionItem } from '../room/RoomHome/RoomHomeHeader'

export type Action = 'addToCalendar' | 'setReminder' | 'share'
type Props = {
  room: Room
  onActionClick: (action: Action) => void
}

export const RoomCoverShare: React.FC<Props> = ({ room, onActionClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const actions: Action[] = ['addToCalendar', 'setReminder', 'share']

  const subscription = useSelect(state =>
    getFirstEntity<Subscription>(
      state.entity,
      'subscription',
      ({ room_id }) => room_id === room.id,
    ),
  )

  const icons: Record<Action, SVG> = {
    addToCalendar: CalendarIcon,
    setReminder: AnnouncementIcon,
    share: ShareIcon,
  }

  const isSelected = (action: string) => {
    if (action === 'setReminder') return !!subscription?.push_date
    if (action === 'addToCalendar') return !!subscription?.event_id
    return false
  }

  // MARK: - Render

  return (
    <Row>
      {actions.map(action => (
        <RoomHomeActionItem
          key={action}
          action={action}
          title={t(action)}
          onActionClick={() => onActionClick(action)}
          icon={icons[action]}
          isSelected={isSelected(action)}
        />
      ))}
    </Row>
  )
}
