import { getAuth } from 'firebase/auth'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Authentication } from './features/authentication'
import { loginSilently } from './redux/reducers/authentication'
import { PageLoader } from './features/common/PageLoader'
import { getLoading, getShowAuthentication, setShowAuthentication } from './redux/reducers/app'
import { getIsAuthenticated } from './redux/reducers/me'
import { useSelect } from './utils/hooks/useSelect'
import { useNavigation } from './utils/hooks/useNavigation'

export const AppAuthenticationProvider: React.FC<{}> = ({ children }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { match, routes } = useNavigation()

  const showAuthentication = useSelect(state => getShowAuthentication(state.app))

  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))

  const [ready, setReady] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      setReady(true)

      if (isAuthenticated || isLoading) {
        unsubscribe()
        return
      }
      if (user?.email || user?.phoneNumber) {
        dispatch(loginSilently(user?.email, user?.phoneNumber))
      }
      unsubscribe()
    })

    return () => {
      unsubscribe()
    }
  }, [dispatch, isAuthenticated])

  // MARK: - Handler

  const handleAuthenticationClose = useCallback(() => {
    dispatch(setShowAuthentication(false))
  }, [])

  // MARK: - Render

  return ready && !isLoading ? (
    <>
      {children}
      <Authentication
        disableClose={match(routes.home)}
        visible={showAuthentication}
        onClose={handleAuthenticationClose}
      />
    </>
  ) : (
    <PageLoader />
  )
}
