import { AuthenticationContinue } from './AuthenticationContinue'
import { AuthenticationEnterPassword } from './AuthenticationEnterPassword'
import { AuthenticationHeader } from './AuthenticationHeader'
import { AuthenticationRegisterForm } from './AuthenticationRegisterForm'
import { AuthStep } from './types'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  clearAuthenticationFlow,
  getAuthStep,
  getAuthUser,
  login,
} from 'src/redux/reducers/authentication'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const Authentication: React.FC<{
  visible: boolean
  disableClose: boolean
  onClose: () => void
}> = ({ visible, disableClose, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const authUser = useSelect(state => getAuthUser(state.authentication))
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const authStep = useSelect(state => getAuthStep(state.authentication))

  // MARK: - Effects

  useEffect(() => {
    if (isAuthenticated && authUser.email) {
      dispatch(clearAuthenticationFlow())
      onClose()
    } else if (authStep === AuthStep.directLogin) {
      dispatch(login(authUser.email, authUser.phoneNumber))
    }
  }, [authStep, isAuthenticated])

  // MARK: - Handlers

  const handleClose = useCallback(() => {
    onClose()
    dispatch(clearAuthenticationFlow())
  }, [])

  // MARK: - Render

  const title = () => {
    if (authStep === AuthStep.continue || authStep === AuthStep.directLogin)
      return t('authContinue')
    if (authStep === AuthStep.enterPassword) return t('authPassword')
    if (authStep === AuthStep.registerForm) return t('authRegister')
    return ''
  }

  const renderInnerContainer = useCallback(() => {
    if (authStep === AuthStep.continue || authStep === AuthStep.directLogin) {
      return <AuthenticationContinue />
    } else if (authStep === AuthStep.enterPassword) {
      return <AuthenticationEnterPassword />
    } else if (authStep === AuthStep.registerForm) {
      return <AuthenticationRegisterForm />
    }
    return null
  }, [authStep])

  return !isAuthenticated && visible ? (
    <ModalWrapper
      disableClose={disableClose}
      size="tiny"
      onClose={handleClose}
      style={{ maxHeight: 600 }}>
      <AuthenticationHeader title={title()} />
      {renderInnerContainer()}
    </ModalWrapper>
  ) : null
}
