import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertModal } from 'src/features/common/AlertModal'
import { Error } from 'src/repository/Error'
import config from 'src/utils/config'

export const RoomAccessNavigateThirdParty: React.FC<{
  roomId: string
}> = ({ roomId }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <AlertModal
      visible
      error={Error.displayable(t('managedRoom'), t('managedRoomDescription'))}
      onClose={() => window.location.replace(config.platformUrl)}
    />
  )
}
