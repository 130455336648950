import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M9 19L9 12L5.48284 12C5.30466 12 5.21543 11.7846 5.34142 11.6586L12 5L18.6586 11.6586C18.7846 11.7846 18.6953 12 18.5172 12L15 12L15 19"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
)

export const UpVoteIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
