import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M14.5 3.35177C13.7063 3.12273 12.8675 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 11.1325 20.8773 10.2937 20.6482 9.5"
      stroke={fill}
      strokeLinecap="round"
    />
    <circle cx="9" cy="10" r="1" fill={fill} stroke={fill} strokeLinecap="round" />
    <circle cx="15" cy="10" r="1" fill={fill} stroke={fill} strokeLinecap="round" />
    <path d="M21 5.5H16" stroke={fill} strokeLinecap="round" />
    <path d="M18.5 3L18.5 8" stroke={fill} strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62889 15C8.34911 15 8.20922 15 8.13459 15.1068C8.05996 15.2136 8.10323 15.3318 8.18977 15.5683C8.70589 16.9788 10.2167 18 12 18C13.7834 18 15.2941 16.9788 15.8102 15.5683C15.8968 15.3318 15.9401 15.2136 15.8654 15.1068C15.7908 15 15.6509 15 15.3711 15H8.62889Z"
      fill={fill}
    />
  </svg>
)

export const AddReactionIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
