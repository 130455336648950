import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, LabelSmall } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { uploadBlobToFirebaseStorage } from 'src/utils/imageUploader'
import { v4 } from 'uuid'

type Props = {
  onImageSelect: (image: string) => void
}

export const ProfileChangeCoverModalUpload: React.FC<Props> = ({ onImageSelect }) => {
  // MARK: - Hooks

  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const { t } = useTranslation()

  // MARK: - Effects

  useEffect(() => {
    if (selectedImage) {
      onImageSelect(URL.createObjectURL(selectedImage))
      uploadImage(selectedImage)
    }
  }, [selectedImage])

  // MARK: - Actions

  const uploadImage = async (blob: Blob) => {
    const fileUrl = window.URL.createObjectURL(blob as any)
    const remoteUrl = await uploadBlobToFirebaseStorage(fileUrl, `cover/${v4()}`)
    onImageSelect(remoteUrl)
  }

  // MARK: - Render

  return (
    <Col style={{ alignItems: 'center' }}>
      <label htmlFor="select-image">
        <PrimaryButton
          style={{ marginTop: 24, marginLeft: 'auto', marginRight: 'auto', width: 260 }}
          onClick={() => {}}
          title={t('chooseAnImage')}
        />
      </label>

      <LabelSmall style={{ marginTop: 12, marginBottom: 16, fontSize: 13 }}>
        {t('imagesWiderThan')}
      </LabelSmall>
      <LabelSmall style={{ marginBottom: 16, fontSize: 13 }}>{t('imagesLowerThan')}</LabelSmall>

      <input
        autoFocus
        id="select-image"
        type="file"
        name="myImage"
        style={{ display: 'none' }}
        onChange={event => setSelectedImage(event.target.files?.[0] ?? null)}
      />
    </Col>
  )
}
