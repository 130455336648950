import React from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { ThreeDotsIcon } from 'src/assets/images/svg/ThreeDotsIcon'
import { FlexCol, FlexRow, LabelMedium, LabelSmall } from 'src/features/common/CommonStyles'
import { DropdownWrapper } from 'src/features/common/DropdownWrapper'
import { Hint } from 'src/features/common/Hint'
import { UserImage } from 'src/features/common/UserImage'
import { getEntity } from 'src/redux/reducers/entity'
import { getMeUser } from 'src/redux/reducers/me'
import { QAQuestion, QAQuestionState, User } from 'src/repository/types'
import { dayString, hourString } from 'src/utils/helpers/dateHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css, useTheme } from 'styled-components'

export const QAListItemHeader: React.FC<{
  qaQuestion: QAQuestion
  onQAQuestionDelete: (qaQuestion: QAQuestion) => void
}> = ({ qaQuestion, onQAQuestionDelete }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const { navigate, routes } = useNavigation()
  const meUserId = useSelect(state => getMeUser(state.me)?.id)
  const user = useSelect(state => getEntity<User>(state.entity, 'user', qaQuestion.user_id))

  const state: Record<QAQuestionState, string> = {
    pending: t('qaQuestionPending'),
    answered: t('qaQuestionAnswered'),
    rejected: t('qaQuestionRejected'),
  }
  const stateColor: Record<QAQuestionState, string> = {
    pending: palette.blue,
    answered: palette.green,
    rejected: palette.primary,
  }

  const timestamp = qaQuestion.created_at
  const time = `${dayString(timestamp)}, ${hourString(timestamp)}`
  const name = `${user?.first_name} ${user?.last_name}`

  const dropdownOptions = [{ title: t('delete'), icon: DeleteIcon }]
  const showDropdown = meUserId === qaQuestion.user_id && qaQuestion.state === 'pending'
  const showHint = meUserId === qaQuestion.user_id && qaQuestion.state !== 'answered'

  // MARK: - Actions

  const handleOptionsDropdownOptionClick = (option: number) => {
    if (option === 0) {
      onQAQuestionDelete(qaQuestion)
    }
  }
  // MARK: - Render

  return (
    <FlexRow style={{ alignItems: 'center' }}>
      <FlexRow style={{ alignItems: 'flex-start' }}>
        <UserImage
          url={user?.image_url}
          size={36}
          style={{ marginTop: 2, cursor: 'pointer' }}
          onClick={() => navigate(routes.profile(qaQuestion.user_id).main)}
        />

        <FlexCol style={{ marginLeft: 8 }}>
          <Name onClick={() => navigate(routes.profile(qaQuestion.user_id).main)}>{name}</Name>
          <Time>{time}</Time>
        </FlexCol>
      </FlexRow>

      {showHint ? (
        <Hint
          alignment="left"
          hint={state[qaQuestion.state]}
          style={{
            alignSelf: 'flex-start',
            backgroundColor: stateColor[qaQuestion.state],
            marginTop: 10,
            marginRight: 0,
          }}
        />
      ) : null}

      {showDropdown ? (
        <DropdownIcon>
          <DropdownWrapper
            icon={<ThreeDotsIcon size={14} />}
            position={{ right: -2, top: 30 }}
            options={dropdownOptions}
            handleOptionClick={handleOptionsDropdownOptionClick}
          />
        </DropdownIcon>
      ) : null}
    </FlexRow>
  )
}

// MARK: - Styles

const Name = styled(LabelMedium)`
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;

  ${() =>
    css`
      &:hover {
        text-decoration-line: underline;
      }
    `};
`

const Time = styled(LabelSmall)`
  font-size: 13px;
  line-height: 18px;
`

const DropdownIcon = styled.div`
  cursor: pointer;
  height: 32px;
  position: absolute;
  right: 32px;
  top: 20px;
  width: 32px;
`
