import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { ChatIcon } from 'src/assets/images/svg/ChatIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { HamburgerIcon } from 'src/assets/images/svg/HamburgerIcon'
import { LeaveIcon } from 'src/assets/images/svg/LeaveIcon'
import { LogoTitleIcon } from 'src/assets/images/svg/LogoTitleIcon'
import { ProfileIcon } from 'src/assets/images/svg/ProfileIcon'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { ActivityList } from 'src/features/activity'
import { Col, Row } from 'src/features/common/CommonStyles'
import { DropdownWrapper } from 'src/features/common/DropdownWrapper'
import { Input } from 'src/features/common/Input'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { UserImage } from 'src/features/common/UserImage'
import { getUnreadUpdateExists } from 'src/redux/reducers/activity'
import { getLoading, setShowAuthentication } from 'src/redux/reducers/app'
import { getConnectionDrawerCollapsed, setConnectionDrawerCollapsed } from 'src/redux/reducers/chat'
import { getIsAuthenticated, getMeUser } from 'src/redux/reducers/me'
import { getDrawerCollapsed, setDrawerCollapsed } from 'src/redux/reducers/room'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { css, useTheme } from 'styled-components'

export const LayoutHeader: React.FC<{
  roomId?: string
  searchText: string
  setSearchText: (text: string) => void
  onEnterPress: (text: string) => void
  onSearchPress: () => void
  onAccountPress: () => void
  onLogoutPress: () => void
}> = ({
  roomId,
  searchText,
  setSearchText,
  onEnterPress,
  onSearchPress,
  onAccountPress,
  onLogoutPress,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { palette } = useTheme()
  const { navigate, routes, match } = useNavigation()
  const { isMobile } = useWindowDimensions()

  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const unreadUpdateExists = useSelect(state => getUnreadUpdateExists(state.activity))
  const drawerCollapsed = useSelect(state => getDrawerCollapsed(state.room))
  const connectionDrawerCollapsed = useSelect(state => getConnectionDrawerCollapsed(state.chat))

  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const meUser = useSelect(state => getMeUser(state.me))

  const [showUpdates, setShowUpdates] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const dropdownOptions = [
    { title: t('profile'), icon: ProfileIcon },
    { title: t('editAccount'), icon: EditIcon },
    { title: t('logout'), icon: LeaveIcon },
  ]

  // MARK: - Actions

  const handleOptionsDropdownOptionClick = useCallback(
    (option: number) => {
      if (!meUser) return
      else if (option === 0) navigate(routes.profile(meUser.id).main)
      else if (option === 1) onAccountPress()
      else if (option === 2) onLogoutPress()
    },
    [meUser],
  )

  // MARK: - Render

  const renderLeading = useCallback(() => {
    if (isMobile && roomId) {
      return (
        <Col
          style={{ padding: 8, paddingRight: 8, paddingTop: 13, position: 'absolute', left: 8 }}
          onClick={() => dispatch(setDrawerCollapsed(!drawerCollapsed))}>
          <HamburgerIcon size={28} />
        </Col>
      )
    } else if (isMobile && match(routes.chat)) {
      return (
        <Col
          style={{ padding: 8, paddingRight: 8, paddingTop: 13, position: 'absolute', left: 8 }}
          onClick={() => dispatch(setConnectionDrawerCollapsed(!connectionDrawerCollapsed))}>
          <HamburgerIcon size={28} />
        </Col>
      )
    } else {
      return (
        <Link
          style={{
            minWidth: isMobile ? 0 : 275,
            position: 'absolute',
            left: isMobile ? 16 : 32,
            marginTop: 3,
          }}
          to={routes.home}>
          <Row>
            <LogoTitleIcon size={80} fill={palette.text.primary} />
          </Row>
        </Link>
      )
    }
  }, [isMobile, drawerCollapsed, connectionDrawerCollapsed, match])

  if (isLoading) {
    return <Container>{renderLeading()}</Container>
  }

  return (
    <Container>
      {renderLeading()}

      {!isMobile && isAuthenticated && (
        <InputContainer isFocused={isFocused}>
          <Input
            style={{ height: 48 }}
            inputStyle={{
              height: 48,
              borderRadius: 24,
              paddingLeft: 16,
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              borderWidth: 0,
            }}
            hasIcon="right"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={t('startYourSearch')}
            value={searchText}
            onChange={setSearchText}
            onEnterPress={() => onEnterPress(searchText)}>
            <SearchIcon fill={palette.text.secondary} />
          </Input>
        </InputContainer>
      )}

      {isAuthenticated ? (
        <RightContainer>
          <ButtonContainer
            onClick={() => navigate(routes.chat)}
            style={{
              cursor: 'pointer',
              position: 'relative',
              height: '100%',
              marginRight: 24,
              padding: 4,
              borderRadius: 30,
            }}>
            <ChatIcon fill={palette.text.secondary} size={36} />
            {!!unreadUpdateExists && <Badge />}
          </ButtonContainer>

          <ButtonContainer
            onClick={() => setShowUpdates(prev => !prev)}
            style={{
              cursor: 'pointer',
              position: 'relative',
              height: '100%',
              marginRight: 20,
              padding: 4,
              borderRadius: 30,
            }}>
            <AnnouncementIcon
              fill={showUpdates ? palette.primary : palette.text.secondary}
              size={36}
            />
            {!!unreadUpdateExists && <Badge />}
          </ButtonContainer>

          {showUpdates && <ActivityList onClose={() => setShowUpdates(false)} />}

          <DropdownWrapper
            onToggle={isOn => isOn && setShowUpdates(false)}
            disabled={!meUser}
            icon={
              <ButtonContainer>
                <HamburgerIcon style={{ marginLeft: 6, marginRight: 12 }} />
                <UserImage size={40} url={meUser?.image_url} />
              </ButtonContainer>
            }
            position={{ right: 0, top: 56 }}
            options={dropdownOptions}
            handleOptionClick={handleOptionsDropdownOptionClick}
          />
        </RightContainer>
      ) : (
        <RightContainer>
          <SecondaryButton
            title={t('login')}
            onClick={() => dispatch(setShowAuthentication(true))}
          />
        </RightContainer>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-width: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  height: 72px;
  justify-content: center;
  padding: 0px 32px;
  width: 100%;

  @media ${device.smallerThan.laptop} {
    padding: 0px 16px;
  }
`

const RightContainer = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 32px;
  user-select: none;

  @media ${device.smallerThan.laptop} {
    right: 16px;
  }
`

const Badge = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 9px;
  height: 10px;
  position: absolute;
  right: 4px;
  top: 8px;
  width: 10px;
`

const InputContainer = styled.div<{ isFocused: boolean }>`
  align-self: center;
  transition: width 0.2s ease-out;
  width: ${({ isFocused }) => (isFocused ? '400' : '320')}px;
`

const ButtonContainer = styled(Row)`
  border-radius: 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  padding: 4px;
  z-index: 3;

  ${() =>
    css`
      &:hover {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
      }
    `};
`
