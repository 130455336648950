import { PageLoader } from '../common/PageLoader'
import { LayoutWrapper } from '../layout/LayoutWrapper'
import { AnnouncementList } from './AnnouncementList'
import { CalendarModal } from './Calendar'
import { FAQList } from './FAQList'
import { MetaHeader } from './MetaHeader'
import { Polling } from './Polling'
import { QAQuestionList } from './QA'
import { ReminderModal } from './Reminder'
import { RoomChat } from './RoomChat'
import { RoomChatChannel } from './RoomChat/RoomChatChannel'
import { RoomHome } from './RoomHome'
import { RoomSideBar } from './RoomSideBar'
import { ShareModal } from './Share'
import { WebList } from './WebList'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { closeThread } from 'src/redux/reducers/chatChannel'
import { fetchSubscription } from 'src/redux/reducers/cover'
import { getEntities, getEntity, getFirstEntity } from 'src/redux/reducers/entity'
import {
  fetchComponents,
  fetchReferralLink,
  fetchRoom,
  getAccessCode,
  getRestrictedPrivacyType,
  setRestrictedRoomPrivacyType,
} from 'src/redux/reducers/room'
import { ChatChannel, Component, ComponentType, Room, Subscription } from 'src/repository/types'
import { useComponentObserver } from 'src/utils/hooks/useComponentObserver'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useRoomObserver } from 'src/utils/hooks/useRoomObserver'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RoomMain: React.FC<{
  roomId: string
  channelId: string | null
}> = ({ roomId, channelId }) => {
  // MARK: - Properties

  const generalItemCount = 1

  // MARK: - Hooks

  const dispatch = useDispatch()
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const { match, routes, navigate, location } = useNavigation()

  const { isMobile } = useWindowDimensions()
  const accessCode = useSelect(state => getAccessCode(state.room))
  const restrictedPrivacyType = useSelect(state => getRestrictedPrivacyType(state.room))
  const room = useSelect(state => getEntity<Room>(state.entity, 'room', roomId))
  const subscription = useSelect(state =>
    getFirstEntity<Subscription>(
      state.entity,
      'subscription',
      item => item.room_id === roomId && item.is_active,
    ),
  )
  const components = useSelect(state =>
    getEntities<Component>(
      state.entity,
      'component',
      component => component.room_id === roomId,
    ).sort((first, second) => first.order - second.order),
  )
  const roomRoutes: string[] = [
    routes.room(roomId).home,
    ...components.map(component => routes.room(roomId).component(component.id)),
  ]

  // MARK: - Effects

  useRoomObserver(roomId)
  useComponentObserver(roomId)

  useEffect(() => {
    if (roomId) {
      dispatch(fetchRoom(roomId, accessCode ?? undefined))
      dispatch(fetchComponents(roomId))
      dispatch(fetchReferralLink(roomId))
      dispatch(fetchSubscription(roomId))
    }
  }, [roomId, dispatch])

  useEffect(() => {
    if (restrictedPrivacyType) {
      navigate(routes.access(roomId, restrictedPrivacyType))
      dispatch(setRestrictedRoomPrivacyType(null))
    }
  }, [restrictedPrivacyType])

  // MARK: - Actions

  const handleActionClick = (action: 'addToCalendar' | 'setReminder' | 'share' | 'chat') => {
    if (action === 'addToCalendar') setShowCalendarModal(true)
    if (action === 'setReminder') setShowReminderModal(true)
    if (action === 'share') setShowShareModal(true)
    if (action === 'chat') navigate(routes.room(roomId).chat.home)
  }

  const handleSideBarItemClick = (option: number) => {
    dispatch(closeThread())

    if (option === 0) {
      navigate(routes.room(roomId).home)
    } else {
      const { id } = components[option - generalItemCount]
      navigate(routes.room(roomId).component(id))
    }
  }

  const handleChannelClick = (channel: ChatChannel | null) => {
    if (channel) navigate(routes.room(roomId).chat.channel(channel.id))
  }

  // MARK: - Render

  const renderComponents = useCallback(() => {
    if (!room) return null

    if (match(routes.room(roomId).home)) {
      return (
        <RoomHome
          room={room}
          onActionClick={handleActionClick}
          onComponentClick={({ id }) => navigate(routes.room(roomId).component(id))}
        />
      )
    } else if (channelId && match(routes.room(roomId).chat.channel(channelId))) {
      return (
        <RoomChatChannel
          channelId={channelId}
          onBackClick={() => navigate(routes.room(roomId).home)}
        />
      )
    } else if (match(routes.room(roomId).chat.home) && isMobile) {
      return (
        <RoomChat
          room={room}
          selectedChannelId={channelId}
          setSelectedChannel={handleChannelClick}
        />
      )
    } else {
      const option = roomRoutes.findIndex(route => match(route))
      const component = components[option - generalItemCount]
      switch (component?.type) {
        case ComponentType.announcements:
          return <AnnouncementList room={room} component={component} />
        case ComponentType.webLinks:
          return <WebList room={room} component={component} />
        case ComponentType.faq:
          return <FAQList room={room} component={component} />
        case ComponentType.qa:
          return <QAQuestionList room={room} component={component} />
        case ComponentType.polling:
          return <Polling room={room} component={component} />
        default:
          return null
      }
    }
  }, [components, location, room, channelId])

  if (!room) return <PageLoader />

  // Render Container
  return (
    <LayoutWrapper roomId={roomId}>
      <MetaHeader room={room} />

      <RoomSideBar
        room={room}
        selectedItem={roomRoutes.findIndex(route => match(route))}
        onSideBarItemClick={handleSideBarItemClick}
      />

      <InnerContainer>
        {renderComponents()}
        <ReminderModal
          room={room}
          subscription={subscription}
          visible={showReminderModal}
          onClose={() => setShowReminderModal(false)}
        />

        <CalendarModal
          visible={showCalendarModal}
          room={room}
          subscription={subscription}
          onClose={() => setShowCalendarModal(false)}
        />
        <ShareModal visible={showShareModal} room={room} onClose={() => setShowShareModal(false)} />
      </InnerContainer>

      {!isMobile && room.config.chat_enabled ? (
        <RoomChat
          room={room}
          selectedChannelId={channelId}
          setSelectedChannel={handleChannelClick}
        />
      ) : null}
    </LayoutWrapper>
  )
}

// MARK: - Styles

const InnerContainer = styled.div`
  flex: 1;

  @media ${device.largerThan.laptop} {
    max-height: 100vh;
  }
`
