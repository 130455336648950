import React from 'react'
import styled from 'styled-components'
import { Loader } from './Loader'

export const PageLoader: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <Container style={style}>
    <Loader />
  </Container>
)

// MARK: - Style

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 80px);
  justify-content: center;
  padding-bottom: 10%;
`
