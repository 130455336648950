import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Row } from './CommonStyles'
import { Loader } from './Loader'

export const FetchMoreButton: React.FC<{
  isLoading: boolean
  onClick: () => void
}> = ({ isLoading, onClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Button onClick={isLoading ? undefined : onClick}>
      {isLoading ? <Loader size={20} thickness={2} color={palette.primary} /> : t('loadMore')}
    </Button>
  )
}

// MARK: - Styles

const Button = styled(Row)`
  align-items: center;
  align-self: center;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  line-height: 34px;
  padding: 1px 16px;
  text-align: center;
  width: 180px;
`
