// MARK: - Request & Response

import { IError } from './Error'

export const success = <T>(value: T, retCode?: number): Success<T> => ({
  type: 'success',
  value: value,
  retCode: retCode,
  success: true,
})

export const failure = (error: IError, retCode?: number): Failure => ({
  type: 'failure',
  error: error,
  retCode: retCode,
  success: false,
})

export type BecomePublisherForm = {
  id: string
  email: string
  audience_size: number
  job_description: string
  links_accounts: string
  target_industry: string
  is_active: boolean
  created_at: number
  updated_at: number
}

export type TeamMember = {
  id: string
  team_id: string
  user: User
  access_level: 'admin' | 'editor' | 'restricted' | 'guest'
}

export type Team = {
  id: string
  name: string
  owner_id: string
}

export type Collaborator = {
  id: string
  user: User
  access_level: 'admin' | 'editor' | 'restricted' | 'guest'
  accessible_component_ids: string[]
}

export type RoomType = 'event' | 'meeting' | 'product_launch' | 'nft_drop' | 'other'

export type Room = {
  id: string
  title: string
  overview: string
  start_date: number
  end_date: number
  timezone: string
  privacy_type: RoomPrivacyType
  short_link: string

  publisher_id: string
  status: RoomStatus
  publish_date: number
  purchase_ids: string[]

  main_media: { url: string; offset: number }
  location: RoomLocation

  links: Link[]
  access_codes: string[]
  tags: string[]
  config: RoomConfiguration

  created_at: number
  updated_at: number
  is_active: boolean
}

export type RoomConfiguration = {
  chat_enabled: boolean
  share_enabled: boolean
  see_more_enabled: boolean
  banners: { link: string; image_url: string }[]
}

export type RoomStatus = 'creating' | 'published'
export type RoomPrivacyType = 'public' | 'private' | 'managed' | 'invite-only'
export enum LinkType {
  facebook = 1,
  twitter,
  instagram,
  email,
  linkedIn,
  website,
  youtube,
  zoom,
}

export type Link = {
  id: string
  type: LinkType
  name: string
  url: string
}

export type RoomLocation = {
  address: string
  title: string
  latitude: number
  longitude: number
  province: string
  city: string
  country: string
  zip_code: number
}

export type Component = {
  id: string
  title: string
  overview: string
  order: number
  type: number
  room_id: string
  image_url: string
  has_badge: boolean
  updated_at: number
  created_at: number
  is_active: boolean
}

export type Subscription = {
  id: string
  user_id: string
  referrer_id: string | null
  room_id: string
  event_id: string
  push_date: number
  notification_enabled: boolean
  notification_components: string[]
  notification_channels: string[]
  is_active: boolean
  created_at: number
  updated_at: number
}

export type Announcement = {
  id: string
  type: string
  room_id: string
  component_id: string

  title: string
  overview: string
  image_url: string
  image_offset: number
  publish_date: number
  is_scheduled: boolean
  notify_users: boolean
  has_detail: boolean
  link: string
  action_name: string

  created_at: number
  updated_at: number
  is_active: boolean
}

export type Template = {
  id: string
  image_url: string
  overview: string
  title: string
  publisher_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type AnnouncementList = {
  id: string
  component_id: string
  room_id: string
  is_notification_enabled: boolean
  created_at: number
  updated_at: number
  is_active: boolean
}

export type ComponentExchange = {
  id: string
  room_id: string
  component_id: string
  user_id: string
  push_enabled: boolean
  favorite: boolean
  notes: string
  updated_at: number
}

export type WebPage = {
  id: string
  component_id: string
  room_id: string
  url: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type SpreadGame = {
  id: string
  room_id: string
  component_id: string
  events: SpreadGameEvent[]
  point_table: SpreadGamePointTableItem[]
  black_list: string[]
  created_at: number
  updated_at: number
  is_active: boolean
}

export type SpreadGameScore = {
  id: string
  user_id: string
  game_id: string
  score: number
  updated_at: number
}

export type SpreadGamePointTableItem = {
  id: string
  name: string
  event: SpreadGameEvent
  point: number
}

export enum SpreadGameEvent {
  subscribe = 'common.room_detail.room_overview.subscribed',
  addCalendarEvent = 'common.room_detail.room_overview.calendar_event_created',
  setPushNotification = 'common.room_detail.room_overview.push_notification_created',
}

export type User = {
  id: string
  firebase_uid: string
  auth_types: string[]
  email: string
  phone_number?: string
  bio: string
  first_name: string
  last_name: string
  user_name: string
  image_url: string
  notification_token: string
  cover_image: string
  cover_image_offset: number
  links: Link[]
  created_at: number
  updated_at: number
}

export type TokenContainer = {
  idToken: string
  refreshToken: string
  expiresIn: string
  localId: string
}

export type TokenHolder = {
  id_token: string
  refresh_token: string
  user_id: string
}

export type RefreshTokenContainer = {
  access_token: string
  expires_in: string
  token_type: string
  refresh_token: string
  id_token: string
  user_id: string
  project_id: string
}

export type Broadcasting = {
  id: string
  broadcaster_id: string
  room_id: string
  publisher_id: string
  broadcasted_at: number
  broadcasting_end_at: number
  latitude: number
  longitude: number
  distance: number
  track_id: string
  radius: number
  count: number
  origin: { latitude: number; longitude: number }
  created_at: number
  updated_at: number
  is_active: boolean
  location: {
    address: string
    title: string
    latitude: number
    longitude: number
    province: string
    city: string
    country: string
    zip_code?: number
  }
}

export type Invitation = {
  id: string
  email: string
  notes: string
  first_name: string
  last_name: string
  room_id: string
  status: 'accepted' | 'pending'
  notify_on_accept: boolean
  invitation_type: 'member' | 'collaborator'
  notify_on_accept_email: string
  invited_by_id: string
  invited_user_id: string
  created_at: number
}

export type InvitationRequest = {
  id: string
  user_id: string
  room_id: string
  email: string
  image_url: string
  first_name: string
  last_name: string
  notes: string
  created_at: number
  invitation_type: 'subscriber' | 'collaborator'
}

export type InvitationGroup = {
  id: string
  name: string
  overview: string
  invitation_ids: string[]
  publisher_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type RoomStatistics = {
  room_id: string
  calendar_event_count: number
  local_push_count: number
  subscription_count: number
  spread_count: number
  updated_at: number
}

export type Discovery = {
  id: string
  user_id: string
  room_id: string
  discovered_at: number
  created_at: number
  updated_at: number
  is_active: boolean
}

export type UserSettings = {
  id: string
  auto_set_notification: boolean
  auto_set_calendar_event: boolean
  updated_at: number
}

export type Me = {
  id: string
  user: User
}

export type AuthType =
  | { type: 'email'; email: string; password: string }
  | { type: 'phone'; phone: string; password: string }
  | { type: 'google' }
  | { type: 'facebook' }
  | { type: 'apple' }

export type ThirdPartyToken = {
  idToken: string
  accessToken: string
}

export type AuthUser = {
  id: string | undefined
  userName: string | undefined
  firebaseToken: string | undefined
  thirdPartyToken: ThirdPartyToken
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  phoneNumber: string | undefined
  imageUrl: string | undefined
  isPublisher: boolean
  authType: AuthType
  nonce: string | undefined
}

export enum ComponentType {
  announcements = 0,
  webPage = 1,
  spreadGame,
  list,
  webLinks,
  ordering,
  sponsors,
  chat,
  polling,
  qa,
  faq,
}

export type ComponentTypeKey =
  | 'announcement_list'
  | 'web_page'
  | 'spread_game'
  | 'list'
  | 'web_links'
  | 'ordering'
  | 'sponsors'
  | 'chat'
  | 'polling'
  | 'qa'
  | 'faq'

export const componentTypeKey = (type: ComponentType): ComponentTypeKey => {
  switch (type) {
    case ComponentType.announcements:
      return 'announcement_list'
    case ComponentType.webPage:
      return 'web_page'
    case ComponentType.spreadGame:
      return 'spread_game'
    case ComponentType.list:
      return 'list'
    case ComponentType.webLinks:
      return 'web_links'
    case ComponentType.ordering:
      return 'ordering'
    case ComponentType.sponsors:
      return 'sponsors'
    case ComponentType.chat:
      return 'chat'
    case ComponentType.polling:
      return 'polling'
    case ComponentType.qa:
      return 'qa'
    case ComponentType.faq:
      return 'faq'
  }
}

export type TrackedUrl = {
  id: string
  room_id: string
  component_id: string
  url: string
  count: number
  name: string
  notify_me_limit: number
  notify_me_email_address: string
  created_at: number
}

export type Notification = {
  id: string
  room_id: string
  publisher_id: string
  title: string
  overview: string
  image_url: string
  image_offset: number
  publish_date: number
  is_scheduled: boolean
  link: string
  feature_id: string
  updated_at: number
  created_at: number
}

export type WebList = {
  id: string
  component_id: string
  room_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type WebListItem = {
  id: string
  room_id: string
  component_id: string
  title: string
  overview: string
  image_url: string
  link: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type FAQList = {
  id: string
  component_id: string
  room_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type FAQ = {
  id: string
  room_id: string
  component_id: string
  question: string
  answer: string
  links: string[]
  created_at: number
  updated_at: number
  is_active: boolean
}

// Chat channel

export type ChatChannelType = 'general' | 'topic' | 'oneToOne' | 'help' | 'thread'

export type ChatChannel = {
  id: string
  room_id: string
  image_url: string
  name: string
  type: ChatChannelType
  member_ids: string[]
  description: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type ChatChannelMessageType = 'text' | 'sound' | 'sticker'
export type ChatChannelMessageAttachmentType = 'image' | 'giphy' | 'video' | 'file'
export type ChatChannelMessageGroupType = 'single' | 'bottom' | 'middle' | 'top'

export type ChatChannelMessageAttachment = {
  id: string
  message_id: string
  type: ChatChannelMessageAttachmentType
  asset_url: string
  asset_thumb_url: string
}

export type AnyChatMessage = ChatChannelMessage | ChatMessage
export type AnyChatMessageWithDate = ChatChannelMessage | ChatMessage | ChatMessageListDate

export type ChatChannelMessage = {
  id: string
  user_id: string
  local_id: string
  channel_id: string
  is_thread: boolean
  group_type: ChatChannelMessageGroupType
  type: ChatChannelMessageType
  text: string
  parent_id: string | null
  quoted_id: string | null
  replies?: Record<string, { user_id: string; created_at: number }>
  reactions?: { user_id: string; reaction: string; created_at: number }[]
  mentions?: string[]
  attachments?: ChatChannelMessageAttachment[]
  created_at: number
  updated_at: number
}

export type QA = {
  id: string
  room_id: string
  component_id: string
  anonymous_enabled: boolean
  created_at: number
  updated_at: number
  is_active: boolean
}

export type QAQuestionState = 'pending' | 'answered' | 'rejected'

export type QAQuestion = {
  id: string
  component_id: string
  user_id: string
  is_anonymous: boolean
  question: string
  answer: string | null
  state: QAQuestionState
  created_at: number
  updated_at: number
  is_active: boolean
}

export type Polling = {
  id: string
  room_id: string
  component_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type Poll = {
  id: string
  question: string
  component_id: string
  options: PollOption[]
  created_at: number
  updated_at: number
  is_active: boolean
  votes: Record<string, number> | null
}

export type PollOption = {
  id: string
  order: number
  answer: string
}

export type PollVote = {
  id: string
  component_id: string
  poll_id: string
  user_id: string
  option_id: string
}

export type CoverImage = {
  thumb: string
  url: string
  download_location: string
  ref: {
    name: string
    portfolio: string
  }
}

export type Friendship = {
  id: string
  target_user_id: string
  source_user_id: string
  status: 'pending' | 'accepted' | 'rejected'
  updated_at: number
  created_at: number
}

export type ActivitySection = 'inbox' | 'archived'

export type Activity = {
  id: string
  user_id: string
  context_id: string | null
  section: ActivitySection
  updated_at: number
  created_at: number
  status: 'read' | 'unread'
  payload:
    | { type: 'request_friendship'; friendship_id: string }
    | { type: 'accept_friendship_request'; friendship_id: string }
}

export type ImageOrientation = 'landscape' | 'portrait' | 'squarish'

// Chat

export type ChatMessageListDate = {
  timestamp: number
  title: string
}

export type ChatMessageReaction = { user_id: string; reaction: string; created_at: number }
export type ChatMessageType = 'text' | 'image' | 'video' | 'audio' | 'event'
export type ChatType = 'oneToOne' | 'group'

export type Chat = {
  id: string
  type: ChatType
  member_ids: string[]
  last_message?: { created_at: number; text: string }
  unread_message_ids: Record<string, string[]>
  updated_at: number
}

type ChatMessageAttachment = {
  id: string
  type: 'place' | 'movie'
  title: string
  description: string
  image_url?: string
  image_aspect_ratio?: number
  user_name?: string
  user_id?: string
  user_photo?: string
}

export type ChatMessage = {
  _id: string | number
  id: string
  text: string
  chat_id: string
  updated_at: number
  created_at: number
  createdAt: Date | number
  user: { _id: string | number }
  type: ChatMessageType
  image?: string
  video?: string
  audio?: string
  system?: boolean
  sent?: boolean
  received?: boolean
  pending?: boolean
  deleted?: boolean
  replied?: ChatMessage | null
  asset_aspect_ratio?: number
  group_type: ChatChannelMessageGroupType
  attachments?: ChatMessageAttachment[]
  reactions: ChatMessageReaction[]
}
