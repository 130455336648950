import React, { useState } from 'react'
import classNames from 'classnames'
import { FormLabel } from './Input/FormLabel'
import { FormHint } from './Input/FormHint'
import styled, { useTheme } from 'styled-components'

export const Select: React.FC<{
  label?: string
  labelHidden?: boolean
  style?: React.CSSProperties
  type?:
    | 'textarea'
    | 'text'
    | 'email'
    | 'tel'
    | 'password'
    | 'number'
    | 'search'
    | 'color'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'phone'
  name?: string
  status?: 'error' | 'success' | 'default'
  disabled?: boolean
  value?: string
  formGroup?: string
  hasIcon?: string
  size?: 'default' | 'sm'
  placeholder?: string
  rows?: number
  hint?: string
  className?: string
  isRequired?: boolean
  onChange?: (value: string) => void
}> = ({
  className = 'form-dark',
  children,
  label = '',
  style,
  labelHidden = false,
  type = 'text',
  name,
  status = '',
  disabled = false,
  value = '0',
  formGroup = null,
  hasIcon = null,
  size = 'sm',
  placeholder = '',
  rows = 3,
  hint = null,
  isRequired = false,
  onChange,
  ...props
}) => {
  const { palette, isDark } = useTheme()
  const classes = classNames(
    'form-select',
    'br-8',
    size && `form-select-${size}`,
    isDark ? 'form-select-dark' : 'form-select-light',
    status && `form-${status}`,
    className,
    'light-select',
  )
  const [focused, setFocused] = useState(false)

  return (
    <Container style={style}>
      {label && (
        <FormLabel labelHidden={labelHidden} id={''} isRequired={isRequired}>
          {label}
        </FormLabel>
      )}
      <select
        {...props}
        className={classes}
        name={name}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        style={{
          paddingRight: 30,
          fontSize: 15,
          borderColor: focused ? palette.primary : palette.background.separator,
          backgroundColor: disabled ? palette.background.disabled : palette.background.primary,
          color: disabled ? palette.text.secondary : palette.text.primary,
        }}
        onChange={event => {
          const text = event.target.value as string
          onChange?.(text)
        }}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}>
        {placeholder && (
          <option disabled value={'0'}>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {hint && <FormHint status={status}>{hint}</FormHint>}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  flex: 1;
`
