import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path d="M4 21V5" stroke={fill} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20 15.6867C14.1818 20.2361 9.81818 11.1373 4 15.6867V4.31329C9.81818 -0.236079 14.1818 8.86266 20 4.31329V15.6867Z"
      stroke={fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const FlagIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
