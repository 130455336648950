import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 12, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 4 12" fill="none">
    <path
      d="M3.19749 10.4791C3.19749 11.1401 2.66049 11.6771 2.00049 11.6771C1.34049 11.6771 0.80249 11.1401 0.80249 10.4791C0.80249 9.81812 1.34049 9.28112 2.00049 9.28112C2.66049 9.28112 3.19749 9.81812 3.19749 10.4791Z"
      fill={fill}
    />
    <path
      d="M3.19749 5.99963C3.19749 6.66063 2.66049 7.19763 2.00049 7.19763C1.34049 7.19763 0.80249 6.66063 0.80249 5.99963C0.80249 5.33863 1.34049 4.80163 2.00049 4.80163C2.66049 4.80163 3.19749 5.33863 3.19749 5.99963Z"
      fill={fill}
    />
    <path
      d="M3.19749 1.52063C3.19749 2.18163 2.66049 2.71863 2.00049 2.71863C1.34049 2.71863 0.80249 2.18163 0.80249 1.52063C0.80249 0.859628 1.34049 0.322628 2.00049 0.322628C2.66049 0.322628 3.19749 0.859628 3.19749 1.52063Z"
      fill={fill}
    />
  </svg>
)

export const ThreeDotsIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
