import React from 'react'
import { Room } from 'src/repository/types'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import { ShareDesktop } from './ShareDesktop'
import { ShareMobile } from './ShareMobile'

export const ShareModal: React.FC<{
  room: Room
  visible: boolean
  onClose: () => void
}> = props => {
  // MARK: - Hooks

  const { isMobile } = useWindowDimensions()

  // MARK: - Render

  return isMobile ? <ShareMobile {...props} /> : props.visible ? <ShareDesktop {...props} /> : null
}
