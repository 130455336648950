import { appReducer } from './reducers/app'
import { chatReducer } from './reducers/chat'
import { entityReducer } from './reducers/entity'
import { meReducer } from './reducers/me'
import { roomReducer } from './reducers/room'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkAction } from 'redux-thunk'
import { activityReducer } from 'src/redux/reducers/activity'
import { authenticationReducer } from 'src/redux/reducers/authentication'
import { chatChannelReducer } from 'src/redux/reducers/chatChannel'
import { roomCoverReducer } from 'src/redux/reducers/cover'
import { profileReducer } from 'src/redux/reducers/profile'
import { roomAccessReducer } from 'src/redux/reducers/roomAccess'
import { roomChatReducer } from 'src/redux/reducers/roomChat'
import { searchReducer } from 'src/redux/reducers/search'

export type Action<T> = { type: T }

export type RootState = ReturnType<typeof rootReducer>
// @ts-ignore
export type Thunk<U = Action<any>, T = void> = ThunkAction<T, RootState, unknown, U>

export type AsyncThunk<U = Action<any>, ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  // @ts-ignore
  U
>
const rootReducer = combineReducers({
  app: appReducer,
  entity: entityReducer,
  me: meReducer,
  access: roomAccessReducer,
  authentication: authenticationReducer,
  chat: chatReducer,
  chatChannel: chatChannelReducer,
  profile: profileReducer,
  roomChat: roomChatReducer,
  roomCover: roomCoverReducer,
  room: roomReducer,
  search: searchReducer,
  activity: activityReducer,
})

const useDevTools = true
let store = createStore(rootReducer, applyMiddleware(thunk))

if (process.env.NODE_ENV !== 'production' && useDevTools) {
  // @ts-ignore
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
}

export default store
