import { ShareInvite } from './ShareInvite'
import { ShareMethodListItem } from './ShareMethodListItem'
import { ShareQRCode } from './ShareQRCode'
import { ShareMethod, shareMethodIcons, shareMethods } from './types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { FlexCol, FlexRow, LabelHeader } from 'src/features/common/CommonStyles'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { getReferralLink } from 'src/redux/reducers/room'
import { Room } from 'src/repository/types'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const ShareDesktop: React.FC<{
  visible: boolean
  room: Room
  onClose: () => void
}> = ({ room, visible, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [selectedMethod, setSelectedMethod] = useState<ShareMethod | null>(null)
  const referralLink = useSelect(state => getReferralLink(state.room) ?? room.short_link)
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const methods = isAuthenticated
    ? shareMethods
    : shareMethods.filter(method => method !== 'invite')

  const title = () => {
    if (selectedMethod === 'qrCode') return t('qrCode')
    else if (selectedMethod === 'invite') return t('invite')
    else return t('share')
  }

  // MARK: - Hooks

  useEffect(() => {
    setSelectedMethod(null)
  }, [visible])

  useEffect(() => {
    if (selectedMethod === 'copyLink') {
      navigator.clipboard.writeText(referralLink)
      toast.success(t('copiedToClipboard'))
      onClose()
    }
  }, [selectedMethod])

  // MARK: - Render

  return (
    <ModalWrapper height="dynamic" size="tiny" closeOnBackgroundClick onClose={onClose}>
      <Container>
        <LabelHeader style={{ marginBottom: 16 }}>{title()}</LabelHeader>

        {selectedMethod === 'qrCode' && (
          <ShareQRCode room={room} onClose={() => setSelectedMethod(null)} />
        )}

        {selectedMethod === 'invite' && (
          <ShareInvite room={room} onClose={() => setSelectedMethod(null)} onSend={onClose} />
        )}

        {selectedMethod !== 'qrCode' && selectedMethod !== 'invite' && (
          <InnerContainer>
            {methods.map(method => (
              <ShareMethodListItem
                key={method}
                title={t(method)}
                icon={shareMethodIcons(room, referralLink)[method]}
                onMethodClick={() => setSelectedMethod(method)}
              />
            ))}
          </InnerContainer>
        )}
      </Container>
    </ModalWrapper>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  flex-wrap: wrap;
  max-width: 100%;
  padding: 16px 24px 40px 24px;
`

const InnerContainer = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0px -5%;
  max-width: 110%;
`
