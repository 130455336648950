const tr = {
  time: 'Thu, Oct 15, 2020, 10:23 AM',
  translation: {
    lobbi: 'Lobbi',
    search: 'Ara',
    cancel: 'İptal',
    ok: 'Tamam',
    save: 'Kaydet',
    oopps: 'Oopps!',
    somethingWentWrong: 'Oops! Bir şeyler yolunda gitmedi.',
    profileImage: 'Profil Resmi',
    language: 'Dil',
    name: 'İsim',
    surname: 'Soy İsim',
    userName: 'Kullanıcı Adı',
    email: 'Email',
    readMore: '...daha fazla',
    showLess: 'daha az',
    home: 'Ana Sayfa',
    theme: 'Tema',
    reportRoom: 'Şikayet et',
    notifications: 'Bildirimler',
    settings: 'Ayarlar',
    features: 'Özellikler',
    account: 'Hesap',
    accountSettingsDescription: 'Hesap ayarlarınızı yönetin.',
    youLookNiceToday: 'Bugün iyi görünüyorsun.',
    youLookNiceTonight: 'Bu gece iyi görünüyorsun.',
    languageSettingsDescription: 'Oda dilini değiştirin.',
    reportRoomDescription:
      'Uygun olmayan davranışlar gösteren etkinlikleri veya kullanıcıları raporlayın.',
    seeYouAround: 'Görüşmek üzere 😢',
    logoutMessage: 'Hesabınızdan çıkış yapmak istediğinize emin misiniz?',
    logout: 'Çıkış Yap',
    dragDrop: 'Resim eklemek için Sürekle & Bırak veya Tıkla. {{ratio}}',
    imageSizeLimit: "JPEG ve PNG, 10MB'e kadar.",
    general: 'Genel',
    noAnnouncementsPostedYet: 'Henüz bir duyuru yayınlanmadı',
    oncePublisherPostedAnnouncements:
      'Yöneticiler duyuru yayınladığında, duyuruları burada görebilirsiniz',
    private: 'Özel',
    channels: 'Kanallar',
    loadMore: 'Daha Fazla',
    noMessagesSent: 'Henüz kanala mesaj gönderilmedi',
    noMessagesSentDescription: 'Bu kanala ilk mesajı siz gönderebilirsiniz',
    chatInputPlaceholder: 'Mesaj gönder...',
    noQAQuestionsPostedYet: 'Henüz soru sorulmadı',
    oncePublisherPostedQAQuestions:
      'Sorunuzu göndererek yöneticilere ilk soruyu siz sorabilirsiniz.',
    askQuestion: 'Soru sor...',
    delete: 'Sil',
    qaQuestionPending: 'Soru en kısa zamanda cevaplanmak üzere bekliyor.',
    qaQuestionAnswered: 'Soru etkinlik yöneticileri tarafından cevaplandı.',
    qaQuestionRejected: 'Soru etkinlik yöneticileri tarafından reddedildi.',
    answer: 'Cevap',
    calendar: 'Takvim',
    reminder: 'Hatırlatıcı',
    share: 'Paylaş',
    links: 'Bağlantılar',
    seeMore: 'Daha Fazla',
    continue: 'Devam et',
    logInOrSignUp: 'Giriş yap veya üye ol',
    welcomeToLobbi: "Lobbi'e hoşgeldiniz",
    continueWith: '{{provider}} ile devam et',
    login: 'Giriş yap',
    chats: 'Sohbetler',
    password: 'Şifre',
    forgotPassword: 'Şifremi unuttum?',
    passwordResetEmailSent: "Şifre sıfılama bağlantısı {{email}}'a gönderildi",
    tooManyTrials:
      'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
    termsOfService: 'Kullanım Koşulları',
    agreeAndContinue: 'Kabul ve Devam Et',
    privacyPolicy: 'Gizlilik Sözleşmesi',
    termsConditionsInfo:
      "Kabul ve Devam Et'i seçerek, Lobbi'in Kullanım Koşulları'nı ve Gizlilik Sözleşmesi'ni kabul ediyorum.",
    firstName: 'İsim',
    lastName: 'Soy İsim',
    authContinue: 'Giriş yap veya kayıt ol',
    authPassword: 'Şifrenizi girin',
    authRegister: 'Kayıdınızı tamamlayın',
    or: 'veya',
    beforeStartDate: 'Başlangıçtan {{time}} önce',
    afterStartDate: 'Başlangıçtan {{time}} sonra',
    dateTime: 'Tarih & Saat',
    setReminder: 'Hatırlatıcı Kur',
    set: 'Kur',
    update: 'Güncelle',
    remove: 'Sil',
    done: 'Tamam',
    clear: 'Temizle',
    minute: '{{minute}} dakika',
    minutes: '{{minute}} dakika',
    hour: '{{hour}} saat',
    hours: '{{hour}} saat',
    day: '{{day}} gün',
    days: '{{day}} gün',
    week: '{{week}} hafta',
    weeks: '{{week}} hafta',
    allowNotifications: 'Bildirimleri etkinleştir',
    allowNotificationsDescription:
      'Bildirimlere izin vererek en son haberlerden ilk siz haberdar olun.',
    reminderSetMessage: 'Hatırlatıcınız başarıyla oluşturuldu.',
    reminderUpdateMessage: 'Hatırlatıcınız başarıyla güncellendi.',
    reminderRemoveMessage: 'Hatırlatıcınız başarıyla silindi.',
    leaveRoom: 'Odadan Ayrıl',
    leaveRoomDescription: 'Sizi özleyeceğiz, görüşmek üzere',
    leaveRoomAlertDescription:
      'Odadan ayrıldığınızda, etkinliğe ilişkin bildirim ve takvim ayarlarınız kaybolacaktır. Etkinlikten çıkmak istediğinizden emin misiniz?',
    reminderTime: 'Tarih ve saat',
    copyLink: 'Link Kopyala',
    invite: 'Davet Gönder',
    qrCode: 'QR Kodu',
    twitter: 'Twitter',
    instagram: 'Instagram',
    whatsapp: 'Whatsapp',
    more: 'Diğer',
    copiedToClipboard: 'Panoya kopyalandı!',
    start: 'Başlangıç',
    waitingRoom: 'Lobi',
    back: 'Geri',
    download: 'İndir',
    notes: 'Notlar',
    send: 'Gönder',
    invitationSent: 'Davetiyeniz başarı ile gönderildi!',
    invalidName: 'Geçersiz isim',
    invalidLastName: 'Geçersiz soy isim',
    invalidEmailAddress: 'Geçersiz email adresi',
    addToCalendar: 'Takvime Ekle',
    invitationRequestSent: 'Davet isteği gönderildi',
    invitationRequest: 'Davet isteği',
    sendRequest: 'İstek gönder',
    inviteOnlyRoom: 'Davetiye Zorunlu Oda',
    inviteOnlyRoomDescription:
      'Odaya erişmek için davet isteği göndermeniz gerekiyor. Oda moderatörleri en kısa sürede size davetiye gönderecektir.',
    invitationRequestSentDescription:
      'Davet talebiniz başarıyla gönderildi. Oda moderatörleri size bir davetiye gönderdiğinde devam edebilirsiniz.',
    privateRoom: 'Gizli Oda',
    accessCode: 'Erişim Kodu',
    privateRoomDescription:
      'Gizli lobilere katılmak için erişim kodunu girmeniz gerekmektedir. Erişim kodunu lobi moderatörlerinden talep edebilirsiniz.',
    managedRoom: 'Yönetilen Oda',
    managedRoomDescription:
      'Yönetilen lobilere erişim sadece yönetici platform tarafından sağlanmaktadır. Odaya katılmak için lütfen yönetici platform ile iletişime geçiniz.',
    noChannelsCreated: 'Henüz kanal oluşturulmadı',
    noChannelsCreatedDescription:
      'Oda moderatörleri kanal oluşturduğunda kanallar burada görünecek.',
    notificationPermission: 'Bildirim İzni',
    notificationPermissionDescription:
      "Tarayıcı ayarlarından Lobbi'a bildirim izni vermeniz gerekiyor.",
    configureNotifications: 'Bildirim tercihleri',
    configureNotificationsDescription:
      'Bildirim tercihlerinizi düzleneyerek odağınızı koruyabilirsiniz.',
    components: 'Özellikler',
    generalChatChannel: 'Genel',
    generalChatChannelDescription: 'Oda geneli duyurlar ve mesajlaşma',
    unknownUser: 'Bilinmeyen kullanıcı',
    help: 'Yardım',
    noActiveFeatures: 'Aktif özellik yok',
    noActiveFeaturesDescription:
      'Bildirim yapılandırması için etkin özellik yok. Odadan yalnızca genel bildirimler alacaksınız.',
    resetPassword: 'Şifre sıfırla',
    directMessages: 'Direkt Mesajlar',
    searchInLobbi: "Lobbi'de ara",
    accounts: 'Hesaplar',
    rooms: 'Odalar',
    'invite-only': 'Davetiyeli',
    public: 'Genel',
    managed: 'Yönetilen',
    noRoomFound: 'Oda bulunamadı',
    noRoomFoundDescription:
      '{{query}} için sonuç bulunamadı, lütfen sorgunuzu kontrol edin veya başka bir sorgu yazın.',
    viewProfile: 'Profili Gör',
    type: 'Tip',
    displayName: 'Görünüm ismi',
    bio: 'Hakkında',
    information: 'Bilgi',
    addNew: 'Ekle',
    profile: 'Profil',
    editAccount: 'Ayarlar',
    darkTheme: 'Karanlık Mod',
    lightTheme: 'Aydınlık Mod',
    changeCover: 'Resmi değiştir',
    reposition: 'Konumladır',
    savePosition: 'Konumu kaydet',
    dragImageToReposition: 'Konumlandırmak için sürükleyin',
    gallery: 'Galeri',
    upload: 'Yükle',
    link: 'Bağlantı',
    unsplash: 'Unsplash',
    chooseAnImage: 'Bir resim seçin',
    imagesWiderThan: '1500 pikselden daha geniş görüntüler en iyi sonucu verir.',
    imagesLowerThan: "Dosya başına maksimum boyut 10 mb'dir.",
    worksWithAnyImage: "Web'den herhangi bir resim ile çalışır.",
    pasteAnImageLink: 'Bir resim bağlantısı yapıştırın...',
    submit: 'Gönder',
    searchAnImage: 'Bir resim arayın...',
    memberSince: "{{date}}'den bu yana üye",
    edit: 'Düzenle',
    upcomingEvents: 'Yaklaşan etkinlikler',
    allEvents: 'Tüm etkinlikler',
    pastEvents: 'Geçmiş etkinlikler',
    ownedRooms: 'Moderatör',
    addFriend: 'Arkadaş ekle',
    joinRoom: 'Odaya Katıl',
    agenda: 'Ajanda',
    pending: 'Beklemede',
    following: 'Takip ediliyor',
    unfriend: 'Arkadaşı sil',
    accept: 'Kabul et',
    noRoomsJoined: '{{name}} henüz bir etkinliğe katılmadı',
    noRoomsJoinedDescription: '{{name}} bir etkinliğe katıldığında burada görünecek',
    noRoomsJoinedPast: '{{name}} henüz geçmiş etkinliğe katılmadı',
    noRoomsJoinedPastDescription: '{{name}} geçmiş bir etkinliğe katıldığında burada görünecek',
    noRoomsModerated: '{{name}} henüz bir etkinliği yönetmedi',
    noRoomsModeratedDescription: '{{name}} bir etkinliği yönetmeye başladığında burada görünecek',
    friends: 'Arkadaşlar',
    followings: 'Takip Edilen',
    report: 'Şikayet Et',
    reported: 'Kullanıcı başarı ile şikayet edildi.',
    unfriendDescription: "{{name}}'i arkadaşlıktan çıkarmak istediğine emin misiniz?",
    removeFriend: 'Çıkar',
    noFriends: '{{name}} henüz bir arkadaş eklemedi',
    noFriendsDescription: '{{name}} bir arkadaş eklediğinde burada görünecek',
    inbox: 'Gelen Kutusu',
    archived: 'Arşiv',
    sentYouFriendRequest: 'size bir arkadaşlık isteği gönderdi.',
    archiveAll: 'Tümünü Arşivle',
    noUpdates: 'Gelen kutunusu boş',
    noUpdatesDescription:
      'Birisi arkadaşlık isteği veya davet gönderdiğinde buradan bilgilendirileceksiniz.',
    noArchives: 'Arşivlenmiş güncelleme yok',
    noArchivesDescription:
      'Güncellemeleri arşivlediğinizde, gelecekteki erişimler için burada depolanacaklar.',
    acceptedYourFriendRequest: 'arkadaşlık isteğini kabul etti.',
    startYourSearch: 'Aramaya başla...',
    startYourDiscovery: 'Keşfe başlayın',
    becomeAPublisher: 'Yönetici ol',
    events: 'Etkinlikler',
    meetings: 'Buluşmalar',
    productLaunches: 'Ürün Lansmanları',
    nftDrops: 'NFT Yayınları',
    other: 'Diğer',
    today: 'Bugün',
    yesterday: 'Dün',
    thread: 'Thread',
    replyPlaceholder: 'Cevapla...',
    repliesCount: '{{count}} cevap',
    replyCount: '{{count}} cevap',
    viewThread: "Thread'ı görüntüle",
    lastReplyTime: 'Son cevap {{time}} önce',
    timeAgo: '{{time}} önce',
    you: 'Siz',
    searchConnections: 'Arkadaş veya sohbet arayın...',
    moderators: 'Yöneticiler',
    members: 'Üyeler',
    searchMembers: 'Üye ara...',
    view_profile: 'Profili gör',
    join_event: 'Etkinliğe katıl',
    join_event_description: 'Bu etkinlik yayında. Şimdi Katıl:',
    notificationsDescription: 'Information posted by room moderators',
    generalSettings: 'General Ayarlar',
    phoneNumber: 'Telefon numarası',
    enterYourEmail: 'hello@lobbi.events',
    enterYourPhoneNumber: '+1 111 111 1111',
    verificationCode: 'Onay kodu',
    deleteAccountWarning: 'Hesabınızı silmek mi istiyorsunuz?',
    dangerZone: 'Tehlikeli bölge',
    yes: 'Evet',
    deleteAccount: 'Hesabı sil',
    deleteMessage:
      'Hesabınızı silmek istediğinize emin misiniz? Hesabınız silindikten sonra geri aktive edilemez.',
    discard: 'Vazgeç',
    typeAMessage: 'Mesajınızı yazın...',
    contactToModerator: 'Moderatöre mesaj at',
    goToRoom: 'Odaya git',
    noWebListItemsPostedYet: 'Henüz bir bağlantı eklenmedi',
    oncePublisherPostedWebListItems:
      'Yöneticiler listeye bağlantı eklediğinde, bağlantılar burada listenecek.',
    noFAQsPostedYet: 'Henüz bir soru eklenmedi',
    oncePublisherPostedFAQs:
      'Yöneticiler sıkça sorulan soru eklediğinde, sorular cevapları ile beraber burada listenecek.',
    noPollingPostedYet: 'Henüz bir soru eklenmedi',
    oncePublisherPostedPolling:
      'Yöneticiler ankete soru eklediğinde, anketi buradan doldurabilirsiniz.',
    audience: 'Katılımcılar',
  },
}

export default tr
